import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import * as turf from "@turf/turf";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import Search from "react-leaflet-search";
import { getItineraryDistance } from "./CaculateDistances";
import DriveTimeCalculation from "./DriveTimeCalculation";
import "./styles.css";
import { SortData } from "./utils";
import { cartActions } from '../store/places-slice';

// let SortData
export default function Leaflet(props) {
  // let sorted_array = [];
  
  const dispatch = useDispatch();

  const centeredCoordinates = useSelector((state) => state.cart.centerMapLL);
  const [coordinates, setCoordinates] = useState({ lat: 51.505, lng: -0.09 });
  const [isMapInit, setIsMapInit] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [CurrentMap, setCurrentMap] = useState(null);
  const [sorted_array, setSorted_array] = useState([]);
  const [coordinatesFromDb, setCoordinatesFromDb] = useState([]);
  const [isInitialed, setIsInitialed] = useState(false);
  // const [routeAsChanged, setRouteAsChanged] = useState('yes');
  // const [count, setCount] = useState(allNoDuplicates2);



  // useEffect(() => {
  //   coordinatesFromDb.length > 0 && setCoordinates({ lat: coordinatesFromDb[0].lat, lng: coordinatesFromDb[0].lng })
  //   console.log("coordinatesFromDb CHEANGED",coordinatesFromDb)
  //   setIsInitialed(true)
  // },[coordinatesFromDb])


  const saveMap = (map) => {
    setCurrentMap(map);
    setIsMapInit(true);
  };
  // let coordinatesFromDb = [];
  // sourceTarget._router._hints.locations
  
  useEffect(()=> {
    // setIsMapInit(false)
    setIsInitialed(false)
    // setCoordinatesFromDb([])
    // setSorted_array([])
    let TEMPcoordinatesFromDb = []
    // console.log("props.allNoDuplicates - LEAFLET.JS",props.allNoDuplicates)
    // alert(`props.allNoDuplicates CHANGED!! - ${props.allNoDuplicates.length} `)
    
    props.allNoDuplicates.forEach((e) => {
      // console.log("EEEE0",e.location[0])
      // console.log("EEEE1",e.location[1])
      TEMPcoordinatesFromDb.push({ lat: e.location[0], lng: e.location[1]})
    })
    console.log("TEMPcoordinatesFromDb$$ - LEAFLET.JS",TEMPcoordinatesFromDb)
    // setCoordinatesFromDb([...coordinatesFromDb, ...TEMPcoordinatesFromDb])
    setCoordinatesFromDb([...TEMPcoordinatesFromDb])
  },[props.allNoDuplicates])

  
  // useEffect(()=> {
  //   console.log("props.showMapLeaflet:::",props.showMapLeaflet)
  // },[props.showMapLeaflet])
  // useEffect(()=> {
  //   if(count === 1){
  //     setRouteAsChanged('yes')
  //     setCount(0)
  //   }
  // },[count])
  
  useEffect(()=> {
    // if(count !== allNoDuplicates2) {
    // setRouteAsChanged('no')
    // }
    setSorted_array([])
    // alert(`LL CHANGED!! - ${sorted_array.length} - ${coordinatesFromDb.length}`)
    // alert(sorted_array.length)
    // alert(coordinatesFromDb.length)
    if(coordinatesFromDb.length > 0 && props.showMapLeaflet === true){
      console.log("coordinatesFromDb>>>", coordinatesFromDb.length)
      setSorted_array(SortData(coordinatesFromDb));

     
    }
    console.log("coordinatesFromDb:::",coordinatesFromDb)
    // setCount(1)
    // setIsMapInit(true)
  },[coordinatesFromDb])



  const handleClick = (e) => {
    setCoordinates(e.latlng);
    console.log("Clicked Coordinates", e.latlng);

    setMarkers((prev) => {
      return [...prev, e.latlng];
    });

    //getItineraryDistance(coordinatesFromDb)
  };

  return (
    // <>
    // {props.showMapLeaflet === true &&
      <>
    {
      sorted_array.length > 0 &&
      <>
      {/* <div>CHANGE GO {props.allNoDuplicates2}</div> */}
      {/* <div>{routeAsChanged} - {count}</div> */}
      {

        
      }

      {/* {routeAsChanged === 'yes' && */}
        <Map
          // center={[coordinates.lat, coordinates.lng]}
          center={[centeredCoordinates[0], centeredCoordinates[1]]}
          zoom={14}
          onClick={handleClick}
          ref={saveMap}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* <Marker position={[coordinates.lat, coordinates.lng]}>
            <Popup>Im a Marker</Popup>
          </Marker> */}
          {markers.map((marker, index) => {
            return (
              <Marker key={index} position={[marker.lat, marker.lng]}>
                <Popup>
                  <b>Lat: </b> {marker.lat}
                  <br></br>
                  <b>Lng: </b> {marker.lng}
                </Popup>
              </Marker>
            );
          })}
          <Search
            position="topleft"
            inputPlaceholder="Search your location"
            onChange={(info) => {
              setCoordinates(info.latLng);
            }}
            showMarker={false}
            //zoom={5}
            closeResultsOnClick={true}
            openSearchOnLoad={false}
          ></Search>
          {isMapInit && (
            <DriveTimeCalculation
              map={CurrentMap}
              coordinates={coordinatesFromDb}
              sorted_array={sorted_array}
              cliked_coordinates={coordinates}
              llOrderedRouteHandler={(e) => props.llOrderedRouteHandler(e)}
            />
          )}
        </Map>
      {/* } */}
    </>
  // }
  // </>
}
  </>
  );
}