import React, {useContext, useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../FormikElements/FormikControl'
import { useHistory } from 'react-router'
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';
import { Avatar, Badge, Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FolderIcon from '@mui/icons-material/Folder';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';



import ImageUpload from '../FormElements/ImageUpload';
import AddMediaToS3 from './AddMediaToS3';
import '../FormikElements/UiWithFormik.css';
import Modal from '../UIElements/Modal';
import { styled } from '@mui/material/styles';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function AddMedia (props) {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.ui.userProfile);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [morDetails0,setMorDetails0] = useState(true)
  const [morDetails1,setMorDetails1] = useState(false)
  const [morDetails2,setMorDetails2] = useState(true)
  const [morDetails3,setMorDetails3] = useState(false)
  const [morDetails4,setMorDetails4] = useState(false)
  const [morDetails5,setMorDetails5] = useState(false)
  const [morDetails6,setMorDetails6] = useState(false)
  const [morDetails7,setMorDetails7] = useState(false)
  const [morDetails8,setMorDetails8] = useState(false)
  const [displayJobForm,setDisplayJobForm] = useState(false)
  const [displayDandOForm,setDisplayDandOForm] = useState(false)
  const [valueDateMode, setValueDateMode] = React.useState(0);
  const [dateType, setDateType] = useState('date');
  const [switchMatrixx, setSwitchMatrixx] = useState('fgfg');
  const [sendedMessage, setSendedMessage] = useState('');
  const [addressesFieldsDetails, setAddressesFieldsDetails] = useState('');
  const [usersProfile, setUsersProfile] = useState('');
  const [oneImage, setOneImage] = useState(null);
  const [oneImageDesc, setOneImageDesc] = useState(null);
  const [openImageUploadModel, setOpenImageUploadModel] = useState(false);
  
//   const handleTypeOfdate = (e) => {console.log('date')
//   setRrre('dfh')
//   console.log(e.target.value)
// };

const [loadedUsers, setLoadedUsers] = useState();
const auth = useContext(AuthContext);

  const updateAddressesFields = (adtessesFields) => {
    console.log("adtessesFields", adtessesFields)
    setAddressesFieldsDetails([...adtessesFields])
  }


  const handleChange4 = (event, newValuee) => {
    setValueDateMode(newValuee);
  };
  
  // const handleSwitchTrueOrFalse = (e) => {
  //   console.log('switch hes been clicked!');
  //   switcher = !switcher;
  //   console.log(switcher);
  //   // setSwitchMatrixx(switcher);
  //   // console.log(e)
  // };
  // const handleSwitchTrueOrFalse = (e) => {
  //   console.log('switch hes been clicked!');
  //   setSwitchMatrixx(e);
  //   // console.log(e)
  // };
  // const [typeOfdate,setTypeOfdate] = useState('soon')
  let allMapCats = []

  props.mapCatsDropDown && props.mapCatsDropDown.forEach((single, idx, allArray)  => {
  let objVal = single.title;
  if(objVal){
    const obj = {value: single.id, key: single.title};
    allMapCats.push(obj);
    // console.log(single)
  }
})

  const initialValues = {
    name: userProfile.name,
    type: userProfile.type,
    business_name: userProfile.business_name,

    // address_auto: '',
    // address: '',
    address: userProfile.address,
    location: {
      lat: userProfile.location && userProfile.location.lat,
      lng: userProfile.location && userProfile.location.lng
    },
    entry: userProfile.entry,
    floor: userProfile.floor,
    apartment: userProfile.apartment,

    email: userProfile.email,
    phone1: userProfile.phone1,
    phone2: userProfile.phone2,
    phone3: userProfile.phone3,
    fax: userProfile.fax,
    po_box: userProfile.po_box,

    cat: userProfile.cat,
    tag: userProfile.tag,

    sku_client: userProfile.sku_client,
    description: userProfile.description,
    imageOne: ''
  }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    // cat: Yup.string().required('בחר קטגוריה'),
    // first_name: Yup.string().required('הזן שם')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

  const history = useHistory();

  const inputImageHandler = (pickedFile) => {
    console.log("inputHandler:pickedFile => ",pickedFile)
    setOneImage(pickedFile)
  };
  const imageDescHandler = (imageDescription) => {
    console.log("imageDescription => ",imageDescription)
    setOneImageDesc(imageDescription)
  };

  useEffect(() => {console.log('oneImage=>' , oneImage)},[oneImage])

  const onSubmit = async values => {
    console.log('Form data values:', values)
    values.address = addressesFieldsDetails[0]
    values.location.lat = addressesFieldsDetails[1]
    values.location.lng = addressesFieldsDetails[2]
    values.imageOne = oneImage
    values.imageOneDesc = oneImageDesc
    
// get secure url from our server
  // try {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", 'Bearer ' + auth.token);
  //   const { url } = await sendRequest(`/api/s3/s3Url`, 'POST', JSON.stringify({fileName: oneImage.name, userFolderId, userFolderId, subFolder}) , myHeaders);
  //   console.log("url from s3 => ",url)


  //   await fetch(url, {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "multipart/form-data"
  //     },
  //     body: oneImage
  //   })
  //   const imageUrl = url.split('?')[0]
  //     console.log(imageUrl)
  //     values.imageOne = imageUrl;
  // } catch (err) {console.log(err)}




    try {
      var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + auth.token);
      await sendRequest(`/api/users/edit/${userProfile._id}`, 'POST', JSON.stringify(values), myHeaders);
      setSendedMessage('איש קשר עודכן בהצלחה!')
      // dispatch(fetchCartData());
      // history.push('/maps');
    } catch (err) {console.log(err)}

  }

  return (
    <Formik 
    // enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          <Form>
            <div>
              <AddAPhotoIcon onClick={() => setOpenImageUploadModel(true)}
              style={{fontSize: '27px', color: '#1976d2', marginRight: '7px'}} />
            </div>


            {openImageUploadModel &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={openImageUploadModel}
                  onCancel={() => setOpenImageUploadModel(false)}
                  header={'העלאת תמונת פרופיל'}
                  style={{zIndex: '999', position: 'fixed', top: '81px', background: '#2e2e2e'}}
                  // contentClass="place-item__modal-content"
                  // footerClass="place-item__modal-actions"
                  // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                  closeButton={<Button variant='contained' onClick={() => setOpenImageUploadModel(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                    {/* <ImageUpload  center id="image" onInput={inputHandler}
                      errorText="הכנס תמונה."
                      /> */}
                      <AddMediaToS3  center id="image" 
                        onInput={inputImageHandler}
                        imageDesc={imageDescHandler}
                        // errorText="הכנס תמונה."
                        userFolderId= {userProfile._id}
                        subFolder= 'itemsjob'
                        objectId={props.objectId}
                        obj={props.obj}
                        pushCommentToCommentsItem={props.pushCommentToCommentsItem}
                        addImageTo={props.addImageTo}
                      />

                  </Grid>
                </Modal>
                </div>
                }
                {/* {oneImage && 
                  <div style={{display: 'flex'}}>
                    {/* <div>התמונה החדשה תהיה:</div> */}
                    {/* <div>{oneImage}</div> */}
                    {/* <div><img  sx={{width: '200px', height: '200px'}} src={oneImage} /></div> */}
                    {/* <div>{oneImageDesc}</div> */}
                  {/* </div> */}

                {/* } */}
            {/* <Grid item xs={6}>
              <Button variant='outlined' type='submit' disabled={!formik.isValid}>
                עדכן פרופיל
              </Button>
            </Grid>            */}
            {/* <Grid item xs={6}>
                <Typography style={{color: 'green'}}>{sendedMessage}</Typography>
            </Grid>            */}
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddMedia
