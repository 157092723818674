import { Avatar, Button, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import { uiActions } from '../../../store/ui-slice';
import { useSelector, useDispatch } from 'react-redux';
import EditProfile from '../FormikElements/EditProfile';
import Modal from '../UIElements/Modal';
import './NavLinks.css';
import { useTranslation } from 'react-i18next';

const NavLinks = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.ui.userProfile);
  const auth = useContext(AuthContext);
  const [loadedUsers, setLoadedUsers] = useState();
  const openMapHandler = () => setShowMap(true);
  const [createContactName, setCreateContactName] = useState(false);
  const [showMap, setShowMap] = useState(false);
  
  useEffect(() => {console.log("authhh >> ", auth)},[auth])
  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {
  //       const responseData = await sendRequest(
  //         '/api/users'
  //       );

  //       setLoadedUsers(responseData.users);
  //       console.log('loadedUsers::',loadedUsers)
  //     } catch (err) {}
  //   };
  //   fetchUsers();
  // }, []);

  return (
    <ul className="nav-links">
      {/* <li>
        { !auth.isLoggedIn && 
        <div className='header_textXS' >שלום אורח</div>
        }
      </li> */}
      {
      auth.isLoggedIn && (
      <li>
        <Avatar onClick={() => {
                    setCreateContactName(true)
                    openMapHandler()
                    }} style={{background: 'white', boxShadow: 'rgb(255 255 255 / 36%) 1px 2px 5px 1px'}} alt={userProfile.name} src={userProfile.imageOne} />
                {createContactName &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createContactName}
                  onCancel={() => setCreateContactName(false)}
                  header={'הפרופיל שלי'}
                  // style={{zIndex: '1001', position: 'fixed', top: '81px'}}
                  // contentClass="place-item__modal-content"
                  // footerClass="place-item__modal-actions"
                  // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                  closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                  {/* {loadedUsers && loadedUsers.map((c) => (
                      <div>sd
                        <div>{c.image} {c.name} {c.id} {user}</div>
                      </div>
                  ))} */}
                  <EditProfile
                  onCloseEditProfileWindow={() => setCreateContactName(false)}
                  whatToEdit='profileDetails'
                  />
                    {/* <FormikUiAddContact 
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    /> */}
                  </Grid>
                </Modal>
                </div>
                }
      </li>
      )
      }
      { auth.isLoggedIn && userProfile.email === 'yehudaad@gmail.com' &&
      <li className='header_textXS_none'>
        <NavLink to="/" exact>
          ALL USERS
        </NavLink>
      </li>
      }
      {/* {auth.isLoggedIn && (
        <li>
          <NavLink to={`/${auth.userId}/places`}>MY PLACES</NavLink>
        </li>
      )} */}
      {/* {auth.isLoggedIn && (
        <li>
          <NavLink to="/places/new">הוסף מיקום</NavLink>
        </li>
      )} */}
      {!auth.isLoggedIn && (
        <li  className='header_textXS' >
          <NavLink to="/auth" style={{background: '#e6e6e6', borderColor: 'none', borderColor: 'none',
    boxShadow: '1px 2px 5px 1px rgb(0 0 0 / 65%)', color: '#292929',padding: '10px 10px 10px 10px',
    minWidth: '56px',
    display: 'block',
    textAlign: 'center'}}>{t("connect__NL")}</NavLink>
        </li>
      )}
      {/* {auth.isLoggedIn && (
        <li>
          <NavLink to="/maps">Naps</NavLink>
        </li>
      )} */}
      {/* {auth.isLoggedIn && (
        <li className='header_textXS'>
          <button onClick={() => 
              {auth.logout()
              localStorage.removeItem('userProfile')
              dispatch(
                uiActions.setUserProfile({name: "שלום אורח"})
              )
            }
          }>יציאה</button>
        </li>
      )} */}
    </ul>
  );
};

export default NavLinks;
