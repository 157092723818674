import React, { useContext, useState, useEffect, useRef } from 'react'
import CreateTagRoute from '../../../items/componnents/CreateTagRoute'

import './TableXlsx.css'
                        // theadData={theadData}
                        // key={key}
                        // indx={indx}
                        // index={index}
                        // row={row}
export default function TableXlsxItem(props) {

    const [cellText, setCellText] = useState('')
    const [errorColor, setErrorColor] = useState('red')

    useEffect(() => {
    setCellText(props.row_key)

    }, [props.row_key])
    const updateAddressesFields = (objNum, addessesFields) => {
        console.log("addessesFields", addessesFields)
        setCellText(addessesFields[0])
        setErrorColor('green')
        console.log("objNum", objNum)
        props.changeNewDataAddress(props.index, props.indx, addessesFields)
        // console.log("changeNewDataAddress:", indexInArray, objectName, newAddress)
    // console.log("locationO", locationO)
    // setAddressesFieldsDetails([...addessesFields])
    // if(typeLocation === 'o'){
    //   const indexExistInArrayO = locationO.filter( (singleO) => {
    //     console.log("singleO[0] => ",singleO[0]) 
    //       if (singleO[0] === objNum){
    //         return singleO[0] === objNum
    //       }
    //     } 
    //   )

    //   console.log("indexExistInArray.length => ", indexExistInArrayO.length, indexExistInArrayO)
    //   if (indexExistInArrayO.length === 0){
    //     setlocationO(  [...locationO , [objNum, addessesFields]])
    //   } else if (indexExistInArrayO.length > 0) {
    //     const newlocationO = locationO.filter((singleO) => singleO[0] !== objNum);
    //     setlocationO(  [...newlocationO , [objNum, addessesFields]])
    //   }
    // }
    // if(typeLocation === 'd'){
    //   const indexExistInArrayD = locationD.filter( (singleD) => {
    //     console.log("singleD[0] => ",singleD[0]) 
    //       if (singleD[0] === objNum){
    //         return singleD[0] === objNum
    //       }
    //     } 
    //   )

    //   console.log("indexExistInArrayD.length => ", indexExistInArrayD.length, indexExistInArrayD)
    //   if (indexExistInArrayD.length === 0){
    //     setlocationD(  [...locationD , [objNum, addessesFields]])
    //   } else if (indexExistInArrayD.length > 0) {
    //     const newlocationD = locationD.filter((singleD) => singleD[0] !== objNum);
    //     setlocationD(  [...newlocationD , [objNum, addessesFields]])
    //   }
    // }

    }
    
    
 return (
    <>
    {props.theadData[props.indx] !== 'pleaseCheck' && props.theadData[props.indx] !== 'fullAddress' && props.theadData[props.indx] !== 'fullAddress_array' &&  
        <div
            className='cell_t'
            onClick={() => {
                console.log("PROPS", props)
                console.log("row[key]", props.row_key)
                console.log("row", props.row)
                console.log("index", props.index)
                console.log("indx", props.indx)
                // console.log("key",  props.key,)
                console.log("t_head", props.theadData[props.indx])
                console.log("t_head_all", props.theadData)
                console.log("row['כתובת'] - row['fullAddress']", props.row['כתובת'] + '-' + props.row['fullAddress'])
            }} 
                
            style={{
                    borderBottom: '1px solid #bbb',
                    borderLeft: '1px solid #bbb',
                    background: (props.theadData[props.indx] === 'כתובת' || props.theadData[props.indx] === 'fullAddress') && props.row['fullAddress'] === undefined ? errorColor : '#fff',
                    color: (props.theadData[props.indx] === 'כתובת' || props.theadData[props.indx] === 'fullAddress') && props.row['pleaseCheck'] === "pleaseCheck" ? errorColor : '#000'
            }}
            key={props.row[props.key]}
        >
            <div>
             {props.theadData[props.indx] !== 'pleaseCheck' &&  props.theadData[props.indx] !== 'fullAddress' &&  props.theadData[props.indx] !== 'fullAddress_array' && 
                //  {heading !== 'pleaseCheck' && heading !== 'fullAddress' && heading !== 'fullAddress_array' &&
                cellText
                }
            </div>
            {(props.row['fullAddress'] === undefined || props.row['pleaseCheck'] === "pleaseCheck") && props.theadData[props.indx] === 'כתובת' &&
                <div>
                    <CreateTagRoute updateAddressesFields={updateAddressesFields} formPurpes='xlsx_address' submitTextButton='עדכון' prevAddress={props.row['כתובת']} fontSize='10' />
                </div>
            }
            {props.theadData[props.indx] === 'כתובת' &&
                <div style={{background: '#cdcdcd', margin: '6px 0px'}}>
                    {props.row['fullAddress']}
                </div>
            }
            {props.theadData[props.indx] === 'מדינה' &&
                <div style={{background: props.row['countryIdFromDb'] !== '...' ? '#cdcdcd' : 'red', margin: '6px 0px'}}>
                    {props.row['countryIdFromDb'] !== '...' ? `V - ${props.row['countryIdFromDb'].substring(0, 3)}` : 'X'}
                </div>
            }
            {props.theadData[props.indx] === 'עיר' &&
                <div style={{background: props.row['cityIdFromDb'] !== '...' ? '#cdcdcd' : 'red', margin: '6px 0px'}}>
                    {props.row['cityIdFromDb'] !== '...' ? `V - ${props.row['cityIdFromDb'].substring(0, 3)}` : 'X'}
                </div>
            }
            {props.theadData[props.indx] === 'קטגוריה' &&
                <div style={{background: props.row['catIdFromDb'] !== '...' ? '#cdcdcd' : 'red', margin: '6px 0px'}}>
                    {props.row['catIdFromDb'] !== '...' ? `V - ${props.row['catIdFromDb'].substring(0, 3)}` : 'X'}
                </div>
            }
            {props.theadData[props.indx] ===  'תת_קטגוריה' &&
                <div style={{background: props.row['subCatIdFromDb'] !== '...' ? '#cdcdcd' : 'red', margin: '6px 0px'}}>
                    {props.row['subCatIdFromDb'] !== '...' ? `V - ${props.row['subCatIdFromDb'].substring(0, 3)}` : 'X'}
                </div>
            }
            {props.theadData[props.indx] === 'תגית' &&
                <div style={{background: props.row['TagsIdFromDb'] !== '...' ? '#cdcdcd' : 'red', margin: '6px 0px'}}>
                    {props.row['TagsIdFromDb'] !== '...' ? `V - ${props.row['TagsIdFromDb'].substring(0, 3)}` : 'X'}
                </div>
            }
        </div>
    }
    </>
);
}