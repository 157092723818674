import * as turf from "@turf/turf";

export const SortData = (coordinate_array) => {
  console.log("coordinate_array[[[utils]]]:", coordinate_array)
  // Setting first point in original array as stop number 1 and last as the last stop in order
  var sorted_array = [
    [coordinate_array[0].lat, coordinate_array[0].lng],
    [
      coordinate_array[coordinate_array.length - 1].lat,
      coordinate_array[coordinate_array.length - 1].lng
    ]
  ];

  var comparison_array = [];
  // Push all coordinates into comparison array to find nearest point from all those points
  coordinate_array.forEach((e, i) => comparison_array.push([e.lat, e.lng]));
  comparison_array.splice(comparison_array.length - 1, 1);

  coordinate_array.forEach((element, index) => {
    if (index < coordinate_array.length - 2) {
      let comparison_point = turf.point(sorted_array[index]);
      //Removing current reference point from the comparison array (So that it does not return itself as the nearest point)
      comparison_array.forEach((e, i) => {
        if (
          e[0] === sorted_array[index][0] &&
          e[1] === sorted_array[index][1]
        ) {
          comparison_array.splice(i, 1);
        }
      });
      let search_array = [];
      comparison_array.forEach((e) => search_array.push(turf.point(e)));
      search_array = turf.featureCollection(search_array);
      // Finding nearest point using turf.js
      let nearest_point = turf.nearestPoint(comparison_point, search_array);
      // Pusing the nearest point to sorted array before last element
      sorted_array.splice(index + 1, 0, nearest_point.geometry.coordinates);
    }
  });

  // Converting sorted array into array of objects

  sorted_array.forEach((element, index) => {
    sorted_array[index] = { lat: element[0], lng: element[1] };
  });

  return sorted_array;
};
