import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { useField, useFormikContext } from 'formik';
import TextError from './TextError'
import { MenuItem, TextField } from '@mui/material';

function Select (props, ...otherProps) {
  const { label, name, options, ...rest } = props
// console.log("options: ", options)
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = evt => {
    const { value } = evt.target;
    setFieldValue(name, value);
    // console.log("jjjkkkkkkkkkk")
    // console.log("jjjkkkkkkkkkkprops", props)
    if(props.handleSelectChange){
      // console.log("yyyyyyyyyyy", props)

      props.handleSelectChange(value)
    }
  };
  
  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange,
    label: label,
    margin: 'normal'
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }


  return (

      <TextField {...configSelect}>
        {options.map((item, pos) => {
          return (
            <MenuItem key={pos} value={item.value}>
              {item.key}
            </MenuItem>
          )
        })}
      </TextField>


    // <div className='form-control'>
    //   <label htmlFor={name}>{label}</label>
    //   <Field as='select' id={name} name={name} {...rest}>
    //     {options.map(option => {
    //       return (
    //         <option key={option.value} value={option.value}>
    //           {option.key}
    //         </option>
    //       )
    //     })}
    //   </Field>
    //   <ErrorMessage component={TextError} name={name} />
    // </div>
  )
}

export default Select
