
import React, { useState, useEffect } from 'react';

function InstallPWA(props) {
  const [showPrompt, setShowPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      // Prevent the default prompt
      event.preventDefault();

      // Store the event for later use
      setDeferredPrompt(event);

      // Show the custom prompt
      setShowPrompt(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    // Hide the custom prompt
    setShowPrompt(false);

    // Show the default browser prompt
    deferredPrompt.prompt();
  };

  return <>{showPrompt && <li
    style={{ width: '100%', margin: '30px 0px 15px 0px', height: '45px', border: '1px solid #cdcdcd', background: '#1976d2', color: '#fff', fontSize: '16px' }}
    onClick={handleInstallClick}>{props.obj}</li>}</>;
}

export default InstallPWA;