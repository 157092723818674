import React from 'react';
import "./message.css";
import { format } from "timeago.js";

export default function Message({ message, own, membersImage, ownImage }) {
  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        <img
          className="messageImg"
          src={own ? ownImage : membersImage}
          alt=""
        />
        {/* <p className="messageText">{message.text} - {message.sender}</p> */}
        <p className="messageText">{message.text}</p>
        {/* <p className="messageText">{message}</p> */}
      </div>
      <div className="messageBottom">{format(message.date)}</div>
    </div>
  );
}
