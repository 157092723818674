import React, { useRef, useContext, useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Compressor from 'compressorjs';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from './FormikControl'
import { useHistory } from 'react-router'
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';
import { Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import ImageUpload from '../FormElements/ImageUpload';
import './UiWithFormik.css';
import '../FormElements/ImageUpload.css';

function AddMediaToS3 (props) {
  const userProfile = useSelector((state) => state.ui.userProfile);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [morDetails0,setMorDetails0] = useState(true)
  const [morDetails2,setMorDetails2] = useState(true)
  const [sendedMessage, setSendedMessage] = useState('');
  const [addressesFieldsDetails, setAddressesFieldsDetails] = useState('');
  const [oneImage, setOneImage] = useState('');
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);
  

const auth = useContext(AuthContext);
const filePickerRef = useRef();

useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = event => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      console.log("pickedFile",pickedFile)
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    console.log("inputHandler:pickedFile => ",pickedFile)
    if(pickedFile.type === 'image/jpeg' || pickedFile.type === 'image/jpg' || pickedFile.type === 'image/png'){
        try {
            new Compressor(pickedFile, {
              quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
              maxWidth: 400,
              success: (compressedResult) => {
                
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.      
                pickedFile = compressedResult;  
                setOneImage(pickedFile)
              //   setCompressedFile(res)
              },
            });
            
        } catch (error) {
            // console.log("Compressor",error)
            // setOneImage(pickedFile)
        }

    } else {
        setOneImage(pickedFile)
    }

    
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };


  const initialValues = {
    name: userProfile.name,
    type: userProfile.type,
    description: userProfile.description,
    imageOne: ''
  }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    // cat: Yup.string().required('בחר קטגוריה'),
    // first_name: Yup.string().required('הזן שם')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

//   const inputHandler = (id, pickedFile, fileIsValid) => {
//     console.log("inputHandler:pickedFile => ",pickedFile)
//     setOneImage(pickedFile)
//   };

  const onSubmit = async values => {
    console.log('Form data values:', values)
    
// get secure url from our server
  try {
    var myHeaders = new Headers();
    console.log("fileName: oneImage.name",oneImage.name)
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", 'Bearer ' + auth.token);
    const { url } = await sendRequest(`/api/s3/s3Url`, 'POST', JSON.stringify({
        fileName: oneImage.name,
        userFolderId: props.userFolderId,
        subFolder: props.subFolder
        }),
        myHeaders
    );
    console.log("url from s3 => ",url)


    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      body: oneImage
    })
    const imageUrl = url.split('?')[0]
      console.log(imageUrl)
      props.onInput(imageUrl);
      values.imageOne = imageUrl;
      setSendedMessage('תמונה הועלה בהצלחה!')
  } catch (err) {console.log(err)}

  


    // try {
    //   var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     myHeaders.append("Authorization", 'Bearer ' + auth.token);
    //   await sendRequest(`/api/users/edit/${userProfile._id}`, 'POST', JSON.stringify(values), myHeaders);
    //   setSendedMessage('איש קשר עודכן בהצלחה!')
    //   // dispatch(fetchCartData());
    //   // history.push('/maps');
    // } catch (err) {console.log(err)}

  }

  return (
    <Formik 
    // enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          // style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px'}}
          <Form style={{zIndex: props.zIndex, background: '#fff', padding: '10px', maxWidth: '450px'}}>
            {/* <Grid item xs={12}> */}
              {/* <Typography  variant="h5" >מספר מזהה: {userProfile._id}</Typography> */}
              {/* {"_id":"61444bc85d4a7e5054ec7c3e","email":"yehudaadd@gmail.com","image":"uploads\\images\\b8df1ce0-178d-11ec-989c-a535fb70cda5.jpeg","name":"yehuda tzin"} */}
            {/* </Grid> */}
            <Grid item xs={12}>
                <div className="form-control">
                    <input
                        id={props.id}
                        ref={filePickerRef}
                        style={{ display: 'none' }}
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        onChange={pickedHandler}
                    />
                    <div className={`image-upload ${props.center && 'center'}`}>
                        <div className="image-upload__preview">
                        {previewUrl && <img src={previewUrl} alt="Preview" />}
                        {!previewUrl && <p>בבקשה בחר תמונה.</p>}
                        </div>
                        <Button type="button" onClick={pickImageHandler}>
                        בחר תמונה
                        </Button>
                    </div>
                    {!isValid && <p>{props.errorText}</p>}
                </div>
            </Grid>
            {/* <Grid item xs={12}>
              <ImageUpload  center id="image" onInput={inputHandler}
                errorText="הכנס תמונה."
              />
            </Grid> */}



            <Grid item xs={6}>
              <Button variant='outlined' type='submit' disabled={!formik.isValid}>
                עדכן תמונה
              </Button>
            </Grid>           
            <Grid item xs={6}>
                <Typography style={{color:"green",background:"#d9d9d9",width:"300px",textAlign:"center",padding:"10px",marginTop:"10px",fontWeight:"bold"}}>{sendedMessage}</Typography>
            </Grid>           
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddMediaToS3
