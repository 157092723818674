import { AddCircleOutlineOutlined, SubjectOutlined,  AccountBalance as AccountBalanceIcon  } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';

import { AppBar, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, MenuItem, Toolbar, Typography } from '@mui/material';
import { IconButton } from '@mui/material';

import { makeStyles } from '@mui/styles';
import React, {useContext} from 'react';
import { NavLink , useLocation} from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import NavLinks from '../Navigation/NavLinks';
import '../Navigation/NavLinks.css';




const AppBarMui = (props) => {
    // const useStyles = makeStyles({
    //     appbar: {width: `width: calc(100% - 100px)`}
    // })
    const auth = useContext(AuthContext);
    const locationPath = useLocation();

    // const classes = useStyles();

    return (
        <div >
            <AppBar 
                // className={classes.appbar}
                // style={{width: `calc(100% - ${props.drawerWidth}px)`, left: 0, right: 'unset'}}
                style={{minHeight: '40px'}}
                // elevation={1} 
            >
                <Toolbar style={{minHeight: '40px'}}>

                <div style={{minHeight: '40px', width: '100%', display: 'flex',  alignItems: 'center', justifyContent: 'space-between', marginRight: '5px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconButton
                        style={{marginLeft: '10px'}}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 }}
                            onClick={props.handleOnCLickHamburger}
                        >
                            <MenuIcon />
                        </IconButton>

                        
                        
                        {/* <Typography
                        style={{marginLeft: '10px'}}
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                            >
                            Drive And Ship
                            </Typography> */}
                            {/* logo_drive_and_ship_fff */}
                            <NavLink exact to={'/items/scheduledtoday'} style={{ textDecoration: 'none'}}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 17,
                                        width: 143,
                                        // maxHeight: { xs: 233, md: 167 },
                                        // maxWidth: { xs: 350, md: 250 },
                                    }}
                                    alt="Drive And Ship"
                                    src={process.env.PUBLIC_URL + "/logo_drive_and_ship_text_fff.svg"}
                                    // src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
                                />
                            </NavLink>

                            <NavLink exact to={'/items/scheduledtoday'} style={{ textDecoration: 'none'}}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 36,
                                        width: 65,
                                        // maxHeight: { xs: 233, md: 167 },
                                        // maxWidth: { xs: 350, md: 250 },
                                    }}
                                    alt="Drive And Ship"
                                    src={process.env.PUBLIC_URL + "/logo_drive_and_ship_fff.svg"}
                                    // src={process.env.PUBLIC_URL + "/Asset_9.svg"}
                                    // src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
                                />
                            </NavLink>
                        {/* <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                                aria-label="open drawer"
                                style={{marginRight: '0px'}}
                            sx={{ mr: 2}}
                            // onClick={props.handleOnCLickAddSomething}
                            onClick={() => {
                                props.handleOnCLickAddSomething()
                                window.scrollTo({
                                  top: 0,
                                  behavior: 'smooth',
                                })
                                console.log("ggggggggggg")
                              }}
                        >
                            <AddIcon sx={{fontSize: 30}} />
                        </IconButton> */}

                        {/* <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2}}
                            // onClick={}
                            onClick={() => {
                                props.handleOnCLickViewSomething()
                                window.scrollTo({
                                  top: 0,
                                  behavior: 'smooth',
                                })
                              }}
                        >
                            <EmojiTransportationIcon sx={{fontSize: 30}} />
                        </IconButton> */}
                            
                        {/* <NavLink exact to={'/items/jobs'} style={{ textDecoration: 'none'}}>
                                <div
                                    className='jobslink'
                                    // style={{ fontSize: '17px', background: '#1976d2', border: '1px solid #fff', borderRadius: '33px', width: '43px', height: '42px', color: '#fff', padding: '7px 5px 0px 0px', margin: '0px 43px 0px 0px' }}
                                >jobs</div>
                        </NavLink> */}
                    </div>

                    <div>
                        <nav className="main-navigation__header-nav">
                            <NavLinks />
                        </nav>
                    </div>
                </div>
            </Toolbar>
        </AppBar>

        </div>
    )
};

export default AppBarMui;