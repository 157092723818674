import React, {useContext, useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import {useHttpClient} from '../../hooks/http-hook'
import { Avatar, Button, Grid, IconButton, ListItem, ListItemAvatar, Tab, Tabs, TextField, Typography } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CloseIcon from '@mui/icons-material/Close';
import RedoIcon from '@mui/icons-material/Redo';

import FormikUiAddContact from '../FormikElements/FormikUiAddContact';
import ListItemText from '@mui/material/ListItemText';

import AddPackagesForTransportation from '../FormikElements/AddPackagesForTransportation';






import '../FormikElements/UiWithFormik.css';


const getCitiesTwo = async (str) => {
  try {
    let searchableCity = str.replace(/,/g, "");
    let url = "/api/contacts/autocomplete_two?city=" + searchableCity;

    let { data } = await axios.get(url);
    return data;
  } catch (error) {
    console.error(error);
  }
};

function AddSomething (props) {
  const userProfile = useSelector((state) => state.ui.userProfile);
  const usersClientsFromContacts = useSelector((state) => state.ui.userProfile.AllClients);

  const allFormInputName = ['id_manager','id_client','id_emploeey',
  'date_delivery','where_to','packs_pallet','packs_bags',
  'packs_cartons','packs_people','description',
  'location_o','location_d','contact_O_r_D',
  'payment','vehicle','physical_work','id_tag_route'];


  const chooseAddSubject = [
    ['מיקומים','places',<AddLocationAltOutlinedIcon sx={{ fontSize: 40 }} />],
    ['אינדקס','index',<MenuBookOutlinedIcon sx={{ fontSize: 40 }} />]
  ]
  
  const formTypeOptions = [
    
    
    { show: true, title: 'הוספת תווית מסלול', subTitle: 'יש לכם מסלול עם כתובות? צרו תווית אשר יהיה שם המסלול אליו תוכלו להוסיף מיקומים', value: 'tag_route', subject: 'index'},
    { show: true, title: 'הוספת כתובת לתווית', subTitle: 'הוספת כתובת לתווית לצורך חישוב מסלול', value: 'location_tag',
    inputes: ['location_d','id_tag_route'] , subject: 'places'},
    { show: true, title: 'הוספת פריט', subTitle: 'הוספת פריט הכוללת לקוח, מוצא ויעד', value: 'c_o_d',
    inputes: ['date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
        'contact_O_r_D', 'payment', 'vehicle', 'physical_work','id_connected_mover','id_connected_businesses'], subject: 'places'
      },
    { show: true, title: 'מוצא ויעד', subTitle: 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', value: 'o_and_d',
    inputes: ['location_o','location_d'] , subject: 'places'},
    { show: true, title: 'איש קשר', subTitle: 'הוספת איש קשר או לקוח', value: 'person', subject: 'index'},
      
    { show: false, title: 'פריטים לשינוע - לקוחות ומחסנים', subTitle: 'הוספת פריט לשינוע עם לקוחות ומחסנים שמורים  במערכת', value: 'c_to_w',
      inputes: ['id_manager','id_client','id_emploeey',
      'date_delivery','where_to','packs_pallet','packs_bags',
      'packs_cartons','packs_people','description'], subject: 'places' },
    { show: false, title: 'פריטים לשינוע - מאיש קשר לאיש קשר', subTitle: 'הוספת פריט מאיש קשר לאיש קשר', value: 'contact_to_contact',
    inputes: ['id_manager','id_client','id_emploeey',
    'date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D'] , subject: 'places'},
    
    { show: true, title: 'דרושים', subTitle: 'הוספת פריט למחלקת הדרושים למציאת נהג לשינוע', value: 'jobs',
    inputes: ['date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description', 'contact_O_r_D',
    'payment','vehicle','physical_work','id_connected_mover','id_connected_businesses'] , subject: 'places'},
    { show: false, title: 'קו קבוע לפי יום - עבור המטריקס', subTitle: 'הוסף מוצא ויעד קבוע באחד מימי השבוע, כך נוכל לשלוח לך עבודות על הדרך', value: 'location_by_day',
    inputes: ['date_delivery', 'location_o','location_d','vehicle'] , subject: 'places'},
    { show: false, title: 'הובלת דירה', subTitle: 'מילוי טופס להובלת דירה או משרד', value: 'home_office' ,
    inputes: ['id_manager','id_client','id_emploeey',
    'date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description','contact_O_r_D',
    'payment','vehicle','physical_work'], subject: 'places'},
    { show: false, title: 'עובד', subTitle: 'הוספת נהג/עובד לו תכולו לשייך משטחים/פריטים לשינוע', value: 'employee', subject: 'index'},
    { show: false, title: 'לקוח', subTitle: 'תוכלו לציין עבור איזה לקוח מבוצע עבודה וכן תוכלו לקבל עבודות מלקוחות אליכם', value: 'client', subject: 'index'},
    { show: false, title: 'לקוח קצה', subTitle: 'לכל לקוח תוכלו להוסיף תחתיו לקוח קצה. כך תוכלו לעבוד מול חברות המבקשים לשלוח פריטים ללקוחות קצה שלהם.', value: 'clients_client', subject: 'index'},
    { show: false, title: 'מחסן', subTitle: 'בכל פריט תוכלו להגדיר מאיזה מחסן יועמס/יופרק הפריט', value: 'warehouse', subject: 'index'},
  ];
  
  const dispatch = useDispatch();

  const [typeOfForm, setTypeOfForm] = useState(['','','',[]]);
  const [showTypeOfFormOptions, setShowTypeOfFormOptions] = useState(true);
  const [showAddSubject, setShowAddSubject] = useState('places');

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
     {/* <div style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px', maxWidth: '450px'}}> */}
    <div style={{background: '#fff', padding: '10px', maxWidth: '450px'}}>
      {/* <div>{showAddSubject+' - '+showTypeOfFormOptions}</div> */}
        <Grid item xs={12} md={12}>

          <div style={{marginTop: '15px'}}>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                    aria-label="open drawer"
                    style={{marginRight: '0px'}}
                sx={{ mr: 2}}
                // onClick={props.handleOnCLickAddSomething}
                onClick={() => {
                    props.handleOnCLickAddSomething()
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                    console.log("ggggggggggg")
                  }}
            >
                <CloseIcon sx={{fontSize: 30}} />
              </IconButton>
            </div>


            {typeOfForm && !showTypeOfFormOptions &&
              <div style={{marginTop: '10px'}}>
                <IconButton edge="start" aria-label="add" onClick={() => {
                      setShowTypeOfFormOptions(true)
                    }}>
                  <RedoIcon sx={{ fontSize: 40 }} />
                </IconButton>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div" style={{textAlign: 'center'}}>
                  {'הוספת ' + typeOfForm[1]}
                </Typography>
                
              </div>
            }
          <div>
            {formTypeOptions && showTypeOfFormOptions &&
            <>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div" style={{textAlign: 'center'}}>
                מה תרצו להוסיף?
              </Typography>


              {/* <div style={{display: 'flex', flexDirection: 'row', alignContent: 'stretch', justifyContent: 'space-between', minHeight: '73px'}} >
                {chooseAddSubject.map(sbjct => (  
                  <div onClick={() => setShowAddSubject(sbjct[1])}
                  style={{display: 'flex', flexDirection: 'column', width: '50%',
                  alignItems: 'center', justifyContent: 'space-between', minHeight: '73px',
                  color: showAddSubject === sbjct[1] ? '#fff' : '#000',
                  background: showAddSubject === sbjct[1] ? '#1976d2' : '#e6e6e6',
                  padding: '3px', border: '2px solid #ededed', borderRadius: '10px', marginLeft: '7px', cursor: 'pointer'
                  }} >
                    <div style={{maxWidth: '275px'}}>{sbjct[0]}</div>
                    <div style={{marginTop: '2px'}} >
                      {sbjct[2]}
                    </div>
                  </div>
                  )
                )}
              </div> */}
              {formTypeOptions.map(type => (
                 type.show === true &&
                // type.subject === showAddSubject && type.show === true &&
                <div style={{display: 'flex', justifyContent: 'space-between', minHeight: '73px', background: '#1976d2',  padding: '11px 14px 12px 6px', margin: '7px 0px 7px 0px'}} >
                    <div style={{maxWidth: '275px', fontSize: '18px', color: '#fff'}}>
                      <ListItemText
                        primary={type.title}
                        secondary={type.subTitle}
                      />
                    </div>
                    <div style={{marginLeft: '11px'}} onClick={() => {
                      setShowTypeOfFormOptions(false)
                      setShowAddSubject(type.subject)
                      // alert(type.value)
                      setTypeOfForm([type.value, type.title, type.subTitle, type.inputes])
                      // if(showAddSubject === 'places'){
                      //   // formRef.current.resetForm()
                      //   // setlocationO([])
                      //   // setlocationD([])
                      //   // setAutoCompleteO([])
                      //   // setAutoCompleteD([])
                      // }
                    }
                  }>
                      <IconButton edge="start" aria-label="add" >
                        <ArrowCircleLeftOutlinedIcon sx={{ fontSize: 40, color: '#fff' }} />
                      </IconButton>
                    </div>
                  </div>
              ))}
            </>
            }
          </div>
        </Grid>


        {showAddSubject === 'index' && !showTypeOfFormOptions &&
          <div style={{marginBottom: '1000px'}}>
    <FormikUiAddContact
          // formPurpesTitle={'formPurpes'}
          typeOfForm={typeOfForm}
          typeLocation='d'
          // zIndex={'zIndex6'}
          // mapCatsDropDown={'mapCats'}
          // selectedAddressD={'selectedAddressD_RD'}
          // selectedAddressO={'selectedAddressO_RD'}
            />
            </div>
    }

        {showAddSubject === 'places' && !showTypeOfFormOptions &&
                    <div style={{marginBottom: '1000px'}}>

    <AddPackagesForTransportation 
      typeOfForm={typeOfForm}
            />
            </div>
    
    }
    </div>
    </div>
  )
}



export default AddSomething
