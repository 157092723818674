import React, {useContext, useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { uiActions } from '../../../store/ui-slice';
import FormikControl from './FormikControl'
import { useHistory } from 'react-router'
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';
import { Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './UiWithFormik.css';


const allInputsAndLabals = {

}


function FormikAddPlaceInMap (props, {AutoCompleteCFromAddContactHandler}) {
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.ui.userProfile);
  const usersClientsFromContacts = useSelector((state) => state.ui.usersClientContacts);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [morDetails0,setMorDetails0] = useState(true)
  const [morDetails1,setMorDetails1] = useState(false)
  const [morDetails2,setMorDetails2] = useState(false)
  const [morDetails3,setMorDetails3] = useState(false)
  const [morDetails4,setMorDetails4] = useState(false)
  const [morDetails5,setMorDetails5] = useState(false)
  const [morDetails6,setMorDetails6] = useState(false)
  const [morDetails7,setMorDetails7] = useState(false)
  const [morDetails8,setMorDetails8] = useState(false)
  const [displayJobForm,setDisplayJobForm] = useState(false)
  const [displayDandOForm,setDisplayDandOForm] = useState(false)
  const [valueDateMode, setValueDateMode] = React.useState(0);
  const [dateType, setDateType] = useState('date');
  const [switchMatrixx, setSwitchMatrixx] = useState('fgfg');
  const [sendedMessage, setSendedMessage] = useState('');
  const [addressesFieldsDetails, setAddressesFieldsDetails] = useState('');
  const [editPurpes, setEditPurpes] = useState(props.formPurpesTitle)
  const [editObjectFromProps, setEditObjectFromProps] = useState({})
  const [allUsersClientContacts, setAllUsersClientContacts] = useState([]);
  // const [allInputsForThisType, setAllInputsForThisType] = useState([]);
  // const [nameForType, setNameForType] = useState();
 


  // const SetTheC = (e) => {
  //   console.log("SetTheC<>", e)
  //   props.AutoCompleteCFromAddContactHandler
  // }
  useEffect(() => {
    setAllUsersClientContacts(
      [{key: userProfile.name, value: userProfile._id}]
    );
    let allContactsFromRedux = [];
    userProfile.AllClients &&
    userProfile.AllClients.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    setAllUsersClientContacts(
        [{key: userProfile.name, value: userProfile._id}, ...allContactsFromRedux]
      )
  },[userProfile])


  // useEffect(() => {
  //   setAllUsersClientContacts([...allUsersClientContacts, ...usersClientsFromContacts])
  // },[usersClientsFromContacts])

//   const handleTypeOfdate = (e) => {console.log('date')
//   setRrre('dfh')
//   console.log(e.target.value)
// };
let switcher = true;
var cccc = {...props.edit_contact}
  useEffect(() => {
    setEditObjectFromProps({...props.edit_contact})
    console.log('("first_name" in cccc)',("first_name" in cccc));
    console.log('("type" in cccc)',("type" in cccc));
  if(props.formPurpesTitle === 'd'){
    setDisplayDandOForm(false);
    }
  if(props.formPurpesTitle !== 'd'){
    setDisplayDandOForm(true);
    }
  },[props.formPurpesTitle, props.edit_contact])
  useEffect(() => {
    // setEditObjectFromProps({...props.edit_contact})
    console.log("editObjectFromProps",editObjectFromProps)
    console.log('("first_name" in editObjectFromProps)',("first_name" in editObjectFromProps));
    console.log('("type" in editObjectFromProps)',("type" in editObjectFromProps));
  },[editObjectFromProps])


  const updateAddressesFields = (objNum, adtessesFields) => {
    console.log("adtessesFields", adtessesFields)
    setAddressesFieldsDetails([...adtessesFields])
  }
  useEffect(() => {
    console.log("props:",props);
  }, [props])
  

  // useEffect(() => {
  //   console.log("switcher:",switcher);
  // },[switcher])

  const handleChange4 = (event, newValuee) => {
    setValueDateMode(newValuee);
  };
  
  const handleSwitchTrueOrFalse = (e) => {
    console.log('switch hes been clicked!');
    switcher = !switcher;
    console.log(switcher);
    // setSwitchMatrixx(switcher);
    // console.log(e)
  };
  // const handleSwitchTrueOrFalse = (e) => {
  //   console.log('switch hes been clicked!');
  //   setSwitchMatrixx(e);
  //   // console.log(e)
  // };
  // const [typeOfdate,setTypeOfdate] = useState('soon')
  const auth = useContext(AuthContext);
  let allMapCats = []
  // allMapCats.push({a: 'aaaor_in_and'},{b: 'bbbor_in_and'})
  // console.log(allMapCats)
  // console.log("props.mapCatsDropDown: ", props.mapCatsDropDown)

  props.mapCatsDropDown && props.mapCatsDropDown.forEach((single, idx, allArray)  => {
  let objVal = single.title;
  if(objVal){
    const obj = {value: single.id, key: single.title};
    allMapCats.push(obj);
    // console.log(single)
  }
})
// formPurpesTitle={'edit_contact'}
                              // edit_contact={obj}
  useEffect(() => {
    console.log("propsCONTACT", props)
    console.log("props.edit_contact.type", typeof(props.edit_contact))
    // setEditPurpes(true);
    if(props.formPurpesTitle === 'edit_contact') {
      console.log("props.formPurpesTitle", props.formPurpesTitle)
      console.log("editPurpes", editPurpes)
      console.log("props.edit_contact}{", props.edit_contact)
    }                           
  },[props.formPurpesTitle])                            
  const initialValues = {
    id_manager: editPurpes === 'edit_contact' ? props.edit_contact.id_user_connection : '',
    id_user_connection: editPurpes === 'edit_contact' ? props.edit_contact.id_user_connection : '',
    id_client_connection: editPurpes === 'edit_contact' ? props.edit_contact.id_client_connection : '',
    first_name:  editPurpes === 'edit_contact' ? props.edit_contact.first_name : '',
    last_name: editPurpes === 'edit_contact' ? props.edit_contact.last_name : '',
    business_name: editPurpes === 'edit_contact' ? props.edit_contact.business_name : '',
    // type:  '',
    // type:  returmMyTheInitialChecked(),
    type:  editPurpes === 'edit_contact' ? props.edit_contact.type :
    props.typeOfForm ? props.typeOfForm[0] :  'person',

    // address_auto: '',
    // address: '',
    address: addressesFieldsDetails[0],
    lat: addressesFieldsDetails[1],
    lng: addressesFieldsDetails[2],
    city: addressesFieldsDetails[3],
    entry: editPurpes === 'edit_contact' ? props.edit_contact.entry : '',
    floor: editPurpes === 'edit_contact' ? props.edit_contact.floor : '',
    apartment: editPurpes === 'edit_contact' ? props.edit_contact.apartment : '',

    email: editPurpes === 'edit_contact' ? props.edit_contact.email : '',
    phone1: editPurpes === 'edit_contact' ? props.edit_contact.phone1 : '',
    phone2: editPurpes === 'edit_contact' ? props.edit_contact.phone2 : '',
    phone3: editPurpes === 'edit_contact' ? props.edit_contact.phone3 : '',
    fax: editPurpes === 'edit_contact' ? props.edit_contact.fax : '',
    po_box: editPurpes === 'edit_contact' ? props.edit_contact.po_box : '',

    cat: editPurpes === 'edit_contact' ? props.edit_contact.cat : '',
    tag: editPurpes === 'edit_contact' ? props.edit_contact.tag : '',

    sku_client: editPurpes === 'edit_contact' ? props.edit_contact.sku_client : '',
    description: editPurpes === 'edit_contact' ? props.edit_contact.description : '',
  }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    // cat: Yup.string().required('בחר קטגוריה'),
    first_name: Yup.string().required('הזן שם')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

  const history = useHistory();

  const onSubmit = async values => {
    console.log('Form data values:', values)
    values.city = addressesFieldsDetails[3]
    values.address = addressesFieldsDetails[0]
    values.lat = addressesFieldsDetails[1]
    values.lng = addressesFieldsDetails[2]
    values.location = {
      lat: addressesFieldsDetails[1],
      lng: addressesFieldsDetails[2]
    }


    if(editPurpes === 'edit_contact'){
      try {
        var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", 'Bearer ' + auth.token);
        await sendRequest(`/api/contacts/edit/${props.edit_contact._id}`, 'POST', JSON.stringify(values), myHeaders);
        setSendedMessage('עדכון בוצע בהצלחה!')
        // dispatch(fetchCartData());
        // history.push('/maps');
      } catch (err) {console.log(err)}
      
    } 
    else if (editPurpes !== 'edit_contact') {
      try {
        var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", 'Bearer ' + auth.token);
          const responseData = await sendRequest('/api/contacts/add', 'POST', JSON.stringify(values), myHeaders);
          console.log("responseData<>", responseData)
          if(props.AutoCompleteCFromAddContactHandler){
            props.AutoCompleteCFromAddContactHandler(responseData)
          }
            // console.log("props.AutoCompleteCFromAddContactHandler",props.AutoCompleteCFromAddContactHandler)
            console.log("props",props)
            // props.AutoCompleteCFromAddContactHandler(responseData)
          // }
        setSendedMessage(`${props.typeOfForm[1]} חדש נוצר בהצלחה!`)
        if(responseData.contact.type === 'tag_route') {
          dispatch(
            uiActions.addTagToTagRoute({
              // name: responseData.userProfile.name,
              // id: responseData.userProfile.id,
              // image: responseData.userProfile.image,
              // email: responseData.userProfile.email,
  
            _id: responseData.contact._id,
            user_id: responseData.contact.user_id,
            type: responseData.contact.type,
            first_name: responseData.contact.first_name
            })
          );
          if (props.responseNewTagRoute) {
            props.responseNewTagRoute(responseData.contact)
          }
        }
        //  const addItemHandler = () => {
    // dispatch(
    //   cartActions.addItemToCart({
    //     id,
    //     title,
    //     price,
    //   })
    // );
  // };
        // dispatch(fetchCartData());
        // history.push('/maps');
      } catch (err) {console.log("errerrerr",err)}
      
    }

  }


  // function returmMyTheChecked(nameValueOfRadioButton){
  //   if(editPurpes === 'edit_contact'){
  //         if (("type" in editObjectFromProps)) {
  //             // console.log('1',nameValueOfRadioButton,editPurpes)
  //             if(editObjectFromProps.type === nameValueOfRadioButton) {
  //               console.log('2:true',nameValueOfRadioButton,editPurpes,editObjectFromProps.type)
  //               return true
  //             } else {
  //               console.log('3:false',nameValueOfRadioButton,editPurpes,editObjectFromProps.type)
  //               return false
  //             }
  //        }  else if(!("type" in editObjectFromProps) &&  nameValueOfRadioButton === 'person') {
  //         console.log('4:true',nameValueOfRadioButton,editPurpes,editObjectFromProps.type)
  //         return true
  //         } 
  //   } 
  //   else if (editPurpes !== 'edit_contact' &&  nameValueOfRadioButton === 'person') {
  //     console.log('5:true',nameValueOfRadioButton,editPurpes,editObjectFromProps.type)
  //     return true
  //   }
  // }


  function returmMyTheInitialChecked(){
    if(editPurpes === 'edit_contact'){
          if (("type" in editObjectFromProps)) {
            console.log("type::",props.edit_contact.type)
            return props.edit_contact.type
         } else if(!("type" in editObjectFromProps)) {
          console.log("type::",'person')
          return 'person'
          } 
    } 
    else if (editPurpes !== 'edit_contact') {
      // console.log('5',nameValueOfRadioButton,editPurpes,editObjectFromProps.type)
      return 'person'
    }
  }


  return (
    <Formik 
    // enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          // style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px'}}
          <Form style={{zIndex: props.zIndex, background: '#fff', padding: '10px', maxWidth: '450px'}}>
            {/* <FormikControl
              control='radio'
              label='צד המשלם'
              name='modeOfContact'
              options={RadioOptions}
            /> */}
            {formik.values.type === 'tag_route' &&
              <Grid xs={12}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם הטיול הוא...'
                  name='first_name'
                />
              </Grid>
            }
            {formik.values.type !== 'tag_route' &&
              <>
            <Grid item xs={12}>
              <Typography  variant="h4" >{editPurpes === 'edit_contact' ?
                <div>עדכון איש קשר</div>
                : <div>הוספה לאינדקס</div>
                }</Typography>
            </Grid>

            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12}>
                <FormikControl
                  control='radio'
                  // type='radio'
                  disabled={editPurpes === 'edit_contact' ? true : false}
                  label='סוג איש קשר:'
                  name={`type`}
                  options={[
                    { key: 'איש קשר', value: 'person'},
                    { key: 'עובד', value: 'employee'},
                    { key: 'לקוח', value: 'client'},
                    { key: 'לקוח קצה', value: 'clients_client'},
                    { key: 'מחסן', value: 'warehouse'},
                    // { key: 'תווית', value: 'tag'},
                    { key: 'תווית למסלול', value: 'tag_route'},
                  ]}
                  // handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                />
              </Grid>
            </Grid>

            {morDetails0 && 
            // <Grid item xs={12}>
            //   <Typography>כתובת יעד</Typography>
            // </Grid>

            <Grid container spacing={1}>
              {/* <Grid item xs={12}>{formik.values.type}</Grid> */}
              <Grid item xs={12}>
              {/* {(formik.values.type === 'warehouse' || formik.values.type === 'warehouse') &&  */}
                 {(formik.values.type === 'warehouse' || formik.values.type === 'clients_client') &&
                    <FormikControl
                    control='select'
                    // type='number'
                    label='שיוך מחסן ללקוח'
                    name='id_client_connection'
                    options={allUsersClientContacts}
                    />
                }
              </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='string'
                  label='מזהה פרופיל משוייך'
                  name='id_user_connection'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם פרטי'
                  name='first_name'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם משפחה'
                  name='last_name'
                />
              </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם עסק'
                  name='business_name'
                />
              </Grid>
              
            <Grid item xs={12}>
                <FormikControl
                  control='addressInput'
                  type='string'
                  label='כתובת אוטומטית'
                  name='address_auto'
                  updateAddressesFields={updateAddressesFields}
                  typeLocation={props.typeLocation ? props.typeLocation : 'contact'}
                  addToId='cc'
                />
            </Grid>

            {/* <Grid item xs={8}>
            <FormikControl
              control='input'
              type='string'
              label='כתובת'
              name='address'
            />
            </Grid> */}

            <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='כניסה'
                  name='entry'
                />
            </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קומה'
                  name='floor'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='number'
                  label='דירה'
                  name='apartment'
                />
              </Grid>
            </Grid>
          }
          <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails2 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails2(!morDetails2)}
                
            ><Typography>אימייל וטלפונים</Typography></Button>
            </Grid>
            {morDetails2 && 
            <div className='mor_details' id="mor_details2">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='email'
                  label='אימייל'
                  name='email'
                />
              </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='string'
                  label='טלפון 1'
                  name='phone1'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='טלפון 2'
                  name='phone2'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='טלפון 3'
                  name='phone3'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='פקס'
                  name='fax'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='תיבת דואר'
                  name='po_box'
                />
              </Grid>
              
              
            </Grid>
            </div>
            }
            <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails1 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails1(!morDetails1)}
                
            ><Typography>קטגוריה/תווית</Typography></Button>
            </Grid>
            {morDetails1 && 
            <div className='mor_details' id="mor_details1">
            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12}>
                <FormikControl
                  control='select'
                  label='קטגוריה'
                  name='cat'
                  options={allMapCats}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='select'
                  label='תווית'
                  name='tag'
                  options={allMapCats}
                />
              </Grid>
            </Grid>
            </div>
            } 
            <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='string'
                  label='קוד לקוח'
                  name='sku_client'
                />
              </Grid>
            <Grid item xs={12}>
                <FormikControl
                  control='textarea'
                  label='הערות'
                  name='description'
                />
              </Grid>  
              </>
          }
            <Grid item xs={12}>
              <Button variant='outlined' type='submit' disabled={!formik.isValid}>
                {editPurpes === 'edit_contact' ?
                <div>עדכון איש קשר</div>
                  : <div>{`הוסף ${props.typeOfForm ? props.typeOfForm[1] : 'חדש'}` }</div>
                }
              </Button>
            </Grid>           
            <Grid item xs={6}>
                <Typography style={{color: 'green'}}>{sendedMessage}</Typography>
            </Grid>           
          </Form>
        )
      }}
    </Formik>
  )
}

export default FormikAddPlaceInMap
