import React, {useContext, useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from './FormikControl'
import { useHistory } from 'react-router'
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';
import { Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './UiWithFormik.css';

function AddContactTag (props) {
  const dispatch = useDispatch();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [morDetails0,setMorDetails0] = useState(true)
  const [morDetails1,setMorDetails1] = useState(false)
  const [morDetails2,setMorDetails2] = useState(false)
  const [morDetails3,setMorDetails3] = useState(false)
  const [morDetails4,setMorDetails4] = useState(false)
  const [morDetails5,setMorDetails5] = useState(false)
  const [morDetails6,setMorDetails6] = useState(false)
  const [morDetails7,setMorDetails7] = useState(false)
  const [morDetails8,setMorDetails8] = useState(false)
  // const [displayJobForm,setDisplayJobForm] = useState(false)
  // const [displayDandOForm,setDisplayDandOForm] = useState(false)
  // const [valueDateMode, setValueDateMode] = React.useState(0);
  // const [dateType, setDateType] = useState('date');
  // const [switchMatrixx, setSwitchMatrixx] = useState('fgfg');
//   const handleTypeOfdate = (e) => {console.log('date')
//   setRrre('dfh')
//   console.log(e.target.value)
// };
// let switcher = true;
  // useEffect(() => {
  // if(props.formPurpesTitle === 'd'){
  //   setDisplayDandOForm(false);
  //   }
  // if(props.formPurpesTitle !== 'd'){
  //   setDisplayDandOForm(true);
  //   }
  // },[props.formPurpesTitle])

  // useEffect(() => {
  //   console.log("switcher:",switcher);
  // },[switcher])

  // const handleChange4 = (event, newValuee) => {
  //   setValueDateMode(newValuee);
  // };
  
  // const handleSwitchTrueOrFalse = (e) => {
  //   console.log('switch hes been clicked!');
  //   switcher = !switcher;
  //   console.log(switcher);
  //   // setSwitchMatrixx(switcher);
  //   // console.log(e)
  // };
  // const handleSwitchTrueOrFalse = (e) => {
  //   console.log('switch hes been clicked!');
  //   setSwitchMatrixx(e);
  //   // console.log(e)
  // };
  // const [typeOfdate,setTypeOfdate] = useState('soon')
  const auth = useContext(AuthContext);
  let allMapCats = []
  // allMapCats.push({a: 'aaaor_in_and'},{b: 'bbbor_in_and'})
  // console.log(allMapCats)
  // console.log("props.mapCatsDropDown: ", props.mapCatsDropDown)

// props.mapCatsDropDown.forEach((single, idx, allArray)  => {
//   let objVal = single.title;
//   if(objVal){
//     const obj = {value: single.id, key: single.title};
//     allMapCats.push(obj);
//     // console.log(single)
//   }
// })

  const initialValues = {
    name: ''
  }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    // cat: Yup.string().required('בחר קטגוריה'),
    name: Yup.string().required('הזן שם תגית')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

  const history = useHistory();

  const onSubmit = async values => {
    console.log('Form data values:', values)
    try {
      var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + auth.token);
      await sendRequest('/api/contacts/add_tag', 'POST', JSON.stringify(values), myHeaders);
      // dispatch(fetchCartData());
      // history.push('/maps');
    } catch (err) {console.log(err)}

  }

  return (
    <Formik 
    enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          // style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px'}}
          <Form style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px', maxWidth: '450px'}}>
            {/* <FormikControl
              control='radio'
              label='צד המשלם'
              name='modeOfContact'
              options={RadioOptions}
            /> */}
            <Grid item xs={12}>
              <Typography  variant="h4" >הוספת תגית</Typography>
            </Grid>

            {morDetails0 && 
            // <Grid item xs={12}>
            //   <Typography>כתובת יעד</Typography>
            // </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם תגית'
                  name='name'
                />
              </Grid>
            </Grid>
          }
            <Button variant='outlined' type='submit' disabled={!formik.isValid}>
              הוסף תגית
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddContactTag
