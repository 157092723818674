import { AddCircleOutlineOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from "react"

import "./SearchBar.css";
// import 'react-datepicker/dist/react-datepicker.css'
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import ItemsEditButtons from "./ItemsEditButtons";

const divInTd = {maxWidth: '370px', padding: '6px', background: '#f1f1f1', minWidth: '310px', margin: '4px', marginTop: '16px'}
function ItemSingleSchedule({
  obj,
  index,
  allSubItems,
}) {

  
  
  
  const userProfile = useSelector((state) => state.ui.userProfile);
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [totalPages, setTotalPages] = useState([]);
  const [currentIndexInTable, setCurrentIndexInTable] = useState(null);
  const [objWasDel, setObjWasDel] = useState('ff');
  const [employeeLink, setEmployeeLink] = useState(false);
  const [startDate, setStartDate] = useState(new Date());


  const ChangeCurrentIndexInTable = (index) => {
    if(currentIndexInTable === index){
      setCurrentIndexInTable(null)
    } else {
      setCurrentIndexInTable(index)
    }
  }

  // useEffect(() => {
  //   console.log('{obj.id_manager}', obj.id_manager && obj.id_manager)
  //   console.log('{obj.id_client}', obj.id_client && obj.id_client)
  //   console.log('{obj.from...}', 'העמסה:', 
  //     obj.from_id_warehouse ? obj.from_id_warehouse
  //     : obj.from_id_clients_client)
  //   console.log('{obj.from...}', `פריקה:`, 
  //     obj.to_id_warehouse ? obj.to_id_warehouse
  //               : obj.to_id_clients_client)
  // },[])

  return (
        <>
          <tr key={obj._id} style={{border: '1px solid #bbb'}}>
              {/* {Object.values(obj).map((value, index2) => ( */}
              <td key={index+'-'+index+1} onClick={() => {ChangeCurrentIndexInTable(index)}}> <AddCircleOutlineOutlined color="primary" /> {obj._id}</td>
              <td key={index+'-'+index+2} >{obj.packs_pallet}</td>
              {objWasDel === obj._id ? 
                  <td key={index+'-'+index+3} style={{color: 'red'}}>איש קשר זה נמחק לצמיתות!</td>
                : 
                <td key={index+'-'+index+4} >{obj.id_client && obj.id_client.first_name}</td>
              }
              <td key={index+'-'+index+5} >{
                obj.from_id_warehouse ? obj.from_id_warehouse.first_name
                : obj.from_id_clients_client.first_name
              }</td>
              <td key={index+'-'+index+6} >{
                obj.to_id_warehouse ? obj.to_id_warehouse.first_name
                : obj.to_id_clients_client.first_name
              }</td>
              <td key={index+'-'+index+7} >{obj.id_manager && obj.id_manager.name}</td>
              <td key={index+'-'+index+8} >{
                obj.where_to === 'warehouse_to_clients_client' ? 'ממחסן ללקוח'
                : obj.where_to === 'clients_client_to_warehouse' ? 'מלקוח למחסן' 
                : obj.where_to === 'warehouse_to_warehouse' ? 'ממחסן למחסן'
                : obj.where_to === 'clients_client_to_clients_client' ? 'מלקוח ללקוח' : null
              }</td>
          </tr>
          <tr key={index+'-dfh'} style={{border: '1px solid #bbb'}}>
            <td key={index+'-'+index+10} colspan="7" style={{marginBottom: '20px'}}>
              <ItemsEditButtons 
                obj={obj} 
                employeeLink={employeeLink}
                AllEmployee={userProfile.AllEmployee}
                startDate={startDate}
              />
            </td>
          </tr>
          {currentIndexInTable === index &&
          <>
            <tr key={index}>
                
                <td key={index+'-'+index+1} colspan="7" style={{marginBottom: '20px'}}>   
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>     
                    <div style={divInTd}>
                        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>כל הפריטים</div>
                        {allSubItems.map((objSub, indexSub) => {
                          return objSub.uuidNumber ===  obj.uuidNumber ?
                          <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>קוד פריט</div>
                                <div>{objSub.uuidNumber}</div>
                            </div>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>מספר משטח</div>
                                <div>{objSub.current_pallet}</div>
                            </div>
                          </div>
                          : null
                        })}
                    </div>
                {/* </div> */}
                {/* ))} */}
            {/* </tr> */}
          {/* <tr key={index}> */}
                {/* <div key={index+'-'+index+1} colspan="7" style={{marginBottom: '20px'}}>         */}
                    <div style={divInTd}>
                        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`חברת משלוחים: ${obj.id_manager && obj.id_manager.name}`}</div>
                          <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>כתובת</div>
                                <div>{obj.id_manager && obj.id_manager.address}</div>
                            </div>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>טלפון</div>
                                <div>{obj.id_manager && obj.id_manager.phone1}</div>
                            </div>
                          </div>
                    </div>
                {/* </div> */}
            {/* </tr> */}

          {/* <tr key={index}> */}
                {/* <div key={index+'-'+index+1} colspan="7" style={{marginBottom: '20px'}}>         */}
                    <div style={divInTd}>
                        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`לקוח: ${obj.id_client && obj.id_client.first_name}`}</div>
                          <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>כתובת</div>
                                <div>{obj.id_client && obj.id_client.address}</div>
                            </div>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>טלפון</div>
                                <div>{obj.id_client && obj.id_client.phone1}</div>
                            </div>
                          </div>
                    </div>
                {/* </td> */}
            {/* </tr> */}



            {/* <tr key={index}> */}
              {/* <td key={index+'-'+index+1} colspan="7" style={{marginBottom: '20px'}}>         */}
                  <div style={divInTd}>
                      <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`העמסה: ${
                        obj.from_id_warehouse ? obj.from_id_warehouse.first_name
                        : obj.from_id_clients_client.first_name
                        }`}</div>
                        <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>כתובת</div>
                              <div>{ obj.from_id_warehouse ? obj.from_id_warehouse.address
                        : obj.from_id_clients_client.address}</div>
                          </div>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>טלפון</div>
                              <div>{ obj.from_id_warehouse ? obj.from_id_warehouse.phone1
                        : obj.from_id_clients_client.phone1}</div>
                          </div>
                        </div>
                  </div>
                {/* </td> */}
            {/* </tr> */}


            {/* <tr key={index}> */}
              {/* <td key={index+'-'+index+1} colspan="7" style={{marginBottom: '20px'}}>         */}
                  <div style={divInTd}>
                      <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`פריקה: ${
                        obj.to_id_warehouse ? obj.to_id_warehouse.first_name
                        : obj.to_id_clients_client.first_name
                        }`}</div>
                        <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>כתובת</div>
                              <div>{obj.to_id_warehouse ? obj.to_id_warehouse.address
                        : obj.to_id_clients_client.address}</div>
                          </div>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>טלפון</div>
                              <div>{obj.to_id_warehouse ? obj.to_id_warehouse.phone1
                        : obj.to_id_clients_client.phone1}</div>
                          </div>
                        </div>
                  </div>
                </div>
              </td>
            </tr>
            </>
          }

        </>
  )
}

export default ItemSingleSchedule