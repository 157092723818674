import React, {useContext, useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from "axios";
import FormikControl from './FormikControl'
import { useHistory } from 'react-router'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import hebrew from 'date-fns/locale/he';
import { format } from 'date-fns'

import { uiActions } from '../../../store/ui-slice';
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';
import { Avatar, Button, Grid, IconButton, ListItem, ListItemAvatar, Tab, Tabs, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ListIcon from '@mui/icons-material/List';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import AddMedia from "../addMedia/AddMedia";


// import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
// import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import SearchIcon from '@mui/icons-material/Search';
import FormikUiAddContact from './FormikUiAddContact';
import SearchContacts from '../../../contacts/searchContacts';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../UIElements/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemText from '@mui/material/ListItemText';





// import './UiWithFormik.css';
import './forumStyle.css';
import { letterSpacing } from '@mui/system';

const buttonStepForum_numbrer = {
    borderRadius: '30px',
    width: '30px',
    height: '30px',
    padding: '4px 10px 0px 0px',
    background: '#1976d2',
    color: '#fff',
    fontWeight: '700',
    margin: '0px 0px 8px 8px',
}
const buttonStepForum_text = { color: '#ooo', paddingTop: '4px' }
function AddPackagesForTransportation (props) {



const auth = useContext(AuthContext);
const userProfile = useSelector((state) => state.ui.userProfile);
const getCitiesTwo = async (str) => {
  try {
    let searchableCity = str.replace(/,/g, "");
    let url = "/api/contacts/autocomplete_two?city=" + searchableCity;
        const response = await fetch(url,
          {method: 'GET',
          body: null,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            },
          }
        );
        let dataContacts = await response.json();
        dataContacts = dataContacts.filter( (el) => el.user_id === userProfile._id)    
    return dataContacts;
  } catch (error) {
    console.error(error);
  }
};

  
  const usersClientsFromContacts = useSelector((state) => state.ui.userProfile.AllClients);
  
  const changeFormikAddressValues = (numInArray) => {
    if(formRef) {// formRef ? 
    formRef.current.values.location_o[numInArray] = 'sdgs sdgs k' 
    // : null 
    console.log("formRef.current.values::",formRef.current.values)
  }
    // console.log("formik.values:", formik.values)
  }


  const allFormInputName = ['id_manager','id_client','id_emploeey',
  'date_delivery','where_to','packs_pallet','packs_bags',
  'packs_cartons','packs_people','description',
  'location_o','location_d','contact_O_r_D',
  'payment','vehicle','physical_work','id_tag_route'];


  const chooseAddSubject = [
    ['מיקומים','places',<AddLocationAltOutlinedIcon sx={{ fontSize: 40 }} />],
    ['אינדקס','index',<MenuBookOutlinedIcon sx={{ fontSize: 40 }} />]
  ]
  
  const formTypeOptions = [
      { title: 'איש קשר', subTitle: 'הוספת איש קשר רגיל לכל מטרה', value: 'person', subject: 'index'},
      { title: 'עובד', subTitle: 'הוספת נהג/עובד לו תכולו לשייך משטחים/פריטים לשינוע', value: 'employee', subject: 'index'},
      { title: 'לקוח', subTitle: 'תוכלו לציין עבור איזה לקוח מבוצע עבודה וכן תוכלו לקבל עבודות מלקוחות אליכם', value: 'client', subject: 'index'},
      { title: 'לקוח קצה', subTitle: 'לכל לקוח תוכלו להוסיף תחתיו לקוח קצה. כך תוכלו לעבוד מול חברות המבקשים לשלוח פריטים ללקוחות קצה שלהם.', value: 'clients_client', subject: 'index'},
      { title: 'מחסן', subTitle: 'בכל פריט תוכלו להגדיר מאיזה מחסן יועמס/יופרק הפריט', value: 'warehouse', subject: 'index'},
      { title: 'תווית למסלול', subTitle: 'יש לכם מסלול עם כתובות? צרו תווית ואחר כך תוסיפו תחתיו כתובות ואחכ לקבל מסלול מחושב כולל נקודות במפה', value: 'tag_route', subject: 'index'},


    { title: 'פריטים לשינוע - לקוחות ומחסנים', subTitle: 'הוספת פריט לשינוע עם לקוחות ומחסנים שמורים  במערכת', value: 'c_to_w',
      inputes: ['id_manager','id_client','id_emploeey',
      'date_delivery','where_to','packs_pallet','packs_bags',
      'packs_cartons','packs_people','description'], subject: 'places' },
    { title: 'פריטים לשינוע - מאיש קשר לאיש קשר', subTitle: 'הוספת פריט מאיש קשר לאיש קשר', value: 'contact_to_contact',
    inputes: ['id_manager','id_client','id_emploeey',
    'date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D'] , subject: 'places'},
    { title: 'הוספת פריט', subTitle: 'הוספת פריט מאיש קשר לאיש קשר', value: 'c_o_d',
    inputes: ['date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D','payment','vehicle','physical_work','id_connected_mover','id_connected_businesses'] , subject: 'places'},
    { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
    inputes: ['location_d','id_tag_route'] , subject: 'places'},
    { title: 'מוצא ויעד', subTitle: 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', value: 'o_and_d',
    inputes: ['location_o','location_d'] , subject: 'places'},
    { title: 'דרושים', subTitle: 'הוספת פריט למחלקת הדרושים למציאת נהג לשינוע', value: 'jobs',
    inputes: ['date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D',
    'payment','vehicle','physical_work','id_connected_mover','id_connected_businesses'] , subject: 'places'},
    { title: 'קו קבוע לפי יום - עבור המטריקס', subTitle: 'הוסף מוצא ויעד קבוע באחד מימי השבוע, כך נוכל לשלוח לך עבודות על הדרך', value: 'location_by_day',
    inputes: ['date_delivery', 'location_o','location_d','vehicle'] , subject: 'places'},
    { title: 'הובלת דירה', subTitle: 'מילוי טופס להובלת דירה או משרד', value: 'home_office' ,
    inputes: ['id_manager','id_client','id_emploeey',
    'date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description','contact_O_r_D',
    'payment','vehicle','physical_work'], subject: 'places'}
  ];
  
  // const ClientsClientOptions = [
  //   { key: 'אושר עד חיפה', value: '666' },
  //   { key: 'אושר עד כנות', value: '777' },
  //   { key: 'יוחננוף טבריה', value: '888' },
  //   { key: 'מחסני השוק צפת', value: '999' },
  //   { key: 'יש השומר', value: '11222' }
  // ];

  const dispatch = useDispatch();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [morDetails0,setMorDetails0] = useState(true)
  const [morDetails1,setMorDetails1] = useState([true,'1'])
  const [morDetails2,setMorDetails2] = useState([true,'1'])
  const [morDetails3,setMorDetails3] = useState(false)
  const [morDetails4,setMorDetails4] = useState(false)
  const [morDetails5,setMorDetails5] = useState(false)
  const [morDetails6,setMorDetails6] = useState([true,'1'])
  const [morDetails7,setMorDetails7] = useState(false)
  const [morDetails8,setMorDetails8] = useState(false)
  const [displayJobForm,setDisplayJobForm] = useState(false)
  const [displayDandOForm,setDisplayDandOForm] = useState(false)
  const [valueDateMode, setValueDateMode] = React.useState(0);
  const [dateType, setDateType] = useState('date');
  const [switchMatrixx, setSwitchMatrixx] = useState('fgfg');
  const [optionsTwo, setOptionsTwo] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [autoCompleteC, setAutoCompleteC] = useState([]);
  const [autoCompleteO, setAutoCompleteO] = useState([]);
  const [locationO, setlocationO] = useState([]);
  const [locationD, setlocationD] = useState([]);
  const [autoCompleteD, setAutoCompleteD] = useState([]);
  const [contact_O_r_D, setContact_O_r_D] = useState('');
  const [typeOfForm, setTypeOfForm] = useState(props.typeOfForm);
  // const [typeOfForm, setTypeOfForm] = useState(['','','',[]]);
  const [showTypeOfFormOptions, setShowTypeOfFormOptions] = useState(true);
  const [showAddSubject, setShowAddSubject] = useState('places');
  //  contact_to_contact 
  // location_tag 
  const [createTagRoute, setCreateTagRoute] = useState(false);
  const [createContactName, setCreateContactName] = useState(false);
  const [searchContactName, setSearchContactName] = useState(false);
  const [optionsManagersIds, setOptionsManagersIds] = useState([]);
  const [optionsConnected_movers, setOptionsConnected_movers] = useState([]);
  const [optionsConnected_businesses, setOptionsConnected_businesses] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [pointNum, setPointNum] = useState([{num: 1}]);
  const [allUsersClientContacts, setAllUsersClientContacts] = useState([]);
  const [allEmployeeContacts, setAllEmployeeContacts] = useState([]);
  const [warehousesOptions, setWarehousesOptions] = useState([]);
  const [tagRouteOptions, setTagRouteOptions] = useState([]);
  const [clientsClientOptions, setClientsClientOptions] = useState([]);
  const [sendedMessage, setSendedMessage] = useState('');
  const [changed, setChanged] = useState(false);
  const [addressesFieldsDetails, setAddressesFieldsDetails] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [cityO, setCityO] = useState('');
  const [cityD, setCityD] = useState('');
  const [cityLt, setCityLt] = useState('');
  const [oneImage, setOneImage] = useState(null);
  const [allSubLocations, setAllSubLocations] = useState([]);

  // const [addLocationO, setAddLocationO] = useState(false);
const handleSelectChange = (e) => {
  console.log("value Tag>", e)
    const currentCat = e;
    console.log("currentCat",currentCat)
    setAllSubLocations([])  
        // }
        const fetchContactsData3 = async () => {
          // console.log("auth.token:", auth.token)
          const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
            let urlJobs
            let pageNumber
            let autoCompleteId
            
            let locationsByAdminTag = 'locationsByAdminTag'
          urlJobs = `/api/items/${locationsByAdminTag}/user/${storedDataOfUser2.userId}?startdate=all&page=0&autocompleteid=autoCompleteId&tagId=${currentCat}`
          const response = await fetch(
            urlJobs,
            {
              method: 'GET',
              body: null,
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
              },
            }
          );
          const dataContacts = await response.json();
          console.log(dataContacts)

          let allTagRouteOptions = []
          
          
          if(dataContacts.contacts){
            dataContacts.contacts.map(elem => {
              allTagRouteOptions.push({key: elem.title, value: elem._id})
            });
            setAllSubLocations(
                [...allTagRouteOptions]
              )
          }
          return dataContacts;
        };
        try {
          fetchContactsData3();
        } catch (error) {
          console.log("error NEAR:", error)
        }
}

  let formRef = useRef();
  useEffect(() => {
    // formRef.current.values.location_o[0] = 'sdgs sdgs k'
    if(!showTypeOfFormOptions && showAddSubject === 'places'){
      console.log("formRef.current.values",formRef.current.values)
    }
  },[formRef, showTypeOfFormOptions])

  useEffect(() => {
      console.log("autoCompleteCCCC",autoCompleteC)
  }, [autoCompleteC])
  
  useEffect(() => {
      console.log("autoCompleteD??",autoCompleteD)
  },[autoCompleteD])


  const pushCommentToCommentsItem = (e) => {
    setOneImage(e.image)
    let TEMPallUserContacts = []
    console.log("e>>", e)
    // allUserContacts.forEach((item,index) => {
    //   if(e.objectId !== item._id){
    //   TEMPallUserContacts.push(item)
    //   }
    //   if(e.objectId === item._id){
    //     let onlyThis = item;
    //     onlyThis.comments.push({...e})
    //     TEMPallUserContacts.push(onlyThis)
    //   }
    // })
    // setAllUserContacts([...TEMPallUserContacts])
  }


  const setAutoCompleteCFromAddContactHandler = (e) => {
    console.log("setAutoCompleteCFromAddContactHandler_E!", e)
    if(contact_O_r_D === 'C'){
      setAutoCompleteC(  [[0, e.contact]])
    }
    if(contact_O_r_D === 'O'){
      setAutoCompleteO(  [[0, e.contact]])
    }
    if(contact_O_r_D === 'D'){
      setAutoCompleteD(  [[0, e.contact]])
    }
  }
  const setAutoCompleteCFromSearchContactHandler = (e) => {
    console.log("setAutoCompleteCFromSearchContactHandler_E!", e)
    if(contact_O_r_D === 'C'){
      setAutoCompleteC([[0, e]])
      setSearchContactName(false)
    }
    if(contact_O_r_D === 'O'){
      setAutoCompleteO([[0, e]])
      setSearchContactName(false)
    }
    if(contact_O_r_D === 'D'){
      setAutoCompleteD([[0, e]])
      setSearchContactName(false)
    }
  }

  const handleSwitchTrueOrFalse = (e) => {
    console.log('switch hes been clicked!');
    switcher = !switcher;
    console.log(switcher);
    // setSwitchMatrixx(switcher);
    // console.log(e)
  };

  const updateAddressesFields = (objNum, addessesFields,typeLocation) => {
    console.log("addessesFields", addessesFields)
    console.log("objNum", objNum)
    // console.log("locationO", locationO)
    // setAddressesFieldsDetails([...addessesFields])
    if(typeLocation === 'o'){
      const indexExistInArrayO = locationO.filter( (singleO) => {
        console.log("singleO[0] => ",singleO[0]) 
          if (singleO[0] === objNum){
            return singleO[0] === objNum
          }
        } 
      )

      console.log("indexExistInArray.length => ", indexExistInArrayO.length, indexExistInArrayO)
      if (indexExistInArrayO.length === 0){
        setlocationO(  [...locationO , [objNum, addessesFields]])
      } else if (indexExistInArrayO.length > 0) {
        const newlocationO = locationO.filter((singleO) => singleO[0] !== objNum);
        setlocationO(  [...newlocationO , [objNum, addessesFields]])
      }
    }
    if(typeLocation === 'd'){
      const indexExistInArrayD = locationD.filter( (singleD) => {
        console.log("singleD[0] => ",singleD[0]) 
          if (singleD[0] === objNum){
            return singleD[0] === objNum
          }
        } 
      )

      console.log("indexExistInArrayD.length => ", indexExistInArrayD.length, indexExistInArrayD)
      if (indexExistInArrayD.length === 0){
        setlocationD(  [...locationD , [objNum, addessesFields]])
      } else if (indexExistInArrayD.length > 0) {
        const newlocationD = locationD.filter((singleD) => singleD[0] !== objNum);
        setlocationD(  [...newlocationD , [objNum, addessesFields]])
      }
    }

  }


  let points = Array.from(Array(pointNum).keys());

  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);

  useEffect(() => {
    setOptionsManagersIds(
      [{key: userProfile.name, value: userProfile._id}]
    );
    userProfile.managers_ids &&
    userProfile.managers_ids.map(elem => {
      setOptionsManagersIds(
        [...optionsManagersIds, {key: elem.name, value: elem._id}]
        )
      });
  }, [userProfile])
  
  useEffect(() => {
    let TEMP_optionsConnected_movers = []
    userProfile.connected_movers &&
      
    userProfile.connected_movers.map(elem => {
      TEMP_optionsConnected_movers.push({key: elem.business_name, value: elem._id})
    });

    setOptionsConnected_movers(
      [...optionsConnected_movers, ...TEMP_optionsConnected_movers]
    )

    userProfile.connected_businesses &&
    userProfile.connected_businesses.map(elem => {
      setOptionsConnected_businesses(
        [...optionsConnected_businesses, {key: elem.name, value: elem._id}]
        )
    });
    
  },[userProfile])
    
  useEffect(() => {
    setAllUsersClientContacts(
      // [{key: userProfile.name, value: userProfile._id}]
      [{key: 'בחר לקוח', value: userProfile._id}]
    );
    let allContactsFromRedux = [];
    userProfile.AllClients &&
    userProfile.AllClients.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    userProfile.wathIsMyClientNumber &&
    userProfile.wathIsMyClientNumber.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    setAllUsersClientContacts(
        [{key: 'בחר לקוח', value: userProfile._id}, ...allContactsFromRedux]
      )
  },[userProfile])


  useEffect(() => {
    setAllEmployeeContacts(
      // [{key: userProfile.name, value: userProfile._id}]
      [{key: 'בחר נהג', value: ''}]
    );
    let allContactsFromRedux = [];
    userProfile.AllEmployee &&
    userProfile.AllEmployee.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    userProfile.wathIsMyClientNumber &&
    userProfile.wathIsMyClientNumber.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    setAllEmployeeContacts(
        [{key: 'בחר נהג', value: ''}, ...allContactsFromRedux]
      )
  },[userProfile])


  useEffect(() => {
    let allTagRoute = [];
    // userProfile.AllTagRoute &&
    // userProfile.AllTagRoute.map(elem => {
    userProfile.AllAdminTagRoute &&
    userProfile.AllAdminTagRoute.map(elem => {
      allTagRoute.push({key: elem.first_name, value: elem._id})
    });
    setTagRouteOptions(
        [...allTagRoute]
      )
  },[userProfile])

  useEffect(() => {
    let allWarehouses = [];
    userProfile.warehouses &&
    userProfile.warehouses.map(elem => {
      allWarehouses.push({key: elem.first_name, value: elem._id})
    });
    setWarehousesOptions(
        [...allWarehouses]
      )
  },[userProfile])

  useEffect(() => {
    let allClientsClient = [];
    userProfile.AllClientsClient &&
    userProfile.AllClientsClient.map(elem => {
      allClientsClient.push({key: elem.first_name, value: elem._id})
    });
    userProfile.AllManagersClientsClient &&
    userProfile.AllManagersClientsClient.map(elem => {
      allClientsClient.push({key: elem.first_name, value: elem._id})
    });
    setClientsClientOptions(
        [...allClientsClient]
      )
      console.log("allClientsClient:", allClientsClient)
  },[userProfile])




  const onChangeTwo = async (e) => {
    console.log(e.target)
    if (e.target.value) {
      let data = await getCitiesTwo(e.target.value);
      setOptionsTwo(data);
    }
  };
  
  const clearInput = () => {
    console.log('ffff')
    setOptionsTwo([]);
    setWordEntered("");
  };


let switcher = true;
  // useEffect(() => {
  // if(props.formPurpesTitle === 'd'){
  //   setDisplayDandOForm(false);
  //   }
  // if(props.formPurpesTitle !== 'd'){
  //   setDisplayDandOForm(true);
  //   }
  // },[props.formPurpesTitle])

  // useEffect(() => {
  //   console.log('autoCompleteO => ',autoCompleteO)
  // },[autoCompleteO])

  // useEffect(() => {
  //   console.log('autoCompleteC => ',autoCompleteC)
  // },[autoCompleteC])

  // useEffect(() => {
  //   console.log('pointNum => ',pointNum)
  // },[pointNum])


  // const handleChange4 = (event, newValuee) => {
  //   setValueDateMode(newValuee);
  // };
  
  // const handleSwitchTrueOrFalse = (e) => {
  //   console.log('switch hes been clicked!');
  //   switcher = !switcher;
  //   console.log(switcher);
  //   // setSwitchMatrixx(switcher);
  //   // console.log(e)
  // };

  // const auth = useContext(AuthContext);
  let allMapCats = []

// props.mapCatsDropDown.forEach((single, idx, allArray)  => {
//   let objVal = single.title;
//   if(objVal){
//     const obj = {value: single.id, key: single.title};
//     allMapCats.push(obj);
//     // console.log(single)
//   }
// })

  const initialValues = {
    pointNum: '',
    typeOfForm: '',
    city_o: '',
    city_d: '',
    city_lt: '',
    title: '',
    video_link: '',
    web_link: '',
    id_manager: '',
    id_connected_mover: '',
    id_connected_businesses: '',
    where_to: ['warehouse_to_clients_client'],
    from_id_warehouse: [''],
    to_id_clients_client: [''],
    from_id_clients_client: [''],
    to_id_warehouse: [''],
    form_purpes: props.formPurpesTitle,
    description: '',
    location_o: [' '],
    // email: '',
    // bio: '',
    // ['id_manager','id_client','id_emploeey',
    // 'date_delivery','where_to','packs_pallet','packs_bags',
    // 'packs_cartons','packs_people','description',
    // 'location_o','location_d','contact_O_r_D',
    // 'payment','vehicle','physical_work'];



    cat: '',
    selectedAddressD: {
      address: 'props.selectedAddressD.address',
      lat: 'props.selectedAddressD.selectedLat',
      lng: 'props.selectedAddressD.selectedLng',
      entry: '',
      floor: '',
      apartment: '',
      description: ''
    },
    selectedAddressO: {
      address: 'props.selectedAddressO.address',
      lat: 'props.selectedAddressO.selectedLat',
      lng: 'props.selectedAddressO.selectedLng',
      entry: '',
      floor: '',
      apartment: '',
      description: ''
    },
    c_contact_id: autoCompleteC._id,
    o_contact_id: autoCompleteO._id,
    o_contact: {
      o_name: '',
      o_phone: '',
      o_address: ''
    },
    d_contact_id: autoCompleteD._id,
    d_contact: {
      d_name: '',
      d_phone: '',
      d_address: ''
    },
    // packs: {
      packs_bags: [''],
      packs_cartons: [''],
      packs_people: [''],
      packs_pallet: [''],
    // },
    // packs_pallet,
    vehicle: {
      type: '',
      ramp: '',
      forklift: '',
      lever: ''
    },
    physical_work: {
      d: '',
      o: '',
      desc: ''
    },
    payment: {
      amount: '',
      whopays: '',
      method: '',
    },
    modeOfdate: dateType,
    date_delivery: '',
    day: '',
    matrix: ''
      }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    // cat: Yup.string().required('בחר קטגוריה'),
    // title: Yup.string().required('הזן כותרת')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

  const history = useHistory();


  useEffect(() => {
    console.log("format>> startDate):",format(startDate, 'MM/dd/yyyy'))
  },[startDate])


  const onSubmit = async values => {
    values.biz_type = ''
    if(userProfile.biz_type) {
      values.biz_type = userProfile.biz_type
    }
    if(oneImage){
      values.image = oneImage
    }
    console.log('Form data values:', values)


    console.log("startDate:",startDate[0],startDate[1],startDate[2])
    console.log("format> startDate):",format(startDate, 'MM/dd/yyyy'))
    console.log("format>-new Date(2014, 1, 11), 'MM/dd/yyyy'):",format(new Date(), 'MM/dd/yyyy'))

    console.log('Form data values222:', values)
    try {
      setSendedMessage('מעבד בקשה...')
      console.log("pointNum=>",pointNum)
      var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + auth.token);
      const responseData = await sendRequest('/api/items/add_coupon', 'POST', JSON.stringify(values), myHeaders);
      setSendedMessage('הפריטים התווספו לשינוע בהצלחה!')
      console.log("responseData<>", responseData)
      // setSendedMessage(`${props.typeOfForm[1]} חדש נוצר בהצלחה!`)


      // if (responseData.contact.form_purpes === 'location_tag') {
      //     console.log("dispatch addLocationToTagRoute")
      //     dispatch(
      //       uiActions.addLocationToTagRoute({
      //       location_d: responseData.contact.location_d,
      //       _id: responseData.contact._id,
      //       user_id: responseData.contact.user_id,
      //       form_purpes: responseData.contact.form_purpes,
      //       id_tag_route: responseData.contact.id_tag_route,
      //       title: responseData.contact.title,
      //       web_link: responseData.contact.web_link,
      //       video_link: responseData.contact.video_link,
      //       })
      //     );
      //   var oldItems = JSON.parse(localStorage.getItem('userProfile'));

      //   var newItem = {
      //     location_d: responseData.contact.location_d,
      //       _id: responseData.contact._id,
      //       user_id: responseData.contact.user_id,
      //       form_purpes: responseData.contact.form_purpes,
      //       id_tag_route: responseData.contact.id_tag_route,
      //       title: responseData.contact.title,
      //       web_link: responseData.contact.web_link,
      //       video_link: responseData.contact.video_link,
      //   };

      //   oldItems.AllLocationTag.push(newItem);
      //   localStorage.setItem(
      //     'userProfile',
      //     JSON.stringify(oldItems)
      //   );
      //   // formRef.current.values = ''
      //   // console.log("formRef.current.values", formRef)


      // }

      if (responseData.contact.form_purpes === 'o_and_d') {
          console.log("dispatch add_O_and_D_Locations")
          dispatch(
            uiActions.addOAndDLocations({
              _id: responseData.contact._id,
              user_id: responseData.contact.user_id,
              form_purpes: responseData.contact.form_purpes,
              location_d: responseData.contact.location_d,
              location_o: responseData.contact.location_o,

            })
          );
        
        var oldItems = JSON.parse(localStorage.getItem('userProfile'));

        var newItem = {
          _id: responseData.contact._id,
          user_id: responseData.contact.user_id,
          form_purpes: responseData.contact.form_purpes,
          location_d: responseData.contact.location_d,
          location_o: responseData.contact.location_o,
        };

        oldItems.All_O_and_D_Locations.push(newItem);
        localStorage.setItem(
          'userProfile',
          JSON.stringify(oldItems)
        );
        }
      // history.push('/maps');

    } catch (err) {console.log(err)}

  }
  return (
    <>
    <div style={{
      display: 'flex',
      background: '#1976d23d',
      padding: '12px',
      borderRadius: '6px',
      }}>
      <img
        style={{maxWidth: '150px',
          border: '1px solid rgb(229 229 229)',
          borderRadius: '8px',
          margin: '0px 16px',
          padding: '4px',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px'
        }}
        alt={userProfile.name_biz ? userProfile.name_biz : 'Add Coupon'}
        src={userProfile.imageOne_biz && userProfile.imageOne_biz}
      />
      <div>
        <div>{userProfile.name_biz && userProfile.name_biz}</div>
        <div>{userProfile.location && userProfile.address_biz && 'כתובת העסק: '+userProfile.address_biz}</div>
        <div>{userProfile.biz_type && 'קטגוריה: '+userProfile.biz_type}</div>
      </div>
    </div>
    <Formik 
    // enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {formik => {
        return (
          <Form>

            {typeOfForm[3].includes('id_tag_route') &&
              <>
                <FormikControl
                  handleSelectChange={handleSelectChange}
                  control='select'
                  // type='number'
                  label='בחר שם מסלול'
                  name='id_tag_route'
                  options={tagRouteOptions}
                />
                {allSubLocations.length > 0 &&
                  <FormikControl
                    // handleSelectChange={handleSelectChange}
                    control='select'
                    // type='number'
                    label='בחר מיקום'
                    name='id_location_tag'
                    options={allSubLocations}
                  />
                }
              </>
              }
            
            


            


          {typeOfForm[3].includes('date_delivery') &&
            <>
            <LocalizationProvider 
              dateAdapter={AdapterDateFns}
              adapterLocale={hebrew}
              >
              <DatePicker
                label="בחר תאריך"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            </>
          }

        {pointNum.map((obj, index) => (
          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {/* <div style={{"width":"35px","height":"35px","background":"#545454","display":"flex","alignItems":"center","justifyContent":"center","color":"#fff","fontSize":"18px","fontWeight":"600","borderRadius":"19px"}}>{index+1}</div> */}
              {/* <div style={{"width":"35px","height":"35px","background":"#545454","display":"flex","alignItems":"center","justifyContent":"center","color":"#fff","fontSize":"18px","fontWeight":"600","borderRadius":"19px"}}>{obj.num-1}</div> */}
              <div style={{display: 'flex', flexDirection: 'column', marginRight: '6px', marginLeft: '30px'}}>
              </div>
            </div>
            <div>

          </div>

            


          
            {/* // } */}
      {/* } */}

      {typeOfForm[3].includes('location_d') &&
            <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='string'
                  label='כותרת קופון'
                  name='title'
                />
                {/* <FormikControl
                  control='input'
                  type='string'
                  label='בחר סרטון'
                  name='video_link'
                /> */}

                <Grid item xs={12}>
                  <FormikControl
                    control='input'
                    type='textarea'
                    label='תיאור קופון'
                    name='description'
                    value=' '
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddMedia
                    // objectId={obj._id}
                    pushCommentToCommentsItem={pushCommentToCommentsItem}
                    addImageTo='coupon'
                  />
                </Grid>
                {oneImage && 
                  <div>
                    <div>תמונת קופון:</div>
                    {/* <div>{oneImage}</div> */}
                    <Avatar sx={{width: '200px', height: '200px'}} alt="Travis Howard" src={oneImage} />
                  </div>

                }
                <FormikControl
                  control='input'
                  type='string'
                  label='לינק למימוש הקופון'
                  name='web_link'
                />
            </Grid>
          }

            </div>
            ))}

            
            <Button variant='contained' type='submit' disabled={!formik.isValid}>
              {props.submitTextButton ? props.submitTextButton : 'צור חדש'}
            </Button>
            <Grid item xs={6}>
                <Typography style={{color: 'green'}}>{sendedMessage}</Typography>
            </Grid>
          </Form>
        )
      }}
    </Formik>
    {/* } */}
    </>
  )
}



export default AddPackagesForTransportation
