import React from 'react';

import Card from '../../shared/components/UIElements/Card';
import ProductItem from './ProductItem';
import Button from '../../shared/components/FormElements/Button';
import './PlaceList.css';

const ProductsList = props => {
  if (props.items.length === 0) {
    return (
      <div className="place-list center">
        <Card>
          <h2>No places found. Maybe create one?</h2>
          <Button to="/places/new">Share Place</Button>
        </Card>
      </div>
    );
  }

//   {
//     "id": 18111,
//     "id_user": "7",
//     "id_store": "193",
//     "id_merchant": 0,
//     "ar_merchant": "",
//     "date": "2020-04-21T07:02:00+03:00",
//     "date_uptate": "2021-02-12T10:42:27+02:00",
//     "visible": "true",
//     "category": "1009",
//     "category_name": "יודאיקה",
//     "sub_category": "1049",
//     "sub_category_name": "שולחן שבת",
//     "sub_sub_category": "1312",
//     "sub_sub_category_name": "מגשים לנרות",
//     "sub_sub_plus": "",
//     "sub_sub_plus2": "",
//     "sku": "701513",
//     "title": "מגש מוכסף + ידיות 60060",
//     "short_description": "מגש נרות גדול ומהודר\r\n\r\nמגש לחלות/נרות שבת בעיצוב יפה וצעיר\r\n\r\nמוכסף\r\n\r\nזוג ידיות \r\n\r\nמידות: 36X50 ס\"מ, עומק 3 ס\"מ.\r\n\r\n",
//     "more_details": "",
//     "technical_details": "",
//     "color": "",
//     "status_stock": "",
//     "inventory": "9",
//     "pre_price": "",
//     "price": 149.9,
//     "kg": "",
//     "length": "",
//     "height": "",
//     "width": "",
//     "shipping_type": "",
//     "shipping_cost": "",
//     "file_name": "https://s3-eu-west-1.amazonaws.com/konimboimages/system/photos/4840749/original/80404485d3ad78a87856053693f81c7a.jpg",
//     "file_name2": "",
//     "file_name3": "",
//     "file_name4": "",
//     "file_name5": "",
//     "file_name6": "",
//     "vars_type": "",
//     "vars_text": "",
//     "txt_var": "",
// },
  return (
    <div className="row" style={{display: 'flex', flexWrap: 'wrap'}}>
      {props.items.map((place, index) => (
        <ProductItem
          key={place.id}
          theIndexProp={index}
          id={place.id}
          store_id={place.id_store}
          productProps={place}
          effectListDuration={props.effectListDuration}
        />
      ))}
    </div>
  );
};

export default ProductsList;
