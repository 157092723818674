import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import './UiWithFormik.css';
import { TramRounded } from '@mui/icons-material';

function RadioButtons (props) {
  const { disabled, objectNum, label, name, options, ...rest } = props
  return (

    <div className='radio-form-container'>
      <label>{label}</label>
      <div className='form-control' style={{flexWrap: 'wrap'}}>
        <Field name={name} >
          {({ field }) => {
            return options.map(option => {
              // console.log('field::::', field)
              return (
                <React.Fragment key={option.key}>
                  <div className='chackbox_container' style={props.style}>
                    {objectNum ? <label htmlFor={option.value+objectNum}>{option.key}</label> 
                    : <label htmlFor={option.value}>{option.key}</label>
                    }
                    {/* <label htmlFor={option.value}>{option.key}</label> */}
                  <input
                  style={{width: '18px'}}
                    type='radio'
                    id={objectNum ? option.value+objectNum : option.value}
                    // id={option.value}
                    {...field}
                    {...rest}
                    value={option.value}
                    checked={field.value === option.value || option.checked === true}
                    disabled={disabled && true}
                  />
                  </div>
                </React.Fragment>
              )
            })
          }}
        </Field>
        <ErrorMessage component={TextError} name={name} />
      </div>
    </div>
  )
}

export default RadioButtons
