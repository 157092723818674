import { AddCircleOutlineOutlined, ContactSupportOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from 'react-redux';
import { format } from "date-fns";

import SearchIcon from '@mui/icons-material/Search';
import React, { useContext, useEffect, useState } from "react"
import TextField from "@mui/material/TextField";
import axios from "axios";

import "./SearchBar.css";
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import Modal from "../shared/components/UIElements/Modal";
import FormikUiAddContact from '../shared/components/FormikElements/FormikUiAddContact';
import { NavLink, useParams } from "react-router-dom";
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import FormikControl from "../shared/components/FormikElements/FormikControl";



const btnStyle = {
  backgroundColor: "black",
  color: "white",
  border: "none",
  padding: "5px 10px",
}




function ItemsEditButtons({obj,employeeLink,AllEmployee,startDate}) {

  const userProfile = useSelector((state) => state.ui.userProfile);
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  
  const { typeOfItems } = useParams();
  // const [currentIndexInTable, setCurrentIndexInTable] = useState(null);
  const [createContactName, setCreateContactName] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [employeeName, setEmployeeName] = useState(false);
  const [startDateSchadule, setStartDateSchadule] = useState(false);
  const [dateNow, setDateNow] = useState( new(Date) );
  const [objWasDel, setObjWasDel] = useState('ff');
  
  useEffect(() => {
    setDateNow(new Date())
    setStartDateSchadule(startDate)
    console.log("dateNow:",dateNow)
  },[])


  useEffect(() => {
    console.log("obj.id_emploeey",obj)
    obj.id_emploeey && AllEmployee && AllEmployee.forEach((item, pos) => {
      if(item._id === obj.id_emploeey._id){
        console.log("item-imployee:",item.first_name)
        setEmployeeName(item.first_name)
      }
    })
  },[obj])
  // const handleChange = evt => {
  //   // const { value } = evt.target;
  //   setEmployeeLink(evt.target.value);
  //   console.log(value,evt);
  // };

  // const ChangeCurrentIndexInTable = (index) => {
  //   if(currentIndexInTable === index){
  //     setCurrentIndexInTable(null)
  //   } else {
  //     setCurrentIndexInTable(index)
  //   }
  // }

  const [value, setValue] = useState("");

  

  const confirmDeleteHandler = async (objectId,path) => {
    setShowConfirmModal(false);

    const deleteContactById = async () => {
      try {
        await sendRequest(
          path,
          'DELETE',
          null,
          {
            Authorization: 'Bearer ' + auth.token
          }
          );
          // props.onDelete(props.id);
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await deleteContactById();
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };


  const confirmEmployeeCLinkHandler = async (objectId,path,employeeLink,uuidNumber,startDate,typeOfEdit,) => {
    // setShowConfirmModal(false);
      setStartDateSchadule(startDate)
      console.log(objectId,path,employeeLink,uuidNumber,startDate)
      var d = new Date();
      d.toLocaleString()
      console.log("new Date()",new Date())
      console.log("AllEmployee",AllEmployee.length)
      AllEmployee && AllEmployee.forEach((item, pos) => {
        if(item._id === employeeLink){
          console.log("item-imployee:",item.first_name)
          setEmployeeName(item.first_name)
        }

        // return (
        //   <MenuItem key={item.first_name} value={item._id}>
        //     {item.first_name}
        //   </MenuItem>
        // )
      })
    const employeeLinkHandler = async () => {

      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      try {
        await sendRequest(
          // path,
          `/api/items/update_items_by_uuid_and_user/${typeOfEdit}/user/${storedDataOfUser.userId}`,
          'POST',
          JSON.stringify({
            objectId,
            path,
            employeeLink,
            uuidNumber,
            startDate: startDate.toLocaleString(),
            byUuidOrObjectId: 'all_by_uuid',
            status: 'waiting_for_execution',
            statusNameForStatusArray: 'manager hes scheduled item to emploeey',
            dateNow: (new(Date)).toLocaleString()
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
          );
          // props.onDelete(props.id);
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await employeeLinkHandler();
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };

  const confirmCancelEmployeeLinkHandler = async (objectId,path,employeeLink,uuidNumber,startDate,typeOfEdit,) => {
      var d = new Date();
      d.toLocaleString()
      AllEmployee && AllEmployee.forEach((item, pos) => {
        if(item._id === employeeLink){
          console.log("item-imployee:",item.first_name)
          setEmployeeName('')
        }
      })
    const employeeLinkHandler = async () => {

      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      try {
        await sendRequest(
          `/api/items/update_items_by_uuid_and_user/${typeOfEdit}/user/${storedDataOfUser.userId}`,
          'POST',
          JSON.stringify({
            objectId,
            path,
            employeeLink,
            uuidNumber,
            startDate: startDate.toLocaleString(),
            byUuidOrObjectId: 'all_by_uuid',
            status: 'pending',
            statusNameForStatusArray: 'manager hes canceled item to emploeey',
            dateNow: (new(Date)).toLocaleString()
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
          );
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await employeeLinkHandler();
  };

  useEffect(() => {
    console.log("employeeLink:", employeeLink)
    
  },[employeeLink])

  return (

    <div>
      {/* <div>{format(new Date(startDate), "MM/dd/yyyy")}</div> */}
        <Button 
          disabled={employeeLink === false && true}
          // variant="contained"
          variant={employeeName ? `contained` : 'outlined'}
          color={employeeName ? "success" : "primary"}
          onClick={() => {
              confirmEmployeeCLinkHandler(obj._id, `/api/contacts/${obj._id}/${obj.uuidNumber}/employeeLink`,employeeLink,obj.uuidNumber,startDate,'manager_adds_items_to_emploeey')
              
              // openMapHandler()
          }}>
          {employeeName ? `שוייך ל ${employeeName} - ${obj.start_date_for_emploeey ?
           
          obj.start_date_for_emploeey.split(',')[0].replace(/\./g, '/') :
            // format(new Date(obj.start_date_for_emploeey), "MM/dd/yyyy") :
            // obj.start_date_for_emploeey  :
            format(startDateSchadule, "MM/dd/yyyy")}` : 'שיוך לעובד'}
        </Button>
        
        {employeeName &&
          <Button 
          disabled={employeeName === false && true}
          variant="contained"
          // variant={employeeName ? `contained` : 'outlined'}
          // color={employeeName ? "success" : "primary"}
          onClick={() => {
            confirmCancelEmployeeLinkHandler(obj._id, `/api/contacts/${obj._id}/${obj.uuidNumber}/employeeLink`,employeeLink,obj.uuidNumber,startDate,'manager_canceled_items_to_emploeey')
            
            // openMapHandler()
          }}>ביטול שיוך</Button>
        }

        <Button 
            onClick={() => {
            confirmDeleteHandler(obj._id, `/api/contacts/${obj._id}`)
          setShowConfirmModal(true);
          
          // openMapHandler()
        }} variant='outlined'>מחק</Button>
        {showConfirmModal &&
          <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
            <Modal
              show={showConfirmModal}
              onCancel={() => setShowConfirmModal(false)}
              header="Are you sure?"
              footerClass="place-item__modal-actions"
              // footer={
              // }
            >
              <p>
                בטוחים שרוצים למחוק איש קשר זה?
              </p>
                <React.Fragment>
                  <Button variant='outlined' inverse onClick={() => setShowConfirmModal(false)}>
                    בטל
                  </Button>
                  <Button variant='outlined' danger onClick={() => {
                    confirmDeleteHandler(obj._id, `/api/contacts/${obj._id}`)
                    // console.log("obj._Id",JSON.stringify(obj._id))
                    setObjWasDel(obj._id)
                    // ChangeCurrentIndexInTable(index)
                  }
                    }>
                    מחק לצמיתות
                  </Button>
                </React.Fragment>
            </Modal>
          </div>
        }
        <Button onClick={() => {
          setCreateContactName(true)
          // openMapHandler()
        }} variant='outlined'>עדכן</Button>
        {createContactName &&
        <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
          <Modal
            show={createContactName}
            onCancel={() => setCreateContactName(false)}
            header={'עדכון איש קשר'}
            // contentClass="place-item__modal-content"
            // footerClass="place-item__modal-actions"
            // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
            closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
          >
            <Grid item xs={12}>
              <FormikUiAddContact

              formPurpesTitle='edit_contact'
              edit_contact={obj}
              zIndex={'zIndex6'}
              // mapCatsDropDown={{}}
              selectedAddressD={'selectedAddressD_RD'}
              selectedAddressO={'selectedAddressO_RD'}
              />
            </Grid>
          </Modal>
        </div>
        }
    </div>

  )
}

export default ItemsEditButtons