import React, { useEffect, useState } from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import './i18n'


import store from './store/index';
import './index.css';
import App from './App';

let beforePromptEvent;
window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
})

export const usePWAInstallPrompt = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        if (beforePromptEvent) {
            setDeferredPrompt(beforePromptEvent);
        }
    }, [beforePromptEvent])
    
    const handlePrompt = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
        }
    }

    return [deferredPrompt, handlePrompt];
};



ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();