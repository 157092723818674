import React, { useState , useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Axios from "axios";
import ProductsList from '../components/ProductsList';


const AllProducts = (props) => {
  
  const [page, setPage] = useState(0);
  const [effectListDuration, setEffectListDuration] = useState(true);
  // const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleMoreInList = (event) => {
    // console.log("scrolleee");
    // const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    // console.log("scrollTop:"+scrollTop);

    // if (scrollHeight - scrollTop === clientHeight) {
      againQuery();
      setEffectListDuration(false);
    // }
  };
  const [morOfSame, setMorOfSame] = useState(true);

  const cat_id = useParams().cat_id;
  const sub_id = useParams().sub_id;
  const sub_sub_id = useParams().sub_sub_id;
  let rest_store_cats_link = '';
  if(cat_id){
    //setMorOfSame(false)
    rest_store_cats_link = `cat/${cat_id}`;
  }
  if(sub_id){
    rest_store_cats_link = `sub/${sub_id}`;
  }
  if(sub_sub_id){
    rest_store_cats_link = `sub_sub/${sub_sub_id}`;
  }
// console.log(cat_id)
  const [prodList, setProdList] = useState([]);

  useEffect( () => {
    setEffectListDuration(true);
    setPage(0);
    Axios.get(`http://localhost:5001/api/store/193/${rest_store_cats_link}` , {params:{page: 0}}).then((response) => {

        setProdList(response.data);
    });  
    console.log(prodList);
  },[cat_id,sub_id,sub_sub_id])
//למה הpage לא משתנה בפעם הראשונה של שינוי הסטייט שלו?
  const againQuery = () => {
    setPage(page+1);
      //alert(page)
    Axios.get(`http://localhost:5001/api/store/193/${rest_store_cats_link}` , {params:{page: page+1}}).then((response) => {
        setProdList([...prodList , ...response.data]);
    });  
    console.log(prodList);
}
  

  const userId = useParams().userId;
  const loadedProduct = prodList.filter(place => place.id > 0);
    return (
      <>
    <ProductsList  items={loadedProduct} effectListDuration={effectListDuration} />
    {loading && <div className="load_mor_products" style={{width: "100%" , 
    textAlign: "center" , paddingTop: "800px"}} onMouseEnter={handleMoreInList}></div>}
    </>
    );
};


export default AllProducts;
