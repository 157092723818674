import { AddCircleOutlineOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from "react"

import "./SearchBar.css";
// import 'react-datepicker/dist/react-datepicker.css'
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import ItemsByJobs from "./ItemsByJobs";


function ItemsJobs({
  allItems,
  selectedOD,
  allSubItems,
  zIndex,
  colNames = [
      'קוד פריט',
      'כמות',
      'לקוח',
      'העמסה',
      'פריקה',
      'חברת משלוחים',
      'סוג'
    ],
  pageNum = 0,
  pageSize = 10,
  width = "auto",
  height = "auto",
  totalList,
}) {

  
  
  
  const userProfile = useSelector((state) => state.ui.userProfile);
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [value, setValue] = useState(null);
  const { typeOfItems } = useParams();
  const [allUserContacts, setAllUserContacts] = useState([]);
  const [allDatesWithSchedule, setAllDatesWithSchedule] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [currentIndexInTable, setCurrentIndexInTable] = useState(null);
  const [employeesOptions, setEmployeesOptions] = useState([]);

  
  

  useEffect(() => {
    let allEmployees = [];
    userProfile.AllEmployee &&
    // console.log("userProfile.allEmployee:", userProfile.AllEmployee)
    userProfile.AllEmployee.map(elem => {
      // console.log("userProfile.allEmployee:elem", elem)
      allEmployees.push({key: elem.first_name, value: elem._id})
    });
    setEmployeesOptions(
        [...allEmployees]
      )
  },[userProfile])

  // useEffect(() => {
  //     console.log("employeesOptions:::",employeesOptions)
  // },[employeesOptions])

  const ChangeCurrentIndexInTable = (index) => {
    if(currentIndexInTable === index){
      setCurrentIndexInTable(null)
    } else {
      setCurrentIndexInTable(index)
    }
  }


  useEffect(() => {
      // console.log(allItems)
      setAllUserContacts(allItems)
  },[allItems])
let allDatesWithSchedule2 = [];

  useEffect(() => {
      // console.log("allUserContact2s: ",allUserContacts)
      if(allUserContacts){
        allUserContacts.forEach(element => {
          if (element) {
            
            console.log("elementtt:",element.start_date_for_emploeey)
            // console.log("element:",element.start_date_for_emploeey.split(',')[0])
            if (element.start_date_for_emploeey) {
              if (!allDatesWithSchedule2.includes(element.start_date_for_emploeey.split(',')[0])) {  
                allDatesWithSchedule2.push(element.start_date_for_emploeey.split(',')[0])
              }
            }
          }
        });
        setAllDatesWithSchedule([...allDatesWithSchedule2])
        console.log("allDatesWithSchedule2:",allDatesWithSchedule2)
      }
  },[allUserContacts])

  // useEffect(() => {
  //       console.log("allDatesWithSchedule:",allDatesWithSchedule)
  // },[allDatesWithSchedule])



  return (

    <div style={{zIndex: zIndex, position: 'absolute', background: '#fff', padding: '10px', width: "100%", maxWidth: '500px'}} >
      {allUserContacts && typeOfItems === 'jobs' && (
        <>
        {allDatesWithSchedule.map((objDate, index3) => (
          <>
          {
            index3 === 0 &&
          <div>
            {/* <div style={{textAlign: 'center', background: '#1976d2', fontSize: '20px', padding: '8px', color: '#fff', margin: '20px 0px'}}>לוז ל - {objDate}</div> */}
            {/* {userProfile.AllEmployee.map((employee, index2) => {
              return employee ?
            <>  */}
                <ItemsByJobs
                employee={'employee'}
                scheduleDate={objDate}
                colNames={colNames}
                allSubItems={allSubItems}
                allItems={allUserContacts}
                selectedOD={selectedOD}
                />
            {/* </> 
            : null
          })} */}
            </div>
        }
            </>
        ))}

        </>
      )}
    </div>
  )
}

export default ItemsJobs