import React, { useState , useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import StoreCatList3 from './StoreCatList3';
//import Axios from "axios";
// import $ from 'jquery';



const StoreCatList2 = (props) => {

    return (

        <React.Fragment>
            {props.all_cats.map(place2 => ( 
                place2.parent_id_cat ==  props.current_cat && place2.grandparent_cat_id < 1 && 
                <li>
                    <NavLink to={`/store/sub/${place2.id}`} exact className="nav-link align-middle">
                        {/* <a class="second_cat_in_nav" href="?sub=1040"> */}
                        {/* </a> */}
                            {place2.name}
                        </NavLink>
                    <ul>
                        <StoreCatList3 all_cats2={props.all_cats} current_cat2={place2.id} />
                    </ul>
                </li>         
            ))}
        </React.Fragment>
         



    )
    
};


export default StoreCatList2;
