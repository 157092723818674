import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: { 
    cartIsVisible: false,
    usersLocation: {lat: 31.7751296, lng: 35.176448},
    userProfile: {name: 'אורח'},
    usersClientContacts: [],
    notification: {status: 'hhh',
    title: 'wait',
    message: 'wait for it',}
  },
  reducers: {
    addTagToTagRoute(state, action) {
      const newItem = action.payload;
        state.userProfile.AllTagRoute.push({
          _id: newItem._id,
          user_id: newItem.user_id,
          type: newItem.type,
          first_name: newItem.first_name
        });
    },
    addAllCountryCityCatSubCatIdsTags(state, action) {
      const newItem = action.payload;
        state.userProfile.AllCountryCityCatSubCatIdsTags.push({
          _id: newItem._id,
          user_id: newItem.user_id,
          type: newItem.type,
          first_name: newItem.first_name,
          parent_id: newItem.parent_id
        });
    },
    removeTagFromTagRoute(state, action) {
      const newItem = action.payload;

      state.userProfile.AllTagRoute = state.userProfile.AllTagRoute.filter((item) => item._id !== newItem._id);
    },
    updateShareableTagFromTagRoute(state, action) {
      const newItem = action.payload;
      console.log("newItem RRR>", newItem)
      const existingItem = state.userProfile.AllTagRoute.find((item) => item._id === newItem._id);
      console.log("existingItem RRR>", existingItem)
      existingItem.shareable = newItem.shareable
    },
    addLocationToTagRoute(state, action) {
      const newItem = action.payload;
      state.userProfile.AllLocationTag.push({
          _id: newItem._id,
          location_d: newItem.location_d,
          user_id: newItem.user_id,
          form_purpes: newItem.form_purpes,
          first_name: newItem.first_name,
          id_tag_route: newItem.id_tag_route,
          title: newItem.title,
          web_link: newItem.web_link,
          video_link: newItem.video_link,
        });
    },
    addOAndDLocations(state, action) {
      const newItem = action.payload;
      state.userProfile.All_O_and_D_Locations.push({
          _id: newItem._id,
          user_id: newItem.user_id,
          form_purpes: newItem.form_purpes,
          location_d: newItem.location_d,
          location_o: newItem.location_o,
        });
    },
    updateUserDetailsAfterEditingProfile(state, action) {
      console.log("action.payload:",action.payload)
      const newItem = action.payload;
      state.userProfile.name = newItem.name
      state.userProfile.phone1 = newItem.phone1
      state.userProfile.address = newItem.address
      state.userProfile.imageOne = newItem.imageOne
      state.userProfile.location = newItem.location

    },
    toggle(state) {
      state.cartIsVisible = !state.cartIsVisible;
    },
    showNotification(state, action) {
      state.notification = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
      };
    },
    // setUserProfile(state, action) {
    //   state.userProfile = {
    //     name: action.payload.name,
    //     id: action.payload.id,
    //     address: action.payload.address,
    //     image: action.payload.image,
    //   };
    // },
    setUserProfile(state, action) {
      state.userProfile = action.payload;
    },
    setUsersLocation(state, action) {
      state.usersLocation = action.payload;
    },
    setUsersClientContacts(state, action) {
      state.usersClientContacts = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;