import { AddCircleOutlineOutlined, ContactSupportOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from 'react-redux';
import { format } from "date-fns";

import SearchIcon from '@mui/icons-material/Search';
import React, { useContext, useEffect, useState } from "react"
import TextField from "@mui/material/TextField";
import axios from "axios";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PaidIcon from '@mui/icons-material/Paid';
import PlaceIcon from '@mui/icons-material/Place';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';

import "./SearchBar.css";
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { cartActions } from '../store/places-slice';
import Modal from "../shared/components/UIElements/Modal";
import AddMedia from "../shared/components/addMedia/AddMedia";
import FormikUiAddContact from '../shared/components/FormikElements/FormikUiAddContact';
import { NavLink, useParams } from "react-router-dom";
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import FormikControl from "../shared/components/FormikElements/FormikControl";
import waze from '../waze.png'
import gwaze from '../google-maps-marker.png'



const startEndStyle_s = {
  backgroundColor: '#165900',
  borderRadius: '20px',
  width: '14px',
  height: '15px',
  color: '#ffffff',
  padding: '1px 3px 0px 0px',
  position: 'absolute',
  top: '-8px',
  left: '6px',
  margin: '0',
  lineHeight: '11px',
  fontSize: '14px',
}
const startEndStyle_e = {
  backgroundColor: '#ff0000',
  borderRadius: '20px',
  width: '14px',
  height: '15px',
  color: '#ffffff',
  padding: '1px 3px 0px 0px',
  position: 'absolute',
  top: '20px',
  left: '7px',
  margin: '0',
  lineHeight: '11px',
  fontSize: '14px',
}
const btnStyle = {
  backgroundColor: "black",
  color: "white",
  border: "none",
  padding: "5px 10px",
}




function ItemsEditSingle({
  pushCommentToCommentsItem,
  removeItemFromArr,
  centerMapLeafletButton,
  obj,
  employeeLink,
  AllEmployee,
  startDate,
  fromOrTo,
  addDeleteButton,
  addMediaButton,
  paidButton,
  completedButton,
  vipButton,
  startOrEndButton,
  wazeButton,
  wazeLatLn
}) {

  const userProfile = useSelector((state) => state.ui.userProfile);
  const dispatch = useDispatch();
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  
  const { typeOfItems } = useParams();
  // const [currentIndexInTable, setCurrentIndexInTable] = useState(null);
  const [createContactName, setCreateContactName] = useState(false);
  const [changeStartOrEnd, setChangeStartOrEnd] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [employeeName, setEmployeeName] = useState(false);
  const [startDateSchadule, setStartDateSchadule] = useState(false);
  const [dateNow, setDateNow] = useState( new(Date) );
  const [objWasDel, setObjWasDel] = useState('ff');
  const [checkThisJob, setCheckThisJob] = useState(false);
  const [vip, setVip] = useState(false);
  const [startOrEnd, setStartOrEnd] = useState(false);
  
  useEffect(() => {
    if(obj.status){
      if(obj.status[0] === 'o_completed'){
        setCheckThisJob(true)
      }
    }
    if(obj.vip){
      if(obj.vip === 'vip'){
        setVip(true)
      }
    }
    if(obj.start_or_end){
      setStartOrEnd(obj.start_or_end)
    }
  },[obj])

  useEffect(() => {
    setDateNow(new Date())
    setStartDateSchadule(startDate)
    // console.log("dateNow:",dateNow)
  },[])


  useEffect(() => {
    console.log("obj.id_emploeey",obj)
    obj.id_emploeey && AllEmployee && AllEmployee.forEach((item, pos) => {
      if(item._id === obj.id_emploeey._id){
        // console.log("item-imployee:",item.first_name)
        setEmployeeName(item.first_name)
      }
    })
  },[obj])
  // const handleChange = evt => {
  //   // const { value } = evt.target;
  //   setEmployeeLink(evt.target.value);
  //   console.log(value,evt);
  // };

  // const ChangeCurrentIndexInTable = (index) => {
  //   if(currentIndexInTable === index){
  //     setCurrentIndexInTable(null)
  //   } else {
  //     setCurrentIndexInTable(index)
  //   }
  // }

  const [value, setValue] = useState("");

  

  const confirmDeleteHandler = async (objectId,path,uuidNumber,typeOfEdit) => {
    setShowConfirmModal(true);

    // console.log(objectId,path,uuidNumber,startDate)
    const dltLink = async () => {

      const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
      try {
        await sendRequest(
            // path,
            `/api/items/update_items_by_uuid_and_user/${typeOfEdit}/user/${storedDataOfUser2.userId}`,
            'POST',
            JSON.stringify({
              objectId,
              // path,
              // employeeLink,
              // uuidNumber,
              byUuidOrObjectId: 'all_by_id',
              // status: 'o_completed',
              statusNameForStatusArray: 'dlt item',
              // dateNow: (new(Date)).toLocaleString()
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
          );
          // setCheckThisJob(true)
          removeItemFromArr(objectId)
          // props.onDelete(props.id);
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await dltLink();
    
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };


  const completedLinkHandler = async (objectId,path,uuidNumber,typeOfEdit) => {
    console.log(objectId,path,uuidNumber,startDate)
    const completedLink = async () => {

      const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
      try {
        await sendRequest(
            // path,
            `/api/items/update_items_by_uuid_and_user/${typeOfEdit}/user/${storedDataOfUser2.userId}`,
            'POST',
            JSON.stringify({
              objectId,
              path,
              employeeLink,
              uuidNumber,
              byUuidOrObjectId: 'all_by_id',
              status: 'o_completed',
              statusNameForStatusArray: 'completed origin delivery',
              dateNow: (new(Date)).toLocaleString()
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
          );
          setCheckThisJob(true)
          // props.onDelete(props.id);
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await completedLink();
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };
  const vipLinkHandler = async (objectId,path,uuidNumber,typeOfEdit) => {
    console.log(objectId,path,uuidNumber,startDate)
    const completedLink = async () => {

      const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
      try {
        await sendRequest(
            // path,
            `/api/items/update_items_by_uuid_and_user/${typeOfEdit}/user/${storedDataOfUser2.userId}`,
            'POST',
            JSON.stringify({
              objectId,
              path,
              employeeLink,
              uuidNumber,
              byUuidOrObjectId: 'all_by_id',
              status: 'vip',
              vip: 'vip',
              statusNameForStatusArray: 'make this location to vip',
              dateNow: (new(Date)).toLocaleString()
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
          );
          
          setVip(!vip)
          // props.onDelete(props.id);
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await completedLink();
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };


  const startOrEndLinkHandler = async (objectId,path,uuidNumber,typeOfEdit,start_or_end) => {
    console.log("ffffobjectId",objectId,path,uuidNumber,startDate)
    const completedLink = async () => {

      const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
      try {
        await sendRequest(
            // path,
            `/api/items/update_items_by_uuid_and_user/${typeOfEdit}/user/${storedDataOfUser2.userId}`,
            'POST',
            JSON.stringify({
              objectId,
              path,
              employeeLink,
              uuidNumber,
              byUuidOrObjectId: 'all_by_id',
              status: start_or_end,
              start_or_end: start_or_end,
              statusNameForStatusArray: 'make this location to be start_or_end',
              dateNow: (new(Date)).toLocaleString()
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
          );
          
          setStartOrEnd(start_or_end)
          
          // props.onDelete(props.id);
        } catch (err) {
          console.log('NOT start_or_end!!!',err,objectId,path)
        }
    }
    await completedLink();
    console.log("start_or_end>>>", start_or_end)
    setChangeStartOrEnd(false)    
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };

  const deldeteLinkHandler = async (objectId,path,uuidNumber,typeOfEdit) => {
    console.log(objectId,path,uuidNumber,startDate)
    const completedLink = async () => {

      const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
      try {
        await sendRequest(
            // path,
            `/api/items/update_items_by_uuid_and_user/${typeOfEdit}/user/${storedDataOfUser2.userId}`,
            'POST',
            JSON.stringify({
              objectId,
              path,
              employeeLink,
              uuidNumber,
              byUuidOrObjectId: 'all_by_id',
              status: 'o_completed',
              statusNameForStatusArray: 'completed origin delivery',
              dateNow: (new(Date)).toLocaleString()
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
          );
          setCheckThisJob(true)
          // props.onDelete(props.id);
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await completedLink();
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };

  const confirmEmployeeCLinkHandler = async (objectId,path,employeeLink,uuidNumber,startDate,typeOfEdit) => {
    // setShowConfirmModal(false);
      setStartDateSchadule(startDate)
      console.log(objectId,path,employeeLink,uuidNumber,startDate)
      var d = new Date();
      d.toLocaleString()
      console.log("new Date()",new Date())
      console.log("AllEmployee",AllEmployee.length)
      AllEmployee && AllEmployee.forEach((item, pos) => {
        if(item._id === employeeLink){
          console.log("item-imployee:",item.first_name)
          setEmployeeName(item.first_name)
        }

        // return (
        //   <MenuItem key={item.first_name} value={item._id}>
        //     {item.first_name}
        //   </MenuItem>
        // )
      })
    const employeeLinkHandler = async () => {

      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      try {
        await sendRequest(
          // path,
          `/api/items/update_items_by_uuid_and_user/${typeOfEdit}/user/${storedDataOfUser.userId}`,
          'POST',
          JSON.stringify({
            objectId,
            path,
            employeeLink,
            uuidNumber,
            startDate: startDate.toLocaleString(),
            byUuidOrObjectId: 'all_by_uuid',
            status: 'waiting_for_execution',
            statusNameForStatusArray: 'manager hes scheduled item to emploeey',
            dateNow: (new(Date)).toLocaleString()
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
          );
          // props.onDelete(props.id);
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await employeeLinkHandler();
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };

  const confirmCancelEmployeeLinkHandler = async (objectId,path,employeeLink,uuidNumber,startDate,typeOfEdit,) => {
      var d = new Date();
      d.toLocaleString()
      AllEmployee && AllEmployee.forEach((item, pos) => {
        if(item._id === employeeLink){
          console.log("item-imployee:",item.first_name)
          setEmployeeName('')
        }
      })
    const employeeLinkHandler = async () => {

      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      try {
        await sendRequest(
          `/api/items/update_items_by_uuid_and_user/${typeOfEdit}/user/${storedDataOfUser.userId}`,
          'POST',
          JSON.stringify({
            objectId,
            path,
            employeeLink,
            uuidNumber,
            startDate: startDate.toLocaleString(),
            byUuidOrObjectId: 'all_by_uuid',
            status: 'pending',
            statusNameForStatusArray: 'manager hes canceled item to emploeey',
            dateNow: (new(Date)).toLocaleString()
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
          );
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await employeeLinkHandler();
  };

  useEffect(() => {
    console.log("employeeLink:", employeeLink)
    
  },[employeeLink])

  return (

    <>

        {paidButton === true && 
          <>
            {
            // allUserContacts.map((obj, index) => (
              fromOrTo === 'to' && obj.payment && obj.payment.whopays === 'd' &&
              <>
                  <div style={{marginRight: '10px'}}>
                    <PaidIcon style={{fontSize: '33px'}} />
                  {/* {obj.payment.amount} - {obj.payment.method} - {obj.payment.whopays} */}
                  </div>
              </>
            // ))
            }
            {
            // allUserContacts.map((obj, index) => (
              fromOrTo === 'from' && obj.payment && obj.payment.whopays === 'o' &&
              <>
                  <div style={{marginRight: '10px'}}>
                    <PaidIcon style={{fontSize: '33px'}} />
                  {/* {obj.payment.amount} - {obj.payment.method} - {obj.payment.whopays} */}
                  </div>
              </>
            // ))
            }
          </>
        }
        {centerMapLeafletButton === true && 
        <div>
          <PlaceIcon style={{fontSize: '29px'}} 
            onClick={() => {
              console.log("[wazeLatLn[0], wazeLatLn[1]]>>",[wazeLatLn[0], wazeLatLn[1]])
              dispatch(
                cartActions.replaceCenterMapLL({
                  centerMapLL: [wazeLatLn[0], wazeLatLn[1]]
                })
              );
              // cheangeCenterMapLeaflet({ lat: wazeLatLn[0], lng: wazeLatLn[1] })                  
              }}
          /> 
        </div>
        
        }
        {completedButton === true && 
        <div>
          
          {checkThisJob === false ? <CheckCircleOutlinedIcon style={{fontSize: '33px'}} 
          onClick={() => {
                  completedLinkHandler(obj._id, `/api/contacts/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'o_compledet')
                  
                  // openMapHandler()
              }}
          /> : <CheckCircleIcon style={{fontSize: '33px', color: 'green'}}/>}
        </div>
        
        }
        {startOrEndButton === true && obj.user_id === userProfile._id &&
        <div style={{position: 'relative'}}>
          

          
          {startOrEnd === 'start' ? <>
          {/* {obj.start_or_end} */}
          <div style={{...startEndStyle_s}}>s</div>
          <MultipleStopIcon style={{fontSize: '27px', color: '#0f3d6b'}} 
          onClick={() => {
            setChangeStartOrEnd(true)
                  // startOrEndLinkHandler(obj._id, `/api/contacts/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'make_start_or_end','start')
              }}
          /></> : startOrEnd === 'end' ? <>
          {/* {obj.start_or_end} */}
          <div style={{...startEndStyle_e}}>e</div>
          <MultipleStopIcon style={{fontSize: '27px', color: '#0f3d6b'}}
          onClick={() => {
            setChangeStartOrEnd(true)
            // startOrEndLinkHandler(obj._id, `/api/contacts/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'make_start_or_end','end')
            }}
        /></> : <>
        {/* {obj.start_or_end} */}
        <MultipleStopIcon style={{fontSize: '27px', color: '#0f3d6b'}}
        onClick={() => {
          setChangeStartOrEnd(true)
          // startOrEndLinkHandler(obj._id, `/api/contacts/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'make_start_or_end','end')
          }}
      />
      </>}

      
       {changeStartOrEnd && obj.user_id === userProfile._id &&
        <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
          <Modal
            show={changeStartOrEnd}
            onCancel={() => setChangeStartOrEnd(false)}
            header={'הגדרת התחלת/סוף מסלול'}
            // contentClass="place-item__modal-content"
            // footerClass="place-item__modal-actions"
            // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
            closeButton={<Button variant='contained' onClick={() => setChangeStartOrEnd(false)}>סגור</Button>}
          >
              {/* <p>האם תרצו להגדיר כתובת זו לנקודת התחלה או סוף</p> */}
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  
                  <Button variant='outlined' danger 
                    onClick={() => {
                      // setChangeStartOrEnd(true)
                      startOrEndLinkHandler(obj._id, `/api/contacts/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'make_start_or_end','start')
                    }}
                    // console.log("obj._Id",JSON.stringify(obj._id))
                    // setObjWasDel(obj._id)
                    // ChangeCurrentIndexInTable(index)

                    >
                    הגדר כהתחלת מסלול
                  </Button>
                  <div style={{height: '10px'}}></div>
                  <Button variant='outlined' danger 
                    onClick={() => {
                      // setChangeStartOrEnd(true)
                      startOrEndLinkHandler(obj._id, `/api/contacts/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'make_start_or_end','end')
                    }}
                    // console.log("obj._Id",JSON.stringify(obj._id))
                    // setObjWasDel(obj._id)
                    // ChangeCurrentIndexInTable(index)

                    >
                    הגדר כסוף מסלול
                  </Button>
                  <div style={{height: '10px'}}></div>
                  <Button variant='outlined' inverse onClick={() => setChangeStartOrEnd(false)}>
                    בטל
                  </Button>
                </div>
          </Modal>
        </div>
        }


        </div>
        
        }
        {vipButton === true && 
        <div>
          {vip === false ? <FavoriteBorderIcon style={{fontSize: '27px'}} 
          onClick={() => {
                  vipLinkHandler(obj._id, `/api/contacts/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'make_vip')
              }}
          /> : <FavoriteIcon style={{fontSize: '27px', color: 'green'}}
          onClick={() => {
            vipLinkHandler(obj._id, `/api/contacts/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'make_vip')
            }}
        />}
        </div>
        
        }

        {addMediaButton === true && 
          <AddMedia
          objectId={obj._id}
          obj={obj}
          pushCommentToCommentsItem={pushCommentToCommentsItem}
          />
        }
        {wazeButton === true && 
          <div>
            <a
            href={`https://waze.com/ul?ll=${wazeLatLn[0]},${wazeLatLn[1]}&z=10`}
            target="_blank" rel="noopener noreferrer"
            ><img src={waze} style={{marginRight: '10px', width: "24px", height: "24px", marginRight: "8px", marginTop: "2px"}} alt="waze" />
            </a>
          </div>
        }
        {wazeButton === true && 
          <div>
            <a
            // href={`https://www.google.com/maps/dir/?api=1&origin=34.1030032,-118.41046840000001&destination=${wazeLatLn[0]},${wazeLatLn[1]}`}
            href={`https://www.google.com/maps/dir/?api=1&destination=${wazeLatLn[0]},${wazeLatLn[1]}`}
            // href={`https://waze.com/ul?ll=${wazeLatLn[0]},${wazeLatLn[1]}&z=10`}
            target="_blank" rel="noopener noreferrer"
            ><img src={gwaze} style={{marginRight: '10px', width: "24px", height: "24px", marginRight: "8px", marginTop: "2px"}} alt="waze" />
            </a>
          </div>
        }
        {/* {wazeButton === true && 
          <div>
          <a href={`https://api.whatsapp.com/send?phone=97225666561&amp;text=https://www.haredimnet.co.il/store/udel_store.php?prod_id=17372 חטף טף ישראטויס`} className="y_bold_link link_ask_about_whatsapp" title="">
          WhatsApp
          </a>
          </div>
        } */}

        {addDeleteButton === true && obj.user_id === userProfile._id &&
        <div>
          <DeleteOutlineRoundedIcon style={{fontSize: '26px'}} 
          onClick={() => {
            setShowConfirmModal(true)
            // confirmDeleteHandler(obj._id, `/api/items/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'o_compledet')
            // deldeteLinkHandler(obj._id, `/api/items/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'o_compledet')
            }}
          />
        </div>
        }

        {showConfirmModal &&
          <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
            <Modal
              show={showConfirmModal}
              onCancel={() => setShowConfirmModal(false)}
              header="בטוחים?"
              footerClass="place-item__modal-actions"
              style={{zIndex: '9999'}}
              // footer={
              // }
            >
              <p>
                בטוחים שרוצים למחוק פריט זה?
              </p>
                <React.Fragment>
                  <Button variant='outlined' inverse onClick={() => setShowConfirmModal(false)}>
                    בטל
                  </Button>
                  <Button variant='outlined' danger onClick={() => {
                    confirmDeleteHandler(obj._id, `/api/items/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'dlt_item')
                    // console.log("obj._Id",JSON.stringify(obj._id))
                    // setObjWasDel(obj._id)
                    // ChangeCurrentIndexInTable(index)
                  }
                    }>
                    מחק לצמיתות
                  </Button>
                </React.Fragment>
            </Modal>
          </div>
        }
        {/* <Button onClick={() => {
          setCreateContactName(true)
          // openMapHandler()
        }} variant='outlined'>עדכן</Button> */}
        {createContactName &&
        <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
          <Modal
            show={createContactName}
            onCancel={() => setCreateContactName(false)}
            header={'עדכון איש קשר'}
            // contentClass="place-item__modal-content"
            // footerClass="place-item__modal-actions"
            // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
            closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
          >
            <Grid item xs={12}>
              <FormikUiAddContact

              formPurpesTitle='edit_contact'
              edit_contact={obj}
              zIndex={'zIndex6'}
              // mapCatsDropDown={{}}
              selectedAddressD={'selectedAddressD_RD'}
              selectedAddressO={'selectedAddressO_RD'}
              />
            </Grid>
          </Modal>
        </div>
        }

    </>

  )
}

export default ItemsEditSingle