import { Grid, TextField, Typography, Button } from '@mui/material'
import React, { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux';

import { uiActions } from '../../store/ui-slice';
import Modal from "../../shared/components/UIElements/Modal";
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SyncIcon from '@mui/icons-material/Sync';




const btnStyle = {
  backgroundColor: "black",
  color: "white",
  border: "none",
  padding: "5px 10px",
}


const buttonStyle = {
  background: 'rgb(25 118 210)', 
  borderRadius: '0px',
  fontSize: '15px',
  color: '#fff',
  marginLeft: '6px',
  minWidth: '61px',
  margin: '6px 2px 6px',
  height: '39px',
  border: '1px solid #dbdbdb',
  cursor: 'pointer'
}

function EditRouteButtons(props,{
  zIndex,
  obj
}) {

  const dispatch = useDispatch();
const auth = useContext(AuthContext);
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showMorDetails, setShowMorDetails] = useState(false)
  const [isMoverConnectedToUser, setIsMoverConnectedToUser] = useState(false)
  const [isUserConnectedToRoute, setIsUserConnectedToRoute] = useState(false)

  useEffect(() => {
    const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
    if(props.obj.shareable_users && props.obj.shareable_users.includes(storedDataOfUser2.userId)){
        setIsUserConnectedToRoute(true)
    }
    if(!props.obj.shareable_users || !props.obj.shareable_users.includes(storedDataOfUser2.userId)){
      setIsUserConnectedToRoute(false)
    }
  },[props.obj])


  useEffect(() => {
    if(props.obj.shareable && props.obj.shareable !== undefined){
      if(props.obj.shareable === 'yes'){
        setIsMoverConnectedToUser(true)
      } else if (props.obj.shareable === ''){
        setIsMoverConnectedToUser(false)
      }
    }
    if(!props.obj.shareable || props.obj.shareable === undefined){
        setIsMoverConnectedToUser(false)
    }
  },[])


  const connectToRoute = async (objectId) => {
    console.log(objectId)
    let purpes;
  const connectToMoverFunc = async () => {

        const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
        if (!isUserConnectedToRoute) {
            purpes = 'connect'
          }
        if (isUserConnectedToRoute) {
            purpes = 'disconnect'
        }
        
        // if (!isMoverConnectedToUser) {
          try {
            await sendRequest(
                // path,
                `/api/contacts/addusertoshareabletag/${storedDataOfUser2.userId}`,
                'POST',
                JSON.stringify({
                  objectId,
                  purpes,
                }),
                {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + auth.token
                }
            );

            if (!isUserConnectedToRoute) {
              console.log('CONNECTED TO MOVER!!!', objectId)
              setIsUserConnectedToRoute(true)


              // dispatch(
              //   uiActions.updateShareableTagFromTagRoute({
              //   _id: objectId,
              //   shareable: 'yes',
              //   })
              // );



            }
            if (isUserConnectedToRoute) {
              console.log('DISCONNECTED TO MOVER!!!', objectId)
              setIsUserConnectedToRoute(false)
              // dispatch(
              //   uiActions.updateShareableTagFromTagRoute({
              //   _id: objectId,
              //   shareable: '',
              //   })
              // );
            }
              // setCheckThisJob(true)
              // props.onDelete(props.id);
            } catch (err) {
              console.log('ERROR CONNECTION TO MOVER!!!',err,objectId)
            }
        // }
      }
        await connectToMoverFunc();
        
      // await console.log('DELETED!!!',objectId,path)
      // await alert('איש קשר זה נמחק!')
    };
  const connectToMover = async (objectId) => {
    console.log(objectId)
    let purpes;
  const connectToMoverFunc = async () => {

        const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
        if (!isMoverConnectedToUser) {
            purpes = 'connect'
          }
        if (isMoverConnectedToUser) {
            purpes = 'disconnect'
        }
        
        // if (!isMoverConnectedToUser) {
          try {
            await sendRequest(
                // path,
                `/api/contacts/editshareable/${storedDataOfUser2.userId}`,
                'POST',
                JSON.stringify({
                  objectId,
                  purpes,
                }),
                {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + auth.token
                }
            );

            if (!isMoverConnectedToUser) {
              console.log('CONNECTED TO MOVER!!!', objectId)
              setIsMoverConnectedToUser(true)


              dispatch(
                uiActions.updateShareableTagFromTagRoute({
                _id: objectId,
                shareable: 'yes',
                })
              );



            }
            if (isMoverConnectedToUser) {
              console.log('DISCONNECTED TO MOVER!!!', objectId)
              setIsMoverConnectedToUser(false)
              dispatch(
                uiActions.updateShareableTagFromTagRoute({
                _id: objectId,
                shareable: '',
                })
              );
            }
              // setCheckThisJob(true)
              // props.onDelete(props.id);
            } catch (err) {
              console.log('ERROR CONNECTION TO MOVER!!!',err,objectId)
            }
        // }
      }
        await connectToMoverFunc();
        
      // await console.log('DELETED!!!',objectId,path)
      // await alert('איש קשר זה נמחק!')
    };

  const confirmDeleteHandler = async (objectId,path) => {
    setShowConfirmModal(false);

    const deleteContactById = async () => {
      try {
        await sendRequest(
          path,
          'DELETE',
          null,
          {
            Authorization: 'Bearer ' + auth.token
          }
          );
          dispatch(
            uiActions.removeTagFromTagRoute({
            _id: objectId,
            })
          );
          // props.onDelete(props.id);
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await deleteContactById();
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };


const allDetailsCss = {display:'flex', justifyContent: 'right', maxWidth: '260px', margin: '10px'};
  return (

    <div style={{display: 'flex', justifyContent: 'space-between'}}
      onClick={() => console.log("PROPS:", props, obj)}
    >
      
      {/* <div 
          style={{
            borderRadius: '20px',
            fontSize: '15px',
            color: 'rgb(84 84 84)',
            margin: '6px 0px',
            width: '151px',
            textAlign: 'center',
            height: '32px',
            paddingTop: '3px',
            border: '1px solid rgb(219, 219, 219)',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-evenly'
          }}
      >
        <div style={{paddingTop: '2px'}}>פרטים נוספים</div>
        <div
          onClick={() => setShowMorDetails(!showMorDetails)}
          style={{
                borderRadius: '20px',
                background: '#1976d2',
                fontSize: '15px',
                color: 'rgb(255 255 255)',
                textAlign: 'center',
                height: '24px',
                padding: '-1px',
                border: '1px solid rgb(219, 219, 219)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-evenly'
          }}
        >{!showMorDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}</div>
      </div> */}

                        {/* <div style={allDetailsCss}> */}
                        
                        {/* <Button style={{marginLeft: '8px'}} onClick={() => {
                          setShowConfirmModal(true);
                          
                          // openMapHandler()
                        }} variant='outlined'>מחק</Button> */}
                        {props.buttons.includes('connect_to_shareable_route') &&
                        <div>
                          <Button
                            style={{padding: '4px 7px'}}
                           color={!isUserConnectedToRoute ? '' : 'success'} variant="contained" endIcon={<SyncIcon />}
                            onClick={() => connectToRoute(props.obj.value)}
                            disabled={isLoading}
                          >
                            <span style={{ margin: '0px' }}>{ !isUserConnectedToRoute ? 'השתתף בטיול' : 'בטל השתתפות'}  </span>
                          </Button>
                          {isLoading ? <div style={{fontSize: '12px', marginBottom: '8px', paddingTop: '4px', color: '#767676',  fontWeight: '500'}}>שולח בקשה ...</div> : '' }
                        </div>
                        }



                        {props.buttons.includes('allow_shareable') &&
                        <div>
                          <Button
                            style={{padding: '4px 7px'}}
                           color={!isMoverConnectedToUser ? '' : 'success'} variant="contained" endIcon={<SyncIcon />}
                            onClick={() => connectToMover(props.obj.value)}
                            disabled={isLoading}
                          >
                            <span style={{ margin: '0px' }}>{ !isMoverConnectedToUser ? 'אפשר חיבור' : 'בטל חיבור'}  </span>
                          </Button>
                          {isLoading ? <div style={{fontSize: '12px', marginBottom: '8px', paddingTop: '4px', color: '#767676',  fontWeight: '500'}}>שולח בקשה ...</div> : '' }
                        </div>
                        }
                        {props.buttons.includes('del') &&
                          <div>
                            <DeleteOutlineRoundedIcon style={{fontSize: '33px', color: '#1976d28f'}} 
                            onClick={() => {
                              setShowConfirmModal(true)
                              // confirmDeleteHandler(obj._id, `/api/items/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'o_compledet')
                              // deldeteLinkHandler(obj._id, `/api/items/${obj._id}/${obj.uuidNumber}/employeeLink`,obj.uuidNumber,'o_compledet')
                              }}
                            />
                          {/* </div> */}
                          


                          {showConfirmModal &&
                          <div className='FormikUiAddContactInMape' style={{marginTop: '20px', marginLeft: '20px'}}>
                            <Modal
                              show={showConfirmModal}
                              onCancel={() => setShowConfirmModal(false)}
                              header="Are you sure?"
                              footerClass="place-item__modal-actions"
                              // footer={
                              // }
                            >
                              <p>
                                בטוחים שרוצים למחוק איש קשר זה?
                              </p>
                                <React.Fragment>
                                  <Button variant='outlined' inverse onClick={() => setShowConfirmModal(false)}>
                                    בטל
                                  </Button>
                                  <Button variant='outlined' danger onClick={() => {
                                    confirmDeleteHandler(props.obj.value, `/api/contacts/${props.obj.value}?locationstrue=true`)
                                    // console.log("obj._Id",JSON.stringify(obj._id))
                                    // setObjWasDel(obj._id)
                                    // ChangeCurrentIndexInTable(index)
                                  }
                                    }>
                                    מחק לצמיתות
                                  </Button>
                                </React.Fragment>
                            </Modal>
                          </div>
                        }


                          {/* <Button onClick={() => {
                            setCreateContactName(true)
                            // openMapHandler()
                          }} variant='outlined'>עדכן</Button>
                          {createContactName &&
                          <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                            <Modal
                              show={createContactName}
                              onCancel={() => setCreateContactName(false)}
                              header={'עדכון איש קשר'}
                              // contentClass="place-item__modal-content"
                              // footerClass="place-item__modal-actions"
                              // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                              closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                            >
                              <Grid item xs={12}>
                                <FormikUiAddContact

                                formPurpesTitle='edit_contact'
                                edit_contact={obj}
                                zIndex={'zIndex6'}
                                // mapCatsDropDown={{}}
                                selectedAddressD={'selectedAddressD_RD'}
                                selectedAddressO={'selectedAddressO_RD'}
                                />
                              </Grid>
                            </Modal>
                          </div>
                        } */}
                          </div>
                        }
    </div>
  )
}

export default EditRouteButtons