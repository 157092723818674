import { AddCircleOutlineOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from "react"

import "./SearchBar.css";
// import 'react-datepicker/dist/react-datepicker.css'
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import ItemsScheduleByDate from "./ItemsScheduleByDate";


function ItemsScheduleToday({
  allItems,
  allCoupons,
  operation,
  selectedOD,
  allSubItems,
  zIndex,
  colNames = [
      'קוד פריט',
      'כמות',
      'לקוח',
      'העמסה',
      'פריקה',
      'חברת משלוחים',
      'סוג'
    ],
  pageNum = 0,
  pageSize = 10,
  width = "auto",
  height = "auto",
  totalList,
  setGoLocations,
}) {

  
  
  
  const userProfile = useSelector((state) => state.ui.userProfile);
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [value, setValue] = useState(null);
  const { typeOfItems } = useParams();
  const [allUserContacts, setAllUserContacts] = useState([]);
  const [allDatesWithSchedule, setAllDatesWithSchedule] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [currentIndexInTable, setCurrentIndexInTable] = useState(null);
  const [employeesOptions, setEmployeesOptions] = useState([]);

  
  

  useEffect(() => {
    let allEmployees = [];
    userProfile.AllEmployee &&
    // console.log("userProfile.allEmployee:", userProfile.AllEmployee)
    userProfile.AllEmployee.map(elem => {
      // console.log("userProfile.allEmployee:elem", elem)
      allEmployees.push({key: elem.first_name, value: elem._id})
    });
    setEmployeesOptions(
        [...allEmployees]
      )
  },[userProfile])

  useEffect(() => {
      console.log("SSOOMMTTHHIINNGG")
  },[])

  const ChangeCurrentIndexInTable = (index) => {
    if(currentIndexInTable === index){
      setCurrentIndexInTable(null)
    } else {
      setCurrentIndexInTable(index)
    }
  }


  useEffect(() => {
      // console.log(allItems)
      setAllUserContacts(allItems)
  },[allItems])
let allDatesWithSchedule2 = [];

  useEffect(() => {
      console.log("allUserContact2s: ",allUserContacts)
      if(allUserContacts && operation === 'scheduledtoday'){
        if(allUserContacts.length > 0) {
          
          allUserContacts.forEach(element => {
            if(element.start_date_for_emploeey) {
              // console.log("element:",element.start_date_for_emploeey)
              // console.log("element:",element.start_date_for_emploeey.split(',')[0])
              if(!allDatesWithSchedule2.includes(element.start_date_for_emploeey.split(',')[0]))
              allDatesWithSchedule2.push(element.start_date_for_emploeey.split(',')[0])
            }
          });
          setAllDatesWithSchedule([...allDatesWithSchedule2])
        }
        // console.log("allDatesWithSchedule2:",allDatesWithSchedule2)
      }
  },[allUserContacts])

  useEffect(() => {
        console.log("allDatesWithSchedule:",allDatesWithSchedule)
  },[allDatesWithSchedule])



  return (

    <div style={{zIndex: zIndex, position: 'absolute', background: '#fff', padding: '10px', width: "100%", maxWidth: '500px'}} >
    
      {allUserContacts && typeOfItems === 'scheduledtoday' && (
        <>
        {/* {allDatesWithSchedule.length > 0 && allDatesWithSchedule.map((objDate, index3) => ( */}
        {allDatesWithSchedule.map((objDate, index3) => (
          <div>

                <ItemsScheduleByDate
                employee={'employee'}
                scheduleDate={objDate}
                colNames={colNames}
                allSubItems={allSubItems}
                allItems={allUserContacts}
                selectedOD={selectedOD}
                />
            {/* </> 
            : null
          })} */}
            </div>
        ))}
        {allDatesWithSchedule.length === 0 &&
          <div>

                <ItemsScheduleByDate
                employee={'employee'}
                scheduleDate={'objDate'}
                colNames={colNames}
                allSubItems={allSubItems}
                allItems={allUserContacts}
                selectedOD={selectedOD}
                allCoupons={allCoupons}
                setGoLocations={setGoLocations}
                />
            </div>
        }

        </>
      )}
      {allUserContacts && typeOfItems === 'jobs' && (
        <>
        {/* {allDatesWithSchedule.length > 0 && allDatesWithSchedule.map((objDate, index3) => ( */}
        {allDatesWithSchedule.map((objDate, index3) => (
          <div>

                <ItemsScheduleByDate
                employee={'employee'}
                scheduleDate={objDate}
                colNames={colNames}
                allSubItems={allSubItems}
                allItems={allUserContacts}
                selectedOD={selectedOD}
                />
            {/* </> 
            : null
          })} */}
            </div>
        ))}
        {allDatesWithSchedule.length === 0 &&
          <div>

                <ItemsScheduleByDate
                employee={'employee'}
                scheduleDate={'objDate'}
                colNames={colNames}
                allSubItems={allSubItems}
                allItems={allUserContacts}
                selectedOD={selectedOD}
                />
            </div>
        }

        </>
      )}
      {allUserContacts && typeOfItems === 'calculateroute' && (
          <div>
            {/* fffffffffffffffffff */}
                {/* {allUserContacts.map((single) => (
                  <div onClick={() => console.log("single", single)}>{single.id_tag_route}kkk</div>
                ))} */}
                <ItemsScheduleByDate
                employee={'employee'}
                scheduleDate={'objDate'}
                colNames={colNames}
                allSubItems={allSubItems}
                allItems={allUserContacts}
                selectedOD={selectedOD}
                operation={operation}
                />

            </div>
      )}
    </div>
  )
}

export default ItemsScheduleToday