import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from "react"
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { Button, Grid } from "@mui/material";
import ExpandIcon from '@mui/icons-material/Expand';
import CompressIcon from '@mui/icons-material/Compress';


import "./SearchBar.css";
import waze from '../waze.png'
import { useHttpClient } from '../shared/hooks/http-hook';
import AddTagAndLocationsByCopy from '../shared/components/FormikElements/AddTagAndLocationsByCopy';

import { AuthContext } from '../shared/context/auth-context';
import ItemSingleScheduledToday from "./ItemSingleScheduledToday";
import Leaflet from "../leaflet/Leaflet";
import ItemsEditSingle from "./ItemsEditSingle";
import ItemsComments from "./ItemsComments";
import Modal from '../shared/components/UIElements/Modal';
import FormikUiAddContact from '../shared/components/FormikElements/FormikUiAddContact';
import LocationDetails from './componnents/LocationDetails';
import CreateTagRoute from './componnents/CreateTagRoute';
// import AddToMyRoute from './AddToMyRoute'; 
import AddPackagesForTransportation from '../shared/components/FormikElements/AddPackagesForTransportation';



const divInTd = {maxWidth: '370px', padding: '6px', background: '#f1f1f1', minWidth: '310px', margin: '4px', marginTop: '16px'}


function ItemsScheduleByEmployee({
  employee,
  operation,
  allSubItems,
  selectedOD,
  allItems,
  allCoupons,
  scheduleDate,
  setGoLocations,
  colNames = [
      'קוד פריט',
      'כמות',
      'לקוח',
      'העמסה',
      'פריקה',
      'חברת משלוחים',
      'סוג'
    ],
    height = "auto",
}) {

  
  const all_o_and_d_Locations = useSelector((state) => state.ui.userProfile.All_O_and_D_Locations);
  const userProfile = useSelector((state) => state.ui.userProfile);
  const usersLocation = useSelector((state) => state.ui.usersLocation);

  const [saveLocationsArray, setSaveLocationsArray] = useState([]);

  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [allUserContacts, setAllUserContacts] = useState([]);
  const [allLatLngInSchedule, setAllLatLngInSchedule] = useState([]);
  const [allNoDuplicates, setAllNoDuplicates] = useState([]);
  const [allNoDuplicatesWithOrder, setAllNoDuplicatesWithOrder] = useState([]);
  const [newRouteOrder, setNewRouteOrder] = useState([]);
  const [centerMapLeaflet, setCenterMapLeaflet] = useState([{ lat: 51.505, lng: -0.09 }]);
  const [originForLL, setOriginForLL] = useState([{address: 'המלאכה, ראש העין', location: [32.10902953800135, 34.968228824436665]}]);
  const [destonationForLL, setDestonationForLL] = useState([{address: 'חי טייב, בני ברק',location: [32.09693660677519, 34.83694148249925]}]);
  const [totalPages, setTotalPages] = useState([]);
  const [checkThisJob, setCheckThisJob] = useState(false);
  const [morDedailsNum, setMorDedailsNum] = useState(null);
  const [isMatchToOrigin, setIsMatchToOrigin] = useState(false);
  

  const [NEW_selectedOptionsBySelectedCat2, setNEW_selectedOptionsBySelectedCat2] = useState([]);
  const pages = Array.from(Array(totalPages).keys());

  var allLocationTag2;
  var NEW_selectedOptionsBySelectedCat;

   const handleInputChange = (event) => {
    const target = event.target;
    console.log("target::",target.value)
    // console.log("target.checked:" , target.checked, target.name)//dto do if checked true - remove; if false - add;
    if(target.checked){
    setNEW_selectedOptionsBySelectedCat2([...NEW_selectedOptionsBySelectedCat2, target.value])
    } 
    if (!target.checked) {
      // console.log("!target.checked:" , target.checked, target.name)
      const currentCat = event.target.value;
      NEW_selectedOptionsBySelectedCat = NEW_selectedOptionsBySelectedCat2.filter((single) => {
        // console.log(single)
        return single !== currentCat;
      })
      setNEW_selectedOptionsBySelectedCat2([...NEW_selectedOptionsBySelectedCat])
    }
  }

  useEffect(() => {
    console.log("originForLL>>> usersLocation >>>", usersLocation)
    // ===========BE CARFULL!!! 26/09/2023========
    console.log("allItems---LL",allItems)

    let allItemsAsOriginForLL = false;
    let allItemsAsDestinationLL = false;
    allItems.forEach((item,index) => {
      if(item.start_or_end){
        if(item.start_or_end === 'start'){
          allItemsAsOriginForLL = true
          if(item.loc){
            setOriginForLL([{location: [parseFloat(item.loc.coordinates[1]), parseFloat(item.loc.coordinates[0])], address: item.location_d[0]}])
          } else {
            setOriginForLL([{location: [parseFloat(item.location_d[2]), parseFloat(item.location_d[1])], address: item.location_d[0]}])
          }
        }
        if(item.start_or_end === 'end'){
          console.log("item.start_or_end === 'end'",item)
          allItemsAsDestinationLL = true
          if(item.loc){
            setDestonationForLL([{location: [parseFloat(item.loc.coordinates[1]), parseFloat(item.loc.coordinates[0])], address: item.location_d[0]}])
          } else {
            setDestonationForLL([{location: [parseFloat(item.location_d[2]), parseFloat(item.location_d[1])], address: item.location_d[0]}])
          }
        }
      }
    })
    if(allItemsAsOriginForLL === false){
      setOriginForLL([{location: [parseFloat(usersLocation.lat), parseFloat(usersLocation.lng)], address: 'המיקום שלי'}])
    }
    if(allItemsAsDestinationLL === false){
      setDestonationForLL([{location: [parseFloat(usersLocation.lat), parseFloat(usersLocation.lng)], address: 'המיקום שלי'}])
    }
    // ===================


        // setDestonationForLL([{location: [parseFloat(selectedODValue.location_d[1]), parseFloat(selectedODValue.location_d[2])], address: selectedODValue.location_d[0]}])

    // if(selectedOD.length > 0){
    //   // console.log("selectedOD",selectedOD)
    //   let selectedODValue = all_o_and_d_Locations.find(place => 
    //     place._id === selectedOD
    //     );
    //     // console.log("selectedODValue",selectedODValue)
    //     setOriginForLL([{location: [parseFloat(selectedODValue.location_o[1]), parseFloat(selectedODValue.location_o[2])], address: selectedODValue.location_o[0]}])
    //     setDestonationForLL([{location: [parseFloat(selectedODValue.location_d[1]), parseFloat(selectedODValue.location_d[2])], address: selectedODValue.location_d[0]}])
    //     allNoDuplicates.forEach((single, index) => {
    //       console.log('SINGLE:', single.location[0], single.location[1], 'ORIGION:', originForLL[0].location[0],originForLL[0].location[1])
    //       console.log('SINGLE:', single)
    //       console.log('SINGLE:', single.location[0])
    //       console.log('originForLL:', originForLL)
    //       console.log('originForLL:', originForLL[0].location[0])
    //     })
    // }
  },[usersLocation, allItems])
  
  useEffect(() => {
    // console.log("originForLL??",originForLL)
    // console.log("destonationForLL??",destonationForLL)
    
  },[originForLL])

              
const llOrderedRouteHandler = (llRoute) => {
  // console.log("llOrderedRouteHandler>", llRoute)
  // console.log("allNoDuplicatesWithOrder>", allNoDuplicatesWithOrder)

  
  // useEffect(() => {
    if(llRoute.length > 0){
      let NEWtempallNoDuplicates = []
      let tempallNoDuplicates = allNoDuplicates.slice()
      let numm
      tempallNoDuplicates.forEach((elem,index) => {
        // console.log("elem.orderCount" , elem.orderCount)
        const orderCount = llRoute.forEach((e, idx) => {
          // if (e.stopNumberInRoute === elem.orderCount){
          if (e.lat === elem.location[0]){
            // console.log("llRoute" , e)
            numm = e.stopNumberInRoute
          }
        })

          NEWtempallNoDuplicates.push({...elem, orderCount: numm})
        let counhh = 0
      })
      setAllNoDuplicatesWithOrder(NEWtempallNoDuplicates)
      // console.log("NEWtempallNoDuplicates>>",NEWtempallNoDuplicates)
    }

}

// useEffect(() => {
//       console.log("allLatLngInSchedule2.push", allUserContacts)
//       // setNEW_selectedOptionsBySelectedCat2([])
//   },[allUserContacts])


  const goRoute = (goLocations) => {
  console.log('EXECCED:', allItems)
  if(operation !== 'calculateroute'){
    setGoLocations(allItems.filter(element => {
      console.log("Form_purpes:location_tag", element.form_purpes)
      console.log("ELEMENT###", element)
      if (goLocations.includes(element._id)) {
        
        return element.form_purpes === 'location_tag';
      }
      // if(operation === 'calculateroute'){
        //   return
      }))
    // setAllUserContacts(allItems.filter(element => {
    //   console.log("Form_purpes:location_tag", element.form_purpes)
    //   console.log("ELEMENT###", element)
    //   // if(element.id_emploeey && operation !== 'calculateroute' && element.form_purpes != 'location_tag'){
    //   //   return element.id_emploeey._id === employee._id && scheduleDate ===  element.start_date_for_emploeey.split(',')[0]
    //   // }
    //   // if(!element.id_emploeey && operation !== 'calculateroute' && element.form_purpes != 'location_tag'){
    //   //   return scheduleDate ===  element.start_date_for_emploeey.split(',')[0]
    //   // }
    //   if (goLocations.includes(element._id)) {
        
    //     return element.form_purpes === 'location_tag';
    //   }
    //   // if(operation === 'calculateroute'){
    //     //   return
    //   }))
    }
    setNEW_selectedOptionsBySelectedCat2([])
  // console.log("01 -- allUserContacts", allUserContacts)
}

  useEffect(() => {
  console.log('EXECCED:', allItems)
  if(operation !== 'calculateroute'){
    setAllUserContacts(allItems.filter(element => {
      console.log("Form_purpes:location_tag", element.form_purpes)
      if(element.id_emploeey && operation !== 'calculateroute' && element.form_purpes != 'location_tag'){
        return element.id_emploeey._id === employee._id && scheduleDate ===  element.start_date_for_emploeey.split(',')[0]
      }
      if(!element.id_emploeey && operation !== 'calculateroute' && element.form_purpes != 'location_tag'){
        return scheduleDate ===  element.start_date_for_emploeey.split(',')[0]
      }
      if (element.form_purpes === 'location_tag') {
        
        return element.form_purpes === 'location_tag';
      }
      // if(operation === 'calculateroute'){
        //   return
      }))
    }

    if(operation === 'calculateroute'){
      // console.log("allItems::", allItems)
      setAllUserContacts(allItems)
    }
  // console.log("01 -- allUserContacts", allUserContacts)
},[allItems,scheduleDate])






  let allLatLngInSchedule2 = [];
  let noDuplicates = [];

  useEffect(() => {
    let toLat, toLng, toId, toAddress;
    let fromLat, fromLng, fromId, fromAddress;

    // console.log("allUserContacts<>", allUserContacts)
        // console.log("allUserContacts:():",allUserContacts)
        if(allUserContacts.length >= 0){
// console.log("setAllUserContacts FROM BY DATE>", allUserContacts)
          allUserContacts.forEach((item,index) => {
            // console.log("itemitemitem>>>><<", item)

            

            if(item.o_contact_id && item.o_contact_id.address && item.o_contact_id.location && item.form_purpes !== 'location_tag'){
              fromLat = item.o_contact_id.location.lat
              fromLng = item.o_contact_id.location.lng
              fromId = item.o_contact_id._id
              fromAddress = item.o_contact_id.address
            }

            if (item.d_contact_id && item.d_contact_id.address && item.d_contact_id.location && item.form_purpes !== 'location_tag') {
              // alert(item.d_contact_id.location)
            toLat = item.d_contact_id.location.lat
            toLng = item.d_contact_id.location.lng
            toId = item.d_contact_id._id
            toAddress = item.d_contact_id.address
            }


            if (item.form_purpes === 'location_tag' && item.location_d) {
              // alert(item.location_d[1][0])
              toLat = item.location_d[1][0]
              toLng = item.location_d[2][0]
              toId = item._id
              toAddress = item.location_d[0]
              fromLat = null
              fromLng = null
              fromId = null
              fromAddress = null
              fromAddress = null
            }

            //1- create array with all places with specific objects
            // console.log("toLat:::", toLat)
             allLatLngInSchedule2.push({
               id: item._id,
               packsNum: item.packs_pallet,
                 from: [ fromLat, fromLng, fromAddress, fromId],
                 to: [ toLat, toLng, toAddress, toId]
                })
              })
console.log("allLatLngInSchedule2.push", allLatLngInSchedule2)
              let orderCount = 0;

              //2- chack if all addresses o and 2 are uniqe
              allLatLngInSchedule2.forEach((elem, index) => {
                const existMoreThenOne_from = allLatLngInSchedule2.filter(e => e.from[3] === elem.from[3])
                // console.log("existMoreThenOne_from",existMoreThenOne_from)
                const existMoreThenOne_to = allLatLngInSchedule2.filter(e => e.to[3] === elem.to[3])
                let from_firstName;
                let to_firstName;
                const from_firstName_find = allItems.filter(e => e._id === elem.id)
                // console.log("from_firstName_find:",from_firstName_find)
                  from_firstName = from_firstName_find[0].o_contact_id && from_firstName_find[0].o_contact_id.first_name

                const to_firstName_find = allItems.filter(e => e._id === elem.id)
                  to_firstName = to_firstName_find[0].d_contact_id && to_firstName_find[0].d_contact_id.first_name


                //3- existMoreThenOne_from is holding all duplicates in allLatLngInSchedule2 array
                // console.log("existMoreThenOne_from>>>", existMoreThenOne_from)
                if(existMoreThenOne_from.length > 1){
                  let idsAndPacks_from = []
                  let sum_from = 0
                  existMoreThenOne_from.forEach((single,ind) => {
                    const to_firstName_find = allItems.filter(e => e._id === single.id)
                      to_firstName = to_firstName_find[0].d_contact_id && to_firstName_find[0].d_contact_id.first_name
                    idsAndPacks_from.push([single.id, single.packsNum, to_firstName])
                    sum_from = sum_from + parseInt(single.packsNum);
                  })
                  const existInnoDuplicates = noDuplicates.filter(e => e.from[3] === elem.from[3])
                  if(!existInnoDuplicates.length && operation !== 'calculateroute' && elem.from[0] !== null){
                    console.log("AA 04--FROM ",elem)
                    noDuplicates.push({
                      location: [elem.from[0],elem.from[1],elem.from[3],from_firstName],
                      id: [...idsAndPacks_from],
                      packsNum: sum_from,
                      from: [...elem.from],
                      to: elem.to,
                      type: elem.from[0] !== null ? 'from' : 'locationTag',
                      multiple: true,
                      orderCount
                    })
                    orderCount++
                  }                 
                }
                if(existMoreThenOne_to.length > 1){
                  // console.log("03 --- existMoreThenOne_to.length > 1", existMoreThenOne_to.length, existMoreThenOne_to)
                  let idsAndPacks_to = []
                  let sum_to = 0
                  existMoreThenOne_to.forEach((single,ind) => {
                    const from_firstName_find = allItems.filter(e => e._id === single.id)
                      from_firstName = from_firstName_find[0].d_contact_id && from_firstName_find[0].d_contact_id.first_name
                    idsAndPacks_to.push([single.id, single.packsNum, from_firstName_find[0].o_contact_id && from_firstName_find[0].o_contact_id.first_name ])
                    sum_to = sum_to + parseInt(single.packsNum);
                  })
                  const existInnoDuplicates = noDuplicates.filter(e => e.to[3] === elem.to[3] && e.type === 'to')
                  // console.log("04--TO BEFORE",existInnoDuplicates)
                  if(!existInnoDuplicates.length){
                    // console.log("04--TO ",existInnoDuplicates)
                    noDuplicates.push({
                      location: [elem.to[0],elem.to[1],elem.to[3],from_firstName],
                      id: [...idsAndPacks_to],
                      packsNum: sum_to,
                      from: [...elem.from],
                      to: elem.to,
                      type: 'to',
                      multiple: true,
                      orderCount
                    })
                    orderCount++
                  }
                } 
                
                
                if(existMoreThenOne_from.length > 1 && existMoreThenOne_to.length === 1) {
                  // alert("dkk")
                    let from_firstName;
                    let to_firstName;
                    const from_firstName_find = allItems.filter(e => e._id === elem.id)
                      from_firstName = from_firstName_find[0].o_contact_id && from_firstName_find[0].o_contact_id.first_name

                    const to_firstName_find = allItems.filter(e => e._id === elem.id)
                      to_firstName = to_firstName_find[0].d_contact_id && to_firstName_find[0].d_contact_id.first_name
                      
                    let to_type = to_firstName_find[0].form_purpes === 'location_tag' ? 'to_location_tag' : 'to';

                  noDuplicates.push({
                    location: [elem.to[0],elem.to[1],elem.to[3],to_firstName],
                    id: [elem.id, elem.packsNum],
                    packsNum: elem.packsNum,
                    from: [...elem.from,from_firstName],
                    to: [...elem.to],
                    type: to_type,
                    multiple: false,
                    orderCount
                  })
                  orderCount++
                }



                if(existMoreThenOne_from.length === 1 && existMoreThenOne_to.length === 1) {
                  let from_firstName;
                  let to_firstName;
                const from_firstName_find = allItems.filter(e => e._id === elem.id)
                  from_firstName = from_firstName_find[0].o_contact_id && from_firstName_find[0].o_contact_id.first_name

                const to_firstName_find = allItems.filter(e => e._id === elem.id)
                  to_firstName = to_firstName_find[0].d_contact_id && to_firstName_find[0].d_contact_id.first_name
                  noDuplicates.push({
                    location: [elem.from[0],elem.from[1],elem.from[3],from_firstName],
                    id: [elem.id, elem.packsNum],
                    packsNum: elem.packsNum,
                    from: [...elem.from],
                    to: [...elem.to,to_firstName],
                    type: 'from',
                    multiple: false,
                    orderCount
                  })
                  orderCount++
                  noDuplicates.push({
                    location: [elem.to[0],elem.to[1],elem.to[3],to_firstName],
                    id: [elem.id, elem.packsNum],
                    packsNum: elem.packsNum,
                    from: [...elem.from,from_firstName],
                    to: [...elem.to],
                    type: 'to',
                    multiple: false,
                    orderCount
                  })
                  orderCount++
                }
              })

console.log("allUserContacts>>>)", allUserContacts)
// =====XX=======
          let new_noDuplicates = [];
          let isMatchToOrigin = false;
          let isMatchToDestination = false;
          let singleMatchToOrigin = [];
          let singleMatchToDestination = [];
          setAllLatLngInSchedule([...allLatLngInSchedule2])

          noDuplicates.forEach((single, index) => {
            if (single.location[0] === originForLL[0].location[0]) {
              
              console.log("singlesingle:", single)
              isMatchToOrigin = true;
              
              single.orderCount = -1
              singleMatchToOrigin.push(single)
            }
            if (single.location[0] === destonationForLL[0].location[0]) {
              
              console.log("singlesingle:", single)
              isMatchToDestination = true;
              
              // single.orderCount = -1
              singleMatchToDestination.push(single)
            }
                // return single.location[0] !== originForLL[0].location[0]
          }) 
          if (isMatchToOrigin === false && noDuplicates.length > 0) {
            console.log("noDuplicates. isMatchToOrigin === false", noDuplicates)
            new_noDuplicates = noDuplicates
            //       new_noDuplicates = noDuplicates.filter(element => {
              // console.log("noDuplicates.", element)
              // if(element.id_emploeey && operation !== 'calculateroute' && element.form_purpes != 'location_tag'){
                //   return element.from[0].length > 1 || element.to[0].length > 1
                // }
                // });
                setIsMatchToOrigin(false)
                console.log("noDuplicates{{}}",[...originForLL,...new_noDuplicates.sort((a, b) => a.orderCount - b.orderCount),...destonationForLL])
                setAllNoDuplicates([...originForLL,...new_noDuplicates.sort((a, b) => a.orderCount - b.orderCount),...destonationForLL])
            }

            if (isMatchToOrigin === true && noDuplicates.length > 0) {

                console.log("destonationForLL??)", destonationForLL)
                console.log("originForLL??)", originForLL)
                console.log("noDuplicates. isMatchToOrigin === true", noDuplicates)
                noDuplicates.forEach((single, index) => {
                if (single.location[0] !== originForLL[0].location[0] && single.location[0] !== destonationForLL[0].location[0]) {
                  console.log("singlesingle:", single)
                  // single.orderCount = -1
                  new_noDuplicates.push(single)
                }
                new_noDuplicates = [...new_noDuplicates]
              // return single.location[0] !== originForLL[0].location[0]
            })
            setIsMatchToOrigin(true)
            setAllNoDuplicates([...singleMatchToOrigin, ...new_noDuplicates.sort((a, b) => a.orderCount - b.orderCount),...singleMatchToDestination])
          }


          console.log("isMatchToOrigin:", isMatchToOrigin)
          
          console.log("new_noDuplicates:", new_noDuplicates)
              
              // noDuplicates.forEach((single, index) => {
            
          //   console.log('SINGLE:', single.location[0], single.location[1], 'ORIGION:', originForLL[0].location[0],originForLL[0].location[1])
          //   console.log('SINGLE:', single)
          //   console.log('SINGLE:', single.location[0])
          //   console.log('originForLL:', originForLL)
          //   console.log('originForLL:', originForLL[0].location[0])
          // })
              // ...originForLL,
              //     ...allNoDuplicates,
              //     ...destonationForLL
              setAllNoDuplicatesWithOrder([...noDuplicates.sort((a,b) => a.orderCount - b.orderCount)])

        }
      },[allUserContacts,scheduleDate,selectedOD])

  const pushCommentToCommentsItem = (e) => {
    let TEMPallUserContacts = []
    allUserContacts.forEach((item,index) => {
      if(e.objectId !== item._id){
      TEMPallUserContacts.push(item)
      }
      if(e.objectId === item._id){
        let onlyThis = item;
        onlyThis.comments.push({...e})
        TEMPallUserContacts.push(onlyThis)
      }
    })
    setAllUserContacts([...TEMPallUserContacts])
  }
  const removeItemFromArr = (e) => {
    let TEMPallUserContacts = []
    allUserContacts.forEach((item,index) => {
      if(e !== item._id){
      TEMPallUserContacts.push(item)
      }
      // if(e.objectId === item._id){
      //   let onlyThis = item;
      //   onlyThis.comments.push({...e})
      //   TEMPallUserContacts.push(onlyThis)
      // }
    })
    setAllUserContacts([...TEMPallUserContacts])
    console.log("removeItemFromArr is EXEX", e)
  }


      const containerStyle = {
        borderRight: '0px solid rgb(25, 118, 210)',
        // borderRight: '5px solid rgb(25, 118, 210)',
        background: '#fff',
        borderBottom: '4px solid #d8d8d8',
        borderTop: '1px solid #bbb',
        minHeight: '100px',
        // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px',
        margin: '10px',
        padding: '6px',
        borderRadius: '4px',
        boxShadow: 'rgb(101 101 101) 1px 2px 6px 0px',
      }
      const numbersStyle = {margin: '0px 2px', background: '#1976d2', color: '#fff', width: '45px', height: '25px',textAlign: 'center', paddingTop: '3px'}
      const toStyle = {padding: '6px 5px 4px 4px'}
      const DoneStyle = {textAlign: 'center', paddingTop: '2px', margin: '0px 6px 0px 0px', background: 'rgb(0 55 109)', color: 'rgb(255, 255, 255)', width: '50px', height: '25px', textalign: 'center', paddingtop: '3px'}

      const divInTd = {padding: '6px'}


      const [initialPos,   setInitialPos] = useState(false);
      const [initialSize, setInitialSize] = useState('33');
    
      // const initial = (e) => {
          
      //     let resizable = document.getElementById('Resizable');
          
      //     setInitialPos(e.clientY);
      //     setInitialSize(resizable.offsetHeight);
          
      // }
      
      // const resize = (e) => {
          
      //     let resizable = document.getElementById('Resizable');
        
      //     resizable.style.height = `${parseInt(initialSize) + parseInt(e.clientY - initialPos)}px`;
        
      // }


  return (
    <div>
    {allUserContacts.length > 0 &&
      <div style={{position: 'relative'}}>
        {/* <div className = 'Block'> */}
            {/* <div id = 'Draggable'
                draggable   = 'true'
                onDragStart = {initial} 
                onDrag      = {resize}
            /> */}
            {/* <div id = 'Resizable'/> */}
        {/* </div> */}
        {/* <div>{allUserContacts.length}</div> */}
        {/* <div>{allNoDuplicates.length}</div> */}
            <div>
              { originForLL.length !== 0 &&
              <>
                {/* <div>{originForLL[0].address}</div> */}
                <Leaflet 
                // allNoDuplicates2={allNoDuplicates.length}
                allNoDuplicates={[
                  ...allNoDuplicates.filter((obj, index) => {
                    return obj.location[0] !== null && index === allNoDuplicates
                    .findIndex(o => obj.location[0] === o.location[0]);
                  })
                ]}
                llOrderedRouteHandler={llOrderedRouteHandler}
                showMapLeaflet={true}
                centerMapLeaflet={centerMapLeaflet}
                />
              </>
              }
            </div>

            <div 
            // id = 'Resizable'
            style={{
                position: 'absolute',
                bottom: '0',
                zIndex: '9999',
                background: '#0000002e',
                paddingTop: '4px',
            }}>
              
              {/* <div id = 'Draggable'
                draggable   = 'true'
                // onDragStart = {initial} 
                // onDrag      = {resize}
                onClick={() => {
                  if (initialPos === false) 
                  {setInitialSize(80)
                  setInitialPos(true)}
                  if (initialPos === true) 
                  {setInitialSize(33)
                  setInitialPos(false)}
                }
              }
            /> */}
              <div style={{
                  position: 'relative',
                  // display: 'flex',
                  // justifyContent: 'space-between',
                  // padding: '4px 8px',
                  // background: 'rgb(191 191 191)',
                  // alignItems: 'center',
                  // margin: '4px 0 4px 0'
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '4px 8px',
                  alignItems: 'center',
                  borderRight: '0px solid rgb(25, 118, 210)',
                  background: 'rgb(255, 255, 255)',
                  borderBottom: '1px solid rgb(216, 216, 216)',
                  borderTop: '1px solid rgb(187, 187, 187)',
                  minHeight: '42px',
                  margin: '2px 14px',
                  borderRadius: '4px',
                  boxShadow: 'rgb(101, 101, 101) 1px 2px 6px 0px',
                
                
                
                }}
                >
                <div style={{
                    position: 'absolute',
                    cursor: 'n-resize',
                    top: '-33px',
                    left: '50%',
                    transform: 'translate(-50%, 50%)',
                    background: '#757575',
                    boxShadow: 'rgb(101, 101, 101) 1px 2px 6px 0px',
                    borderRadius: '38px',
                    padding: '5px 5px 0px 0px',
                    width: '27px',
                    height: '27px',
                }}>
                  {initialPos === false && 
                    <div onClick={() => {
                      {setInitialSize(80)
                      setInitialPos(true)}
                    }}>
                      <ExpandIcon style={{fontSize: '17', color: '#fff'}}/>
                    </div>
                  }
                  {initialPos === true && 
                    <div onClick={() => {
                      {setInitialSize(33)
                      setInitialPos(false)}
                    }}>
                      <CompressIcon style={{fontSize: '17', color: '#fff'}}/>
                    </div>
                  }
                </div>
                <div>נקודות במסלול: {allNoDuplicatesWithOrder.length}</div>
                <div style={{display: 'flex'}}>
                  {NEW_selectedOptionsBySelectedCat2.length > 0 &&
                    <div style={{marginLeft: '7px'}}>
                      <Button 
                        variant='contained' 
                        // disabled={props.allLocationsToCopy.length === 0 ? true : false}
                        onClick={() => {
                          goRoute(NEW_selectedOptionsBySelectedCat2)
                          // setGoLocations(NEW_selectedOptionsBySelectedCat2)
                      }}>צא לטיול</Button>
                    </div>
                  }
                  <SaveLocationsArray 
                    setNEW_selectedOptionsBySelectedCat2={setNEW_selectedOptionsBySelectedCat2}
                    allLocationsToCopy={NEW_selectedOptionsBySelectedCat2}
                    allLocationsToCopyWithDetailes={
                      allItems.filter(element => {
                        if (NEW_selectedOptionsBySelectedCat2.includes(element._id)) {
                          return element.form_purpes === 'location_tag';
                          }
                        })
                      }
                  />
                </div>
              </div>
              <div style={{
                // height: '51vh',
                height: `calc(${initialSize}vh - 90px)`,
                overflow: 'scroll'}}>
                {allNoDuplicatesWithOrder && 
                <>
                  {/* {isMatchToOrigin === false && */}
                    <div>
                    {/* #1 location O */}
                    <div style={{ ...containerStyle, minHeight: '65px' }}
                      onClick={() => console.log("originForLL:", originForLL)}
                    >
                      
                      <div style={{ display: "flex", justifyContent: 'space-between' }}>

                        <div style={{ display: 'flex' }}>
                          <div style={{ ...numbersStyle, marginTop: '10px', width: '226px', borderRadius: '15px', boxShadow: '1px 3px 5px #373737', height: 'unset', minHeight: '25px' }}>1. <span style={{ fontWeight: 'bold' }}>
                            {` כתובת התחלה - ${originForLL[0].address}`}</span>
                          </div>
                        </div>

                        <div>{morDedailsNum}</div>
                        <div style={{ display: 'flex', marginTop: '10px', marginLeft: '10px' }}>
                          <ItemsEditSingle
                            obj={allUserContacts[0]}
                            fromOrTo='from'
                            employeeLink={'employeeLink'}
                            AllEmployee={userProfile.AllEmployee}
                            startDate={'startDate'}
                            // paidButton={true}
                            // completedButton={true}
                            // addMediaButton={true}
                            wazeButton={true}
                            // vipButton={true}
                            wazeLatLn={[originForLL[0].location[0], originForLL[0].location[1]]}
                            // cheangeCenterMapLeaflet={setCenterMapLeaflet}
                            centerMapLeafletButton={true}
                          />
                        </div>
                        
                      </div>
                    </div>
                  </div>
              
                  {/* } */}
                  {/* ==========VIP START========== */}
                {/* {
                  
                  allNoDuplicatesWithOrder.sort((a, b) => a.orderCount - b.orderCount).map((single, indx) => (
                    <div>
                      {indx === 1 && 
                      <div style={{
                      textAlign: 'center',
                      marginTop: '13px',
                      background: '#0f3d6b',
                      margin: '10px',
                      padding: '6px',
                      color: '#fff',
                      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px'
                    }}>מומלצים בסביבה:</div>
                    }
                    {single.type === 'to_location_tag' &&  single.multiple === false &&

                          
                    <>
                    <>
                    
                    {allUserContacts.map((obj, index) => (
                          employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' && obj.vip === 'vip' &&
                          <div 
                          style={{
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            margin: '10px',
                            padding: '6px',
                            background: '#1976d25c'
                          }}
                          onClick={() => console.log("obj::>>", obj)}>
                      <div style={{display: "flex", justifyContent: 'space-between'}}>

                        <div style={{display: 'flex'}}>
                          <div style={numbersStyle}> {indx+1}. <span style={{fontWeight: 'bold'}}> נקודה </span>
                          </div>
                        </div>

                        
                        <div style={{display: 'flex', marginTop: '10px', marginLeft: '10px'}}> 
                        {allUserContacts.map((obj, index) => (
                          employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                          <> 
                            <ItemsEditSingle 
                              obj={obj} 
                              fromOrTo='to'
                              employeeLink={'employeeLink'}
                              AllEmployee={userProfile.AllEmployee}
                              startDate={'startDate'}
                              paidButton={true}
                              wazeButton={true}
                              vipButton={true}
                              addDeleteButton={true}
                              wazeLatLn={[single.location[0],single.location[1]]}
                              centerMapLeafletButton={true}
                              jobs
                              pushCommentToCommentsItem={pushCommentToCommentsItem}
                              removeItemFromArr={removeItemFromArr}
                            />        
                          </>
                          ))} 
                        </div>
                      </div>

                      

                        <div className="toppings-list-item" style={{ display: "flex",
                          justifyContent: "space-between"}}>
                          <div style={{position: 'relative', width: '100%'}}
                            className="left-section">
                            <input
                            style={{width: '25px', height: '25px'}}
                              type="checkbox"
                              value={single.id[0]}
                              onChange={handleInputChange}
                            />
                          </div>
                          </div>
                          

                      {allUserContacts.map((obj, index) => (
                          employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                          <>
                            {
                              <div style={divInTd}>
                                <GoToDedails 
                                  setMorDedailsNumber={(e) => {
                                        if(morDedailsNum === index){
                                          setMorDedailsNum(null)
                                        }
                                        if(morDedailsNum !== index){
                                          setMorDedailsNum(e)
                                        }
                                      }
                                    }
                                  indexNumber={index} 
                                  obj={obj} 
                                  single={single} 
                                  to_location_tag
                                  o_or_d='d' 
                                  // allUserContacts={allUserContacts}
                                />
                                { morDedailsNum === index &&
                                  <MorDedails obj={obj}  single={single} o_or_d='o' />
                                }
                              </div>
                              
                            }
                          </>
                          ))}
                        </div>
                        ))}
                    </>
                    
                    </>

    }

                    </div>
                  ))
                } */}
                {/* ==========END VIP START========== */}

                {
                  allNoDuplicatesWithOrder.sort((a, b) => a.orderCount - b.orderCount).map((single, indx) => (
                    <div 
                    // onClick={()=> console.log("single",single)}
                    >
                    {single.type === 'from' && single.id.length > 1 && single.multiple === true && single.type !== 'to_location_tag' &&
                    <div  style={{...containerStyle, borderRight: '5px solid #da0000'}}
                    >
                      <div style={{display: "flex", justifyContent: 'space-between'}}>
                        <div style={{display: 'flex'}}>
                          <div style={{...numbersStyle, background: '#da0000'}}> {indx+1}. <span style={{fontWeight: 'bold'}}> העמסה + </span>
                          </div>
                        </div>


                        

                      {single.id.map((ids,idx) => (
                        <>
                          {allUserContacts.map((obj, index) => (
                              employee &&  obj._id === ids[0] &&
                              <>
                                {  idx === 1 &&
                                <div style={{display: 'flex', marginTop: '10px', marginLeft: '10px'}}> 
                                    <ItemsEditSingle 
                                    obj={obj} 
                                    fromOrTo='from'
                                    employeeLink={'employeeLink'}
                                    AllEmployee={userProfile.AllEmployee}
                                    startDate={'startDate'}
                                    // paidButton={true}
                                    // completedButton={true}
                                    wazeButton={true}
                                    // addMediaButton={true}
                                    wazeLatLn={[single.location[0],single.location[1]]}
                                    centerMapLeafletButton={true}
                                  removeItemFromArr={removeItemFromArr}
                                  addDeleteButton={true}
                                  />
                                </div>
                                }
                              </>
                            ))}   
                          </>
                        ))}
                      </div>


                      {single.id.map((ids,idx) => (
                        <>
                          {allUserContacts.map((obj, index) => (
                              employee &&  obj._id === ids[0] &&
                              <>
                                {  idx === 1 &&
                          <div style={{marginTop: '20px', marginBottom: '10px'}}>
                            {obj.o_contact_id &&
                            <div style={{marginBottom: '3px', display: 'flex'}}>
                                <div style={{color: '#757575', marginLeft: '3px'}}>כתובת: </div>
                                <div>{obj.o_contact_id.address}</div>
                            </div>
                            }
                            {obj.o_contact_id &&
                            <div style={{marginBottom: '3px', display: 'flex'}}>
                                <div style={{color: '#757575', marginLeft: '3px'}}>שם: </div>
                                <div>{obj.o_contact_id.first_name}</div>
                            </div>
                            }
                            {obj.o_contact_id &&
                            <div style={{marginBottom: '3px', display: 'flex'}}>
                                <div style={{color: '#757575', marginLeft: '3px'}}>טלפון: </div>
                                <div>{obj.o_contact_id.phone1}</div>
                            </div>
                            }
                            <div style={{marginBottom: '3px', display: 'flex'}}>
                                <div style={{color: '#757575', marginLeft: '3px'}}>מספר פריטים: </div>
                                <div>{single.packsNum}</div>
                            </div>
                            {/* <div style={{marginBottom: '3px', display: 'flex'}}>
                                <div style={{color: '#757575', marginLeft: '3px'}}>שם לקוח: </div>
                                <div>{props.obj.c_contact_id.first_name}</div>
                            </div> */}
                            {/* <div onClick={() => props.setMorDedailsNumber(props.indexNumber)} style={{marginBottom: '3px', display: 'flex'}}>
                              <ExpandCircleDownOutlinedIcon
                              style={{color: '#fff', backgroundColor: 'blue', borderRadius: '30px'}} />
                            </div> */}
                          </div>
                            }
                          </>
                          ))}   
                          </>
                        ))}

                      <div style={toStyle}>העמסה מ {single.location[3]} | <span>ל {single.id.length} נקודות | </span><span>סה"כ {single.packsNum} משטחים</span></div>
                      <div>עבור:</div>
                      {single.id.map((ids,idx) => (
                        <div>
                          {ids.length > 2 &&
                          <>
                            <div>#{idx+1} {ids[2]} - {ids[1]} משטח</div>
                            {allUserContacts.map((obj, index) => (
                              employee &&  obj._id === ids[0] ?
                              <>
                                <div style={{display: 'flex', marginTop: '10px', marginLeft: '10px'}}> 
                                    <ItemsEditSingle 
                                    obj={obj} 
                                    fromOrTo='from'
                                    employeeLink={'employeeLink'}
                                    AllEmployee={userProfile.AllEmployee}
                                    startDate={'startDate'}
                                    paidButton={true}
                                    completedButton={true}
                                    wazeButton={true}
                                    addMediaButton={true}
                                    wazeLatLn={[obj.d_contact_id && obj.d_contact_id.location.lat, obj.d_contact_id && obj.d_contact_id.location.lng]}
                                    centerMapLeafletButton={true}
                                    jobs
                                    pushCommentToCommentsItem={pushCommentToCommentsItem}
                                  />
                                </div>
                                {/* <div style={{marginBottom: '3px', display: 'flex'}}> */}
                                  {/* {allUserContacts.map((obj, index) => ( */}
                                    {/* employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' && */}
                                    {/* <> 
                                      <ItemsComments 
                                        obj={obj} 
                                        fromOrTo='to'
                                        employeeLink={'employeeLink'}
                                        // AllEmployee={userProfile.AllEmployee}
                                        startDate={'startDate'}
                                        paidButton={false}
                                        completedButton={false}
                                        wazeButton={true}
                                        addMediaButton={true}
                                        wazeLatLn={[obj.o_contact_id && obj.o_contact_id.location.lat, obj.o_contact_id && obj.o_contact_id.location.lng]}
                                        // cheangeCenterMapLeaflet={setCenterMapLeaflet}
                                        centerMapLeafletButton={true}
                                        jobs
                                        />  
                                      </> */}
                                    {/* ))} */}
                                {/* </div> */}

                              <div style={divInTd}>
                                <GoToDedails 
                                  setMorDedailsNumber={(e) => {
                                        if(morDedailsNum === index){
                                          setMorDedailsNum(null)
                                        }
                                        if(morDedailsNum !== index){
                                          setMorDedailsNum(e)
                                        }
                                      }
                                    }
                                  indexNumber={index} 
                                  obj={obj} 
                                  single={single} 
                                  o_or_d='d'  
                                />
                                { morDedailsNum === index &&
                                  <MorDedails obj={obj}  single={single} o_or_d='d' />
                                }
                              </div>
                                
                              </> : null
                            ))}
                          </>
                          }
                        </div>
                      ))}
                    </div>
                    }
                    {single.type === 'to' && single.id.length > 1 && single.multiple === true && single.type !== 'to_location_tag' &&
                    <div style={containerStyle}
                    >
                      <div style={{display: "flex", justifyContent: 'space-between'}}>
                        <div style={{display: 'flex'}}>
                          <div style={numbersStyle}> {indx+1}. <span style={{fontWeight: 'bold'}}> פריקה + </span>
                          </div>
                        </div>

                        <div style={{display: "flex"}}>
                          
                        {single.id.map((ids,idx) => (
                        <>
                          {allUserContacts.map((obj, index) => (
                              employee &&  obj._id === ids[0] &&
                              <>
                                {  idx === 1 &&
                                <div style={{display: 'flex', marginTop: '10px', marginLeft: '10px'}}> 
                                    <ItemsEditSingle 
                                    obj={obj} 
                                    fromOrTo='to'
                                    employeeLink={'employeeLink'}
                                    AllEmployee={userProfile.AllEmployee}
                                    startDate={'startDate'}
                                    // paidButton={true}
                                    // completedButton={true}
                                    wazeButton={true}
                                    // addMediaButton={true}
                                    wazeLatLn={[single.location[0],single.location[1]]}
                                    centerMapLeafletButton={true}
                                  removeItemFromArr={removeItemFromArr}
                                  addDeleteButton={true}
                                  />
                                </div>
                                }
                              </>
                            ))}   
                          </>
                        ))}
                      </div>

                      </div>


                      {single.id.map((ids,idx) => (
                        <>
                          {allUserContacts.map((obj, index) => (
                              employee &&  obj._id === ids[0] && index < 2 &&
                              <>
                                {
                          <div style={{marginTop: '20px', marginBottom: '10px'}}>
                            {obj.d_contact_id &&
                            <div style={{marginBottom: '3px', display: 'flex'}}>
                                <div style={{color: '#757575', marginLeft: '3px'}}>כתובת: </div>
                                <div>{obj.d_contact_id.address}</div>
                            </div>
                            }
                            {obj.d_contact_id &&
                            <div style={{marginBottom: '3px', display: 'flex'}}>
                                <div style={{color: '#757575', marginLeft: '3px'}}>שם: </div>
                                <div>{obj.d_contact_id.first_name}</div>
                            </div>
                            }
                            {obj.d_contact_id &&
                            <div style={{marginBottom: '3px', display: 'flex'}}>
                                <div style={{color: '#757575', marginLeft: '3px'}}>טלפון: </div>
                                <div>{obj.d_contact_id.phone1}</div>
                            </div>
                            }
                            <div style={{marginBottom: '3px', display: 'flex'}}>
                                <div style={{color: '#757575', marginLeft: '3px'}}>מספר פריטים: </div>
                                <div>{single.packsNum}</div>
                            </div>
                            {/* <div style={{marginBottom: '3px', display: 'flex'}}>
                                <div style={{color: '#757575', marginLeft: '3px'}}>שם לקוח: </div>
                                <div>{props.obj.c_contact_id.first_name}</div>
                            </div> */}
                            {/* <div onClick={() => props.setMorDedailsNumber(props.indexNumber)} style={{marginBottom: '3px', display: 'flex'}}>
                              <ExpandCircleDownOutlinedIcon
                              style={{color: '#fff', backgroundColor: 'blue', borderRadius: '30px'}} />
                            </div> */}
                          </div>
                            }
                          </>
                          ))}   
                          </>
                        ))}


                      <div style={toStyle}>פריקה ל {single.location[3]} | <span>מ {single.id.length} נקודות העמסה | </span><span>סה"כ {single.packsNum} משטחים</span></div>
                      <div>שהועמסו ב:</div>
                      {single.id.map((ids,idx) => (
                        <div>
                          {ids.length > 2 &&
                          <>
                            <div>#{idx+1} {ids[2]} - {ids[1]} משטח</div>
                            {allUserContacts.map((obj, index) => (
                              employee &&  obj._id === ids[0] ?
                              <>

                                <div style={{display: 'flex', marginTop: '10px', marginLeft: '10px'}}> 
                                    <ItemsEditSingle 
                                    obj={obj} 
                                    fromOrTo='to'
                                    employeeLink={'employeeLink'}
                                    AllEmployee={userProfile.AllEmployee}
                                    startDate={'startDate'}
                                    paidButton={true}
                                    completedButton={true}
                                    wazeButton={true}
                                    addMediaButton={true}
                                    wazeLatLn={[obj.o_contact_id && obj.o_contact_id.location.lat, obj.o_contact_id &&obj.o_contact_id.location.lng]}
                                    centerMapLeafletButton={true}
                                    jobs
                                    pushCommentToCommentsItem={pushCommentToCommentsItem}
                                  />
                                </div>
                                {/* <div style={{marginBottom: '3px', display: 'flex'}}> */}
                                  {/* {allUserContacts.map((obj, index) => ( */}
                                    {/* employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' && */}
                                    {/* <> 
                                      <ItemsComments 
                                        obj={obj} 
                                        fromOrTo='from'
                                        employeeLink={'employeeLink'}
                                        // AllEmployee={userProfile.AllEmployee}
                                        startDate={'startDate'}
                                        paidButton={false}
                                        completedButton={false}
                                        wazeButton={true}
                                        addMediaButton={true}
                                        wazeLatLn={[obj.o_contact_id && obj.o_contact_id.location.lat, obj.o_contact_id && obj.o_contact_id.location.lng]}
                                        // cheangeCenterMapLeaflet={setCenterMapLeaflet}
                                        centerMapLeafletButton={true}
                                        jobs
                                        />  
                                      </> */}
                                    {/* ))} */}
                                {/* </div> */}

                                <div style={divInTd}>
                                <GoToDedails 
                                  setMorDedailsNumber={(e) => {
                                        if(morDedailsNum === index){
                                          setMorDedailsNum(null)
                                        }
                                        if(morDedailsNum !== index){
                                          setMorDedailsNum(e)
                                        }
                                      }
                                    }
                                  indexNumber={index} 
                                  obj={obj} 
                                  single={single} 
                                  o_or_d='o'  
                                />
                                { morDedailsNum === index &&
                                  <MorDedails obj={obj}  single={single} o_or_d='o' />
                                }
                              </div>


                              {/* <div style={divInTd}>
                                  <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`פריקה: ${obj.d_contact_id.first_name}`}</div>
                                    <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                                      <div style={{marginBottom: '10px'}}>
                                          <div style={{color: '#757575'}}>כתובת</div>
                                          <div>{obj.d_contact_id.address}</div>
                                      </div>
                                      <div style={{marginBottom: '10px'}}>
                                          <div style={{color: '#757575'}}>טלפון</div>
                                          <div>{obj.d_contact_id.phone1}</div>
                                      </div>
                                    </div>
                              </div> */}

                                {/* <ItemSingleScheduledToday
                                firstItem={index}
                                obj={obj}
                                employeeTitleName={employee.key}
                                index={index}
                                colNames={colNames}
                                allSubItems={allSubItems}
                                styleForMobile={true}
                                /> */}
                              </> : null
                            ))}
                          </>
                          }
                        </div>
                      ))}
                    </div>
                    }
                    {single.type === 'from' &&  single.multiple === false && single.type !== 'to_location_tag' &&
                    <div  style={{...containerStyle, borderRight: '5px solid #da0000'}}>
                      <div style={{display: "flex", justifyContent: 'space-between'}}>
                        <div style={{display: 'flex'}}>
                          <div style={{...numbersStyle, background: '#da0000'}}> {indx+1}. <span style={{fontWeight: 'bold'}}> העמסה </span>
                          </div>
                        </div>

                      <div style={{display: "flex", marginTop: '10px', marginLeft: '10px'}}>

                        {allUserContacts.map((obj, index) => (
                            employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                            <> 
                              <ItemsEditSingle 
                                obj={obj} 
                                fromOrTo='from'
                                employeeLink={'employeeLink'}
                                AllEmployee={userProfile.AllEmployee}
                                startDate={'startDate'}
                                paidButton={true}
                                completedButton={true}
                                wazeButton={true}
                                addMediaButton={true}
                              removeItemFromArr={removeItemFromArr}
                              addDeleteButton={true}
                                wazeLatLn={[single.location[0],single.location[1]]}
                                // cheangeCenterMapLeaflet={setCenterMapLeaflet}
                                centerMapLeafletButton={true}
                                jobs
                                pushCommentToCommentsItem={pushCommentToCommentsItem}
                              />  
                              

                            </>
                            ))}
                      </div>
                    </div>
                      {/* <div style={{marginBottom: '3px', display: 'flex'}}>
                          {allUserContacts.map((obj, index) => (
                            employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                            <> 
                              <ItemsComments 
                                obj={obj} 
                                fromOrTo='from'
                                employeeLink={'employeeLink'}
                                // AllEmployee={userProfile.AllEmployee}
                                startDate={'startDate'}
                                paidButton={false}
                                completedButton={false}
                                wazeButton={true}
                                addMediaButton={true}
                                wazeLatLn={[obj.o_contact_id && obj.o_contact_id.location.lat, obj.o_contact_id && obj.o_contact_id.location.lng]}
                                // cheangeCenterMapLeaflet={setCenterMapLeaflet}
                                centerMapLeafletButton={true}
                                removeItemFromArr={removeItemFromArr}
                                jobs
                                />  
                              </>
                            ))}
                        </div> */}

                        {allUserContacts.map((obj, index) => (
                          employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                          <>
                            {
                              <div style={divInTd}>
                                <GoToDedails 
                                    setMorDedailsNumber={(e) => {
                                      if(morDedailsNum === index){
                                        setMorDedailsNum(null)
                                      }
                                      if(morDedailsNum !== index){
                                        setMorDedailsNum(e)
                                      }
                                    }
                                    }                              
                                    indexNumber={index} 
                                  obj={obj} 
                                  single={single} 
                                  o_or_d='o'
                                />
                                { morDedailsNum === index &&
                                  <MorDedails obj={obj}  single={single} o_or_d='o' />
                                }
                              </div>
                              
                            }
                          </>
                        ))}
                    </div>
                    }
                    {single.type === 'to' &&  single.multiple === false && single.type !== 'to_location_tag' &&
                    <div style={{...containerStyle}}>
                      
                      <div style={{display: "flex", justifyContent: 'space-between'}}>

                        <div style={{display: 'flex'}}>
                          <div style={numbersStyle}> {indx+1}. <span style={{fontWeight: 'bold'}}> פריקה </span>
                          </div>
                        </div>

                        
                        <div style={{display: 'flex', marginTop: '10px', marginLeft: '10px'}}> 
                        {allUserContacts.map((obj, index) => (
                          employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                          <> 
                            <ItemsEditSingle 
                              obj={obj} 
                              fromOrTo='to'
                              employeeLink={'employeeLink'}
                              AllEmployee={userProfile.AllEmployee}
                              startDate={'startDate'}
                              paidButton={true}
                              completedButton={true}
                              wazeButton={true}
                              addMediaButton={true}
                              wazeLatLn={[single.location[0],single.location[1]]}
                              centerMapLeafletButton={true}
                              jobs
                              pushCommentToCommentsItem={pushCommentToCommentsItem}
                              removeItemFromArr={removeItemFromArr}
                              addDeleteButton={true}
                            />        
                          </>
                          ))} 
                        </div>
                      </div>

                        <div style={{marginBottom: '3px', display: 'flex'}}>
                          {allUserContacts.map((obj, index) => (
                            employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                            <> 
                              <ItemsComments 
                                obj={obj} 
                                fromOrTo='from'
                                employeeLink={'employeeLink'}
                                // AllEmployee={userProfile.AllEmployee}
                                startDate={'startDate'}
                                paidButton={false}
                                completedButton={false}
                                wazeButton={true}
                                addMediaButton={true}
                                wazeLatLn={[obj.o_contact_id && obj.o_contact_id.location.lat, obj.o_contact_id && obj.o_contact_id.location.lng]}
                                // cheangeCenterMapLeaflet={setCenterMapLeaflet}
                                centerMapLeafletButton={true}
                                
                                />  
                              </>
                            ))}
                        </div>

                      {allUserContacts.map((obj, index) => (
                          employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                          <>
                            {
                              <div style={divInTd}>
                                <GoToDedails 
                                  setMorDedailsNumber={(e) => {
                                        if(morDedailsNum === index){
                                          setMorDedailsNum(null)
                                        }
                                        if(morDedailsNum !== index){
                                          setMorDedailsNum(e)
                                        }
                                      }
                                    }
                                  indexNumber={index} 
                                  obj={obj} 
                                  single={single} 
                                  o_or_d='d' 
                                />
                                { morDedailsNum === index &&
                                  <MorDedails obj={obj}  single={single} o_or_d='o' />
                                }
                              </div>
                              
                            }
                          </>
                        ))}
                    </div>
                    // =========================
                    }
                    {single.type === 'to_location_tag' &&  single.multiple === false &&
                    <>
                    <div style={{...containerStyle}} onClick={() => console.log("single:>", single)}>
                      
                      <div style={{display: "flex", justifyContent: 'space-between'}}>

                        <div style={{display: 'flex'}}>
                          {/* <div style={{...numbersStyle, marginTop: '10px', borderRadius: '15px', boxShadow: '1px 3px 5px #373737'}}> {indx+1}. <span style={{fontWeight: 'bold'}}> נקודה </span> */}
                          <div style={{...numbersStyle, marginTop: '10px', borderRadius: '15px', boxShadow: '1px 3px 5px #373737'}}> {indx+1}
                          </div>
                        </div>

                        
                        <div style={{display: 'flex', marginTop: '10px', marginLeft: '10px'}}> 
                        {allUserContacts.map((obj, index) => (
                          employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                          <> 
                            <ItemsEditSingle 
                              obj={obj} 
                              fromOrTo='to'
                              employeeLink={'employeeLink'}
                              AllEmployee={userProfile.AllEmployee}
                              startDate={'startDate'}
                              paidButton={true}
                              // completedButton={true}
                              wazeButton={true}
                              vipButton={true}
                              startOrEndButton={true}
                              addMediaButton={true}
                              addDeleteButton={true}
                              wazeLatLn={[single.location[0],single.location[1]]}
                              centerMapLeafletButton={true}
                              jobs
                              pushCommentToCommentsItem={pushCommentToCommentsItem}
                              removeItemFromArr={removeItemFromArr}
                            />        
                          </>
                          ))} 
                        </div>
                      </div>

                        {/* <div style={{marginBottom: '3px', display: 'flex'}}>
                          {allUserContacts.map((obj, index) => (
                            employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                            <> 
                              <ItemsComments 
                                obj={obj} 
                                fromOrTo='from'
                                employeeLink={'employeeLink'}
                                // AllEmployee={userProfile.AllEmployee}
                                startDate={'startDate'}
                                paidButton={false}
                                completedButton={false}
                                wazeButton={true}
                                addMediaButton={true}
                                wazeLatLn={[obj.o_contact_id && obj.o_contact_id.location.lat, obj.o_contact_id && obj.o_contact_id.location.lng]}
                                // cheangeCenterMapLeaflet={setCenterMapLeaflet}
                                centerMapLeafletButton={true}
                                
                                />  
                              </>
                            ))}
                          </div> */}
                          
                          {/* CHECKBOX */}

                          <div style={{
                            display: "flex",
                            // justifyContent: 'flex-end'
                            }}>
                            <div className="toppings-list-item" style={{
                              display: "flex",
                              background: '#dbdbdb',
                              color: '#434343',
                              width: '92px',
                              alignItems: 'center',
                              paddingRight: '8px',
                              margin: '6px',
                              }}>
                              <div style={{fontSize: '11px'}}
                              className="left-section">הוסף לטיול</div>
                              <div
                                className="left-section">
                                <input
                                style={{width: '25px', height: '25px'}}
                                  type="checkbox"
                                  value={single.id[0]}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div> */}
                            {/* <AddToMyRoute allLocationsToCopy={NEW_selectedOptionsBySelectedCat2} /> */}
                            {/* <AddToMyRoute allLocationsToCopy={[single.id[0]]} nameLocation={'חחח'} /> */}
                          {/* </div> */}
                          

                      {allUserContacts.map((obj, index) => (
                          employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' &&
                          <>
                            {
                              <div style={divInTd}>
                                <GoToDedails 
                                  setMorDedailsNumber={(e) => {
                                        if(morDedailsNum === index){
                                          setMorDedailsNum(null)
                                        }
                                        if(morDedailsNum !== index){
                                          setMorDedailsNum(e)
                                        }
                                      }
                                    }
                                  indexNumber={index} 
                                  obj={obj} 
                                  single={single} 
                                  to_location_tag
                                  o_or_d='d' 
                                  // allUserContacts={allUserContacts}
                                />
                                { morDedailsNum === index &&
                                  <MorDedails obj={obj}  single={single} o_or_d='o' />
                                }
                              </div>
                              
                            }
                          </>
                        ))}
                    </div>
                    </>
                    // =========================
                    }

                    </div>
                  ))
                  }
                                {allCoupons && allCoupons.length > 0 &&
                      <div>
                        <div style={{padding: '13px', background: '#ffffab'}}>
                          <div>קופונים בסביבה:</div>
                          <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                border: '2px #000',
                                borderStyle: 'dashed',
                                padding: '3px',
                                marginTop: '5px'
                          }}>
                            {allCoupons.map((coupon, index) => (
                              <>
                                {index < 4 &&
                                  <div style={{width: '25%'}}>
                                    <div key={index} style={{
                                      backgroundImage: `url(${coupon.imageOne})`,
                                      position: 'relative',
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center center',
                                      height: '90px',
                                      // width: '100px',
                                      margin: '4px'
                                    }}>
                                    </div>
                                    <div key={index} style={{
                                      maxWidth: '100px',
                                      fontSize: '12px',
                                      paddingRight: '5px',
                                      paddingLeft: '5px'
                                    }}>{coupon.title}</div>
                                  </div>
                                }
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    }
                  <div>
                    {/* #END location D */}
                    <div style={{...containerStyle, minHeight: '65px'}}>
                      
                      <div style={{display: "flex", justifyContent: 'space-between'}}>

                        <div style={{display: 'flex'}}>
                          <div style={{...numbersStyle, marginTop: '10px', width: '226px', borderRadius: '15px', boxShadow: '1px 3px 5px #373737', height: 'unset', minHeight: '25px'}}> <span style={{fontWeight: 'bold'}}>{` סוף מסלול- ${destonationForLL[0].address}`}</span>
                          </div>
                        </div>

                        
                        <div style={{display: 'flex', marginTop: '10px', marginLeft: '10px'}}> 
                            <ItemsEditSingle 
                              obj={allUserContacts[0]} 
                              fromOrTo='from'
                              employeeLink={'employeeLink'}
                              AllEmployee={userProfile.AllEmployee}
                              startDate={'startDate'}
                              // paidButton={true}
                              // completedButton={true}
                              wazeButton={true}
                              wazeLatLn={[destonationForLL[0].location[0], destonationForLL[0].location[1]]}
                              // cheangeCenterMapLeaflet={setCenterMapLeaflet}
                              centerMapLeafletButton={true}
                            />        
                        </div>
                      </div>
                    </div>
                </div>
                </>
                }
              </div>
          </div>
          {operation === 'calculateroute99'  &&
            
          
            <div>
                <table
                  cellSpacing="0"
                  style={{ borderCollapse: 'collapse', width: "100%", height: height, padding: "5px 10px", marginTop: '15px' }}
                >
                  <thead style={{ backgroundColor: "#343434", color: "white" }}>
                    <tr>
                      {colNames.map((headerItem, index) => (
                        <th key={index}>{headerItem.toUpperCase()}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                {allUserContacts.map((obj, index) => {
                  return employee ?
                  <>
                    <ItemSingleScheduledToday
                    firstItem={index}
                    obj={obj}
                    employeeTitleName={employee.key}
                    index={index}
                    colNames={colNames}
                    allSubIגtems={allSubItems}
                    removeItemFromArr={removeItemFromArr}
                    />
                  </> : null
                })}
                </tbody>
              </table>
        </div>
        }
    </div>
  }
    </div>
  )
}

function MorDedails(props) {
  return (
    <div style={{marginBottom: '10px'}}>
      <div style={{marginBottom: '20px'}}>   
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>     
                    <div style={divInTd}>
                        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>כל הפריטים</div>
                          <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>קוד פריט</div>
                                <div>{props.obj.uuidNumber}</div>
                            </div>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>מספר משטח</div>
                                <div>{props.obj.current_pallet}</div>
                            </div>
                          </div>
                    </div>


                    <div style={divInTd}>
                        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`לקוח: ${props.obj.id_client && props.obj.id_client.first_name}`}</div>
                          <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>כתובת</div>
                                <div>{props.obj.id_client && props.obj.id_client.address}</div>
                            </div>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>טלפון</div>
                                <div>{props.obj.id_client && props.obj.id_client.phone1}</div>
                            </div>
                          </div>
                    </div>


                  <div style={divInTd}>
                      <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`העמסה: ${props.obj.o_contact_id.first_name}`}</div>
                        <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>כתובת</div>
                              <div>{props.obj.o_contact_id.address}</div>
                          </div>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>טלפון</div>
                              <div>{props.obj.o_contact_id.phone1}</div>
                          </div>
                        </div>
                  </div>


                  <div style={divInTd}>
                      <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`פריקה: ${props.obj.d_contact_id.first_name}`}</div>
                        <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>כתובת</div>
                              <div>{props.obj.d_contact_id.address}</div>
                          </div>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>טלפון</div>
                              <div>{props.obj.d_contact_id.phone1}</div>
                          </div>
                        </div>
                  </div>

                {props.obj.id_connected_mover &&
                  <div style={divInTd}>
                      <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`משלוחן מבצע: ${props.obj.id_connected_mover.business_name}`}</div>
                        {/* <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>כתובת</div>
                              <div>{props.obj.d_contact_id.address}</div>
                          </div>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>טלפון</div>
                              <div>{props.obj.d_contact_id.phone1}</div>
                          </div>
                        </div> */}
                  </div>
                
                }

                  <div style={divInTd}>
                      <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`תשלום:`}</div>
                        <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>סכום</div>
                              <div>{props.obj.payment.amount}</div>
                          </div>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>צד המשלם</div>
                              <div>{props.obj.payment.whopays}</div>
                          </div>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>צורת תשלום</div>
                              <div>{props.obj.payment.method}</div>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
    </div>
  );
}
function GoToDedails(props) {
  return (
    <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'column'}}>
      <div>
        {props.obj.id_sub_cat &&
          <div style={{marginBottom: '3px', display: 'flex', justifyContent: 'flex-end'}}>
              {/* <div style={{color: '#757575', marginLeft: '3px'}}>כותרת: </div> */}
              <div style={{fontSize: '14px', color: '#676767'}}>{`${props.obj.id_cat.first_name} | ${props.obj.id_sub_cat.first_name}`}</div>
          </div>
        }
      </div>
      <div>
        {props.obj.title &&
          <div style={{marginBottom: '3px', display: 'flex'}}>
              {/* <div style={{color: '#757575', marginLeft: '3px'}}>כותרת: </div> */}
              <div style={{fontSize: '22px'}}>{props.obj.title}</div>
          </div>
        }
      </div>

      <div style={{
            display: 'flex',
            marginTop: '12px',
            padding: '4px',
      }}>
        <div>
            {/* <div style={{marginBottom: '3px', display: 'flex'}}> */}
            <div style={{marginBottom: '11px'}}>
                <div onClick={() => console.log(props)} style={{color: '#757575', marginLeft: '3px', fontSize: '11px'}}>כתובת: </div>
                <div style={{fontSize: '13px'}}>{props.o_or_d === 'd' && props.obj.d_contact_id ?   props.obj.d_contact_id.address : 
                props.o_or_d === 'o' && props.obj.o_contact_id ? props.obj.o_contact_id.address : 
                props.obj.form_purpes === 'location_tag' ?  props.obj.location_d[0] : null}
                </div>
            </div>
            <div style={{marginBottom: '11px'}}>
                <div onClick={() => console.log(props)} style={{color: '#757575', marginLeft: '3px', fontSize: '11px'}}>שעות פתיחה:</div>
                <div style={{fontSize: '13px'}}>{'בוקר מ09:00 עד 14:00'}</div>
                <div style={{fontSize: '13px'}}>{'ערב מ15:00 עד 22:30'}</div>
            </div>
            <div style={{marginBottom: '11px'}}>
                <div onClick={() => console.log(props)} style={{color: '#757575', marginLeft: '3px', fontSize: '11px'}}>משך זמן ביקור מומלץ:</div>
                <div style={{fontSize: '13px'}}>{'בערך 3.5 שעות'}</div>
            </div>
            
        </div>
        <div>
        {props.obj.video_link && props.obj.video_link !== undefined && props.obj.video_link.length > 0 &&
          <>
            {/* <div style={{marginBottom: '3px', display: 'flex'}}>
                <div style={{color: '#757575', marginLeft: '3px'}}><a href={props.obj.video_link} target="_blank" >וידאו</a></div>
            </div> */}
            {props.obj.video_link.split("v=")[1] && props.obj.video_link.split("v=")[1].length > 1 &&
            <div className="video-responsive" style={{background: '#cdcdcd'}}>
              <iframe
                width="100%"
                height="150"
                // src={`https://www.youtube.com/embed/${embedId}`}
                src={`https://www.youtube.com/embed/${props.obj.video_link.split("v=")[1].substring(0, 11)}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                />
            </div>
            }
          </>
        }
        </div>
      </div>
      <div style={{marginBottom: '11px'}}>
                <div onClick={() => console.log(props)} style={{color: '#757575', marginLeft: '3px', fontSize: '11px'}}>תיאור מקום: </div>
                <div style={{fontSize: '13px'}}>{'מקום מומלץ מאוד עם חוויה בלתי נשכחת  לכל באי העולם. מומלץ לבוא עם ליטר מים, פנס וחגורת בטיחות.'}</div>
      </div>

      {props.obj.web_link &&
        <div style={{marginBottom: '3px', display: 'flex'}}>
            <div style={{color: '#757575', marginLeft: '3px'}}><a href={props.obj.web_link} target="_blank" >ויקיפדיה</a></div>
            {/* <div>{props.obj.web_link}
            </div> */}
        </div>
      }

      {props.obj.coupons && props.obj.coupons !== undefined && props.obj.coupons.length > 0 &&
        <>
              <div>
                <div style={{padding: '13px'}}>
                  <div>קופונים במיקום זה:</div>
                  <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        border: '2px #000',
                        borderStyle: 'dashed',
                        padding: '3px',
                        marginTop: '5px'
                  }}>
                    {props.obj.coupons.map((coupon, index) => (
                      <>
                        {index < 4 &&
                          <div style={{width: '25%'}}>
                            <div key={index} style={{
                              backgroundImage: `url(${coupon.imageOne})`,
                              position: 'relative',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center center',
                              height: '90px',
                              // width: '100px',
                              margin: '4px'
                            }}>
                            </div>
                            <div key={index} style={{
                              width: '100px',
                              fontSize: '12px',
                              paddingRight: '5px',
                              paddingLeft: '5px'
                            }}>{coupon.title}</div>
                          </div>
                        }
                      </>
                    ))}
                  </div>
                </div>
              </div>
        </>
      }

      {/* <FormikControl
                  control='input'
                  type='string'
                  label='כותרת מיקום'
                  name='title'
                />
                <FormikControl
                  control='input'
                  type='string'
                  label='בחר סרטון'
                  name='video_link'
                />
                <FormikControl
                  control='input'
                  type='string'
                  label='לינק לויקיפדיה'
                  name='web_link'
      /> */}
      

      {!props.to_location_tag &&
      <>
        <div style={{marginBottom: '3px', display: 'flex'}}>
            <div style={{color: '#757575', marginLeft: '3px'}}>שם: </div>
            <div>{props.o_or_d === 'd' ?  props.obj.d_contact_id && props.obj.d_contact_id.first_name : 
            props.o_or_d === 'o' && props.obj.o_contact_id ? props.obj.o_contact_id.first_name : null}</div>
        </div>
        <div style={{marginBottom: '3px', display: 'flex'}}>
            <div style={{color: '#757575', marginLeft: '3px'}}>טלפון: </div>
            <div>{props.o_or_d === 'd' ? props.obj.d_contact_id &&  props.obj.d_contact_id.phone1 : 
            props.o_or_d === 'o' && props.obj.o_contact_id ? props.obj.o_contact_id.phone1 : null}</div>
        </div>
        <div style={{marginBottom: '3px', display: 'flex'}}>
            <div style={{color: '#757575', marginLeft: '3px'}}>מספר פריטים: </div>
            <div>{props.single.packsNum}</div>
        </div>
        <div style={{marginBottom: '3px', display: 'flex'}}>
            <div style={{color: '#757575', marginLeft: '3px'}}>שם לקוח: </div>
            <div>{props.obj.c_contact_id && props.obj.c_contact_id.first_name}</div>
        </div>
      </>
      
    }
    {!props.to_location_tag &&
      <div onClick={() => props.setMorDedailsNumber(props.indexNumber)} style={{marginBottom: '3px', display: 'flex'}}>
        <ExpandCircleDownOutlinedIcon
        style={{color: '#fff', backgroundColor: 'blue', borderRadius: '30px'}} />
      </div>
    }
    </div>
  );
}

function SaveLocationsArray(props) {
  const [createTagRoute, setCreateTagRoute] = useState(false);
  const [newTagRouteCreated, setNewTagRouteCreated] = useState(false);
  const [chooseADDBox, setChooseADDBox] = useState(false);

  const responseNewTagRoute = async (e) => {
    console.log("responseNewTagRouteee", e)
    if (e._id) {
      setNewTagRouteCreated(e._id)
    }
  }
      return (
        <div>
          {/* <CreateTagRoute formPurpes='location_tag' submitTextButton={trnslt("add address to route")} setChooseADDBox={()=> setChooseADDBox(!chooseADDBox)} isTagarouteExist={tagRouteOptions.length > 0} /> */}
          {/* <CreateTagRoute formPurpes='location_tag' submitTextButton={"add address to route"} setChooseADDBox={()=> setChooseADDBox(!chooseADDBox)} isTagarouteExist={false} /> */}
          <div style={{display: 'flex'}}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            background: props.allLocationsToCopy.length === 0 ? 'rgb(187 187 187)' : '#0f3d6b',
            borderRadius: '5px'
          }}>

            
          {props.allLocationsToCopy.length > 0 &&
            <>
              <div style={{
                color: '#fff',
                margin: '0px 7px'
              }}
              >{`(${props.allLocationsToCopy.length})`}</div>
                <Button 
                variant='contained' 
                disabled={props.allLocationsToCopy.length === 0 ? true : false}
                onClick={() => {
                  console.log(props)
                  setCreateTagRoute(true)
                }
              }>שמור טיול</Button>
            </>
          }
            {chooseADDBox &&
              <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                show={chooseADDBox}
                    onCancel={() => {
                        props.setChooseADDBox()
                        setChooseADDBox(false)
                    }}
                header={'הוספת כתובת לטיול'}
                    closeButton={<Button variant='contained' onClick={() => {
                      setChooseADDBox(false)
                        // props.setChooseADDBox()
                    }
                    }>סגור</Button>}
                    >
                        {/* { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
    inputes: ['location_d','id_tag_route'] , subject: 'places'}, */}
                    {/* {props.isTagarouteExist === false &&
                        <div>
                            {'כדי להוסיף כתובת, יש לחבר אותו לאחד משמות המסלולים שיצרת'}
                            {'בבקשה צור לפחות שם מסלול אחד'}
                        </div>
                    } */}
                <Grid item xs={12}>
                    <AddPackagesForTransportation 
                                // typeOfForm={['location_tag', 'מיקומים לתווית - לחישוב מסלול', 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי שם מסלול לצורך חישוב מסלול בלבד', ['location_d', 'id_tag_route', 'choose_tag_then_location']]}
                                typeOfForm={['location_tag', 'מיקומים לתווית - לחישוב מסלול', 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי שם מסלול לצורך חישוב מסלול בלבד', ['location_d', 'choose_tag_then_location']]}
                                submitTextButton='הוסף כתובת לטיול'
                    />
                </Grid>
                </Modal>
              </div>
            }
          </div>
          <div style={{
              }}>
          <div onClick={() => {
                      setChooseADDBox(true)
                    }
                  }
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: '#fff',
                    padding: '1px 4px 5px 4px',
                    borderRadius: '6px',
                    boxShadow: 'rgb(11, 31, 49) 1px 2px 4px 0px',
                    cursor: 'pointer',
                  }}
                  >
                    <div style={{
                      paddingTop: '4px',
                      margin: '0px 0px 0px 7px',
                      fontSize: '12px',
                    }}
                  >הוסף כתובת</div>
                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="AddLocationIcon" 
                style={{
                  filter: 'drop-shadow(rgba(0, 0, 0, 0.2) 3px 3px 1px)',
                  fontSize: '20px',
                  color: 'rgb(15, 61, 107)',
                  boxShadow: 'rgb(11, 73, 135) 1px 2px 0px 1px',
                  // background: '#faebd700',
                  borderRadius: '127px',
                  // padding: '15px',
                }}>
                  <path d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm4 8h-3v3h-2v-3H8V8h3V5h2v3h3v2z"
                  style={{boxShadow: 'rgb(11, 73, 135) 0px 2px 20px 12px, rgba(255, 255, 255, 0.53) 0px 4px 5px 0px, rgb(255, 255, 255) 0px 1px 10px 0px'}}></path>
                </svg>
              </div>
                {/* <AddLocationIcon 
                  style={{
                    fontSize: '28px',
                    color: 'rgb(15, 61, 107)',
                  }}
                  onClick={() => {
                      setChooseADDBox(true)
                    }
                  }
              /> */}
            </div>
          </div>
          {createTagRoute &&
            <div className='FormikUiAddContactInMape' style={{ marginTop: '20px' }}>
              <Modal
                style_modal__content={{maxHeight: 'unset',height: 'calc(100vh - 79px)', overflow: 'hidden'}}
                show={createTagRoute}
                onCancel={() => {
                  // props.setChooseODBox()
                  setCreateTagRoute(false)
                }}
                style={{maxHeight: 'unset',height: 'calc(100vh - 79px)'}}
                header={'הוסף/עדכן טיול חדש'}
                closeButton={<Button variant='contained'
                  onClick={() => {
                    setCreateTagRoute(false)
                    // props.setChooseODBox()
                  }
                                    
                  }>סגור</Button>}
              >
                {/* { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
            inputes: ['location_d','id_tag_route'] , subject: 'places'}, */}
                
                {/* <Grid item xs={12}>
                    <FormikUiAddContact 
                    // AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_o'
                    typeOfForm={['tag_route', 'הזן שם טיול']}
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    responseNewTagRoute={(e)=> responseNewTagRoute(e)}
                    />
                </Grid>
                <div>או</div> */}

                
                {/* {newTagRouteCreated !== false && */}
                  <Grid item xs={12}>
                    <div>{ newTagRouteCreated }</div>
                    <AddTagAndLocationsByCopy
                      // newTagRouteCreated={newTagRouteCreated}
                      setNEW_selectedOptionsBySelectedCat2={props.setNEW_selectedOptionsBySelectedCat2}
                      allLocationsToCopy={props.allLocationsToCopy}
                      allLocationsToCopyWithDetailes={props.allLocationsToCopyWithDetailes}
                      typeOfForm={['locations_tag_by_copy', 'מוצא ויעד', 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', ['location_o', 'location_d']]}
                      submitTextButton='הוסף טיול'
                    />
                  </Grid>
                {/* } */}

              </Modal>
            </div>
          }

          
        </div>
        
      );
    }
export default ItemsScheduleByEmployee