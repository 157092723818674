import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    centerMapLL: [32.08493466267377, 34.78146171255503],
    placesCats: [],
    contactCatsAndTags: [],
    items: [],
    WPItems: [],
    markers: [],
    panToLng: 35.21371,
    panToLat: 31.768319,
    // panToLng: 35.47742479034422,
    // panToLat: 31.80686747768828,
    selectedAddress: '',
    selectedLng: 35.21371,
    selectedLat: 31.768319,
    selectedAddress_o: {
      address:'',
      selectedLng: 35.21371,
      selectedLat: 31.768319,
    },
    selectedAddress_d: {
      address:'',
      selectedLng: 35.21371,
      selectedLat: 31.768319,
    },
    totalQuantity: 0,
    changed: false,
  },
  reducers: {
    replaceCart(state, action) {
      state.totalQuantity = action.payload.totalQuantity;
      state.items = action.payload.items;
    },
    replacePlacesCats(state, action) {
      state.placesCats = action.payload.placesCats;
    },
    replaceCenterMapLL(state, action) {
      // state.totalQuantity = action.payload.items;
      state.centerMapLL = action.payload.centerMapLL;
    },
    replaceMarkers(state, action) {
      // state.totalQuantity = action.payload.items;
      state.markers = action.payload.markers;
    },
    replacePenTo(state, action) {
      state.panToLng = action.payload.panToLng;
      state.panToLat = action.payload.panToLat;
    },
    replaceselectedLngLat(state, action) {
      // state.totalQuantity = action.payload.items;
      state.selectedAddress = action.payload.selectedAddress;
      state.selectedLng = action.payload.selectedLng;
      state.selectedLat = action.payload.selectedLat;
    },
    replaceselectedLngLat_o(state, action) {
      // state.totalQuantity = action.payload.items;
      state.selectedAddress_o.address = action.payload.selectedAddress;
      state.selectedAddress_o.selectedLng = action.payload.selectedLng;
      state.selectedAddress_o.selectedLat = action.payload.selectedLat;
    },
    replaceselectedLngLat_d(state, action) {
      // state.totalQuantity = action.payload.items;
      state.selectedAddress_d.address = action.payload.selectedAddress;
      state.selectedAddress_d.selectedLng = action.payload.selectedLng;
      state.selectedAddress_d.selectedLat = action.payload.selectedLat;
    },

    replaceChanged(state, action) {
      state.changed = true;
    },
    clearWPitems(state, action) {
        state.WPItems = [];
    },
    addOrChangeOrderInPlace(state, action) {
      const idPlace = action.payload.idPlace;
      const orderPlace = action.payload.orderPlace;
      const existingItem = state.items.find((item) => item.id === idPlace);
      state.totalQuantity++;
      state.changed = true;
      if (!existingItem) {
        // state.items.push({
        //   id: newItem.id,
        //   price: newItem.price,
        //   quantity: 1,
        //   totalPrice: newItem.price,
        //   name: newItem.title,
        // });
      } else {
        existingItem.order = orderPlace;
        state.WPItems.push(existingItem);
      }
    },
    addItemToCart(state, action) {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);
      state.totalQuantity++;
      state.changed = true;
      if (!existingItem) {
        state.items.push({
          id: newItem.id,
          price: newItem.price,
          quantity: 1,
          totalPrice: newItem.price,
          name: newItem.title,
        });
      } else {
        existingItem.quantity++;
        existingItem.totalPrice = existingItem.totalPrice + newItem.price;
      }
    },
    removeItemFromCart(state, action) {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      state.totalQuantity--;
      state.changed = true;
      if (existingItem.quantity === 1) {
        state.items = state.items.filter((item) => item.id !== id);
      } else {
        existingItem.quantity--;
        existingItem.totalPrice = existingItem.totalPrice - existingItem.price;
      }
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice;