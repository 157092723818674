import React, {useContext} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from './FormikControl'
import { useHistory } from 'react-router'
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';

function FormikAddPlaceInMap (props) {
  const dispatch = useDispatch();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  let allMapCats = []
  // allMapCats.push({a: 'aaaor_in_and'},{b: 'bbbor_in_and'})
  console.log(allMapCats)
  console.log("props.mapCatsDropDown: ", props.mapCatsDropDown)

props.mapCatsDropDown.forEach((single, idx, allArray)  => {
  let objVal = single.title;
  if(objVal){
    const obj = {value: single.id, key: single.title};
    allMapCats.push(obj);
    console.log(single)
  }
})
const dropdownOptions = [
  { key: 'Select your course', value: '' },
  { key: 'React', value: 'react' },
  { key: 'Angular', value: 'angular' },
  { key: 'Vue', value: 'vue' }
]
console.log("allMapCats:: " , allMapCats)
console.log("dropdownOptions:: " , dropdownOptions)

  const checkboxOptions = [
    { key: 'HTML', value: 'html' },
    { key: 'CSS', value: 'css' },
    { key: 'JavaScript', value: 'javascript' }
  ]

  const RadioOptions = [
    { key: 'מיקום 1', value: 'reg' },
    { key: 'מוצא ויעד', value: 'om' },
    { key: 'עבודה', value: 'job' }
  ]

  const initialValues = {
    title: '',
    description: '',
    // email: '',
    // bio: '',
    cat: '',
    // skills: [],
    selectedAddress: props.selectedAddress, 
    selectedLat: props.selectedLat,
    selectedLng: props.selectedLng
    
    // courseDate: null
  }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    cat: Yup.string().required('Required')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

  const history = useHistory();
  // const placeSubmitHandler = async event => {
    
  //   event.preventDefault();
  //   try {
  //     const formData = new FormData();
  //     formData.append('title', formState.inputs.title.value);
  //     formData.append('description', formState.inputs.description.value);
  //     formData.append('address', formState.inputs.address.value);
  //     // formData.append('image', formState.inputs.image.value);
  //     formData.append('lat', selectedLatRD);
  //     formData.append('lng', selectedLngRD);
  //     formData.append('cat', formState.inputs.cat.value);
  //     console.log(formData)
  //     await sendRequest('http://localhost:5000/api/places/inmap', 'POST', formData, {
  //       Authorization: 'Bearer ' + auth.token
  //     });
  //     dispatch(fetchCartData());
  //     history.push('/maps');
  //   } catch (err) {}
  // };




  const onSubmit = async values => {
    console.log('Form data', values)
    try {
    //   {
    //     "title": "דכייגדגכי",
    //     "description": "גכיגכי",
    //     "cat": "61806600973fef37689403c9",
    //     "selectedAddress": "גכיגכיגכי",
    //     "selectedLat": 31.768319,
    //     "selectedLng": 35.21371
    // }
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('address', values.selectedAddress);
      // formData.append('image', values.);
      formData.append('lat', values.selectedLat);
      formData.append('lng', values.selectedLng);
      formData.append('cat', values.cat);
      formData.append('imagereq', 'no');
      console.log(formData)
      await sendRequest('/api/places/inmap', 'POST', formData, {
      // const formData = new FormData();
      // formData.append('title', formState.inputs.title.value);
      // formData.append('description', formState.inputs.description.value);
      // formData.append('address', formState.inputs.address.value);
      // // formData.append('image', formState.inputs.image.value);
      // formData.append('lat', selectedLatRD);
      // formData.append('lng', selectedLngRD);
      // formData.append('cat', formState.inputs.cat.value);
      // console.log(formData)
      // await sendRequest('http://localhost:5000/api/places/inmap', 'POST', JSON.stringify([{title: 'תרצה 1, ירושלים',
      // description: 'תרצה 1, ירושלים',
      // address: 'גני גד 12',
      // lat: '31.778890699916342',
      // lng: '35.18474578857422',
      // cat: '61806600973fef37689403c9'}]), {
        Authorization: 'Bearer ' + auth.token
      });
      dispatch(fetchCartData());
      history.push('/maps');
    } catch (err) {}

  }

  return (
    <Formik 
    enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          <Form style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px'}}>
            {/* <FormikControl
              control='input'
              type='email'
              label='אימייל'
              name='email'
            /> */}
            <FormikControl
              control='radio'
              label='סוג טופס'
              name='modeOfContact'
              options={RadioOptions}
            />
            <FormikControl
              control='select'
              label='קטגוריה'
              name='cat'
              options={allMapCats}
            />
            <FormikControl
              control='input'
              type='string'
              label='כותרת מיקום'
              name='title'
            />




            <FormikControl
              control='input'
              type='string'
              label='כתובת מלאה'
              name='selectedAddress'
            />
            <FormikControl
              control='input'
              type='string'
              label='קו אורך lat'
              name='selectedLat'
            />
            <FormikControl
              control='input'
              type='string'
              label='קו רוחב lng'
              name='selectedLng'
            />
{/* selectedAddress: props.selectedAddress, 
    selectedLat: props.selectedLat,
    selectedLng: propsselectedLng */}




            <FormikControl
              control='textarea'
              label='תיאור/הערות'
              name='description'
            />
            
            {/* <FormikControl
              control='checkbox'
              label='Your skillset'
              name='skills'
              options={checkboxOptions}
            /> */}
            {/* <FormikControl
              control='date'
              label='Course date'
              name='courseDate'
            /> */}
            <button type='submit' disabled={!formik.isValid}>
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default FormikAddPlaceInMap
