import React, {useContext, useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from "axios";
import FormikControl from './FormikControl'
import { useHistory } from 'react-router'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import hebrew from 'date-fns/locale/he';
import { format } from 'date-fns'

import { uiActions } from '../../../store/ui-slice';
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';
import { Avatar, Button, Grid, IconButton, ListItem, ListItemAvatar, Tab, Tabs, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ListIcon from '@mui/icons-material/List';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import AddMedia from "../addMedia/AddMedia";


// import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
// import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import SearchIcon from '@mui/icons-material/Search';
import FormikUiAddContact from './FormikUiAddContact';
import SearchContacts from '../../../contacts/searchContacts';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../UIElements/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemText from '@mui/material/ListItemText';





// import './UiWithFormik.css';
import './forumStyle.css';
import { letterSpacing } from '@mui/system';

const buttonStepForum_numbrer = {
    borderRadius: '30px',
    width: '30px',
    height: '30px',
    padding: '4px 10px 0px 0px',
    background: '#1976d2',
    color: '#fff',
    fontWeight: '700',
    margin: '0px 0px 8px 8px',
}
const buttonStepForum_text = { color: '#ooo', paddingTop: '4px' }
function AddTagAndLocationsByCopy (props) {

const auth = useContext(AuthContext);
const userProfile = useSelector((state) => state.ui.userProfile);
const [allLocationTag, setAllLocationTag] = useState([]);
const [newtripOrUpdate, setNewtripOrUpdate] = useState('new');
const [saveCopyLocations, setSaveCopyLocations] = useState([]);

  
useEffect(() => {
    let allTheLocationTag = [];
    userProfile.AllLocationTag &&
    userProfile.AllLocationTag.map(elem => {
      allTheLocationTag.push(elem)
    });
    setAllLocationTag(
        [...allTheLocationTag]
      )
}, [userProfile])
  
const getCitiesTwo = async (str) => {
  try {
    let searchableCity = str.replace(/,/g, "");
    let url = "/api/contacts/autocomplete_two?city=" + searchableCity;
        const response = await fetch(url,
          {method: 'GET',
          body: null,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            },
          }
        );
        let dataContacts = await response.json();
        dataContacts = dataContacts.filter( (el) => el.user_id === userProfile._id)    
    return dataContacts;
  } catch (error) {
    console.error(error);
  }
};

  
  const usersClientsFromContacts = useSelector((state) => state.ui.userProfile.AllClients);
  
  const changeFormikAddressValues = (numInArray) => {
    if(formRef) {// formRef ? 
    formRef.current.values.location_o[numInArray] = 'sdgs sdgs k' 
    // : null 
    console.log("formRef.current.values::",formRef.current.values)
  }
    // console.log("formik.values:", formik.values)
  }


  const allFormInputName = ['id_manager','id_client','id_emploeey',
  'date_delivery','where_to','packs_pallet','packs_bags',
  'packs_cartons','packs_people','description',
  'location_o','location_d','contact_O_r_D',
  'payment','vehicle','physical_work','id_tag_route'];


  const chooseAddSubject = [
    ['מיקומים','places',<AddLocationAltOutlinedIcon sx={{ fontSize: 40 }} />],
    ['אינדקס','index',<MenuBookOutlinedIcon sx={{ fontSize: 40 }} />]
  ]
  
  const formTypeOptions = [
      { title: 'איש קשר', subTitle: 'הוספת איש קשר רגיל לכל מטרה', value: 'person', subject: 'index'},
      { title: 'עובד', subTitle: 'הוספת נהג/עובד לו תכולו לשייך משטחים/פריטים לשינוע', value: 'employee', subject: 'index'},
      { title: 'לקוח', subTitle: 'תוכלו לציין עבור איזה לקוח מבוצע עבודה וכן תוכלו לקבל עבודות מלקוחות אליכם', value: 'client', subject: 'index'},
      { title: 'לקוח קצה', subTitle: 'לכל לקוח תוכלו להוסיף תחתיו לקוח קצה. כך תוכלו לעבוד מול חברות המבקשים לשלוח פריטים ללקוחות קצה שלהם.', value: 'clients_client', subject: 'index'},
      { title: 'מחסן', subTitle: 'בכל פריט תוכלו להגדיר מאיזה מחסן יועמס/יופרק הפריט', value: 'warehouse', subject: 'index'},
      { title: 'תווית למסלול', subTitle: 'יש לכם מסלול עם כתובות? צרו תווית ואחר כך תוסיפו תחתיו כתובות ואחכ לקבל מסלול מחושב כולל נקודות במפה', value: 'tag_route', subject: 'index'},


    { title: 'פריטים לשינוע - לקוחות ומחסנים', subTitle: 'הוספת פריט לשינוע עם לקוחות ומחסנים שמורים  במערכת', value: 'c_to_w',
      inputes: ['id_manager','id_client','id_emploeey',
      'date_delivery','where_to','packs_pallet','packs_bags',
      'packs_cartons','packs_people','description'], subject: 'places' },
    { title: 'פריטים לשינוע - מאיש קשר לאיש קשר', subTitle: 'הוספת פריט מאיש קשר לאיש קשר', value: 'contact_to_contact',
    inputes: ['id_manager','id_client','id_emploeey',
    'date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D'] , subject: 'places'},
    { title: 'הוספת פריט', subTitle: 'הוספת פריט מאיש קשר לאיש קשר', value: 'c_o_d',
    inputes: ['date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D','payment','vehicle','physical_work','id_connected_mover','id_connected_businesses'] , subject: 'places'},
    { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
    inputes: ['location_d','id_tag_route'] , subject: 'places'},
    { title: 'מוצא ויעד', subTitle: 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', value: 'o_and_d',
    inputes: ['location_o','location_d'] , subject: 'places'},
    { title: 'דרושים', subTitle: 'הוספת פריט למחלקת הדרושים למציאת נהג לשינוע', value: 'jobs',
    inputes: ['date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D',
    'payment','vehicle','physical_work','id_connected_mover','id_connected_businesses'] , subject: 'places'},
    { title: 'קו קבוע לפי יום - עבור המטריקס', subTitle: 'הוסף מוצא ויעד קבוע באחד מימי השבוע, כך נוכל לשלוח לך עבודות על הדרך', value: 'location_by_day',
    inputes: ['date_delivery', 'location_o','location_d','vehicle'] , subject: 'places'},
    { title: 'הובלת דירה', subTitle: 'מילוי טופס להובלת דירה או משרד', value: 'home_office' ,
    inputes: ['id_manager','id_client','id_emploeey',
    'date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description','contact_O_r_D',
    'payment','vehicle','physical_work'], subject: 'places'}
  ];
  
  // const ClientsClientOptions = [
  //   { key: 'אושר עד חיפה', value: '666' },
  //   { key: 'אושר עד כנות', value: '777' },
  //   { key: 'יוחננוף טבריה', value: '888' },
  //   { key: 'מחסני השוק צפת', value: '999' },
  //   { key: 'יש השומר', value: '11222' }
  // ];

  const dispatch = useDispatch();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [morDetails0,setMorDetails0] = useState(true)
  const [morDetails1,setMorDetails1] = useState([true,'1'])
  const [morDetails2,setMorDetails2] = useState([true,'1'])
  const [morDetails3,setMorDetails3] = useState(false)
  const [morDetails4,setMorDetails4] = useState(false)
  const [morDetails5,setMorDetails5] = useState(false)
  const [morDetails6,setMorDetails6] = useState([true,'1'])
  const [morDetails7,setMorDetails7] = useState(false)
  const [morDetails8,setMorDetails8] = useState(false)
  const [displayJobForm,setDisplayJobForm] = useState(false)
  const [displayDandOForm,setDisplayDandOForm] = useState(false)
  const [valueDateMode, setValueDateMode] = React.useState(0);
  const [dateType, setDateType] = useState('date');
  const [switchMatrixx, setSwitchMatrixx] = useState('fgfg');
  const [optionsTwo, setOptionsTwo] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [autoCompleteC, setAutoCompleteC] = useState([]);
  const [autoCompleteO, setAutoCompleteO] = useState([]);
  const [locationO, setlocationO] = useState([]);
  const [locationD, setlocationD] = useState([]);
  const [autoCompleteD, setAutoCompleteD] = useState([]);
  const [contact_O_r_D, setContact_O_r_D] = useState('');
  const [typeOfForm, setTypeOfForm] = useState(props.typeOfForm);
  // const [typeOfForm, setTypeOfForm] = useState(['','','',[]]);
  const [showTypeOfFormOptions, setShowTypeOfFormOptions] = useState(true);
  const [showAddSubject, setShowAddSubject] = useState('places');
  //  contact_to_contact 
  // location_tag 
  const [createTagRoute, setCreateTagRoute] = useState(true);
  const [createContactName, setCreateContactName] = useState(false);
  const [searchContactName, setSearchContactName] = useState(false);
  const [optionsManagersIds, setOptionsManagersIds] = useState([]);
  const [optionsConnected_movers, setOptionsConnected_movers] = useState([]);
  const [optionsConnected_businesses, setOptionsConnected_businesses] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [pointNum, setPointNum] = useState([{num: 1}]);
  const [allUsersClientContacts, setAllUsersClientContacts] = useState([]);
  const [allEmployeeContacts, setAllEmployeeContacts] = useState([]);
  const [warehousesOptions, setWarehousesOptions] = useState([]);
  const [clientsClientOptions, setClientsClientOptions] = useState([]);  const [tagRouteOptions, setTagRouteOptions] = useState([]);

  const [sendedMessage, setSendedMessage] = useState('');
  const [changed, setChanged] = useState(false);
  const [addressesFieldsDetails, setAddressesFieldsDetails] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [cityO, setCityO] = useState('');
  const [cityD, setCityD] = useState('');
  const [cityLt, setCityLt] = useState('');
  const [currentCatRouteId, setCurrentCatRouteId] = useState('');
  // const [addLocationO, setAddLocationO] = useState(false);

  const selectedMapCatChanged = (tagId,tagName) => {
    const currentCat = tagId;
    const currentCatName = tagName;
    setCurrentCatRouteId(tagId)
    console.log("currentCat",currentCat)
  }


  let formRef = useRef();
  useEffect(() => {
    // formRef.current.values.location_o[0] = 'sdgs sdgs k'
    if(!showTypeOfFormOptions && showAddSubject === 'places'){
      console.log("formRef.current.values",formRef.current.values)
    }
  },[formRef, showTypeOfFormOptions])

  useEffect(() => {
      console.log("autoCompleteCCCC",autoCompleteC)
  }, [autoCompleteC])
  
  useEffect(() => {
      console.log("autoCompleteD??",autoCompleteD)
  },[autoCompleteD])

  const setAutoCompleteCFromAddContactHandler = (e) => {
    console.log("setAutoCompleteCFromAddContactHandler_E!", e)
    if(contact_O_r_D === 'C'){
      setAutoCompleteC(  [[0, e.contact]])
    }
    if(contact_O_r_D === 'O'){
      setAutoCompleteO(  [[0, e.contact]])
    }
    if(contact_O_r_D === 'D'){
      setAutoCompleteD(  [[0, e.contact]])
    }
  }
  const setAutoCompleteCFromSearchContactHandler = (e) => {
    console.log("setAutoCompleteCFromSearchContactHandler_E!", e)
    if(contact_O_r_D === 'C'){
      setAutoCompleteC([[0, e]])
      setSearchContactName(false)
    }
    if(contact_O_r_D === 'O'){
      setAutoCompleteO([[0, e]])
      setSearchContactName(false)
    }
    if(contact_O_r_D === 'D'){
      setAutoCompleteD([[0, e]])
      setSearchContactName(false)
    }
  }

  const handleSwitchTrueOrFalse = (e) => {
    console.log('switch hes been clicked!');
    switcher = !switcher;
    console.log(switcher);
    // setSwitchMatrixx(switcher);
    // console.log(e)
  };

  const updateAddressesFields = (objNum, addessesFields,typeLocation) => {
    console.log("addessesFields", addessesFields)
    console.log("objNum", objNum)
    // console.log("locationO", locationO)
    // setAddressesFieldsDetails([...addessesFields])
    if(typeLocation === 'o'){
      const indexExistInArrayO = locationO.filter( (singleO) => {
        console.log("singleO[0] => ",singleO[0]) 
          if (singleO[0] === objNum){
            return singleO[0] === objNum
          }
        } 
      )

      console.log("indexExistInArray.length => ", indexExistInArrayO.length, indexExistInArrayO)
      if (indexExistInArrayO.length === 0){
        setlocationO(  [...locationO , [objNum, addessesFields]])
      } else if (indexExistInArrayO.length > 0) {
        const newlocationO = locationO.filter((singleO) => singleO[0] !== objNum);
        setlocationO(  [...newlocationO , [objNum, addessesFields]])
      }
    }
    if(typeLocation === 'd'){
      const indexExistInArrayD = locationD.filter( (singleD) => {
        console.log("singleD[0] => ",singleD[0]) 
          if (singleD[0] === objNum){
            return singleD[0] === objNum
          }
        } 
      )

      console.log("indexExistInArrayD.length => ", indexExistInArrayD.length, indexExistInArrayD)
      if (indexExistInArrayD.length === 0){
        setlocationD(  [...locationD , [objNum, addessesFields]])
      } else if (indexExistInArrayD.length > 0) {
        const newlocationD = locationD.filter((singleD) => singleD[0] !== objNum);
        setlocationD(  [...newlocationD , [objNum, addessesFields]])
      }
    }

  }


  let points = Array.from(Array(pointNum).keys());

  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);

  useEffect(() => {
    setOptionsManagersIds(
      [{key: userProfile.name, value: userProfile._id}]
    );
    userProfile.managers_ids &&
    userProfile.managers_ids.map(elem => {
      setOptionsManagersIds(
        [...optionsManagersIds, {key: elem.name, value: elem._id}]
        )
      });
  }, [userProfile])
  
  useEffect(() => {
    let TEMP_optionsConnected_movers = []
    userProfile.connected_movers &&
      
    userProfile.connected_movers.map(elem => {
      TEMP_optionsConnected_movers.push({key: elem.business_name, value: elem._id})
    });

    setOptionsConnected_movers(
      [...optionsConnected_movers, ...TEMP_optionsConnected_movers]
    )

    userProfile.connected_businesses &&
    userProfile.connected_businesses.map(elem => {
      setOptionsConnected_businesses(
        [...optionsConnected_businesses, {key: elem.name, value: elem._id}]
        )
    });
    
  },[userProfile])
    
  useEffect(() => {
    setAllUsersClientContacts(
      // [{key: userProfile.name, value: userProfile._id}]
      [{key: 'בחר לקוח', value: userProfile._id}]
    );
    let allContactsFromRedux = [];
    userProfile.AllClients &&
    userProfile.AllClients.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    userProfile.wathIsMyClientNumber &&
    userProfile.wathIsMyClientNumber.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    setAllUsersClientContacts(
        [{key: 'בחר לקוח', value: userProfile._id}, ...allContactsFromRedux]
      )
  },[userProfile])


  useEffect(() => {
    setAllEmployeeContacts(
      // [{key: userProfile.name, value: userProfile._id}]
      [{key: 'בחר נהג', value: ''}]
    );
    let allContactsFromRedux = [];
    userProfile.AllEmployee &&
    userProfile.AllEmployee.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    userProfile.wathIsMyClientNumber &&
    userProfile.wathIsMyClientNumber.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    setAllEmployeeContacts(
        [{key: 'בחר נהג', value: ''}, ...allContactsFromRedux]
      )
  },[userProfile])


  useEffect(() => {
    let allTagRoute = [];
    userProfile.AllTagRoute &&
    userProfile.AllTagRoute.map(elem => {
      allTagRoute.push({key: elem.first_name, value: elem._id})
    });
    setTagRouteOptions(
        [...allTagRoute]
      )
  },[userProfile])

  useEffect(() => {
    let allWarehouses = [];
    userProfile.warehouses &&
    userProfile.warehouses.map(elem => {
      allWarehouses.push({key: elem.first_name, value: elem._id})
    });
    setWarehousesOptions(
        [...allWarehouses]
      )
  },[userProfile])

  useEffect(() => {
    let allClientsClient = [];
    userProfile.AllClientsClient &&
    userProfile.AllClientsClient.map(elem => {
      allClientsClient.push({key: elem.first_name, value: elem._id})
    });
    userProfile.AllManagersClientsClient &&
    userProfile.AllManagersClientsClient.map(elem => {
      allClientsClient.push({key: elem.first_name, value: elem._id})
    });
    setClientsClientOptions(
        [...allClientsClient]
      )
      console.log("allClientsClient:", allClientsClient)
  },[userProfile])




  const onChangeTwo = async (e) => {
    console.log(e.target)
    if (e.target.value) {
      let data = await getCitiesTwo(e.target.value);
      setOptionsTwo(data);
    }
  };
  
  const clearInput = () => {
    console.log('ffff')
    setOptionsTwo([]);
    setWordEntered("");
  };


let switcher = true;
  // useEffect(() => {
  // if(props.formPurpesTitle === 'd'){
  //   setDisplayDandOForm(false);
  //   }
  // if(props.formPurpesTitle !== 'd'){
  //   setDisplayDandOForm(true);
  //   }
  // },[props.formPurpesTitle])

  // useEffect(() => {
  //   console.log('autoCompleteO => ',autoCompleteO)
  // },[autoCompleteO])

  // useEffect(() => {
  //   console.log('autoCompleteC => ',autoCompleteC)
  // },[autoCompleteC])

  // useEffect(() => {
  //   console.log('pointNum => ',pointNum)
  // },[pointNum])


  // const handleChange4 = (event, newValuee) => {
  //   setValueDateMode(newValuee);
  // };
  
  // const handleSwitchTrueOrFalse = (e) => {
  //   console.log('switch hes been clicked!');
  //   switcher = !switcher;
  //   console.log(switcher);
  //   // setSwitchMatrixx(switcher);
  //   // console.log(e)
  // };

  // const auth = useContext(AuthContext);
  let allMapCats = []

// props.mapCatsDropDown.forEach((single, idx, allArray)  => {
//   let objVal = single.title;
//   if(objVal){
//     const obj = {value: single.id, key: single.title};
//     allMapCats.push(obj);
//     // console.log(single)
//   }
// })

  const initialValues = {
    pointNum: '',
    typeOfForm: '',
    city_o: '',
    city_d: '',
    city_lt: '',
    title: '',
    // saveCopiedLocations: [],
    video_link: '',
    web_link: '',
    id_manager: '',
    id_connected_mover: '',
    id_connected_businesses: '',
    where_to: ['warehouse_to_clients_client'],
    from_id_warehouse: [''],
    to_id_clients_client: [''],
    from_id_clients_client: [''],
    to_id_warehouse: [''],
    form_purpes: props.formPurpesTitle,
    description: [' '],
    location_o: [' '],
    // email: '',
    // bio: '',
    // ['id_manager','id_client','id_emploeey',
    // 'date_delivery','where_to','packs_pallet','packs_bags',
    // 'packs_cartons','packs_people','description',
    // 'location_o','location_d','contact_O_r_D',
    // 'payment','vehicle','physical_work'];



    cat: '',
    selectedAddressD: {
      address: 'props.selectedAddressD.address',
      lat: 'props.selectedAddressD.selectedLat',
      lng: 'props.selectedAddressD.selectedLng',
      entry: '',
      floor: '',
      apartment: '',
      description: ''
    },
    selectedAddressO: {
      address: 'props.selectedAddressO.address',
      lat: 'props.selectedAddressO.selectedLat',
      lng: 'props.selectedAddressO.selectedLng',
      entry: '',
      floor: '',
      apartment: '',
      description: ''
    },
    c_contact_id: autoCompleteC._id,
    o_contact_id: autoCompleteO._id,
    o_contact: {
      o_name: '',
      o_phone: '',
      o_address: ''
    },
    d_contact_id: autoCompleteD._id,
    d_contact: {
      d_name: '',
      d_phone: '',
      d_address: ''
    },
    // packs: {
      packs_bags: [''],
      packs_cartons: [''],
      packs_people: [''],
      packs_pallet: [''],
    // },
    // packs_pallet,
    vehicle: {
      type: '',
      ramp: '',
      forklift: '',
      lever: ''
    },
    physical_work: {
      d: '',
      o: '',
      desc: ''
    },
    payment: {
      amount: '',
      whopays: '',
      method: '',
    },
    modeOfdate: dateType,
    date_delivery: '',
    day: '',
    matrix: ''
      }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    // cat: Yup.string().required('בחר קטגוריה'),
    // title: Yup.string().required('הזן כותרת')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

  const history = useHistory();


  useEffect(() => {
    console.log("format>> startDate):",format(startDate, 'MM/dd/yyyy'))
  },[startDate])


  const onSubmit = async values => {

    // ========STAGE 1 CREATE TAG ROUTE=========
    let responseDataContacts;
    try {
      if(newtripOrUpdate === 'new') {
        var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", 'Bearer ' + auth.token);
          // const responseData = await sendRequest('/api/contacts/add', 'POST', JSON.stringify(values), myHeaders);
          responseDataContacts = await sendRequest('/api/contacts/add', 'POST', JSON.stringify({
            first_name: values.title,
            type: "tag_route",
          }), myHeaders);
          console.log("responseDataNEW_TAG_CREATED<>", responseDataContacts)
          // if(props.AutoCompleteCFromAddContactHandler){
          //   props.AutoCompleteCFromAddContactHandler(responseData)
          // }
        setSendedMessage(`${'שם טיול'} חדש נוצר בהצלחה! ${responseDataContacts.contact._id}`)
        if(responseDataContacts.contact.type === 'tag_route') {
          dispatch(
            uiActions.addTagToTagRoute({
            _id: responseDataContacts.contact._id,
            user_id: responseDataContacts.contact.user_id,
            type: responseDataContacts.contact.type,
            first_name: responseDataContacts.contact.first_name
            })
          );
          // if (props.responseNewTagRoute) {
          //   props.responseNewTagRoute(responseDataContacts.contact)
          // }
        }
      }





    // ========STAGE 2=========


    console.log('Form data values:', values)
    values.typeOfForm = typeOfForm[0];

    var saveCopiedLocations = []
    if(props.allLocationsToCopy.length > 0) {
      // console.log("props.allLocationsToCopy:",props.allLocationsToCopy)
      // console.log("responseData.contact._id:",responseDataContacts.contact._id)
      // console.log("allLocationTag:", allLocationTag)
      
      // let NEW_allLocationsCopy = [];
      // props.allLocationsToCopy.forEach(location => {
      //   NEW_allLocationsCopy.push(location)
      // });
      let NEW_allLocationTag = [];
      // console.log("allLocationTag??",allLocationTag)
      allLocationTag.forEach(location => {
        // alert(location)
          NEW_allLocationTag.push(location)
      });

      // console.log("NEW_allLocationTag??",NEW_allLocationTag)
      // console.log("NEW_allLocationsCopy??",NEW_allLocationsCopy)
      // console.log("NEW_allLocationTag-elem")
      let idTag;
      if (newtripOrUpdate === 'new') {
        idTag = responseDataContacts.contact._id
      }
      if (newtripOrUpdate === 'update') {
        // idTag = values.id_tag_route
        idTag = currentCatRouteId
      }
      if (NEW_allLocationTag.length > 0 && props.allLocationsToCopyWithDetailes.length > 0) {
        props.allLocationsToCopyWithDetailes.forEach(elem => {
              const tempArrElem = { ...elem }
              tempArrElem.id_tag_route = idTag
              tempArrElem.prev_id = tempArrElem._id;
              delete tempArrElem._id;
              saveCopiedLocations.push(tempArrElem)
          });
        // console.log("NEW_allLocationsCopy:", NEW_allLocationsCopy)
        // console.log("saveCopiedLocations:", saveCopiedLocations)
        values.saveCopiedLocations = saveCopiedLocations
      }

    }

      console.log('Form data values222:', values)
        try {
          setSendedMessage('מעבד בקשה...')
          console.log("pointNum=>",pointNum)
          var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", 'Bearer ' + auth.token);
          const responseData = await sendRequest('/api/items/add', 'POST', JSON.stringify(values), myHeaders);
          setSendedMessage('טיול חדש נוצר בהצלחה!')
          console.log("responseData<>", responseData)
          props.setNEW_selectedOptionsBySelectedCat2([])
          // setSendedMessage(`${props.typeOfForm[1]} חדש נוצר בהצלחה!`)


          if (responseData.contact.form_purpes === 'location_tag' && responseData.allCreatedItem.length > 0) {
              console.log("dispatch responseData.allCreatedItem.length", responseData.allCreatedItem.length)
              responseData.allCreatedItem.forEach((loc, index) => {
                console.log("LOC!!!>",index ,loc )
                dispatch(
                uiActions.addLocationToTagRoute({
                location_d: loc.location_d,
                _id: loc._id,
                user_id: loc.user_id,
                form_purpes: loc.form_purpes,
                id_tag_route: loc.id_tag_route,
                title: loc.title,
                video_link: loc.video_link,
                web_link: loc.web_link,
                })
              );
              });
        }
      // if (responseData.contact.form_purpes === 'location_tag' && responseData.allCreatedItem.length === 0) {
      //     console.log("dispatch addLocationToTagRoute")
      //     dispatch(
      //       uiActions.addLocationToTagRoute({
      //       location_d: responseData.contact.location_d,
      //       _id: responseData.contact._id,
      //       user_id: responseData.contact.user_id,
      //       form_purpes: responseData.contact.form_purpes,
      //       id_tag_route: responseData.contact.id_tag_route
      //       })
      //     );
      //   var oldItems = JSON.parse(localStorage.getItem('userProfile'));

      //   var newItem = {
      //     location_d: responseData.contact.location_d,
      //       _id: responseData.contact._id,
      //       user_id: responseData.contact.user_id,
      //       form_purpes: responseData.contact.form_purpes,
      //       id_tag_route: responseData.contact.id_tag_route
      //   };

      //   oldItems.AllLocationTag.push(newItem);
      //   localStorage.setItem(
      //     'userProfile',
      //     JSON.stringify(oldItems)
      //   );
      //   // formRef.current.values = ''
      //   // console.log("formRef.current.values", formRef)


      // }

      // history.push('/maps');

      } catch (err) {console.log(err)}
    
    } catch (err) {console.log("errerrerr",err)}

    
  }

  const onSubmitContactNewRoute = async values => {
    console.log('Form data values:', values)
    // values.city = addressesFieldsDetails[3]
    // values.address = addressesFieldsDetails[0]
    // values.lat = addressesFieldsDetails[1]
    // values.lng = addressesFieldsDetails[2]
    // values.location = {
    //   lat: addressesFieldsDetails[1],
    //   lng: addressesFieldsDetails[2]
    // }
      
  }
  return (
    <>

    
    <Formik 
    // enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {formik => {
        return (
          // style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px'}}
          <Form>
            {/* <FormikControl
              control='radio'
              label='צד המשלם'
              name='modeOfContact'
              options={RadioOptions}
            /> */}

            {typeOfForm[3].includes('id_manager') &&

              <FormikControl
                control='select'
                // type='number'
                label='חברת משלוחים'
                name='id_manager'
                options={optionsManagersIds}
              />
            }
            {typeOfForm[3].includes('id_connected_mover') && optionsConnected_movers.length > 0 &&
            
            <FormikControl
            control='select'
            // type='number'
            label='משלוחנים מחוברים'
            name='id_connected_mover'
            options={optionsConnected_movers}
            />
            }

            {typeOfForm[3].includes('id_connected_businesses') && optionsConnected_businesses.length > 0 &&
              <FormikControl
                control='select'
                // type='number'
                label='מעבידים'
                name='id_connected_businesses'
                options={optionsConnected_businesses}
              />
            }

            {typeOfForm[3].includes('id_client') &&
              <FormikControl
                control='select'
                // type='number'
                label='שם לקוח'
                name='id_client'
                options={allUsersClientContacts}
              />
            }

            {typeOfForm[3].includes('id_emploeey') &&
              <FormikControl
                control='select'
                // type='number'
                label='עבור נהג'
                name='id_emploeey'
                options={allEmployeeContacts}
              />
            }

            {/* {typeOfForm[3].includes('id_tag_route') && */}
              <>
              {/* <Grid style={{margin: '5px', flexDirection: 'row-reverse', display: 'flex'}}>
                <Button onClick={() => {
                  setCreateTagRoute(true)
                  // setAddTagRoute(true)
                  openMapHandler()
                  }} variant='outlined'>צור שם מסלול חדש</Button>
              </Grid> */}
              {createTagRoute === '999999999999' &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                {/* <Modal
                  show={createTagRoute}
                  onCancel={() => setCreateTagRoute(false)}
                  header={'צור שם מסלול'}
                  closeButton={<Button variant='contained' onClick={() => setCreateTagRoute(false)}>סגור</Button>}
                > */}
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    // AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_o'
                    typeOfForm={['tag_route', 'צור טיול']}
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                {/* </Modal> */}
                </div>
                }
                
                <div>
                  <div
                    onClick={() => {
                      setNewtripOrUpdate('new')
                    }} 
                    style={{
                      background: newtripOrUpdate === 'new' ? 'rgb(221 221 221)' : '#fff',
                        padding: '10px',
                        marginBottom: '12px',
                        // minHeight: '200px',
                        // overflow: 'scroll',
                        // height: 'calc(100vh - 390px)',
                        // overflowZ: 'hidden',
                        // color: '#fff',
                    }}>
                    <div style={{padding: '10px'}}>איזה שם תתנו לטיול?</div>
                    <div style={{background: '#fff', borderRadius: '9px', padding: '6px'}}>
                      <FormikControl
                        control='input'
                        type='string'
                        label='שם טיול'
                        name='title'
                      />
                    </div>
                  </div>
                  <div style={{
                      textAlign: 'center',
                      background: 'rgb(227 227 227)',
                      color: 'rgb(107 107 107)',
                      fontSize: '18px',
                      padding: '7px',
                      margin: '6px 0px',
                    }}>או</div>
                  <div 
                    onClick={() => {
                      setNewtripOrUpdate('update')
                    }}
                    style={{
                        // background: newtripOrUpdate === 'update' ? 'rgb(0 33 66)' : '#fff',
                        background: newtripOrUpdate === 'update' ? 'rgb(0 33 66)' : 'rgb(0 33 66)',
                        padding: '10px',
                        marginBottom: '12px',
                        minHeight: '200px',
                        overflow: 'scroll',
                        overflowX: 'auto',
                        height: 'calc(100vh - 390px)',
                        color: 'rgb(255, 255, 255)',
                    }}>
                    <div style={{padding: '10px', color: '#fff'}}>הוסיפו לטיול קיים</div>
                    {tagRouteOptions.map(el => {
                      return (
                        <div style={{display: 'flex', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '10px 4px'}}>
                          <div style={{
                            padding: '8px 7px 9px',
                            fontSize: '15px',
                            border: '1px solid rgb(25, 118, 210)',
                            boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
                            background: newtripOrUpdate === 'update' && currentCatRouteId === el.value ? 'rgb(0 33 66)' : '#fff',
                            color: newtripOrUpdate === 'update' && currentCatRouteId === el.value ? '#fff' : '#000',
                          }} key={el.value} value={el.value}
                          onClick={()=> {
                            // setModelChooseTagRoute(false)
                            selectedMapCatChanged(el.value,el.key)
                          }
                          }
                          >
                              {el.key}
                          </div>
                          {/* ===EDIT BUTTONS=== */}
                          {/* <EditRouteButtons obj={el} buttons={['allow_shareable', 'del']} /> */}
                          {/* ===END EDIT BUTTONS=== */}
                        </div>
                        )
                    })}
                    {/* <FormikControl
                      control='select'
                      // type='number'
                      label='בחר שם טיול'
                      name='id_tag_route'
                      options={tagRouteOptions}
                      /> */}
                  </div>
                </div>
              </>
            {/* } */}

            
            


            


          {typeOfForm[3].includes('date_delivery') &&
            <>
            <LocalizationProvider 
              dateAdapter={AdapterDateFns}
              adapterLocale={hebrew}
              >
              <DatePicker
                label="בחר תאריך"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
              {/* <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  // startIcon={<KeyboardArrowUpIcon/>}
                  endIcon={morDetails4 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  onClick={() => setMorDetails4(!morDetails4)}
                >
                  <Typography>עבור תאריך</Typography>
                </Button>
              </Grid> */}
              {/* {morDetails4 &&  */}
              {/* <div className='mor_details' id="mor_details2">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikControl
                      control='date'
                      type='date'
                      label=''
                      name='date_delivery'
                    />

                  </Grid>
                </Grid>
              </div> */}
              {/* } */}
            </>
          }

        {pointNum.map((obj, index) => (
          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {/* <div style={{"width":"35px","height":"35px","background":"#545454","display":"flex","alignItems":"center","justifyContent":"center","color":"#fff","fontSize":"18px","fontWeight":"600","borderRadius":"19px"}}>{index+1}</div> */}
              {/* <div style={{"width":"35px","height":"35px","background":"#545454","display":"flex","alignItems":"center","justifyContent":"center","color":"#fff","fontSize":"18px","fontWeight":"600","borderRadius":"19px"}}>{obj.num-1}</div> */}
              <div style={{display: 'flex', flexDirection: 'column', marginRight: '6px', marginLeft: '30px'}}>


              </div>

            </div>
            <div>

            <div>
            {locationO.map((single) => (
              single[0] === obj.num-1
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>מ:</div>
                      <div style={{color: '#000'}}>{single[1][0]}</div>
                    </div>
                )
                : null
              ))}
            {locationD.map((single) => (
              single[0] === obj.num-1
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>ל:</div>
                      <div style={{color: '#000'}}>{single[1][0]}</div>
                    </div>
                )
                : null
              ))}

            {autoCompleteO.map((single) => (
              single[0] === obj.num-1
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>ל:</div>
                      <div style={{color: '#000'}}>{single[1].first_name}</div>
                    </div>
                )
                : null
              ))}
            {autoCompleteD.map((single) => (
              single[0] === obj.num-1
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>תכולה:</div>
                      <div style={{color: '#000'}}>{single[1].first_name}</div>
                    </div>
                )
                : null
              ))}
            </div>
            <div>
              {formik.values.packs_pallet[obj.num-1] && 
              <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>משטחים:</div>
                <div style={{color: '#000'}}>{formik.values.packs_pallet[obj.num-1]}</div>
              </div>
              }
              {formik.values.where_to[obj.num-1] && typeOfForm[3].includes('where_to') &&
              <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                <div style={{color: '#000', fontWeight: 'bold'}}>{
                formik.values.where_to[obj.num-1] === 'warehouse_to_clients_client' ? 'ממחסן - ללקוח קצה' : 
                formik.values.where_to[obj.num-1] === 'clients_client_to_warehouse' ? 'מלקוח קצה - למחסן' :
                formik.values.where_to[obj.num-1] === 'warehouse_to_warehouse' ? 'ממחסן - למחסן' :
                formik.values.where_to[obj.num-1] === 'clients_client_to_clients_client' && 'מלקוח קצה - ללקוח קצה'}</div>
              </div>
              }
            </div>
            {/* </Grid>
          </Grid> */}
          </div>
            {typeOfForm[3].includes('where_to') &&
            <>
            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12}>
                <FormikControl
                  control='radio'
                  style={{flexDirection: 'column-reverse', alignItems: 'center'}}
                  objectNum={obj.num-1}
                  label=''
                  name={`where_to[${obj.num-1}]`}
                  options={[
                    { key: 'ממחסן - ללקוח קצה', value: 'warehouse_to_clients_client' },
                    { key: 'מלקוח קצה - למחסן', value: 'clients_client_to_warehouse' },
                    { key: 'ממחסן - למחסן', value: 'warehouse_to_warehouse' },
                    { key: 'מלקוח קצה - ללקוח קצה', value: 'clients_client_to_clients_client' },
                  ]}
                />
              </Grid>
            </Grid>

            {formik.values.where_to[obj.num-1] === 'warehouse_to_clients_client' ? 
              
              <div>
                <WarehousesAndClientsClientField  objNum={obj.num-1} labal='מחסן' name={`from_id_warehouse[${obj.num-1}]`} options={warehousesOptions} />
                <WarehousesAndClientsClientField  objNum={obj.num-1} labal='לקוח קצה' name={`to_id_clients_client[${obj.num-1}]`} options={clientsClientOptions} />
              </div>

              :  formik.values.where_to[obj.num-1] === 'clients_client_to_warehouse' ? 

              <div>
                <WarehousesAndClientsClientField  objNum={obj.num-1} labal='לקוח קצה' name={`from_id_clients_client[${obj.num-1}]`} options={clientsClientOptions} />
                <WarehousesAndClientsClientField objNum={obj.num-1} labal='מחסן' name={`to_id_warehouse[${obj.num-1}]`} options={warehousesOptions} />
              </div>

            : formik.values.where_to[obj.num-1] === 'warehouse_to_warehouse' ? 

              <div>
                <WarehousesAndClientsClientField objNum={obj.num-1} labal='מחסן' name={`from_id_warehouse[${obj.num-1}]`} options={warehousesOptions} />
                <WarehousesAndClientsClientField objNum={obj.num-1} labal='מחסן' name={`to_id_warehouse[${obj.num-1}]`} options={warehousesOptions} />
              </div>

              : formik.values.where_to[obj.num-1] === 'clients_client_to_clients_client' && 

              <div>
              <WarehousesAndClientsClientField objNum={obj.num-1} labal='לקוח קצה' name={`from_id_clients_client[${obj.num-1}]`} options={clientsClientOptions} />
              <WarehousesAndClientsClientField objNum={obj.num-1} labal='לקוח קצה' name={`to_id_clients_client[${obj.num-1}]`} options={clientsClientOptions} />
              </div>
            }
            </>
          }
            <div className='mor_details' id="mor_details2">
              <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                  <AddMedia
                    // objectId={obj._id}
                    // pushCommentToCommentsItem={pushCommentToCommentsItem}
                  />
                </Grid> */}
              {typeOfForm[3].includes('packs_pallet') &&
                <Grid item xs={3}>
                  <FormikControl
                    control='input'
                    type='number'
                    label='משטחים'
                    name={`packs_pallet[${obj.num-1}]`}
                  />
                </Grid>
              }
              {typeOfForm[3].includes('packs_bags') &&
                <Grid item xs={3}>
                  <FormikControl
                    control='input'
                    type='number'
                    label='שקיות'
                    name={`packs_bags[${obj.num-1}]`}
                    />
                </Grid>
              }
              {typeOfForm[3].includes('packs_cartons') &&
                <Grid item xs={3}>
                  <FormikControl
                    control='input'
                    type='number'
                    label='קרטונים'
                    name={`packs_cartons[${obj.num-1}]`}
                  />
                </Grid>
              }
              {typeOfForm[3].includes('packs_people') &&
                <Grid item xs={3}>
                  <FormikControl
                    control='input'
                    type='number'
                    label='אנשים'
                    name={`packs_people[${obj.num-1}]`}
                  />
                </Grid>
              }
              </Grid>

              {typeOfForm[3].includes('description') &&
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='textarea'
                  label='הערות'
                  name={`description[${obj.num-1}]`}
                  value=' '
                />
              </Grid>
              }
            </div>
            

      {/* } */}

      {typeOfForm[3].includes('location_d') &&
            <Grid item xs={12}>
                {/* <FormikControl
                  control='addressInput'
                  addToId={obj.num-1}
                  type='string'
                  label='כתובת אוטומטית'
                  name={`location_d[${obj.num-1}]`}
                  typeLocation='d'
                  specialLabal="כתובת יעד"
                  changed={changed}
                  // changeFormikAddressValues={() => {
                  //   changeFormikAddressValues(obj.num-1)
                  //   // updateAddressesFields = (obj.num-1, addessesFields)
                  //   }
                  // }
                  // AddressFromRedux={userProfile.address}
                  updateAddressesFields={updateAddressesFields}
                /> */}
                {/* <FormikControl
                  control='input'
                  type='string'
                  label='כותרת מיקום'
                  name='title'
                />
                <FormikControl
                  control='input'
                  type='string'
                  label='בחר סרטון'
                  name='video_link'
                />
                <FormikControl
                  control='input'
                  type='string'
                  label='לינק לויקיפדיה'
                  name='web_link'
                /> */}
            </Grid>
          }


          {typeOfForm[3].includes('contact_O_r_D') &&
          <>

          {typeOfForm[0] !== 'jobs' &&
          <>
            <div style={{ display: 'flex', marginTop: '15px'}}>
              <div style={buttonStepForum_numbrer} >1</div>
              <div style={buttonStepForum_text}>לקוח - כתובת ואיש קשר</div>
            </div>
                
                <div className='mor_details' id="mor_details1">

      {autoCompleteC.map((single) => (
        single[0] === obj.num-1
          ? (
            <div style={{display: 'flex', flexDirection: 'column', padding: '10px', background: '#262626', borderRadius: '10px', marginBottom: '13px'}}>
              <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>שם:</div>
                <div style={{color: '#fff'}}>{single[1].first_name + ' - ' + single[1].last_name}</div>
              </div>
              <div style={{display: 'flex', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>טלפון:</div>
                <div style={{color: '#fff'}}>{single[1].phone1}</div>
              </div>
              <div style={{display: 'flex', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>אימייל:</div>
                <div style={{color: '#fff'}}>{single[1].email}</div>
              </div>
              <div style={{display: 'flex', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>כתובת:</div>
                <div style={{color: '#fff'}}>{single[1].address}</div>
              </div>
            </div>
          )
          : null
      ))}
    
    
              
    
    
                  <Grid container columnSpacing={1} rowSpacing={1}>
                    <Grid item xs={8}>
                      {/* <div> */}
                        <div className="search">
                            <div className="searchInputs">
                              <input
                                type="text"
                                placeholder={'חיפוש לקוח'}
                                value={contact_O_r_D === 'C' ? wordEntered : ''}
                                // onChange={handleFilter}
                                onChange={(e) => {
                                  setContact_O_r_D('C')
                                  onChangeTwo(e)
                                  setWordEntered(e.target.value)
                                }}
                              />
                              <div className="searchIcon">
                                {optionsTwo.length === 0 && 
                                  <SearchIcon />
                                }
                                {optionsTwo.length !== 0 && contact_O_r_D === 'C' && 
                                <CancelOutlinedIcon id="clearBtn" onClick={clearInput} />
                                }
                              </div>
                          </div>
    
    
                          {optionsTwo.length != 0 && contact_O_r_D === 'C' && (
                            <div className="dataResult">
                              {optionsTwo.slice(0, 15).map((value, key) => {
                                return (
                                  // <a className="dataItem" href={value.link} target="_blank">
                                    <div className="singleResult"
                                      onClick={ ()=> {
                                        console.log(value)
                                        const indexExistInArray = autoCompleteC.filter( (single) => {
                                          // console.log("single[0] => ",single[0]) 
                                            // if (single[0] === index){
                                              return single[0] === obj.num-1
                                            // }
                                          } 
                                        )
                                        console.log("indexExistInArray.length => ", indexExistInArray.length, indexExistInArray)
                                        if (indexExistInArray.length === 0){
                                          setAutoCompleteC(  [...autoCompleteC , [obj.num-1, value]])
                                        } else if (indexExistInArray.length > 0) {
                                          const newAutoCompleteC = autoCompleteC.filter((single) => single[0] !== obj.num-1);
                                          console.log("newAutoCompleteC:", newAutoCompleteC)
                                          setAutoCompleteC(  [...newAutoCompleteC , [obj.num-1, value]])
                                        }
                                        clearInput()
                                      }
                                        // (e) => setAutoCompleteId(e)
                                      }>
                                      {value.first_name + ' - ' + value.last_name + ' - ' + value.phone1 + ' - ' + value.email + ' - ' + value.address} </div>
                                  // </a>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      {/* </div> */}
                    </Grid>
                    <Grid container>
                      <Grid style={{margin: '5px'}}>
                        <Button onClick={() => {
                          setCreateContactName(true)
                          setContact_O_r_D('C')
                          openMapHandler()
                          }} variant='outlined'>צור לקוח </Button>
                      </Grid>
                      <Grid style={{margin: '5px'}}>
                        <Button onClick={() => {
                          setSearchContactName(true)
                          setContact_O_r_D('C')
                          // openMapHandler()
                          }} variant='outlined'>חיפוש באינדקס</Button>
                      </Grid>
                    </Grid>
                    {searchContactName && contact_O_r_D === 'C' &&
                      <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                        <Modal
                          show={searchContactName}
                          onCancel={() => setSearchContactName(false)}
                          header={'חיפוש באינדקס'}
                          closeButton={<Button variant='contained' onClick={() => setSearchContactName(false)}>סגור</Button>}
                        >
                          <Grid item xs={12}>
                            <SearchContacts 
                              AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromSearchContactHandler(e)}
                            />
                          </Grid>
                        </Modal>
                      </div>
                    }
                    {createContactName && contact_O_r_D === 'C' &&
                    <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                    <Modal
                      show={createContactName}
                      onCancel={() => setCreateContactName(false)}
                      header={'צור לקוח'}
                      // contentClass="place-item__modal-content"
                      // footerClass="place-item__modal-actions"
                      // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                      closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                    >
                      <Grid item xs={12}>
                        <FormikUiAddContact 
                        AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                        typeOfForm={['client', 'לקוח', 'תוכלו לציין עבור איזה לקוח מבוצע עבודה וכן תוכלו לקבל עבודות מלקוחות אליכם', 'type_inputes']}
                        typeLocation='contact_o'
                        formPurpesTitle={'formPurpes'}
                        zIndex={'zIndex6'}
                        // mapCatsDropDown={{}}
                        selectedAddressD={'selectedAddressD_RD'}
                        selectedAddressO={'selectedAddressO_RD'}
                    //     typeLocation='contact_o'
                    // formPurpesTitle={'formPurpes'}
                    // zIndex={'zIndex6'}
                    // // mapCatsDropDown={{}}
                    // selectedAddressD={'selectedAddressD_RD'}
                    // selectedAddressO={'selectedAddressO_RD'}
                        />
                      </Grid>
                    </Modal>
                    </div>
                    }
                  </Grid>
    
                </div>
                </>
                }











            <div style={{ display: 'flex', marginTop: '15px'}}>
              <div style={buttonStepForum_numbrer} >2</div>
              <div style={buttonStepForum_text}>מוצא - כתובת ואיש קשר</div>
            </div>

            {/* {morDetails1[0] && morDetails1[1] === index && */}
            
            <div className='mor_details' id="mor_details1">
{/* {data.map((record) => (
    record.list.length > 0
      ? (<YourRenderComponent record={record} key={record.id} />)
      : null
  ))} */}
  {autoCompleteO.map((single) => (
    single[0] === obj.num-1
      ? (
        <div style={{display: 'flex', flexDirection: 'column', padding: '10px', background: '#262626', borderRadius: '10px', marginBottom: '13px'}}>
          <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>שם:</div>
            <div style={{color: '#fff'}}>{single[1].first_name + ' - ' + single[1].last_name}</div>
          </div>
          <div style={{display: 'flex', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>טלפון:</div>
            <div style={{color: '#fff'}}>{single[1].phone1}</div>
          </div>
          <div style={{display: 'flex', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>אימייל:</div>
            <div style={{color: '#fff'}}>{single[1].email}</div>
          </div>
          <div style={{display: 'flex', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>כתובת:</div>
            <div style={{color: '#fff'}}>{single[1].address}</div>
          </div>
        </div>
      )
      : null
  ))}


          


              <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={8}>
                  {/* <div> */}
                    <div className="search">
                        <div className="searchInputs">
                          <input
                            type="text"
                            placeholder={'חיפוש איש קשר'}
                            value={contact_O_r_D === 'O' ? wordEntered : ''}
                            // onChange={handleFilter}
                            onChange={(e) => {
                              setContact_O_r_D('O')
                              onChangeTwo(e)
                              setWordEntered(e.target.value)
                            }}
                          />
                          <div className="searchIcon">
                            {optionsTwo.length === 0 && 
                              <SearchIcon />
                            }
                            {optionsTwo.length !== 0 && contact_O_r_D === 'O' && 
                            <CancelOutlinedIcon id="clearBtn" onClick={clearInput} />
                            }
                          </div>
                      </div>


                      {optionsTwo.length != 0 && contact_O_r_D === 'O' && (
                        <div className="dataResult">
                          {optionsTwo.slice(0, 15).map((value, key) => {
                            return (
                                <div className="singleResult"
                                  onClick={ ()=> {
                                    console.log(value)
                                    const indexExistInArray = autoCompleteO.filter( (single) => {
                                          return single[0] === obj.num-1
                                      } 
                                    )
                                    console.log("indexExistInArray.length => ", indexExistInArray.length, indexExistInArray)
                                    if (indexExistInArray.length === 0){
                                      setAutoCompleteO(  [...autoCompleteO , [obj.num-1, value]])
                                    } else if (indexExistInArray.length > 0) {
                                      const newAutoCompleteO = autoCompleteO.filter((single) => single[0] !== obj.num-1);
                                      console.log("newAutoCompleteO:", newAutoCompleteO)
                                      setAutoCompleteO(  [...newAutoCompleteO , [obj.num-1, value]])
                                    }
                                    clearInput()
                                  }
                                  }>
                                  {value.first_name + ' - ' + value.last_name + ' - ' + value.phone1 + ' - ' + value.email + ' - ' + value.address} </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  {/* </div> */}
                  </Grid>
                  
                  <Grid container>
                    <Grid style={{margin: '5px'}}>
                      <Button onClick={() => {
                        setCreateContactName(true)
                        setContact_O_r_D('O')
                        openMapHandler()
                        }} variant='outlined'>צור חדש</Button>
                    </Grid>
                    <Grid style={{margin: '5px'}}>
                      <Button onClick={() => {
                        setSearchContactName(true)
                        setContact_O_r_D('O')
                        // openMapHandler()
                        }} variant='outlined'>חיפוש באינדקס</Button>
                    </Grid>
                  </Grid>
                  {searchContactName && contact_O_r_D === 'O' &&
                      <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                        <Modal
                          show={searchContactName}
                          onCancel={() => setSearchContactName(false)}
                          header={'חיפוש באינדקס'}
                          closeButton={<Button variant='contained' onClick={() => setSearchContactName(false)}>סגור</Button>}
                        >
                          <Grid item xs={12}>
                            <SearchContacts 
                              AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromSearchContactHandler(e)}
                            />
                          </Grid>
                        </Modal>
                      </div>
                    }
                {createContactName && contact_O_r_D === 'O' &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createContactName}
                  onCancel={() => setCreateContactName(false)}
                  header={'צור איש קשר'}
                  closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_o'
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                </Modal>
                </div>
                }
              </Grid>

            </div>
            {/* // } */}
         
         
            <div style={{ display: 'flex', marginTop: '15px'}}>
              <div style={buttonStepForum_numbrer} >3</div>
              <div style={buttonStepForum_text}>יעד - כתובת ואיש קשר</div>
            </div>
            {/* }  */}
            {/* {morDetails6[0] && morDetails6[1] === index && */}
            <div className='mor_details' id="mor_details1">
              
              {autoCompleteD.map((single) => (
                single[0] === obj.num-1
                  ? (
                  <div style={{display: 'flex', flexDirection: 'column', padding: '10px', background: '#262626', borderRadius: '10px', marginBottom: '13px'}}>
                          <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>שם:</div>
                            <div style={{color: '#fff'}}>{single[1].first_name + ' - ' + single[1].last_name}</div>
                          </div>
                          <div style={{display: 'flex', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>טלפון:</div>
                            <div style={{color: '#fff'}}>{single[1].phone1}</div>
                          </div>
                          <div style={{display: 'flex', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>אימייל:</div>
                            <div style={{color: '#fff'}}>{single[1].email}</div>
                          </div>
                          <div style={{display: 'flex', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>כתובת:</div>
                            <div style={{color: '#fff'}}>{single[1].address}</div>
                          </div>
                        </div>
                  )
                  : null
              ))}


          <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={8}>
                  <div className="search">
                      <div className="searchInputs">
                        <input
                          type="text"
                          placeholder={'חיפוש איש קשר'}
                          value={contact_O_r_D === 'D' ? wordEntered : ''}
                          // onChange={handleFilter}
                          onChange={(e) => {
                            setContact_O_r_D('D')
                            onChangeTwo(e)
                            setWordEntered(e.target.value)
                          }}
                        />
                        <div className="searchIcon">
                          {optionsTwo.length === 0 &&
                            <SearchIcon />
                          }
                          {optionsTwo.length !== 0 && contact_O_r_D === 'D' && 
                          <CancelOutlinedIcon id="clearBtn" onClick={clearInput} />
                          }
                        </div>
                    </div>


                    {optionsTwo.length != 0 &&  contact_O_r_D === 'D' &&  (
                      <div className="dataResult">
                          {optionsTwo.slice(0, 15).map((value, key) => {
                          return (
                              <div className="singleResult"
                                onClick={ ()=> {
                                  console.log(value)
                                  const indexExistInArray = autoCompleteD.filter( (single) => {
                                    console.log("single[0] => ",single[0]) 
                                      if (single[0] === obj.num-1){
                                        return single[0] === obj.num-1
                                      }
                                    } 
                                  )
                                console.log("indexExistInArray.length => ", indexExistInArray.length, indexExistInArray)
                                if (indexExistInArray.length === 0){
                                  setAutoCompleteD(  [...autoCompleteD , [obj.num-1, value]])
                                } else if (indexExistInArray.length > 0) {
                                  const newAutoCompleteD = autoCompleteD.filter((single) => single[0] !== obj.num-1);
                                  setAutoCompleteD(  [...newAutoCompleteD , [obj.num-1, value]])
                                }
                                  clearInput()
                                }
                                }>
                                {value.first_name + ' - ' + value.last_name + ' - ' + value.phone1 + ' - ' + value.email + ' - ' + value.address} </div>
                            // </a>
                          );
                        })}
                      </div>
                    )}
                    
                  </div>
                </Grid>

                  <Grid container>
                    <Grid style={{margin: '5px'}}>
                      <Button onClick={() => {
                        setCreateContactName(true)
                        setContact_O_r_D('D')
                        openMapHandler()
                        }} variant='outlined'>צור חדש</Button>
                    </Grid>
                    <Grid style={{margin: '5px'}}>
                      <Button onClick={() => {
                        setSearchContactName(true)
                        setContact_O_r_D('D')
                        // openMapHandler()
                        }} variant='outlined'>חיפוש באינדקס</Button>
                    </Grid>
                  </Grid>
                  {searchContactName && contact_O_r_D === 'D' &&
                    <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                      <Modal
                        show={searchContactName}
                        onCancel={() => setSearchContactName(false)}
                        header={'חיפוש באינדקס'}
                        closeButton={<Button variant='contained' onClick={() => setSearchContactName(false)}>סגור</Button>}
                      >
                        <Grid item xs={12}>
                          <SearchContacts 
                            AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromSearchContactHandler(e)}
                          />
                        </Grid>
                      </Modal>
                    </div>
                  }
                {createContactName && contact_O_r_D === 'D' &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createContactName}
                  onCancel={() => setCreateContactName(false)}
                  header={'צור איש קשר חדש'}
                  // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                  closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_d'
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                </Modal>
                </div>
                }
              </Grid>
            </div>
            {/* } */}
        </>
        }
            {/* <Grid item xs={12}>
              <Typography>מספר חבילות</Typography>
            </Grid> */}
            {/* <Grid item xs={12}>
              
              <Button
                variant="text"
                // color="primary"
                style={{color: '#fff'}}
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails2 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => {
                  morDetails2[1] === index ? setMorDetails2([!morDetails2[0],index])
                    :  morDetails2[0] === false ?  setMorDetails2([!morDetails2[0],index])
                     : morDetails2[0] === true &&  setMorDetails2([true,index])                  
                  }
                }
                
            ><Typography style={{color: '#fff'}}>מספר חבילות</Typography></Button>
            </Grid> */}


            {/* {morDetails2[0] && morDetails2[1] === index &&

            <div className='mor_details' id="mor_details2">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='משטחים'
                  name={`packs[${obj.num-1}].pack_pallet`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='שקיות'
                  name={`packs[${obj.num-1}].pack_bags`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קרטונים'
                  name={`packs[${obj.num-1}].pack_cartons`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='אנשים'
                  name={`packs[${obj.num-1}].pack_people`}
                />
              </Grid>
            </Grid>
            </div>
            } */}
            </div>
            ))}
   {/* {displayDandOForm && */}
              {/* <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  // startIcon={<KeyboardArrowUpIcon/>}
                  endIcon={morDetails5 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  onClick={() => setMorDetails5(!morDetails5)}
                >
                  <Typography>כתובת מוצא</Typography>
                </Button>
              </Grid> */}
            {/* } */}
 {/* <Grid item xs={12}>
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails0 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails0(!morDetails0)}
                
              >
                <Typography>כתובת יעד</Typography>
              </Button>
            </Grid>
            {morDetails0 && 
            // <Grid item xs={12}>
            //   <Typography>כתובת יעד</Typography>
            // </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
            <FormikControl
              control='input'
              type='string'
              label='כותרת מיקום'
              name='title'
            />
            </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  label='קטגוריה'
                  name='cat'
                  options={allMapCats}
                />
              </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='כתובת יעד'
              name='selectedAddressD.address'
            />
            </Grid>
            <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='כניסה'
                  name='selectedAddressD.entry'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קומה'
                  name='selectedAddressD.floor'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='דירה'
                  name='selectedAddressD.apartment'
                />
              </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו אורך lat'
              name='selectedAddressD.lat'
            />
            </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו רוחב lng'
              name='selectedAddressD.lng'
            />
            </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='textarea'
                  label='הערות יעד'
                  name='selectedAddressD.description'
                />
              </Grid>
            </Grid>
          } */}

 {/* {morDetails5 && 

            <Grid container spacing={1}>
            <Grid item xs={12}>
            <FormikControl
              control='input'
              type='string'
              label='כתובת מלאה'
              name='selectedAddressO.address'
            />
            </Grid>
            <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='כניסה'
                  name='selectedAddressO.entry'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קומה'
                  name='selectedAddressO.floor'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='דירה'
                  name='selectedAddressO.apartment'
                />
              </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו אורך lat'
              name='selectedAddressO.lat'
            />
            </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו רוחב lng'
              name='selectedAddressO.lng'
            />
            </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='textarea'
                  label='הערות מוצא'
                  name='selectedAddressO.description'
                />
              </Grid>
            </Grid>
          } */}
            {/* <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם'
                  name='d_contact.d_name'
                />
              </Grid>
              <Grid item xs={6}>
              <FormikControl
                control='input'
                type='string'
                label='טלפון'
                name='d_contact.d_phone'
              />
              </Grid>
              <Grid item xs={6}>
              <FormikControl
                control='input'
                type='string'
                label='כתובת'
                name='d_contact.d_address'
              />
              </Grid>
            </Grid> */}
            {typeOfForm[3].includes('payment') &&
            <>
            <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails3 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails3(!morDetails3)}
                
            ><Typography>פרטי תשלום</Typography></Button>
            </Grid>
            {morDetails3 && 

            <div className='mor_details' id="mor_details2">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='סכום'
                  name='payment.amount'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='צד המשלם'
                  name='payment.whopays'
                  options={[
                    { key: 'מי משלם', value: '' },
                    { key: 'לקוח', value: 'c' },
                    { key: 'מוצא', value: 'o' },
                    { key: 'יעד', value: 'd' },
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='צורת תשלום'
                  name='payment.method'
                  options={[
                    { key: 'צורת תשלום', value: '' },
                    { key: 'מערכת dlance', value: 'dlance' },
                    { key: 'אשראי', value: 'card' },
                    { key: 'העברה בנקאית', value: 'bank' },
                    { key: 'ציק', value: 'check' },
                  ]}
                />
              </Grid>
            </Grid>
            </div>
            }
          </>
          }

          {typeOfForm[3].includes('vehicle') &&
          <>
            {/* {displayDandOForm && */}
              <Grid item xs={12}>
                
                <Button
                  variant="text"
                  color="primary"
                  // startIcon={<KeyboardArrowUpIcon/>}
                  endIcon={morDetails7 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  onClick={() => setMorDetails7(!morDetails7)}
                  
              ><Typography>סוג רכב</Typography></Button>
              </Grid>
            {/* } */}
            {morDetails7 && 

            <div className='mor_details' id="mor_details2">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='סוג רכב'
                  name='vehicle.type'
                  options={[
                    { key: 'רכב', value: 'car' },
                    { key: 'אופנוע', value: 'motorcycle' },
                    { key: 'אופניים', value: 'bicycle' },
                    { key: 'משאית 5 טון', value: 'truck5' },
                    { key: 'משאית 7 טון', value: 'truck7' },
                    { key: 'משאית 10 טון', value: 'truck10' },
                    { key: 'משאית 12 טון', value: 'truck12' },
                    { key: 'משאית 15 טון', value: 'truck15' },
                    { key: 'משאית 18 טון', value: 'truck18' },
                    { key: 'משאית מעל 18 טון', value: 'truck18plus' },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='רמפה'
                  name='vehicle.ramp'
                  options={[
                    { key: 'לא חייב', value: 'no' },
                    { key: 'חייב רמפה', value: 'yes' },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='מלגזה'
                  name='vehicle.forklift'
                  options={[
                    { key: 'לא חייב', value: 'no' },
                    { key: 'חייב מלגזה', value: 'yes' },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='מנוף'
                  name='vehicle.lever'
                  options={[
                    { key: 'לא חייב', value: 'no' },
                    { key: 'חייב מנוף', value: 'yes' },
                  ]}
                />
              </Grid>
            </Grid>
            </div>
            }
          </>
          }

          {typeOfForm[3].includes('physical_work') &&
          <>
            <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails8 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails8(!morDetails8)}
                
            ><Typography>עבודה פיזית</Typography></Button>
            </Grid>
            {morDetails8 && 

            <div className='mor_details' id="mor_details2">
             <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <FormikControl
                  control='select'
                  // type='number'
                  label='בהלוך'
                  name='physical_work.o'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                /> */}
                <FormikControl
                  control='radio'
                  // type='radio'
                  label='בהלוך'
                  name='physical_work.o'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                  handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                />
              </Grid>
              <Grid item xs={6}>
                {/* <FormikControl
                  control='select'
                  // type='number'
                  label='בחזור'
                  name='physical_work.d'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                /> */}
                <FormikControl
                  control='radio'
                  // type='radio'
                  label='בחזור'
                  name='physical_work.d'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                  handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                />
              </Grid>

              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='textarea'
                  label='הערות סבלות'
                  name='physical_work.desc'
                />
              </Grid>
            </Grid>
            </div>
            }
          </>
          }
            {/* <Android12Switch/> */}
            
            <Button variant='contained' type='submit' disabled={!formik.isValid}>
              {props.submitTextButton ? props.submitTextButton : 'צור חדש'}
            </Button>
            <Grid item xs={6}>
                <Typography style={{color: 'green'}}>{sendedMessage}</Typography>
            </Grid>
          </Form>
        )
      }}
    </Formik>
    {/* } */}
    </>
  )
}


function WarehousesAndClientsClientField(props) {
  return (
          <FormikControl
            control='select'
            // type='number'
            label={props.labal}
            name={props.name}
            options={props.options}
          />
  );
}


export default AddTagAndLocationsByCopy
