import { Button, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Card from '../UIElements/Card';
import classes from './Cart.module.css';
import CartItem from './CartItem';

const Cart = (props) => {
  // let placesToShow;
  const cartItems = useSelector((state) => state.cart.items);
  const mapCats = useSelector((state) => state.cart.placesCats);
  const [placesType, setPlacesType] = useState(null);
  const [placesToShow, setPlacesToShow] = useState([]);

  const RadioStyle = {
    formControl: {display: "flex", marginTop: "20px", flexDirection: 'column'},
    radioContainer: {display:"flex",justifyContent:"flexStart", width:"unset", minWidth:"83px",},
    radioLabel: {"fontWeight":"400","marginBottom":"0.5rem","minWidth":"51px"},
    radioInput: {width: "18px", height: "18px","font":"inherit","border":"1px solid #ccc","background":"#f8f8f8","padding":"0.15rem 0.25rem"},
    radioGroupOfContainers: {display:"flex"},
  }
useEffect(() => {
console.log("placesToShow::: ", placesToShow)
},[placesToShow])
  const handlePlacesSubmit = () => {
    console.log(placesType)
    // placesToShow
    setPlacesToShow([])
    cartItems.map(place => {
      if(place.form_purpes == placesType){
        setPlacesToShow (oldArray => [
          ...oldArray,
          {place}
        ])
      }
    })
  }
  const selectedMapCatChanged = event => {
    const currentCat = event.target.value;
    console.log("currentCat::", currentCat)
    // setSelectedOptionsBySelectedCat([]);
    // cartItems.map(place => {
    //   if(place.cat === currentCat){
    //     setSelectedOptionsBySelectedCat (oldArray => [
    //       ...oldArray,
    //       {place}
    //     ])
    //   }
    // })
    // console.log("selectedOptionsBySelectedCat", selectedOptionsBySelectedCat)
  }

  return (
    <Card className={classes.cart} style={{display: props.showCartStyle, zIndex: props.zIndex}} >
      <h2>כל המיקומים שלי{props.showCartStyle}</h2>
      <div style={RadioStyle.formControl}>
        <div style={RadioStyle.radioGroupOfContainers}>
          <div style={RadioStyle.radioContainer}>
            <label for="yes1" style={RadioStyle.radioLabel}>מיקומים</label>
            <input type="radio" id="yes1" name="matrix" value="d" style={RadioStyle.radioInput} onClick={(e) => setPlacesType(e.target.value)}/>
          </div>
          <div style={RadioStyle.radioContainer}>
            <label for="no2" style={RadioStyle.radioLabel}>מוצא ויעד</label>
            <input type="radio" id="no2" name="matrix" value="d&o" style={RadioStyle.radioInput} onClick={(e) => setPlacesType(e.target.value)}/>
          </div>
        </div>
        <div style={RadioStyle.radioGroupOfContainers}>
          <div style={RadioStyle.radioContainer}>
            <label for="no3" style={RadioStyle.radioLabel}>עבודות</label>
            <input type="radio" id="no3" name="matrix" value="job" style={RadioStyle.radioInput} onClick={(e) => setPlacesType(e.target.value)}/>
          </div>
          <div style={RadioStyle.radioContainer}>
            <label for="no4" style={RadioStyle.radioLabel}>עבודות שקיבלתי</label>
            <input type="radio" id="no4" name="matrix" value="dd" style={RadioStyle.radioInput} onClick={(e) => setPlacesType(e.target.value)}/>
          </div>
        </div>
      </div>


      <div style={{display: 'flex', flexDirection: 'column'}}>
        {/* <div>בחר קטגרויה</div> */}
        <div>
        <TextField 
          select={true}
          variant='filled'
          fullWidth={true}
          bgColor='#fff'
          style={{background: '#fff', color: '#000'}}
          // onChange={handleChange}
          label='בחר קטגוריה'
          margin='normal'
        >
          {/* {mapCats.map(option => {
              let objVal = option.title;
              if(objVal){
                return (
                  <option key={option.id} value={option.id}>
                      {option.title}
                    </option>
                  )
                }
              })} */}
        {mapCats.map(option => {
          let objVal = option.title;
          if(objVal){
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            )
          }
        })}
      </TextField>
          {/* <select style={{height: "40px", padding: "8px", border: "0", borderBottom: "1px solid #bbb", margin: "8px 0px 25px 0px", fontSize: '17px'}} id="allmapcats" onChange={(e) => selectedMapCatChanged(e)}>
                  <option key={'00000'} value={'00000'}>בחר קטגרויה</option>
            {mapCats.map(option => {
              let objVal = option.title;
              if(objVal){
                return (
                  <option key={option.id} value={option.id}>
                      {option.title}
                    </option>
                  )
                }
              })}
          </select> */}
        </div>

        <div>
          <Button 
            variant='contained' 
            fullWidth type='submit' 
            disabled={placesType === null ? true : false} 
            onClick={handlePlacesSubmit}>חפש {placesType === 'd' && 'מיקומים'}
            {placesType === 'd&o' && 'מוצא ויעד'}
            {placesType === 'job' && 'עבודות'}
          </Button>
        </div>
        
      </div>
      <ul id='allSeeOnMapByFormPurpes'>
        { 
        // placesToShow.lange > 2 &&
        placesToShow.slice(0).reverse().map((item, index) => (
        // {console.log(item)}
          // <div>{index}</div>
          <CartItem
            // key={item.selectedAddressD.location.lng}
            // item={{
            //   id: item.id,
            //   title: item.title,
            //   quantity: item.quantity,
            //   total: item.totalPrice,
            //   price: item.price,
            //   price: item.price,
            // }}
            // place={item}
            place={item}
          />
        ))
        }
      </ul>
    </Card>
  );
};

export default Cart;