import React, {useState} from 'react'
import DateView from 'react-datepicker'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import 'react-datepicker/dist/react-datepicker.css'
import './UiWithFormik.css'

function DatePicker (props) {
  const { label, name, ...rest } = props
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div className='form-control'>
      <label htmlFor={name}>{label}</label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form
          const { value } = field
          return (
            <DateView
              id={name}
              selected={startDate}
              // {...field}
              // {...rest}
              // selected={value}
              // onChange={val => setFieldValue(name, val)}
              onChange={(date) => setStartDate(date)}
            />
          )
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default DatePicker
