import React, {useContext, useState, useEffect, useRef} from 'react'

import TableXlsxItem from './TableXlsxItem'
import './TableXlsx.css'

export default function TableXlsx({ theadData, tbodyData, changeNewDataAddress }) {


    // useEffect(() => {
    //     console.log("theadData, tbodyData, changeNewDataAddress :",theadData, tbodyData, changeNewDataAddress )
    // }, [])
    
 return (
   <div className='container_t'>
{/*        <div className='container_in_t'> */}
          <div className='headet_t' style={{height: '36px'}}>
           {theadData.map(heading => {
                // we exlude heading not for view in table like "pleaseCheck, fullAddress, fullAddress_array"
               return <>{heading !== 'pleaseCheck' && heading !== 'fullAddress' && heading !== 'fullAddress_array' &&
                   <div className='cell_t' key={heading} style={
                       { background: '#535353', color: '#fff', borderLeft: '1px solid #fff' }
                   }>{heading}</div>
               }</>
           })}
         </div>
{/*        </div> */}
       <div className='container_in_t'>
           {tbodyData.map((row, index) => {
               return <>
                   <div className='headet_t' key={index}>
                   {theadData.map((key, indx) => {
                       return <TableXlsxItem
                           theadData={theadData}
                           key={key}
                           row_key={row[key]}
                           theadData_index={theadData[index]}
                           indx={indx}
                           index={index}
                           row={row}
                           changeNewDataAddress={changeNewDataAddress}
                       />
                    //    <div
                    //        className='cell_t'
                    //        onClick={() => {
                    //        console.log("row[key]", row[key])
                    //        console.log("row", row)
                    //        console.log("index", index)
                    //        console.log("key",  key,)
                    //        console.log("theadData[index]", theadData[index])
                    //        console.log("row['כתובת'] - row['fullAddress']", row['כתובת'] + '-' + row['fullAddress'])
                    //    }
                    // //    } style={{ background: row['fullAddress'].includes(row['כתובת'].split(' ')[0]) ? '#fff' : 'red' }} key={row[key]}>{row[key] + ' - ' + row + '-' + index}</td>
                    //    } style={
                    //        { borderBottom: '1px solid #bbb', borderLeft: '1px solid #bbb', background: row['fullAddress'] === undefined ? 'red' : '#fff', color: row['pleaseCheck'] === "pleaseCheck" && 'red' }
                    //    } key={row[key]}>
                    //        {key !== 'pleaseCheck' &&
                    //            row[key]
                    //        }</div>
                   })}
               </div>
               </>;
           })}
       </div>
   </div>
);
}