import React, {useContext, useState, useEffect, useRef} from 'react'
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import {useHttpClient} from '../../hooks/http-hook'
import { Avatar, Button, Grid, IconButton, ListItem, ListItemAvatar, Tab, Tabs, TextField, Typography } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';

import FormikUiAddContact from '../FormikElements/FormikUiAddContact';
import ListItemText from '@mui/material/ListItemText';

import AddPackagesForTransportation from '../FormikElements/AddPackagesForTransportation';






import '../FormikElements/UiWithFormik.css';


const getCitiesTwo = async (str) => {
  try {
    let searchableCity = str.replace(/,/g, "");
    let url = "/api/contacts/autocomplete_two?city=" + searchableCity;

    let { data } = await axios.get(url);
    return data;
  } catch (error) {
    console.error(error);
  }
};
const buttonStyle = {
  background: 'rgb(230 230 230)', 
  borderRadius: '20px',
  border: '0px solid rgb(219, 219, 219)',
  fontSize: '15px',
  color: 'rgb(103 103 103)',
  // marginLeft: '6px',
  minWidth: '61px',
  margin: '6px 2px',
  height: '39px',
  border: '1px solid #dbdbdb',
  cursor: 'pointer',
  padding: '0px 14px'
}
// background: rgb(230 230 230);
// border-radius: 20px;
// font-size: 15px;
// color: rgb(103 103 103);
// margin: 6px 2px;
// min-width: 61px;
// height: 39px;
// border: 0px solid rgb(219, 219, 219);
// cursor: pointer;
// padding: 0 14px;
function ViewSomething (props) {
  const userProfile = useSelector((state) => state.ui.userProfile);
  const usersClientsFromContacts = useSelector((state) => state.ui.userProfile.AllClients);

  const allFormInputName = ['id_manager','id_client','id_emploeey',
  'date_delivery','where_to','packs_pallet','packs_bags',
  'packs_cartons','packs_people','description',
  'location_o','location_d','contact_O_r_D',
  'payment','vehicle','physical_work','id_tag_route'];


  const chooseAddSubject = [
    ['מיקומים','places',<AddLocationAltOutlinedIcon sx={{ fontSize: 40 }} />],
    ['אינדקס','index',<MenuBookOutlinedIcon sx={{ fontSize: 40 }} />]
  ]
  
  const formTypeOptions = [
      { title: 'אנשי קשר', subTitle: 'הוספת איש קשר רגיל לכל מטרה', value: 'person', subject: 'index', path: '/contacts/all'},
      { title: 'עובדים', subTitle: 'הוספת נהג/עובד לו תכולו לשייך משטחים/פריטים לשינוע', value: 'employee', subject: 'index', path: '/contacts/employee'},
      { title: 'לקוחות', subTitle: 'תוכלו לציין עבור איזה לקוח מבוצע עבודה וכן תוכלו לקבל עבודות מלקוחות אליכם', value: 'client', subject: 'index', path: '/contacts/client'},
      { title: 'לקוחות קצה', subTitle: 'לכל לקוח תוכלו להוסיף תחתיו לקוח קצה. כך תוכלו לעבוד מול חברות המבקשים לשלוח פריטים ללקוחות קצה שלהם.', value: 'clients_client', subject: 'index', path: '/contacts/clients_client'},
      { title: 'מחסנים', subTitle: 'בכל פריט תוכלו להגדיר מאיזה מחסן יועמס/יופרק הפריט', value: 'warehouse', subject: 'index', path: '/contacts/warehouse'},
      { title: 'תוויות למסלול', subTitle: 'יש לכם מסלול עם כתובות? צרו תווית ואחר כך תוסיפו תחתיו כתובות ואחכ לקבל מסלול מחושב כולל נקודות במפה', value: 'tag_route', subject: 'index', path: '/contacts/tag_route'},


    // { title: 'פריטים לשינוע - לקוחות ומחסנים', subTitle: 'הוספת פריט לשינוע עם לקוחות ומחסנים שמורים  במערכת', value: 'c_to_w',
    //   inputes: ['id_manager','id_client','id_emploeey',
    //   'date_delivery','where_to','packs_pallet','packs_bags',
    //   'packs_cartons','packs_people','description'], subject: 'places', path: '/items/all' },
    { title: 'פריטים לשינוע - מאיש קשר לאיש קשר', subTitle: 'הוספת פריט מאיש קשר לאיש קשר', value: 'contact_to_contact',
    inputes: ['id_manager','id_client','id_emploeey',
    'date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D'] , subject: 'places', path: '/'},
    { title: ' 2חישוב מסלול לפי תווית', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
    inputes: ['location_o','location_d','id_tag_route'] , subject: 'places', path: '/calculateroute'},
    { title: 'חישוב מסלול לפי תווית', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
    inputes: ['location_o','location_d','id_tag_route'] , subject: 'places', path: '/items/calculateroute'},
    { title: 'מוצא ויעד', subTitle: 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', value: 'o_and_d',
    inputes: ['location_o','location_d'] , subject: 'places', path: '/'},
    { title: 'דרושים', subTitle: 'הוספת פריט למחלקת הדרושים למציאת נהג לשינוע', value: 'jobs',
    inputes: ['date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'location_o','location_d','contact_O_r_D',
    'payment','vehicle','physical_work'] , subject: 'places', path: '/items/jobs'},
    { title: 'קו קבוע לפי יום - עבור המטריקס', subTitle: 'הוסף מוצא ויעד קבוע באחד מימי השבוע, כך נוכל לשלוח לך עבודות על הדרך', value: 'location_by_day',
    inputes: ['date_delivery', 'location_o','location_d','vehicle'] , subject: 'places', path: '/'},
    { title: 'הובלת דירה', subTitle: 'מילוי טופס להובלת דירה או משרד', value: 'home_office' ,
    inputes: ['id_manager','id_client','id_emploeey',
    'date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description','contact_O_r_D',
    'payment','vehicle','physical_work'], subject: 'places', path: '/'}
  ];

  const dispatch = useDispatch();

  const [typeOfForm, setTypeOfForm] = useState(['','','',[]]);
  const [showTypeOfFormOptions, setShowTypeOfFormOptions] = useState(true);
  const [showAddSubject, setShowAddSubject] = useState('places');

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
     {/* <div style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px', maxWidth: '450px'}}> */}
    <div style={{background: '#fff', padding: '10px', maxWidth: '450px'}}>
      {/* <div>{showAddSubject+' - '+showTypeOfFormOptions}</div> */}
          <Grid item xs={12} md={12}>
            {typeOfForm && !showTypeOfFormOptions &&
              <div>
                <IconButton edge="start" aria-label="add" onClick={() => {
                      setShowTypeOfFormOptions(true)
                    }}>
                  <ListIcon sx={{ fontSize: 40 }} />
                </IconButton>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div" style={{textAlign: 'center'}}>
                  {'הוספת ' + typeOfForm[1]}
                </Typography>
                
              </div>
            }
          <div>
            {formTypeOptions && showTypeOfFormOptions &&
            <>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div" style={{textAlign: 'center'}}>
                במה תרצו לצפות
              </Typography>


              <div style={{display: 'flex', flexDirection: 'row', alignContent: 'stretch', justifyContent: 'space-between', minHeight: '73px'}} >
                {chooseAddSubject.map(sbjct => (  
                  <div onClick={() => setShowAddSubject(sbjct[1])}
                  style={{display: 'flex', flexDirection: 'column', width: '50%',
                  alignItems: 'center', justifyContent: 'space-between', minHeight: '73px',
                  color: showAddSubject === sbjct[1] ? '#fff' : '#000',
                  background: showAddSubject === sbjct[1] ? '#1976d2' : '#e6e6e6',
                  padding: '3px', border: '2px solid #ededed', borderRadius: '10px', marginLeft: '7px', cursor: 'pointer'
                  }} >
                    <div style={{maxWidth: '275px'}}>{sbjct[0]}</div>
                    <div style={{marginTop: '2px'}} >
                      {sbjct[2]}
                    </div>
                  </div>
                  )
                )}
              </div>

            {showAddSubject === 'places' && 
            <>
              <div style={{display: 'flex', justifyContent: 'space-between', minHeight: '73px'}} >
                    <div style={{maxWidth: '275px', minWidth: '275px', paddingTop: '19px'}}> {'פריטים לשינוע - לקוחות ומחסנים'}
                    </div>
                    <NavLink
                      onClick={() => {
                        props.handleOnCLickCloseAddAndView()
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      }}
                      to={'/items/all'}
                      style={{textDecoration: 'none',backgroundColor: '#fff'}}
                    >
                      <div style={{marginLeft: '11px'}}>
                        <IconButton edge="start" aria-label="add" >
                          <ArrowCircleLeftOutlinedIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                      </div>
                    </NavLink>
                  </div>
                  <div>
                  <NavLink onClick={() => {
                        props.handleOnCLickCloseAddAndView()
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      }}
                      to="/items/all" exact>
                  <button 
                        style={{
                        ...buttonStyle,
                        // ...(typeOfContacts === 'all' && { background: '#003264' }),
                      }}
                        // onClick={() => setAutoCompleteId('')} 
                        >כל הפריטים</button>
                  </NavLink>
                  <NavLink onClick={() => {
                        props.handleOnCLickCloseAddAndView()
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      }}
                      to="/items/pending" exact>
                  <button 
                        style={{
                        ...buttonStyle,
                        // ...(typeOfContacts === 'person' && { background: '#003264' }),
                      }}
                        // onClick={() => setAutoCompleteId('')} 
                        >בהמתנה</button>
                  </NavLink>
                  <NavLink onClick={() => {
                        props.handleOnCLickCloseAddAndView()
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      }}
                      to="/items/waiting_for_execution" exact>
                  <button 
                        style={{
                        ...buttonStyle,
                        // ...(typeOfContacts === 'client' && { background: '#003264' }),
                      }}
                        // onClick={() => setAutoCompleteId('')} 
                        >ממתין להעמסה</button>
                  </NavLink>
                  <NavLink onClick={() => {
                        props.handleOnCLickCloseAddAndView()
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      }}
                      to="/items/completed" exact>
                  <button 
                        style={{
                          ...buttonStyle,
                        // ...(typeOfContacts === 'employee' && { background: '#003264' }),
                      }}
                        // onClick={() => setAutoCompleteId('')} 
                        >בוצע</button>
                  </NavLink>
                  <NavLink onClick={() => {
                        props.handleOnCLickCloseAddAndView()
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      }}
                      to="/items/scheduled" exact>
                  <button 
                        style={{
                          ...buttonStyle, 
                        // ...(typeOfContacts === 'clients_client' && { background: '#003264' })
                      }}
                        // onClick={() => setAutoCompleteId('')} 
                        >כל הלוזים</button>
                  </NavLink>
                  <NavLink onClick={() => {
                        props.handleOnCLickCloseAddAndView()
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      }}
                      to="/items/scheduledtoday" exact>
                  <button 
                        style={{
                          ...buttonStyle
                      }}
                        >לוז להיום</button>
                  </NavLink>
                 

                </div>
              </>
            }
              {formTypeOptions.map(type => (
                type.subject === showAddSubject && 
                <div style={{display: 'flex', justifyContent: 'space-between', minHeight: '73px'}} >
                    <div style={{maxWidth: '275px', minWidth: '275px', paddingTop: '19px'}}> {type.title}
                      {/* <ListItemText
                        primary={type.title}
                        // secondary={type.subTitle}
                      /> */}
                    </div>
                    <NavLink
                      onClick={() => {
                        props.handleOnCLickCloseAddAndView()
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      }}
                      to={type.path === 'close' ? '' : type.path}
                      style={{textDecoration: 'none',backgroundColor: '#fff'}}
                    >
                            {/* <ListItem 
                            onClick={props.closeOnClickNav}
                            button key={item.text}
                            className={classes.activee} 
                            style={locationPath.pathname === item.path ? {backgroundColor: '#d0d0d0', color: "#fff", textDecoration: 'none'} : null} 
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem> */}
                        
                      <div style={{marginLeft: '11px'}} onClick={() => {
                        // props.handleOnCLickCloseAddAndView()
                          // setShowTypeOfFormOptions(false)
                          // alert(type.value)
                          // setTypeOfForm([type.value, type.title, type.subTitle, type.inputes])
                          // if(showAddSubject === 'places'){
                          //   // formRef.current.resetForm()
                          //   // setlocationO([])
                          //   // setlocationD([])
                          //   // setAutoCompleteO([])
                          //   // setAutoCompleteD([])
                          // }
                          }
                        }>
                        <IconButton edge="start" aria-label="add" >
                          <ArrowCircleLeftOutlinedIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                      </div>
                    </NavLink>
                  </div>
              ))}
            </>
            }
          </div>
        </Grid>


    {showAddSubject === 'index' && !showTypeOfFormOptions &&
    <FormikUiAddContact
          // formPurpesTitle={'formPurpes'}
          typeOfForm={typeOfForm}
          typeLocation='d'
          // zIndex={'zIndex6'}
          // mapCatsDropDown={'mapCats'}
          // selectedAddressD={'selectedAddressD_RD'}
          // selectedAddressO={'selectedAddressO_RD'}
        />
    }

    {showAddSubject === 'places' && !showTypeOfFormOptions &&
    <AddPackagesForTransportation 
      typeOfForm={typeOfForm}
    />
    
    }
    </div>
    </div>
  )
}



export default ViewSomething
