import { MapLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import { withLeaflet } from "react-leaflet";
import icons from "leaflet-color-number-markers";
import * as turf from "@turf/turf";
import { memo } from "react";

class DriveTimeCalculation extends MapLayer {
  createLeafletElement() {
    const { map, sorted_array } = this.props;
    let coordinatesLeafletFormat = sorted_array.map((value) => {
      return L.latLng(value.lat, value.lng);
    });
    // Runs Before Route Calculation
    let leafletElement = L.Routing.control({
      waypoints: [...coordinatesLeafletFormat],
      // create dynamic marker
      createMarker: function (waypointIndex, waypoint, numberOfWaypoints) {
        return new L.marker(waypoint.latLng, {
          draggable: true,
          icon: icons.black.numbers[waypointIndex + 1],
          opacity: 0
        });
      },
      show: false,
      collapsible: true
    }).addTo(map.leafletElement);

    let final_time_array = [];

    const getDistance = (start, end, instructions) => {
      let time_from_previous_point = 0;
      // Determine time from previous point
      instructions.forEach((el, i) => {
        if (el.index >= start && el.index <= end) {
          // console.log(el);
          if (el.index === 0) {
            final_time_array.push(1);
          } else if (el.type === "DestinationReached") {
            final_time_array.push(1);
          } else if (el.type !== "WaypointReached") {
            time_from_previous_point += el.time;
          } else if (el.type === "WaypointReached") {
            final_time_array.push(parseFloat(time_from_previous_point));

            time_from_previous_point = 0;
          }
        }
      });
      final_time_array.forEach((time, time_index) => {
        if (time === 0) {
          final_time_array.splice(time_index, 1);
        }
      });

      // console.log(timeArray.reduce((a, b) => ({ time: a.time + b.time })));
    };

    const getDistancesBewtenEachPoint = (waypointIndices, instructions) => {
      waypointIndices.map((value, index) => {
        if (index < waypointIndices.length - 1) {
          getDistance(value, waypointIndices[index + 1], instructions);
        }
      });
    };
    // Runs After Route Calculation on if "routesfound" even triggers
    leafletElement.on("routesfound", (e) => {
      let distance = e.routes[0].summary.totalDistance;
      let time = e.routes[0].summary.totalTime;
      let instructions = e.routes[0].instructions;
      let waypoints = e.routes[0].waypointIndices;

      getDistancesBewtenEachPoint(waypoints, instructions);
      // console.log(instructions.reduce((a, b) => ({ time: a.time + b.time })));
      // console.log("distance", distance); //metros
      // console.log("time", time); //segundos

      // creating stop number object
      let final_array = [];
      sorted_array.forEach((value, index) => {
        final_array.push({
          lat: value.lat,
          lng: value.lng,
          stopNumberInRoute: index + 1,
          time_from_previous_point:
            index === 0
              ? "Starting Point"
              : index === sorted_array.length - 1
              ? "Ending Point"
              : (final_time_array[index] / 60).toFixed(2) < 60
              ? (final_time_array[index] / 60).toFixed(2) + " mins"
              : (final_time_array[index] / 60 / 60).toFixed(2) + " hours",
          distanceFromPreviousStop:
            index === 0
              ? "Starting Point"
              : turf
                  .distance(
                    turf.point([value.lat, value.lng]),
                    turf.point([
                      sorted_array[index - 1].lat,
                      sorted_array[index - 1].lng
                    ])
                  )
                  .toFixed(2) + " km"
        });
      });

      // Replot markers with data from routes found
      final_array.forEach((value, index) => {
        let marker = L.marker([value.lat, value.lng], {
          draggable: false,
          icon: icons.black.numbers[index + 1]
        })
          .addTo(map.leafletElement)
          .bindPopup(
            "<b>Coordinates:</b> " +
              " [" +
              value.lat +
              "," +
              value.lng +
              "]<br>" +
              "<b>Stop Number:</b> " +
              value.stopNumberInRoute +
              "<br><b>Time From Previous Stop:</b> " +
              value.time_from_previous_point +
              "<br><b>Distance From Previous Stop:</b> " +
              value.distanceFromPreviousStop
          );
      });

      console.log(final_array);
      if(typeof(this.props.llOrderedRouteHandler) === 'function'){
        this.props.llOrderedRouteHandler(final_array)
      }
    });

    return leafletElement.getPlan();
  }
}
export default memo(withLeaflet(DriveTimeCalculation));
