import React, { useState, useContext } from 'react';

import Card from '../../shared/components/UIElements/Card';
import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import Map from '../../shared/components/UIElements/Map';
import { AuthContext } from '../../shared/context/auth-context';
import './ProductItem.css';

const ProductItem = props => {
  let effectListDurationName = "fadeAndScale2";
  if(props.effectListDuration == false) { effectListDurationName = "";}
  const auth = useContext(AuthContext);
  const [showMap, setShowMap] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const openMapHandler = () => setShowMap(true);

  const closeMapHandler = () => setShowMap(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = () => {
    setShowConfirmModal(false);
    console.log('DELETING...');
  };

  return (
 
    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 fade_in_y1" style={{minWidth: '200px' ,animationName: effectListDurationName , animationDuration: `${2000+props.theIndexProp*100}ms`, flex: '1 0 21%', margin: '5px'}}>
    <div className="my-list">
        <a href={`https://haredimnet.co.il/store/udel_store.php?prod_id=${props.productProps.id}`}>
            <div className="my_list_img">

                <img src={!props.productProps.id_store == "193" ? `https://haredimnet.co.il/${props.productProps.file_name}` : props.productProps.file_name} alt={props.title} />
            </div>
        </a>
        <h3>{props.productProps.title}</h3>

        <div style={{fontSize: "17px" , fontWeight: "300" , textAlign: "right" , float: "right"}}>מקט  {props.productProps.sku}</div>
        <div className="amount_details" style={{float: "left" , minHeight: "75px"}}>
            <div className="amount_pre_price"></div>
            <div className="amount_price">{props.productProps.price}</div>
        </div>

    <div className="add_to_card_p">
        <input type="number" className="number_of_same_product number_of_same_product_in_quick" name="number_of_same_product" id="number_of_same_product" value="1" />
        <div className="add_to_card_button_p  eerr eerr17372 add_cart_selected go_shop" data-id="17372" data-price="49.9" data-title="חטף טף ישראטויס" data-sku="7290106874695" data-var_id="0" data-img="7290106874695" data-decp="7290106874695" data-shippingcost="7290106874695" data-comment_var="7290106874695" data-storeid="193" data-inventory="12">הוסף לסל</div>
        <a href="udel_store.php?prod_id=17372חטף_טף_ישראטויס">
            <div className="go_to_inside_kupon" styler="background: #004b9a; background: none;">
                <img src="https://haredimnet.co.il/_images_admin/icons/store_icons/add_to_basket.svg" />
            </div>
        <div styler="font-size: 14px; font-weight: 400; text-align: center; float: none; height: 15px;"></div>
        </a>
    </div>
</div>
</div>
     
  );
};

export default ProductItem;
