import React from 'react';

import UserItem from './UserItem';
import Card from '../../shared/components/UIElements/Card';
import './UsersList.css';

const UsersList = props => {
  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>לא נמצאו משלוחנים!</h2>
        </Card>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '500px', margin: '0 auto', marginTop: '30px' }}>
      {props.items.map(user => (
        <UserItem
          key={user.id}
          id={user.id}
          image={user.imageOne}
          name={user.name}
          // placeCount={user.places.length}
          business_name={user.business_name}
          car_type={user.car_type}
          phone1={user.phone1}
          moverConnectedToUser={user.mover_connected_to_user}
        />
      ))}
    </div>
  );
};

export default UsersList;
