import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from "react"
// import { Link } from 'react-router-dom';

import { AddCircleOutlineOutlined } from "@mui/icons-material"
import PaidIcon from '@mui/icons-material/Paid';
// import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';


import "./SearchBar.css";
import waze from '../waze.png'
// import 'react-datepicker/dist/react-datepicker.css'
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { Link, NavLink, useParams } from "react-router-dom";
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import ItemSingleScheduledToday from "./ItemSingleScheduledToday";
import ItemsMaps from "./ItemsMaps";
import Leaflet from "../leaflet/Leaflet";
import { blue } from "@mui/material/colors";
import ItemsEditSingle from "./ItemsEditSingle";
import ItemsComments from "./ItemsComments";

const divInTd = {maxWidth: '370px', padding: '6px', background: '#f1f1f1', minWidth: '310px', margin: '4px', marginTop: '16px'}


function ItemsByJobs({
  employee,
  allSubItems,
  selectedOD,
  allItems,
  scheduleDate,
  colNames = [
      'קוד פריט',
      'כמות',
      'לקוח',
      'העמסה',
      'פריקה',
      'חברת משלוחים',
      'סוג'
    ],
    height = "auto",
}) {

  
  const all_o_and_d_Locations = useSelector((state) => state.ui.userProfile.All_O_and_D_Locations);
  const userProfile = useSelector((state) => state.ui.userProfile);
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [allUserContacts, setAllUserContacts] = useState([]);
  const [allLatLngInSchedule, setAllLatLngInSchedule] = useState([]);
  const [allNoDuplicates, setAllNoDuplicates] = useState([]);
  const [allNoDuplicatesWithOrder, setAllNoDuplicatesWithOrder] = useState([]);
  const [newRouteOrder, setNewRouteOrder] = useState([]);
  const [centerMapLeaflet, setCenterMapLeaflet] = useState([{ lat: 51.505, lng: -0.09 }]);
  const [originAndDestonationForLL, setOriginAndDestonationForLL] = useState(false);
  const [originForLL, setOriginForLL] = useState([{location: [32.10902953800135, 34.968228824436665]}]);
  const [destonationForLL, setDestonationForLL] = useState([{location: [32.09693660677519, 34.83694148249925]}]);
  const [totalPages, setTotalPages] = useState([]);
  const [checkThisJob, setCheckThisJob] = useState(false);
  const [morDedailsNum, setMorDedailsNum] = useState(null);
  const pages = Array.from(Array(totalPages).keys());

  useEffect(() => {
    if(selectedOD.length > 0){
      console.log("selectedOD",selectedOD)
      let selectedODValue = all_o_and_d_Locations.find(place => 
        place._id === selectedOD
        );
        console.log("selectedODValue",selectedODValue)
        setOriginForLL([{location: [parseFloat(selectedODValue.location_o[1]), parseFloat(selectedODValue.location_o[2])], address: selectedODValue.location_o[0]}])
        setDestonationForLL([{location: [parseFloat(selectedODValue.location_d[1]), parseFloat(selectedODValue.location_d[2])], address: selectedODValue.location_d[0]}])
    }
  },[selectedOD])
  useEffect(() => {
    console.log("originForLL??",originForLL)
    console.log("destonationForLL??",destonationForLL)
  },[originForLL])

  // let originForLL = {location: [32.10902953800135, 34.968228824436665]}
  // let destonationForLL = {location: [32.09693660677519, 34.83694148249925]}

              
const removeItemFromArr = (e) => {
    let TEMPallUserContacts = []
    allUserContacts.forEach((item,index) => {
      if(e !== item._id){
      TEMPallUserContacts.push(item)
      }
      // if(e.objectId === item._id){
      //   let onlyThis = item;
      //   onlyThis.comments.push({...e})
      //   TEMPallUserContacts.push(onlyThis)
      // }
    })
    setAllUserContacts([...TEMPallUserContacts])
    console.log("removeItemFromArr is EXEX", e)
}
  
  

useEffect(() => {
      console.log("centerMapLeaflet:>", centerMapLeaflet)
  },[centerMapLeaflet])


useEffect(() => {
      // console.log(allItems)
      // console.log("scheduleDate[[]]:",scheduleDate)
      setAllUserContacts(allItems)
      // setAllUserContacts(allItems.filter(element => {
      //   if(element.id_emploeey){
      //     return element.id_emploeey._id === employee._id && scheduleDate ===  element.start_date_for_emploeey.split(',')[0]
      //   }
      //   if(!element.id_emploeey){
      //     return scheduleDate ===  element.start_date_for_emploeey.split(',')[0]
      //   }
      // }))
      console.log("scheduleDate", scheduleDate)
  },[allItems,scheduleDate])


  let allLatLngInSchedule2 = [];
  let noDuplicates = [];

  useEffect(() => {
//     let toLat, toLng, toId, toAddress;
//     let fromLat, fromLng, fromId, fromAddress;

//     console.log("allUserContacts<>", allUserContacts)
//         // console.log("allUserContacts:():",allUserContacts)
//         if(allUserContacts.length >= 0){

//           allUserContacts.forEach((item,index) => {
//             console.log("itemitemitem>>>><<", item)
//             if(item !== undefined) {
//             if (item.o_contact_id) {
//                if(item.o_contact_id.address && item.o_contact_id.location){
//                 fromLat = item.o_contact_id.location.lat
//                 fromLng = item.o_contact_id.location.lng
//                 fromId = item.o_contact_id._id
//                 fromAddress = item.o_contact_id.address
//               }
//             }
           
//             if (item.d_contact_id) {
//               if(item.d_contact_id.address && item.d_contact_id.location){
//                 toLat = item.d_contact_id.location.lat
//                 toLng = item.d_contact_id.location.lng
//                 toId = item.d_contact_id._id
//                 toAddress = item.d_contact_id.address
//               }
//             }
            
//             //  if(item.to_id_warehouse){
//             //   toId = item.to_id_warehouse._id
//             //  } else if(item.to_id_clients_client) {
//             //   if(item.to_id_clients_client.location) {
//             //     toId = item.to_id_clients_client._id
//             //   }
//             //  }
//             //  if(item.to_id_warehouse){
//             //   toLat = item.to_id_warehouse.location.lat
//             //  } else if(item.to_id_clients_client) {
//             //   if(item.to_id_clients_client.location) {
//             //   toLat = item.to_id_clients_client.location.lat
//             //   }
//             //  }
//             //  if(item.to_id_warehouse){
//             //   toLng = item.to_id_warehouse.location.lng
//             //  } else if(item.to_id_clients_client) {
//             //   if(item.to_id_clients_client.location) {
//             //     toLng = item.to_id_clients_client.location.lng
//             //   }
//             //  }

//             //  allLatLngInSchedule2.push({
//             //    id: item._id,
//             //    packsNum: item.packs_pallet,
//             //    from: [
//             //      item.from_id_warehouse ? item.from_id_warehouse.location.lat
//             //      : item.from_id_clients_client.location.lat,
//             //      item.from_id_warehouse ? item.from_id_warehouse.location.lng
//             //      : item.from_id_clients_client.location.lng,
//             //      item.from_id_warehouse ? item.from_id_warehouse.address
//             //      : item.from_id_clients_client.address,
//             //      item.from_id_warehouse ? item.from_id_warehouse._id
//             //      : item.from_id_clients_client._id],
//             //      to: [ toLat, toLng,
//             //       item.to_id_warehouse ? item.to_id_warehouse.address
//             //       : item.to_id_clients_client.address, toId]
//             //     })
//              allLatLngInSchedule2.push({
//                id: item._id,
//                packsNum: item.packs_pallet,
//               //  from: [
//               //    item.from_id_warehouse ? item.from_id_warehouse.location.lat
//               //    : item.from_id_clients_client.location.lat,
//               //    item.from_id_warehouse ? item.from_id_warehouse.location.lng
//               //    : item.from_id_clients_client.location.lng,
//               //    item.from_id_warehouse ? item.from_id_warehouse.address
//               //    : item.from_id_clients_client.address,
//               //    item.from_id_warehouse ? item.from_id_warehouse._id
//               //    : item.from_id_clients_client._id],
//                  from: [ fromLat, fromLng, fromAddress, fromId],
//                  to: [ toLat, toLng, toAddress, toId]
//              })
//                 }
//               })
// console.log("allLatLngInSchedule2>>>:", allLatLngInSchedule2)
//               let orderCount = 0;
//               allLatLngInSchedule2.forEach((elem, index) => {
//                 const existMoreThenOne_from = allLatLngInSchedule2.filter(e => e.from[3] === elem.from[3])
//                 console.log("existMoreThenOne_from",existMoreThenOne_from)
//                 const existMoreThenOne_to = allLatLngInSchedule2.filter(e => e.to[3] === elem.to[3])
//                 let from_firstName;
//                 let to_firstName;
//                 const from_firstName_find = allItems.filter(e => e._id === elem.id)
//                 console.log("from_firstName_find:",from_firstName_find)
//                 // if(from_firstName_find[0].from_id_warehouse){
//                   from_firstName = from_firstName_find[0].o_contact_id.first_name
//                 // }
//                 // if(from_firstName_find[0].from_id_clients_client){
//                 //   from_firstName = from_firstName_find[0].from_id_clients_client.first_name
//                 // }

//                 const to_firstName_find = allItems.filter(e => e._id === elem.id)
//                 // if(to_firstName_find[0].to_id_warehouse){
//                   to_firstName = to_firstName_find[0].d_contact_id.first_name
//                 // }
//                 // if(to_firstName_find[0].to_id_clients_client){
//                 //   to_firstName = to_firstName_find[0].to_id_clients_client.first_name
//                 // }


//                 if(existMoreThenOne_from.length > 1){
//                   let idsAndPacks_from = []
//                   let sum_from = 0
//                   existMoreThenOne_from.forEach((single, ind) => {
//                     if (single) {
//                       console.log("singlesinglesingle", single)
//                       const to_firstName_find = allItems.filter(e => e._id === single.id)
//                       if(to_firstName_find){
//                         to_firstName = to_firstName_find[0].d_contact_id && to_firstName_find[0].d_contact_id.first_name
//                       }
//                       // if(to_firstName_find[0].to_id_clients_client){
//                       //   to_firstName = to_firstName_find[0].to_id_clients_client.first_name
//                       // }
  
//                       idsAndPacks_from.push([single.id, single.packsNum, to_firstName])
//                       sum_from = sum_from + parseInt(single.packsNum);
                      
//                     }

//                   })
//                   const existInnoDuplicates = noDuplicates.filter(e => e.from[3] === elem.from[3])
//                   if(!existInnoDuplicates.length){
//                     noDuplicates.push({
//                       location: [elem.from[0],elem.from[1],elem.from[3],from_firstName],
//                       id: [...idsAndPacks_from],
//                       packsNum: sum_from,
//                       from: [...elem.from],
//                       to: elem.to,
//                       type: 'from',
//                       multiple: true,
//                       orderCount
//                     })
//                     orderCount++
//                   }                 
//                 }
//                 if(existMoreThenOne_to.length > 1){
//                   let idsAndPacks_to = []
//                   let sum_to = 0
//                   // let multiple = true
//                   existMoreThenOne_to.forEach((single,ind) => {
//                     idsAndPacks_to.push([single.id, single.packsNum])
//                     sum_to = sum_to + parseInt(single.packsNum);
//                   })
//                   const existInnoDuplicates = noDuplicates.filter(e => e.to[3] === elem.to[3])
//                   if(!existInnoDuplicates.length){
//                     noDuplicates.push({
//                       location: [elem.from[0],elem.from[1],elem.from[3]],
//                       id: [...idsAndPacks_to],
//                       packsNum: sum_to,
//                       from: [...elem.from],
//                       to: elem.to,
//                       type: 'to',
//                       multiple: true,
//                       orderCount
//                     })
//                     orderCount++
//                   }
//                 } 
                
                
//                 if(existMoreThenOne_from.length > 1 && existMoreThenOne_to.length === 1) {
//                     let from_firstName;
//                     let to_firstName;
//                     const from_firstName_find = allItems.filter(e => e._id === elem.id)
//                     // if(from_firstName_find[0].from_id_warehouse){
//                       from_firstName = from_firstName_find[0].o_contact_id.first_name
//                     // }
//                     // if(from_firstName_find[0].from_id_clients_client){
//                     //   from_firstName = from_firstName_find[0].from_id_clients_client.first_name
//                     // }

//                     const to_firstName_find = allItems.filter(e => e._id === elem.id)
//                     // if(to_firstName_find[0].to_id_warehouse){
//                       to_firstName = to_firstName_find[0].d_contact_id.first_name
//                     // }
//                     // if(to_firstName_find[0].to_id_clients_client){
//                     //   to_firstName = to_firstName_find[0].to_id_clients_client.first_name
//                     // }
//                   noDuplicates.push({
//                     location: [elem.to[0],elem.to[1],elem.to[3],to_firstName],
//                     id: [elem.id, elem.packsNum],
//                     packsNum: elem.packsNum,
//                     from: [...elem.from,from_firstName],
//                     to: [...elem.to],
//                     type: 'to',
//                     multiple: false,
//                     orderCount
//                   })
//                   orderCount++
//                 }



//                 if(existMoreThenOne_from.length === 1 && existMoreThenOne_to.length === 1) {
//                   let from_firstName;
//                   let to_firstName;
//                 const from_firstName_find = allItems.filter(e => e._id === elem.id)
//                 // if(from_firstName_find[0].from_id_warehouse){
//                   from_firstName = from_firstName_find[0].o_contact_id.first_name
//                 // }
//                 // if(from_firstName_find[0].from_id_clients_client){
//                 //   from_firstName = from_firstName_find[0].from_id_clients_client.first_name
//                 // }

//                 const to_firstName_find = allItems.filter(e => e._id === elem.id)
//                 // if(to_firstName_find[0].to_id_warehouse){
//                   to_firstName = to_firstName_find[0].d_contact_id.first_name
//                 // }
//                 // if(to_firstName_find[0].to_id_clients_client){
//                 //   to_firstName = to_firstName_find[0].to_id_clients_client.first_name
//                 // }
//                   noDuplicates.push({
//                     location: [elem.from[0],elem.from[1],elem.from[3],from_firstName],
//                     id: [elem.id, elem.packsNum],
//                     packsNum: elem.packsNum,
//                     from: [...elem.from],
//                     to: [...elem.to,to_firstName],
//                     type: 'from',
//                     multiple: false,
//                     orderCount
//                   })
//                   orderCount++
//                   noDuplicates.push({
//                     location: [elem.to[0],elem.to[1],elem.to[3],to_firstName],
//                     id: [elem.id, elem.packsNum],
//                     packsNum: elem.packsNum,
//                     from: [...elem.from,from_firstName],
//                     to: [...elem.to],
//                     type: 'to',
//                     multiple: false,
//                     orderCount
//                   })
//                   orderCount++
//                 }
//               })




//               setAllLatLngInSchedule([...allLatLngInSchedule2])
//               setAllNoDuplicates([...noDuplicates.sort((a,b) => a.orderCount - b.orderCount)])
//               setAllNoDuplicatesWithOrder([...noDuplicates.sort((a,b) => a.orderCount - b.orderCount)])
//               // console.log("allLatLngInSchedule2<>", allLatLngInSchedule2)
//               console.log("noDuplicates<>8888", noDuplicates)
        // }
      },[allUserContacts,scheduleDate,selectedOD])
const pushCommentToCommentsItem = (e) => {
  let TEMPallUserContacts = []
  allUserContacts.forEach((item,index) => {
    if(e.objectId !== item._id){
    TEMPallUserContacts.push(item)
    }
    if(e.objectId === item._id){
      let onlyThis = item;
      onlyThis.comments.push({...e})
      TEMPallUserContacts.push(onlyThis)
    }
  })
  setAllUserContacts([...TEMPallUserContacts])
}


      const containerStyle = {borderRight: '5px solid rgb(25, 118, 210)', background: '#fff', borderBottom: '4px solid #d8d8d8', minHeight: '100px'}
      const numbersStyle = {margin: '0px 2px', background: '#1976d2', color: '#fff', width: '114px', height: '25px',textAlign: 'center', paddingTop: '3px'}
      const toStyle = {padding: '6px 5px 4px 4px'}
      const DoneStyle = {textAlign: 'center', paddingTop: '2px', margin: '0px 6px 0px 0px', background: 'rgb(0 55 109)', color: 'rgb(255, 255, 255)', width: '50px', height: '25px', textalign: 'center', paddingtop: '3px'}

      const divInTd = {padding: '6px'}

  return (
    <div>
    {allUserContacts.length > 0 &&
      <div>
            {/* <div>
              
              <Leaflet 
                allNoDuplicates={[
                // ...originForLL,
                ...allNoDuplicates,
                // ...destonationForLL
              ]}
                llOrderedRouteHandler={llOrderedRouteHandler}
                showMapLeaflet={true}
                centerMapLeaflet={centerMapLeaflet}
              />

            </div> */}

          <div style={{height: '60vh', overflow: 'scroll'}}>
            {allUserContacts && 
            <>
            {
              allUserContacts.map((single, indx) => (
                <div onClick={()=> console.log("single",single)}>
                {/* {single.type === 'from' &&  single.multiple === false && */}
                <div  style={{...containerStyle, borderRight: '5px solid #da0000'}}>
                  <div style={{display: "flex", justifyContent: 'space-between'}}>
                    <div style={{display: 'flex'}}>
                      <div style={{...numbersStyle, background: '#da0000', width: '75px'}}>#  {indx+1} </div>
                    </div>

                  <div style={{display: "flex", marginTop: '10px', marginLeft: '10px'}}>

                   
                    {/* {allUserContacts.map((obj, index) => ( */}
                        {/* // employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' && */}
                        {/* <>  */}
                          {/* <ItemsEditSingle 
                            obj={single} 
                            fromOrTo='from'
                            employeeLink={'employeeLink'}
                            AllEmployee={userProfile.AllEmployee}
                            startDate={'startDate'}
                            paidButton={true}
                            completedButton={true}
                            wazeButton={true}
                            wazeLatLn={[single.d_contact_id.location.lat,single.d_contact_id.location.lng]}
                            // cheangeCenterMapLeaflet={setCenterMapLeaflet}
                            centerMapLeafletButton={true}
                            jobs
                          />       */}
                        {/* </> */}
                        {/* ))} */}
                  </div>

                </div>
                  {/* <div style={toStyle}>העמסה מ {single.location[3]} | <span>עבור {single.to[4]}</span> | <span>סה"כ {single.packsNum} משטחים</span></div> */}

                    {/* {allUserContacts.map((obj, index) => ( */}
                      {/* // employee &&  obj._id === single.id[0] && typeof (single.id[0]) === 'string' && */}
                      <>
                        {
                          <div style={divInTd}>
                            <GoToDedails 
                            pushCommentToCommentsItem={pushCommentToCommentsItem}
                                setMorDedailsNumber={(e) => {
                                  if(morDedailsNum === indx){
                                    setMorDedailsNum(null)
                                  }
                                  if(morDedailsNum !== indx){
                                    setMorDedailsNum(e)
                                  }
                                }
                                }                              
                                indexNumber={indx} 
                              obj={single} 
                              single={single} 
                            o_or_d='o'
                            removeItemFromArr={removeItemFromArr}
                            />
                          {morDedailsNum === indx &&
                            // <div>gg</div>
                            <MorDedails obj={single} single={single} o_or_d='o' />
                            }
                              {/* <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`פריקה: ${obj.d_contact_id.first_name}`}</div> */}
                          </div>
                          
                        }
                      </>
                    {/* ))} */}
                </div>
                {/* } */}

                </div>
              ))
              }
            </>
            }
          </div>


            {/* <div>
                <table
                  cellSpacing="0"
                  style={{ borderCollapse: 'collapse', width: "100%", height: height, padding: "5px 10px", marginTop: '15px' }}
                >
                  <thead style={{ backgroundColor: "#343434", color: "white" }}>
                    <tr>
                      {colNames.map((headerItem, index) => (
                        <th key={index}>{headerItem.toUpperCase()}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                {allUserContacts.map((obj, index) => {
                  return employee ?
                  <>
                    <ItemSingleScheduledToday
                    firstItem={index}
                    obj={obj}
                    employeeTitleName={employee.key}
                    index={index}
                    colNames={colNames}
                    allSubIגtems={allSubItems}
                    />
                  </> : null
                })}
                </tbody>
              </table>
        </div> */}
    </div>
  }
    </div>
  )
}

function MorDedails(props) {
  return (
    <div style={{marginBottom: '10px'}}>
      <div style={{marginBottom: '20px'}}>   
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>     
                    <div style={divInTd}>
                        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>כל הפריטים</div>
                          <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>קוד פריט</div>
                                <div>{props.obj.uuidNumber}</div>
                            </div>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>מספר משטח</div>
                                <div>{props.obj.current_pallet}</div>
                            </div>
                          </div>
                    </div>
                {/* </div> */}
                {/* ))} */}
            {/* </tr> */}
          {/* <tr key={index}> */}
                {/* <div key={index+'-'+index+1} colspan="7" style={{marginBottom: '20px'}}>         */}
                    {/* <div style={divInTd}>
                        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`חברת משלוחים: ${props.obj.id_manager && props.obj.id_manager.name}`}</div>
                          <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>כתובת</div>
                                <div>{props.obj.id_manager && props.obj.id_manager.address}</div>
                            </div>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>טלפון</div>
                                <div>{props.obj.id_manager && props.obj.id_manager.phone1}</div>
                            </div>
                          </div>
                    </div> */}
                {/* </div> */}
            {/* </tr> */}

          {/* <tr key={index}> */}
                {/* <div key={index+'-'+index+1} colspan="7" style={{marginBottom: '20px'}}>         */}
                    <div style={divInTd}>
                        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`לקוח: ${props.obj.id_client && props.obj.id_client.first_name}`}</div>
                          <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>כתובת</div>
                                <div>{props.obj.id_client && props.obj.id_client.address}</div>
                            </div>
                            <div style={{marginBottom: '10px'}}>
                                <div style={{color: '#757575'}}>טלפון</div>
                                <div>{props.obj.id_client && props.obj.id_client.phone1}</div>
                            </div>
                          </div>
                    </div>
                {/* </td> */}
            {/* </tr> */}



            {/* <tr key={index}> */}
              {/* <td key={index+'-'+index+1} colspan="7" style={{marginBottom: '20px'}}>         */}
                  <div style={divInTd}>
                      <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`העמסה: ${props.obj.o_contact_id.first_name}`}</div>
                        <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>כתובת</div>
                              <div>{props.obj.o_contact_id.address}</div>
                          </div>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>טלפון</div>
                              <div>{props.obj.o_contact_id.phone1}</div>
                          </div>
                        </div>
                  </div>
                {/* </td> */}
            {/* </tr> */}


            {/* <tr key={index}> */}
              {/* <td key={index+'-'+index+1} colspan="7" style={{marginBottom: '20px'}}>         */}
                  <div style={divInTd}>
                      <div style={{fontWeight: 'bold', marginBottom: '20px'}}>{`פריקה: ${props.obj.d_contact_id.first_name}`}</div>
                        <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>כתובת</div>
                              <div>{props.obj.d_contact_id.address}</div>
                          </div>
                          <div style={{marginBottom: '10px'}}>
                              <div style={{color: '#757575'}}>טלפון</div>
                              <div>{props.obj.d_contact_id.phone1}</div>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
    </div>
  );
}
function GoToDedails(props) {
  return (
    <div style={{marginBottom: '10px'}}>
      <div style={{marginBottom: '3px', display: 'flex'}}>
          <div style={{color: '#757575', marginLeft: '3px'}}>תאריך ביצוע: </div>
          
          <div>{props.obj.start_date_for_emploeey}</div>
      </div>
      <div style={{marginBottom: '3px', display: 'flex'}}>
          <div style={{color: '#757575', marginLeft: '3px'}}>מוצא: </div>
          
          <div>{props.obj.o_contact_id && props.obj.o_contact_id.address}</div>
      </div>
      <div style={{marginBottom: '3px', display: 'flex'}}>

          <ItemsEditSingle 
            obj={props.obj} 
            fromOrTo='from'
            employeeLink={'employeeLink'}
            // AllEmployee={userProfile.AllEmployee}
            startDate={'startDate'}
            paidButton={false}
            completedButton={false}
            wazeButton={true}
            addMediaButton={true}
            wazeLatLn={[props.obj.o_contact_id && props.obj.o_contact_id.location && props.obj.o_contact_id.location.lat,props.obj.o_contact_id && props.obj.o_contact_id.location && props.obj.o_contact_id.location.lng]}
            // cheangeCenterMapLeaflet={setCenterMapLeaflet}
            centerMapLeafletButton={true}
            jobs
            pushCommentToCommentsItem={props.pushCommentToCommentsItem}
            removeItemFromArr={props.removeItemFromArr}
            addDeleteButton={true}
            />  
      </div>
      <div style={{marginBottom: '3px', display: 'flex'}}>

          <ItemsComments 
            obj={props.obj} 
            fromOrTo='from'
            employeeLink={'employeeLink'}
            // AllEmployee={userProfile.AllEmployee}
            startDate={'startDate'}
            paidButton={false}
            completedButton={false}
            wazeButton={true}
            addMediaButton={true}
            wazeLatLn={[props.obj.o_contact_id && props.obj.o_contact_id.location && props.obj.o_contact_id.location.lat,props.obj.o_contact_id && props.obj.o_contact_id.location && props.obj.o_contact_id.location.lng]}
            // cheangeCenterMapLeaflet={setCenterMapLeaflet}
            centerMapLeafletButton={true}
            jobs
            />  
      </div>
      <div style={{marginBottom: '3px', display: 'flex'}}>
          <div style={{color: '#757575', marginLeft: '3px'}}>יעד: </div>
          <div>{props.obj.d_contact_id && props.obj.d_contact_id.address}</div>
      </div>
      <div style={{marginBottom: '3px', display: 'flex'}}>
      <ItemsEditSingle 
        obj={props.obj} 
        fromOrTo='from'
        employeeLink={'employeeLink'}
        // AllEmployee={userProfile.AllEmployee}
        startDate={'startDate'}
        paidButton={false}
        completedButton={false}
        wazeButton={true}
        wazeLatLn={[props.obj.d_contact_id && props.obj.d_contact_id.location && props.obj.d_contact_id.location.lat, props.obj.d_contact_id && props.obj.d_contact_id.location && props.obj.d_contact_id.location.lng]}
        // cheangeCenterMapLeaflet={setCenterMapLeaflet}
        centerMapLeafletButton={true}
        jobs
        />  
      </div>
      {/* <div style={{marginBottom: '3px', display: 'flex'}}>
          <div style={{color: '#757575', marginLeft: '3px'}}>שם: </div>
          <div>{props.o_or_d === 'd' ?  props.obj.d_contact_id.first_name : 
          props.o_or_d === 'o' ? props.obj.o_contact_id.first_name : null}</div>
      </div> */}
      {/* <div style={{marginBottom: '3px', display: 'flex'}}>
          <div style={{color: '#757575', marginLeft: '3px'}}>טלפון: </div>
          <div>{props.o_or_d === 'd' ?  props.obj.d_contact_id.phone1 : 
          props.o_or_d === 'o' ? props.obj.o_contact_id.phone1 : null}</div>
      </div> */}
      <div style={{marginBottom: '3px', display: 'flex'}}>
          <div style={{color: '#757575', marginLeft: '3px'}}>מספר פריטים: </div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {props.obj.packs_pallet &&
            <div>{props.obj.packs_pallet} משטחים</div>
            }
            {props.obj.packs_bags &&
            <div>{props.obj.packs_bags} שקיות</div>
            }
            {props.obj.packs_cartons &&
            <div>{props.obj.packs_cartons} קרטונים</div>
            }
            {props.obj.packs_people &&
            <div>{props.obj.packs_people} אנשים</div>
            }
          </div>
      </div>
      {/* <div style={{marginBottom: '3px', display: 'flex'}}>
          <div style={{color: '#757575', marginLeft: '3px'}}>שם לקוח: </div>
          <div>{props.obj.c_contact_id && props.obj.c_contact_id.first_name}</div>
      </div> */}
      <div onClick={() => props.setMorDedailsNumber(props.indexNumber)} style={{marginBottom: '3px', display: 'flex'}}>
        <ExpandCircleDownOutlinedIcon
        style={{color: '#fff', backgroundColor: 'blue', borderRadius: '30px'}} />
      </div>
    </div>
  );
}

export default ItemsByJobs