import React, { useState, useCallback, useRef, Children, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import { Grid, TextField, Typography } from '@mui/material'



import Cart from '../shared/components/Cart/Cart';
import { cartActions } from '../store/places-slice';
import { uiActions } from '../store/ui-slice';
import NewPlaceInMap from '../places/pages/NewPlaceInMap';
import NewPlaceCat from '../places/pages/NewPlaceCat';
import WpItems from './WpItems';
import FormikAddPlaceInMap from '../shared/components/FormikElements/FormikAddPlaceInMap';
import FormikUiAddPlaceInMap from '../shared/components/FormikElements/FormikUiAddPlaceInMap';
import AddEmployeeSchedule from '../shared/components/FormikElements/AddEmployeeSchedule';
import AddPackagesForTransportation from '../shared/components/miu/AddSomething';
var map;
/* eslint-disable no-undef */
var cartItems;
// var WPItems;
var mapCats;
var autocomplete_o;
var autocomplete_d;
var NEW_selectedOptionsBySelectedCat;
var originTest;
const ItemsMaps = (props) => {
  const dispatch = useDispatch();
  cartItems = useSelector((state) => state.cart.items);
  // WPItems = useSelector((state) => state.cart.WPItems);
  mapCats = useSelector((state) => state.cart.placesCats);
  
  const markers2 = useSelector((state) => state.cart.markers);
  const selectedAddressD_RD = useSelector((state) => state.cart.selectedAddress_d);
  const selectedAddressO_RD = useSelector((state) => state.cart.selectedAddress_o);
  const selectedAddressRD = useSelector((state) => state.cart.selectedAddress);
  const selectedLngRD = useSelector((state) => state.cart.selectedLng);
  const selectedLatRD = useSelector((state) => state.cart.selectedLat);
  const changedRD = useSelector((state) => state.cart.changed);
  const showCart = useSelector((state) => state.ui.cartIsVisible);
  // const panToLngRD = useSelector((state) => state.cart.panToLng);
  // const panToLatRD = useSelector((state) => state.cart.panToLat);
  const [response, setResponse] = useState(null);
  const [show1, setShow1] = React.useState(false);
  const [formPurpes, setFormPurpes] = React.useState('d');
  // const [formPurpes, setFormPurpes] = React.useState('d&o');
  // const [formPurpes, setFormPurpes] = React.useState('job');
  let zIndex1, zIndex2, zIndex3, zIndex4, zIndex5, zIndex6;
  show1 === 1 ? zIndex1 = '99' : zIndex1 = '0';
  show1 === 2 ? zIndex2 = '99' : zIndex2 = '0';
  show1 === 3 ? zIndex3 = '99' : zIndex3 = '0';
  show1 === 4 ? zIndex4 = '99' : zIndex4 = '0';
  show1 === 5 ? zIndex5 = '99' : zIndex5 = '0';
  show1 === 6 ? zIndex6 = '99' : zIndex6 = '0';
  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [selectedOptionsBySelectedCat, setSelectedOptionsBySelectedCat] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [value3, setValue3] = React.useState(0);
  const [value2, setValue2] = React.useState(0);


  useEffect(() => {
    console.log("allNoDuplicatesINMAP:",props.allNoDuplicates)
    console.log("submitID:",props.submitID)
  },[props.allNoDuplicates,props.submitID])
  const handleChange3 = (event, newValue) => {
    setValue3(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const mapRef = useRef();
  NEW_selectedOptionsBySelectedCat = selectedOptionsBySelectedCat;

  const handleInputChange = (event) => {
    const target = event.target;
    // console.log(target.name)
    // console.log("target.checked:" , target.checked, target.name)//dto do if checked true - remove; if false - add;
    if(target.checked){
    NEW_selectedOptionsBySelectedCat = NEW_selectedOptionsBySelectedCat.filter((single) => {
      // console.log(single)
      return single.place.id != target.name;
    })
    } 
    if (!target.checked) {
      // console.log("!target.checked:" , target.checked, target.name)
      const currentCat = event.target.value;
      cartItems.map(single => {
          console.log("place.cat:" , single.cat,single.id,target.checked, target.name)
          if(single.id === target.name){
            // console.log("cuurentChecked: ", single)
            NEW_selectedOptionsBySelectedCat.push({place: single})
            // ])
          }
        })

    }
    // console.log("selectedOptionsBySelectedCat: ",selectedOptionsBySelectedCat)
    // console.log("NEW_selectedOptionsBySelectedCat: ",NEW_selectedOptionsBySelectedCat)

  }
  

  const selectedMapCatChanged = event => {
    const currentCat = event.target.value;
    setSelectedOptionsBySelectedCat([]);
    cartItems.map(place => {
      if(place.cat === currentCat){
        setSelectedOptionsBySelectedCat (oldArray => [
          ...oldArray,
          {place}
        ])
      }
    })
    console.log("selectedOptionsBySelectedCat", selectedOptionsBySelectedCat)
  }
  
  function calculateAndDisplayRoute(directionsService, directionsRenderer) {
    const wayptsMor = [];
    // let selectedMapCat = document.getElementById("allmapcats");
    // let selectedOD = document.getElementById("allod");
    // console.log("selectedOD: ",selectedOD.options[selectedOD.selectedIndex].value)
    // let selectedODValue = cartItems.find(place => 
    //   place.id === selectedOD.options[selectedOD.selectedIndex].value);
      // console.log("selectedODValue:: ", selectedODValue)
    // console.log()
    // let catMapId = selectedMapCat.options[selectedMapCat.selectedIndex].value;
    // console.log(catMapId)
      // NEW_selectedOptionsBySelectedCat.map((marker ,  index) => {
      //   // if(marker.cat === catMapId){
      //     wayptsMor.push({
      //       location: {lat: marker.place.selectedAddressD.location.lat, lng: marker.place.selectedAddressD.location.lng},
      //       stopover: true,
      //     });
      //   // }
      // })
      props.allNoDuplicates.map((marker ,  index) => {
        wayptsMor.push({
          location: {lat: marker.location[0], lng: marker.location[1]},
          stopover: true,
        });
        originTest = {lat: props.allNoDuplicates[0].location[0], lng: props.allNoDuplicates[0].location[1]}
        console.log("originTest{{}}", originTest)
        // if(index === 1){
        //   map.setCenter({
        //     lat: marker.location[0], lng: marker.location[1]
        //   });
        // }
      // cartItems.map((marker ,  index) => {
      //   if(marker.cat === catMapId){
      //     wayptsMor.push({
      //       location: {lat: marker.location.lat, lng: marker.location.lng},
      //       stopover: true,
      //     });
      //   }
      // })


      
      })



      // console.log("wayptsMor: " , wayptsMor)
      // const waypts = [];
      // const checkboxArray = document.getElementById("waypoints");
      
      // for (let i = 0; i < checkboxArray.length; i++) {
      //   if (checkboxArray.options[i].selected) {
      //     waypts.push({
      //       location: checkboxArray[i].value,
      //       stopover: true,
      //     });
      //   }
      // }
      // console.log("waypts: " , waypts)
      directionsService
      .route({
        // origin: originTest,
        origin: { lat: 32.11480514227291, lng: 34.972872812142306 },
        // destination: [31.988265, 34.772900],
        // destination: {lat: 31.988265, lng: 34.772900},
        destination: {lat: 32.099549572118235, lng: 34.888586972322784},
        waypoints: wayptsMor,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        console.log(response)
        directionsRenderer.setDirections(response);
        
        const route = response.routes[0];
        const summaryPanel = document.getElementById("directions-panel"+props.submitID.id[0]);
        
        summaryPanel.innerHTML = "<br><br><b>המסלול החדש</b><br><br>";
        
        for (let i = 0; i < route.legs.length; i++) {
          const routeSegment = i + 1;
          
          summaryPanel.innerHTML += routeSegment + "<b># </b><br>";
          summaryPanel.innerHTML += "<b> מ  </b>" +route.legs[i].start_address + "<br>";
          summaryPanel.innerHTML += "<b> ל  </b>" +route.legs[i].end_address + "<br>";
          summaryPanel.innerHTML += "<b> מרחק: </b>" +route.legs[i].distance.text + "<br>";
          summaryPanel.innerHTML += "<b> זמן: </b>" +route.legs[i].duration.text + "<br><br>";
        }

// TO DO route.waypoint_order ====== wayptsMor ===== ADD TO REDUX cartItems A NEW ORDER for WAYPOINTS
        console.log("route.waypoint_order:" , route.waypoint_order)
        props.setNewRouteOrder([...route.waypoint_order])
        let i = 1;
          // for(const currentInArrObj of route.waypoint_order){

            // route.waypoint_order > מערך של הסדר החדש של גוגל
            // dispatch(
            //   cartActions.clearWPitems({})
            // );
          for(const currentInArrObj of route.waypoint_order){
            console.log("currentInArrObj:" ,currentInArrObj)
          //   // מוסיפים אובייקט חדש order:
          //   NEW_selectedOptionsBySelectedCat[currentInArrObj]["place"] = {
          //     ...NEW_selectedOptionsBySelectedCat[currentInArrObj]["place"],
          //      order: i};
          //   // currentInArrObj מחזיק את המספר של הסדר וממילא מקבלים את הplace הנכון
          //   console.log(NEW_selectedOptionsBySelectedCat[currentInArrObj].place.id)
          //   let currentIdLocation = NEW_selectedOptionsBySelectedCat[currentInArrObj].place.id;
          //   console.log(NEW_selectedOptionsBySelectedCat[currentInArrObj].place.location.lat)
          //   console.log(NEW_selectedOptionsBySelectedCat[currentInArrObj])
          //   // TO DO - לעדכן order ב cartItems
          //   // console.log("cartItems:" , cartItems);
          //   cartItems.forEach(single => {
          //     if(single.id === currentIdLocation){
          //       single = {
          //         ...single,
          //          order: i};
          //       console.log("single:: " , single)
          //       dispatch(
          //         cartActions.addOrChangeOrderInPlace({
          //           idPlace: currentIdLocation,
          //           orderPlace: i,
          //         })
          //       );
          //     }
          //   });
          //   // let cartItem = cartItems.filter((single) => {
          //   //   console.log(currentIdLocation)
          //   //   // console.log(single)
          //   //   // console.log(single)
          //   //   return single.id === currentIdLocation;
          //   // })
          //   // console.log("cartItem: ", cartItem);
          //   // console.log("cartItem:" , cartItem);
          //   // console.log("for of:" + currentInArrObj);
          //   // console.log("#" + i + currentInArrObj.location.lat);
          //   // for(key in currentInArrObj){
          //   //   console.log(`${key} => ${currentInArrObj[key]}`);
          //   // }
          //   i++;
          }
      })
      .catch((e) => {console.log("Directions request failed due to ",e)
    });
    }

    
// useEffect(() => {console.log("selectedOptionsBySelectedCat: " , selectedOptionsBySelectedCat)},[selectedOptionsBySelectedCat])
useEffect(() => {
  setTimeout(
    function initMap() {
      // console.log("cartItems: " , cartItems)
      // if(!changedRD){
        // console.log("changedRD-0: ",changedRD)
        
      
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();
      const map = new google.maps.Map(mapRef.current, {
        zoom: 8,
        center: {
          lat: selectedLatRD,
          lng: selectedLngRD,
        },
      });
    
      directionsRenderer.setMap(map);
      if(props.submitID){
        document.getElementById(props.submitID.id[0]).addEventListener("click", () => {
          calculateAndDisplayRoute(directionsService, directionsRenderer);
        });
      }
      new window.google.maps.Marker({ position: {lat: -34, lng: 150}, map: map });


      // ======CLICK ON MAP=====
      let latLngFromClick;
      map.addListener("click", (mapsMouseEvent) => {
        console.log("mapsMouseEvent: ", mapsMouseEvent)
        
      latLngFromClick = mapsMouseEvent.latLng.toJSON();
      const geocoder = new google.maps.Geocoder();
      const latlng = {
        lat: parseFloat(latLngFromClick.lat),
        lng: parseFloat(latLngFromClick.lng),
      };
      console.log("latlng: " , latlng)

      const geocoderForLanLng = async (latlng) => {
        await geocoder.geocode({ location: latlng })
        .then((response) => {
          console.log("response: " , response.results[0].formatted_address);
          // dispatch(
          //   cartActions.replaceselectedLngLat({
          //     selectedAddress: response.results[0].formatted_address,
          //     selectedLat: latLngFromClick.lat,
          //     selectedLng: latLngFromClick.lng,
          //   })
          // );
          dispatch(
            cartActions.replaceselectedLngLat_d({
              selectedAddress: response.results[0].formatted_address,
              selectedLat: latLngFromClick.lat,
              selectedLng: latLngFromClick.lng,
            })
          );
          return response.results[0].formatted_address;
        })
        .catch((e) => window.alert("Geocoder failed due to: " + e));
      } 

      console.log(geocoderForLanLng(latlng))

        console.log(latLngFromClick)
        console.log(latLngFromClick.lat)

        new window.google.maps.Marker({ position: latLngFromClick, map: map });

      });
// ======END CLICK ON MAP======


      // cartItems.map((marker ,  index) => {
      //   new window.google.maps.Marker({ position: { lat: marker.selectedAddressD.location.lat, lng: marker.selectedAddressD.location.lng }, map: map });
      // })
      console.log("props.allNoDuplicates:", props.allNoDuplicates)
      props.allNoDuplicates.map((marker ,  index) => {
        console.log("markermarker:", marker)
        if(index === 1){
          map.setCenter({
            lat: marker.location[0], lng: marker.location[1]
          });
        }
        
        new window.google.maps.Marker({ position: { lat: marker.location[0], lng: marker.location[1] }, map: map });
      })
      new window.google.maps.Marker({ position: { lat: 31.728371, lng: 35.040161 }, map: map });
      function newLocation(newLat,newLng)
      {
        map.setCenter({
          lat : newLat,
          lng : newLng
        });
      }
      google.maps.event.addDomListener(window, 'load', initMap);

      // google.maps.event.addDomListener(document.querySelectorAll('.seeOnMap'), 'click', function () {
      //   alert("seeOnMap hes clicked!");
      // // newLocation(48.1293954,11.556663);
      // // new window.google.maps.Marker({ position: { lat: 48.1293954, lng: 11.556663 }, map: map });
      // });

      // const buttons = document.querySelectorAll("allSeeOnMapByFormPurpes")

      // ====מחקתי עכשיו וזה משמש לקליק על כתובת וזה מופיע במראה
      // document.getElementById("allSeeOnMapByFormPurpes").addEventListener("click", () => {
      //   // calculateAndDisplayRoute(directionsService, directionsRenderer);
      //   const buttons = document.querySelectorAll(".seeOnMap")
      //   for (const button of buttons) {
      //     button.addEventListener('click', function(event) {
      //       const iddat = parseFloat(event.target.getAttribute("data-locationlat"));
      //       const iddng = parseFloat(event.target.getAttribute("data-locationlng"));
      //       // map.setCenter({
      //       //   lat : iddat,
      //       //   lng : iddng
      //       // });
      //       newLocation(iddat,iddng);
      //       console.log(".seeOnMap:",selectedLatRD ,selectedLngRD)
      //     })
      //   }
      // });



      // const buttons = document.querySelectorAll(".seeOnMap")
      // for (const button of buttons) {
      //   button.addEventListener('click', function(event) {
      //     // console.log('ggggggggggg')
      //     const iddat = parseFloat(event.target.getAttribute("data-locationlat"));
      //     const iddng = parseFloat(event.target.getAttribute("data-locationlng"));
      //     // map.setCenter({
      //     //   lat : iddat,
      //     //   lng : iddng
      //     // });
      //     newLocation(iddat,iddng);
      //     console.log(".seeOnMap:",selectedLatRD ,selectedLngRD)
      //   })
      // }


      
      autocomplete_o = new google.maps.places.Autocomplete((document.getElementById('search_location_o')), {
       types: ['geocode'],
       /*componentRestrictions: {
        country: "USA"
       }*/
      });

      autocomplete_d = new google.maps.places.Autocomplete((document.getElementById('search_location_d')), {
       types: ['geocode'],
       /*componentRestrictions: {
        country: "USA"
       }*/
      });
       
      google.maps.event.addListener(autocomplete_o, 'place_changed', function () {
       var near_place = autocomplete_o.getPlace();
       console.log("near_place: ", near_place)
       console.log("near_place.geometry.location.lat: ", near_place.geometry.location.lat())
       console.log("near_place.geometry.location.lng: ", near_place.geometry.location.lng())
       console.log("near_place.formatted_address: ", near_place.formatted_address)
       dispatch(
          cartActions.replaceselectedLngLat_o({
            selectedAddress: near_place.formatted_address,
            selectedLat: near_place.geometry.location.lat(),
            selectedLng: near_place.geometry.location.lng(),
          })
        );
       map.setCenter(near_place.geometry.location);
       new window.google.maps.Marker({ position: near_place.geometry.location, map: map });
      });

      google.maps.event.addListener(autocomplete_d, 'place_changed', function () {
       var near_place = autocomplete_d.getPlace();
       console.log(near_place)
       console.log(near_place.geometry.location)
        dispatch(
          cartActions.replaceselectedLngLat_d({
            selectedAddress: near_place.formatted_address,
            selectedLat: near_place.geometry.location.lat(),
            selectedLng: near_place.geometry.location.lng(),
          })
        );
       map.setCenter(near_place.geometry.location);
       new window.google.maps.Marker({ position: near_place.geometry.location, map: map });
      });



    // google.maps.event.addDomListener(document.getElementById('1'), 'click', function () {
    // newLocation(48.1293954,11.556663);
    // new window.google.maps.Marker({ position: { lat: 48.1293954, lng: 11.556663 }, map: map });
    // });
    // google.maps.event.addDomListener(document.getElementById('2'), 'click', function () {
    //   newLocation(40.7033127,-73.979681);
    //   new window.google.maps.Marker({ position: { lat: 40.7033127, lng: -73.979681 }, map: map });
    // });
    // google.maps.event.addDomListener(document.getElementById('3'), 'click', function () {
    //   newLocation(55.749792,37.632495);
    //   new window.google.maps.Marker({ position: { lat: 55.749792, lng: 37.632495 }, map: map });
    // });
  // }
    dispatch(cartActions.replaceChanged({}));
  
  // if(changedRD){
  //   console.log("changedRD-1: ",changedRD)
  //   // setCenter({ lat: 41.878, lng: -87.629 });
  // }
  // console.log("changedRD-2: ",changedRD) 
}, 1000);
  }, [showCart, props.allNoDuplicates]);
  
  // console.log("placesCats:: ", mapCats)
  // console.log("cartItems:: ", cartItems)

  const submitStyle = {background: '#1976d2', color: '#002448', width: '114px', 
  height: '25px', borderRadius: '8px',border: '0px',fontSize: '15px'
  ,textAlign: 'center', paddingTop: '0px'}
  return (
    <>
    <div>
      {/* <div style={{width: '100%'}}> */}
          {/* <Tabs value={value3} onChange={handleChange3} aria-label="icon label tabs example"> */}
            {/* <Tab icon={<MapIcon />} label="מפה ראשית" onClick={() => {setShow1(0)}}/> */}
            {/* <Tab icon={<ListIcon />} label="כל המיקומים שלי" onClick={() => {setShow1(4)}}/> */}
            {/* <Tab icon={<AddLocationAltOutlinedIcon />} label="הוספת מיקום" onClick={() => {setShow1(6); setFormPurpes('d');}} /> */}
            {/* <Tab icon={<AddLocationAltOutlinedIcon />} label="הוספת מוצא ויעד" onClick={() => {setShow1(6); setFormPurpes('d&o');}} /> */}
            {/* <Tab icon={<AddLocationAltOutlinedIcon />} label="הוספת עבודה" onClick={() => {setShow1(6); setFormPurpes('job');}} /> */}
            {/* <Tab icon={<AddLocationAltOutlinedIcon />} label="לוז לעובד" onClick={() => {setShow1(2)}} /> */}
            {/* <Tab icon={<AddLocationAltOutlinedIcon />} label="הוספה לשינוע" onClick={() => {setShow1(5)}} /> */}
            {/* <Tab icon={<AddLocationAltOutlinedIcon />} label=" IK הוספת מיקום" onClick={() => {setShow1(5)}} /> */}
            {/* <Tab icon={<AddRoadIcon />} label="חישוב מסלול"  onClick={() => {setShow1(1)}}/> */}
            {/* <Tab icon={<AccountTreeIcon />} label="הוספת קטגוריה" onClick={() => {setShow1(3)}}/> */}
          {/* </Tabs> */}
      {/* </div> */}
      {/* <div style={{width: '100%', display:'flex'}}> */}
      {/* <div> */}
        {/* <div>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id='search_location_o'
                // name={name}
                label="כתובת מוצא"
                margin='normal'
                variant='outlined'
                fullWidth={true}
                // type={props.type}
                // {...configTextfield}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='search_location_d'
                // name={name}
                label="כתובת יעד"
                margin='normal'
                variant='outlined'
                fullWidth={true}
                // type={props.type}
                // {...configTextfield}
              />
            </Grid>
          </Grid>
        </div> */}
      {/* </div> */}
    
      
  {/* <div id="container"  style={{display: "flex"}}> */}
  <div id="container" style={{minHeight: '300px'}}>
      <div ref={mapRef} style={{width: '100%', height: '297px', position: 'absolute', zIndex: '90'}}></div>
      {/* <div ref={mapRef} style={{zIndex: '90'}}></div> */}
      <h2>lat: {selectedLatRD}
    lng: {selectedLngRD} selectedAddressRD: {selectedAddressRD}</h2>
    {/* <Cart zIndex={zIndex4} /> */}
    {/* <FormikUiAddPlaceInMap
      formPurpesTitle={formPurpes}
      zIndex={zIndex6}
      mapCatsDropDown={mapCats}
      selectedAddressD={selectedAddressD_RD}
      selectedAddressO={selectedAddressO_RD}
    />  */}
    {/* <FormikAddPlaceInMap 
      zIndex={zIndex5}
      mapCatsDropDown={mapCats} 
      selectedAddress={selectedAddressRD} 
      selectedLat={selectedLatRD} 
      selectedLng={selectedLngRD}
    /> */}
    {/* <AddEmployeeSchedule zIndex={zIndex2}
      mapCatsDropDown={mapCats}
      selectedAddress={selectedAddressRD} 
      selectedLat={selectedLatRD} 
      selectedLng={selectedLngRD}
    /> */}
    {/* <AddPackagesForTransportation zIndex={zIndex5}
      mapCatsDropDown={mapCats}
      selectedAddress={selectedAddressRD} 
      selectedLat={selectedLatRD} 
      selectedLng={selectedLngRD}
    /> */}
      {/* <NewPlaceCat zIndex={zIndex3}
          selectedAddress={selectedAddressRD} 
          selectedLat={selectedLatRD} 
          selectedLng={selectedLngRD}
        /> */}
        </div>
        </div>
        <div id="sidebar" style={{background: '#fff', padding: '20px', display: 'block'}}>
          <Grid container xs={12} spacing={2}>
          <div>
            {/* <Grid item xs={12} md={12}>
              <Typography>בחירת מוצא ויעד</Typography>
            </Grid> */}
            {/* <Grid item xs={12} md={12}> */}
              {/* <select style={{height: "40px", padding: "8px", border: "0", borderBottom: "1px solid #bbb", margin: "8px 0px 25px 0px", fontSize: '17px'}} id="allod" onChange={(e) => selectedMapCatChanged(e)}> */}
              {/* <select style={{height: "40px", padding: "8px", border: "0", borderBottom: "1px solid #bbb", margin: "8px 0px 25px 0px", fontSize: '17px'}} id="allod">
              {cartItems.map(place => {
                if(place.form_purpes === 'd&o'){
                  return (
                    <option key={place.id} value={place.id}>
                        {place.title}
                      </option>
                    )
                }
              })}
              </select> */}
            {/* </Grid> */}

            {/* <Grid item xs={12} md={12}>
              <Typography>קטגוריית מיקומים:</Typography>
            </Grid> */}
            {/* <Grid item xs={12} md={12}> */}
              {/* <select style={{height: "40px", padding: "8px", border: "0", borderBottom: "1px solid #bbb", margin: "8px 0px 25px 0px", fontSize: '17px'}} id="allmapcats" onChange={(e) => selectedMapCatChanged(e)}>
                {mapCats.map(option => {
                  let objVal = option.title;
                  if(objVal){
                    return (
                      <option key={option.id} value={option.id}>
                          {option.title}
                        </option>
                      )
                    }
                  })}
              </select> */}
            {/* </Grid> */}
                {/* <Grid item xs={12} md={12}>
            <div style={{marginBottom: '9px', fontWeight: '600'}}>סמן להוצאה מהרשימה:</div>
            </Grid> */}
            {/* <Grid item xs={12} md={12}> */}
              {/* {selectedOptionsBySelectedCat.map((single ,  index) => {
                  let placeTitle = single.place.title;
                    if(placeTitle){
                    return (

                      <div className="toppings-list-item" style={{ display: "flex",
                        justifyContent: "space-between"}}>
                        <div className="left-section">
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name={single.place.id}
                            value={single.place.title}
                            onChange={handleInputChange}
                          />
                          <label htmlFor={`custom-checkbox-${index}`}>{single.place.title}</label>
                        </div>
                      </div>

                    )
                  }
                })} */}
                {/* </Grid> */}

            <Grid item xs={12} md={12}>
            <input style={submitStyle} type="submit" id={props.submitID && props.submitID.id[0]} value="חישוב מסלול" />
            </Grid>
            </div>
              <Grid item xs={12} md={12}>
              <div id={`directions-panel${props.submitID && props.submitID.id[0]}`}></div>
              </Grid>
            </Grid>
        </div>
      
        {/* <WpItems></WpItems> */}



      </>
  );
};
export default ItemsMaps;