import React, { useState, useCallback, useRef, Children, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, Typography, Button } from '@mui/material'


import Modal from '../shared/components/UIElements/Modal';
import FormikUiAddContact from '../shared/components/FormikElements/FormikUiAddContact';
import TableData from './TableData';
var map;
/* eslint-disable no-undef */
var cartItems;
// var WPItems;
var mapCats;
var autocomplete_o;
var autocomplete_d;
var NEW_selectedOptionsBySelectedCat;
const Contacts = (props) => {



  const dispatch = useDispatch();
  cartItems = useSelector((state) => state.cart.items);
  // WPItems = useSelector((state) => state.cart.WPItems);
  mapCats = useSelector((state) => state.cart.placesCats);
  
  const markers2 = useSelector((state) => state.cart.markers);
  const selectedAddressD_RD = useSelector((state) => state.cart.selectedAddress_d);
  const selectedAddressO_RD = useSelector((state) => state.cart.selectedAddress_o);
  const selectedAddressRD = useSelector((state) => state.cart.selectedAddress);
  const selectedLngRD = useSelector((state) => state.cart.selectedLng);
  const selectedLatRD = useSelector((state) => state.cart.selectedLat);
  const changedRD = useSelector((state) => state.cart.changed);
  const showCart = useSelector((state) => state.ui.cartIsVisible);
  // const panToLngRD = useSelector((state) => state.cart.panToLng);
  // const panToLatRD = useSelector((state) => state.cart.panToLat);

  

  // const [allUserContacts, setAllUserContacts] = useState([]);
  // const [allUserContactsNumber, setAllUserContactsNumber] = useState([]);
  // const [allUserContactsPageNumber, setAllUserContactsPageNumber] = useState(0);
  // const [allUserContactsTotalPages, setAllUserContactsTotalPages] = useState([]);
  const [response, setResponse] = useState(null);
  const [show1, setShow1] = React.useState(0);
  const [formPurpes, setFormPurpes] = React.useState('d');
  // const [formPurpes, setFormPurpes] = React.useState('d&o');
  // const [formPurpes, setFormPurpes] = React.useState('job');
  let zIndex0, zIndex1, zIndex2, zIndex3, zIndex4, zIndex5, zIndex6;
  show1 === 0 ? zIndex0 = '99' : zIndex0 = '0';
  show1 === 1 ? zIndex1 = '99' : zIndex1 = '0';
  show1 === 2 ? zIndex2 = '99' : zIndex2 = '0';
  show1 === 3 ? zIndex3 = '99' : zIndex3 = '0';
  show1 === 4 ? zIndex4 = '99' : zIndex4 = '0';
  show1 === 5 ? zIndex5 = '99' : zIndex5 = '0';
  show1 === 6 ? zIndex6 = '99' : zIndex6 = '0';
  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [selectedOptionsBySelectedCat, setSelectedOptionsBySelectedCat] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [value3, setValue3] = React.useState(0);
  const [value2, setValue2] = React.useState(0);
  const [createContactName, setCreateContactName] = useState(false);
  // const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
  
  // console.log("pages:", pages)
  const handleChange3 = (event, newValue) => {
    setValue3(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };
  // useEffect(() => {
  //   const fetchContactsData = async () => {
  //     const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
  //     // console.log("storedDataOfUser:",storedDataOfUser.userId)
  //     const response = await fetch(
  //       `http://localhost:5000/api/contacts/user/${storedDataOfUser.userId}?page=${allUserContactsPageNumber}`
  //     );
  //     // if (!response.ok) {
  //     //   throw new Error('Could not fetch contacts data of ' , storedDataOfUser.userId , '!');
  //     // }
  //     const dataContacts = await response.json();
  //     console.log(dataContacts)
  //     setAllUserContacts(dataContacts.contacts)
  //     setAllUserContactsNumber(dataContacts.totalData)
  //     setAllUserContactsTotalPages(dataContacts.totalPages)
  //     console.log("AllUserContacts::",allUserContacts)
  //     console.log("AllUserContactsNumber::",allUserContactsNumber)
  //     return dataContacts;
  //   };
  //   try {
  //     fetchContactsData();
  //     // const contactsData = await fetchContactsData();
  //     // console.log('contactsData:', contactsData)
  //   } catch (error) {
  //     console.log(error)
  //   }
  
    
  // },[allUserContactsPageNumber])




  
  return (
    <div>

      <div style={{ width: '100%', display: 'flex',  flexDirection: 'column', alignItems: 'center', marginTop: '15px'}}>
        <Typography variant="h4">אנשי קשר</Typography>
        {/* <Grid style={{margin: '20px'}}>
          <Button onClick={() => {
            setCreateContactName(true)
            // setContact_O_r_D('C')
            // openMapHandler()
            }} variant='outlined'>צור איש קשר</Button>
        </Grid> */}
          {/* <Tabs value={value3} onChange={handleChange3} aria-label="icon label tabs example">
            <Tab icon={<MapIcon />} label="אנשי קשר" onClick={() => {setShow1(0)}}/>
            <Tab icon={<AddLocationAltOutlinedIcon />} label="הוספת איש קשר" onClick={() => {setShow1(6); setFormPurpes('d');}} />
            <Tab icon={<ListIcon />} label="הוספת קטגוריה" onClick={() => {setShow1(3)}}/>
            <Tab icon={<AccountTreeIcon />} label="הוספת תגית" onClick={() => {setShow1(5)}}/>
          </Tabs> */}
      </div>
      
      {/* {createContactName &&
                    <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                    <Modal
                      show={createContactName}
                      onCancel={() => setCreateContactName(false)}
                      header={'צור לקוח'}
                      // contentClass="place-item__modal-content"
                      // footerClass="place-item__modal-actions"
                      // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                      closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                    >
                      <Grid item xs={12}>
                        <FormikUiAddContact 
                        AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                        typeLocation='contact_o'
                        formPurpesTitle={'formPurpes'}
                        zIndex={'zIndex6'}
                        // mapCatsDropDown={{}}
                        selectedAddressD={'selectedAddressD_RD'}
                        selectedAddressO={'selectedAddressO_RD'}
                        />
                      </Grid>
                    </Modal>
                    </div>
                    } */}

      
    
      
      <div id="container"  style={{width: '100%', height: '100VH', display: "flex", justifyContent: 'center'}}>
      {/* <div class="absulute_bg" style={{position: 'absolute', background: '#fff', width: '100%', height: '100vh', zIndex: '98'}}></div> */}
      <TableData zIndex={zIndex0} />


      {/* <FormikUiAddContact
        formPurpesTitle={formPurpes}
        typeLocation='d'
        zIndex={zIndex6}
        mapCatsDropDown={mapCats}
        selectedAddressD={selectedAddressD_RD}
        selectedAddressO={selectedAddressO_RD}
      />  */}
      {/* <AddContactCat zIndex={zIndex3}
          selectedAddress={selectedAddressRD} 
          selectedLat={selectedLatRD} 
          selectedLng={selectedLngRD}
        /> */}
      {/* <AddContactTag zIndex={zIndex5}
          selectedAddress={selectedAddressRD} 
          selectedLat={selectedLatRD} 
          selectedLng={selectedLngRD}
        /> */}

      </div>
      </div>
  );
};
export default Contacts;