import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

// import Avatar from '../../shared/components/UIElements/Avatar';
import Card from '../../shared/components/UIElements/Card';
import { AllCarTypesHE } from '../../shared/moduls/allCarTypes';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import {Button} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './UserItem.css';
import { disable } from 'workbox-navigation-preload';

const UserItem = props => {

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showMorDetails, setShowMorDetails] = useState(false)
  const [isMoverConnectedToUser, setIsMoverConnectedToUser] = useState(false)

    useEffect(() => {
      // console.log("isMoverConnectedToUser:", isMoverConnectedToUser)
      props.moverConnectedToUser &&
        setIsMoverConnectedToUser(true)
    
    
    }, [props.moverConnectedToUser])
  
    const connectToMover = async (objectId) => {
      console.log(objectId)
      let purpes;
    const connectToMoverFunc = async () => {

      const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
      if (!isMoverConnectedToUser) {
          purpes = 'connect'
        }
      if (isMoverConnectedToUser) {
          purpes = 'disconnect'
      }
      
      // if (!isMoverConnectedToUser) {
        try {
          await sendRequest(
              // path,
              `/api/items/connect_to_mover/${storedDataOfUser2.userId}`,
              'POST',
              JSON.stringify({
                objectId,
                purpes,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
              }
          );

          if (!isMoverConnectedToUser) {
            console.log('CONNECTED TO MOVER!!!', objectId)
            setIsMoverConnectedToUser(true)
          }
          if (isMoverConnectedToUser) {
            console.log('DISCONNECTED TO MOVER!!!', objectId)
            setIsMoverConnectedToUser(false)
          }
            // setCheckThisJob(true)
            // props.onDelete(props.id);
          } catch (err) {
            console.log('ERROR CONNECTION TO MOVER!!!',err,objectId)
          }
      // }
    }
      await connectToMoverFunc();
      
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };

  return (
    <div>
      <div className="user-item__content">
        {/* <Link to={`/${props.id}/places`} style={{color: '#525252', textDecoration: 'unset'}}> */}
          {/* <div style={{display: 'flex', justifyContent: 'flex-start', margin: '10px 10px 28px 10px', boxShadow: 'rgb(168 168 168) 1px 2px 6px 0px', padding: '5px', borderRadius: '4px'}}> */}
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '10px 10px 28px 10px', boxShadow: 'rgb(168 168 168) 1px 2px 6px 0px', padding: '5px', borderRadius: '4px'}}>
            <div style={{display: 'flex'}}>
              <div style={{width: '70px', height: '70px', backgroundImage: `url(${props.image})`, backgroundSize: 'cover', margin: '8px 8px 8px 14px', borderRadius: '50px',border: isMoverConnectedToUser ? '5px solid green' : ''}}>
                {/* <img src={props.image} alt={props.business_name}/> */}
              </div>
              <div style={{ margin: '8px 0px 0px 16px' }}>
                <h2 style={{margin: '0px'}}>{props.business_name}</h2>
                <div style={{margin: '0px'}}>
                  {/* {props.placeCount} {props.placeCount === 1 ? 'Place' : 'Places'} */}
                  <AllCarTypesHE carType={props.car_type} />
                </div>
                <div 
                  style={{
                    // background: 'rgb(25 118 210)', 
                    borderRadius: '20px',
                    fontSize: '15px',
                    color: 'rgb(84 84 84)',
                    margin: '6px 0px',
                    width: '151px',
                    textAlign: 'center',
                    height: '32px',
                    paddingTop: '3px',
                    border: '1px solid rgb(219, 219, 219)',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-evenly'
                  }}
                  // onClick={() => setAutoCompleteId('')} 
              >
                <div style={{paddingTop: '2px'}}>פרטים נוספים</div>
                <div
                  onClick={() => setShowMorDetails(!showMorDetails)}
                  style={{
                        borderRadius: '20px',
                        background: '#1976d2',
                        fontSize: '15px',
                        color: 'rgb(255 255 255)',
                        /* margin: 6px 2px; */
                        /* width: 129px; */
                        textAlign: 'center',
                        height: '24px',
                        padding: '-1px',
                        border: '1px solid rgb(219, 219, 219)',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-evenly'
                  }}
                >{!showMorDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}</div>
              </div>
              
              </div>
          </div>
          {showMorDetails &&
            <div style={{margin: '0px 95px 15px 0px'}}>
              <div style={{fontSize: '12px', marginBottom: '8px', paddingTop: '4px', color: '#767676',  fontWeight: '500'}}>טלפון: {props.phone1}</div>
              <Button color={!isMoverConnectedToUser ? '' : 'success'} variant="contained" endIcon={<SyncIcon />}
                onClick={() => connectToMover(props.id)}
                disabled={isLoading}
              >
                <span style={{ margin: '0px 17px 0px 17px' }}>{ !isMoverConnectedToUser ? 'צור חיבור ישיר' : 'בטל חיבור ישיר'}  </span>
              </Button>
              {isLoading ? <div style={{fontSize: '12px', marginBottom: '8px', paddingTop: '4px', color: '#767676',  fontWeight: '500'}}>שולח בקשה ...</div> : '' }
            </div>
          
          }
          </div>
        {/* </Link> */}
      </div>
    </div>
  );
};

export default UserItem;
