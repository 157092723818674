import React, { useRef, useState, useEffect } from 'react';

import Button from './Button';
import './ImageUpload.css';

const ImageUpload = props => {
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = event => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      console.log("pickedFile",pickedFile)
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    props.onInput(props.id, pickedFile, fileIsValid);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <div className="form-control">
      <input
        id={props.id}
        ref={filePickerRef}
        style={{ display: 'none' }}
        type="file"
        accept=".jpg,.png,.jpeg"
        onChange={pickedHandler}
      />
      <div className={`image-upload ${props.center && 'center'}`}>
        <div className="image-upload__preview">
          {previewUrl && <img src={previewUrl} alt="Preview" />}
          {!previewUrl && <p>בבקשה בחר תמונה.</p>}
        </div>
        <Button type="button" onClick={pickImageHandler}>
          בחר תמונה
        </Button>
      </div>
      {!isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default ImageUpload;




// imageForm.addEventListener("submit", async event => {
//   event.preventDefault()
//   const file = imageInput.files[0]

//   // get secure url from our server
//   const { url } = await fetch("/s3Url").then(res => res.json())
//   console.log(url)

//   // post the image direclty to the s3 bucket
//   await fetch(url, {
//     method: "PUT",
//     headers: {
//       "Content-Type": "multipart/form-data"
//     },
//     body: file
//   })

//   const imageUrl = url.split('?')[0]
//   console.log(imageUrl)

//   // post requst to my server to store any extra data
  
  
//   const img = document.createElement("img")
//   img.src = imageUrl
//   document.body.appendChild(img)
// })