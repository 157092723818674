import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, Typography, Button } from '@mui/material'
import React, { useContext, useEffect, useState } from "react"

import "./style.css";
import Modal from "../shared/components/UIElements/Modal";
import FormikUiAddContact from '../shared/components/FormikElements/FormikUiAddContact';
import { NavLink, useParams } from "react-router-dom";
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import { format } from "timeago.js"; 



const btnStyle = {
  backgroundColor: "black",
  color: "white",
  border: "none",
  padding: "5px 10px",
}


const buttonStyle = {
  background: 'rgb(25 118 210)', 
  borderRadius: '67px',
  padding: '0px 13px 0px 13px',
  fontSize: '15px',
  color: '#fff',
  marginLeft: '6px',
  minWidth: '61px',
  margin: '6px 2px 6px',
  height: '39px',
  border: '1px solid #dbdbdb',
  cursor: 'pointer'
}

function Table({...props}) {

const auth = useContext(AuthContext);
const userProfile = useSelector((state) => state.ui.userProfile);

  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { typeOfCat } = useParams();
  const typeOfContacts = 'all';
  const [allUserContacts, setAllUserContacts] = useState([]);
  const [allMediaCats, setAllMediaCats] = useState([]);
  const [autoCompleteId, setAutoCompleteId] = useState([]);
  const [allUserContactsNumber, setAllUserContactsNumber] = useState([]);
  const [allUserContactsPageNumber, setAllUserContactsPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState([]);
  const pages = Array.from(Array(totalPages).keys());
  // const [page, setPage] = useState(pageNum)
  const [pageNumber, setPageNumber] = useState(0);
  const [currentIndexInTable, setCurrentIndexInTable] = useState(null);
  const [createContactName, setCreateContactName] = useState(false);
  const [createContactName2, setCreateContactName2] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [objWasDel, setObjWasDel] = useState('ff');
  const [tagRouteOptionsAdmin, setTagRouteOptionsAdmin] = useState([]);
  

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    // let allTagRoute = [];
    let allTagRoute2 = [];
    userProfile.AllTagRoute &&
    // userProfile.AllTagRoute.map(elem => {
    //   allTagRoute.push({key: elem.first_name, value: elem._id})
    // });
    userProfile.AllAdminTagRoute.map(elem => {
      allTagRoute2.push({key: elem.first_name, value: elem._id})
    });
    setTagRouteOptionsAdmin(
        [...allTagRoute2]
      )
    // setTagRouteOptions(
    //     [...allTagRoute]
    //   )
  }, [userProfile])

  const ChangeCurrentIndexInTable = (index) => {
    if(currentIndexInTable === index){
      setCurrentIndexInTable(null)
    } else {
      setCurrentIndexInTable(index)
    }
  }



useEffect(() => {
  console.log("AllMediaCats>", allMediaCats)
},[allMediaCats])





  useEffect(() => {
    const fetchUsersMediaCatsData = async () => {
      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      const response = await fetch(
        `/api/images/madia_cats/user/${storedDataOfUser.userId}`
      );
      const dataContacts = await response.json();
      console.log(dataContacts)
      setAllMediaCats(dataContacts.contacts[0].all_tags_routes)
      return dataContacts;
    };
    try {
      fetchUsersMediaCatsData();
    } catch (error) {
      console.log(error)
    }
  
    
  },[])




  useEffect(() => {
    console.log("autoCompleteId:",autoCompleteId)
    const fetchContactsData = async () => {
      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      // console.log("storedDataOfUser:",storedDataOfUser.userId)
      
      const response = await fetch(
        // `/api/contacts/${typeOfContacts}/user/${storedDataOfUser.userId}?page=${pageNumber}&autocompleteid=${autoCompleteId}`
        `/api/coupons/${typeOfCat}/user/${storedDataOfUser.userId}?page=${pageNumber}&autocompleteid=${autoCompleteId}`
      );
      // if (!response.ok) {
      //   throw new Error('Could not fetch contacts data of ' , storedDataOfUser.userId , '!');
      // }
      const dataContacts = await response.json();
      console.log(dataContacts)
      setAllUserContacts(dataContacts.contacts)
      setAllUserContactsNumber(dataContacts.totalData)
      setTotalPages(dataContacts.totalPages)
      setCurrentIndexInTable(null)
      console.log("AllUserContacts::",allUserContacts)
      console.log("AllUserContactsNumber::",allUserContactsNumber)
      return dataContacts;
    };
    try {
      fetchContactsData();
      // const contactsData = await fetchContactsData();
      // console.log('contactsData:', contactsData)
    } catch (error) {
      console.log(error)
    }
  
    
  },[pageNumber,autoCompleteId, typeOfCat])


  const gotoPrevious = () => {
    setPageNumber(Math.max(0, pageNumber - 1));
  };

  const gotoNext = () => {
    setPageNumber(Math.min(totalPages - 1, pageNumber + 1));
  };
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const confirmDeleteHandler = async (objectId,path) => {
    setShowConfirmModal(false);

    const deleteContactById = async () => {
      try {
        await sendRequest(
          path,
          'DELETE',
          null,
          {
            Authorization: 'Bearer ' + auth.token
          }
          );
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await deleteContactById();
  };


const allDetailsCss = {display:'flex', justifyContent: 'right', maxWidth: '260px', margin: '10px'};
  return (

    <div style={{background: '#1976d2'}}>
      <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', padding: '10px'}}>
        <NavLink to="/coupons/all" exact>
        <button 
              style={{
              ...buttonStyle,
              ...(typeOfCat === 'all' && { background: '#003264' }),
            }}
              onClick={() => setAutoCompleteId('')} 
              >כל הקופונים</button>
        </NavLink>
      {tagRouteOptionsAdmin && tagRouteOptionsAdmin.map((cat) => (
        <NavLink to={`/coupons/${cat.value}`} exact>
        <button 
              style={{
              ...buttonStyle,
              ...(typeOfCat === cat.value && { background: '#003264' }),
            }}
              onClick={() => setAutoCompleteId('')} 
              >{cat.key}</button>
        </NavLink>
      ))}
      </div>


      <div style={{paddingRight: '10px', color: '#fff'}}>{allUserContactsNumber} קופונים | <span>דף {pageNumber+1} מתוך {totalPages} דפים</span></div>
      
      {allUserContacts && (
        <div>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {allUserContacts.map((obj, index) => (
                <>
                <div style={{display: 'flex', flexDirection: 'column', 
                    width: windowSize[0] > 950 ? '48%' : '100%',
                    background: '#fff',
                    boxShadow: 'rgb(255 255 255 / 27%) 1px 2px 15px 5px',
                    margin: '34px 9px'
              }}>
                  <div style={{fontSize: '19px', padding: '14px 12px 14px 0px', background: '#003264', color: '#fff'}}>{obj.title}</div>
                  
                  <div style={{display: 'flex', height: '300px'}}>
                    <div key={index} style={{
                      backgroundImage: `url(${obj.imageOne})`,
                      position: 'relative',
                      backgroundSize: 'cover',
                      height: windowSize[0] > 1200 ? '270px' : windowSize[0] > 600 ? '270px' : '270px', minWidth: '270px',
                      height: '100%',
                      // padding: '3px',
                      width: windowSize[0] > 800 ? '70%' : windowSize[0] > 600 ? '70%' : '70%', minWidth: '70%',
                      }}>
                      {/* <div key={index+'-'+index+1} onClick={() => {ChangeCurrentIndexInTable(index)}}> <AddCircleOutlineOutlined color="primary" /> {obj.sku_client}</div> */}
                    
                    <>
                   
                    </> 

                    </div>
                    <div style={{
                      width: '30%',
                      }}>
                      {/* <div key={index+'-'+index+1} onClick={() => {ChangeCurrentIndexInTable(index)}}> <AddCircleOutlineOutlined color="primary" /> {obj.sku_client}</div> */}
                    <>
                    <div style={{
                      display: 'flex',
                      padding: '12px',
                      maxWidth: '140px'
                      }}>
                      <img
                        style={{
                          border: '1px solid rgb(229 229 229)',
                          borderRadius: '0px',
                          padding: '4px',
                          boxShadow: 'rgb(0 0 0 / 0%) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px'
                        }}
                        alt={obj.user_biz_id ? obj.user_biz_id.name_biz : 'Add Coupon'}
                        src={obj.user_biz_id.imageOne_biz && userProfile.imageOne_biz}
                      />
                    </div>
                    <div style={{
                        padding: '7px',
                        fontSize: '13px'
                      }}>
                      <div key={obj.name_biz} >{obj.user_biz_id.name_biz} | {obj.biz_type} </div>
                    </div>
                    <div style={{
                        padding: '7px',
                        fontSize: '13px'
                      }}>
                      <div key={obj.name_biz} >{obj.user_biz_id.address_biz}</div>
                    </div>
                    <div style={{padding: '12px'}}>
                      <div style={{width: '100%', borderBottom: '1px solid #bbb'}}></div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}>
                        <div style={{fontSize: '15px', padding: '3px 7px 10px 0px'}}>{obj.description}</div>
                        {/* <div style={{
                          // position: 'absolute',
                          // top: '10px',
                          // right: '10px',
                          padding: '3px',
                          color: 'rgb(255 255 255)',
                          background: 'rgb(25 118 210 / 85%)',
                          fontSize: '12px',
                          borderRadius: '11px',
                          margin: '6px'
                        }}>
                          {obj.id_tag_route && obj.id_tag_route.first_name && obj.id_tag_route.first_name}
                        </div> */}
                      {/* <div style={{
                        // position: 'absolute',
                        // top: '39px',
                        // right: '10px',
                        padding: '3px',
                        color: 'rgb(255 255 255)',
                        background: 'rgb(25 118 210 / 85%)',
                        fontSize: '12px',
                        borderRadius: '11px',
                        margin: '6px'
                      }}>
                        {obj.id_location_tag && obj.id_location_tag.title && obj.id_location_tag.title}
                      </div> */}
                      {/* <div style={{
                        // position: 'absolute',
                        // top: '69px',
                        // right: '10px',
                        padding: '3px',
                        color: 'rgb(0 0 0)',
                        background: 'rgb(255 255 255 / 73%)',
                        borderRadius: '11px',
                        fontSize: '12px',
                        margin: '6px'
                      }}>
                        {format(obj.date)}
                      </div> */}
                      {/* <div style={{
                        // position: 'absolute',
                        // bottom: '8px',
                        // left: '10px',
                        padding: '7px',
                        color: '#fff',
                        background: '#1976d282',
                        margin: '6px'
                      }}>
                        <div key={obj.title} >{obj.biz_type} </div>
                      </div> */}
                    </div>
                    
                    </> 
                    </div>

                  </div>
                </div>
                {/* </div> */}
              </>
            ))} 
            
          </div>
          
        </div>
      )}
      <div style={{margin: '22px 0px' }}>
        <button 
            style={{
            background: '#00376c', 
            color: '#fff',
            marginLeft: '6px',
            width: '60px',
            height: '39px',
            border: '1px solid #dbdbdb',
            cursor: 'pointer',
          }}
            onClick={gotoPrevious} 
            >הקודם</button>

              {pages.map((pageIndex) => (
                    // background: #1975d2;
                    // color: #fff;
                <button key={pageIndex} onClick={() => setPageNumber(pageIndex)} style={{
                  background: pageIndex === pageNumber ? '#1975d2' : '#fff',
                  color: pageIndex === pageNumber ? '#fff' : '#000',
                  marginLeft: '6px',
                  width: '52px',
                  height: '39px',
                  border: '1px solid #dbdbdb',
                  cursor: 'pointer',
                }}>
                  {pageIndex + 1}
                </button>
              ))}

          <button 
          style={{
            background: '#00376c', 
            color: '#fff',
            marginLeft: '6px',
            width: '60px',
            height: '39px',
            border: '1px solid #dbdbdb',
            cursor: 'pointer',
          }}
          onClick={gotoNext}>הבא</button>
        </div>
    </div>
  )
}

export default Table