import { AddCircleOutlineOutlined, TramOutlined } from "@mui/icons-material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector, useDispatch } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SearchContactsRoute from '../contacts/searchContactsRoute';
import SearchIcon from '@mui/icons-material/Search';
import Modal from '../shared/components/UIElements/Modal';
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import CloseIcon from '@mui/icons-material/Close';
import AddPackagesForTransportation from '../shared/components/FormikElements/AddPackagesForTransportation';




import hebrew from 'date-fns/locale/he';
import { format } from 'date-fns'

// import { useTranslation } from 'react-i18next';

import React, { useContext, useEffect, useState } from "react"
import TextField from "@mui/material/TextField";

import { useLang } from '../shared/hooks/lang-hook';

import "./styleItems.css";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { NavLink, useParams } from "react-router-dom";
import { useHttpClient } from '../shared/hooks/http-hook';
import { uiActions } from '../store/ui-slice';
import { AuthContext } from '../shared/context/auth-context';
import ItemsEditButtons from "./ItemsEditButtons";
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import RouteIcon from '@mui/icons-material/Route';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CancelIcon from '@mui/icons-material/Cancel';
import ItemsSchedule from "./ItemsSchedule";
import ItemsScheduleToday from "./ItemsScheduleToday";
import ItemsJobs from "./ItemsJobs";
import CreateTagRoute from './componnents/CreateTagRoute'
import Instructions from "./componnents/instructions";
import EditRouteButtons from "./componnents/EditRouteButtons";





const buttonStepForum_numbrer = {
  borderRadius: '30px',
  width: '30px',
  height: '30px',
  padding: '4px 2px 0px 0px',
  background: '#1976d2',
  color: '#fff',
  fontWeight: '700',
  margin: '4px 0px 5px 8px',
}

const instructions = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    background: 'rgb(242, 242, 242)',
    color: 'rgb(157, 157, 157)',
    flexDirection: 'column'
}
const instruction = {
    display: 'flex',
    marginTop: '15px',
    flexDirection: 'column',
    // alignItems: 'center',
    background: '#fff',
    padding: '58px',
    marginBottom: '11px',
    width: '374px'
}
const optionsDiv = {
  position: 'relative',
  background: 'rgb(57 57 57)',
  color: 'rgb(255, 255, 255)',
  textAlign: 'center',
  padding: '13px',
  margin: '2px',
  boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
  fontSize: '20px',
  borderRadius: '33px',
  marginBottom: '9px'
  }
const optionsSpan = {fontSize: '14px', textDecoration: 'underline', marginRight: '11px'}
const optionsClose = {position: 'absolute', top: '-11px'}
const optionsCloseIcon = {fontSize: '15px', background: '#1976d2',  borderRadius: '35px', padding: '2px'}
const optionsTitle = {
  width: '100%',
  textAlign: 'center',
  padding: '13px',
  background: '#1976d2',
  color: '#fff',
  fontSize: '20px',
  marginBottom: '2px',
  borderRadius: '10px 10px 0px 0px'
}
const buttonStepForum_text = { fontSize: '18px' }
let isLatLngChanged = {lng: 0, lat: 0}
const kmOptions = [
  {key: '0.3 km', value: '0.3'},
  {key: '1 km', value: '1'},
  {key: '2 km', value: '2'},
  {key: '5 km', value: '5'},
  {key: '10 km', value: '10'},
  {key: '15 km', value: '15'},
  // {key: '20 km', value: '20'},
  // {key: '50 km', value: '50'},
  // {key: '100 km', value: '100'},
  // {key: '200 km', value: '200'},
  // {key: '300 km', value: '300'},
  // {key: '400 km', value: '400'},
  // {key: '500 km', value: '500'},
  // {key: '1000 km', value: '1000'},
  // {key: '1500 km', value: '1500'},
  // {key: '2000 km', value: '2000'},
];


function ItemsTableData({
  list,
  zIndex,
  colNames = [
      'קוד פריט',
      'כמות',
      'לקוח',
      'העמסה',
      'פריקה',
      'חברת משלוחים',
      'סוג'
    ],
  pageNum = 0,
  pageSize = 10,
  width = "auto",
  height = "auto",
  totalList,
}) {

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  // const {t} = useTranslation()
  const dispatch = useDispatch();
  const { currentLang, ltrOrRtl, trnslt } = useLang();

  const userProfile = useSelector((state) => state.ui.userProfile);
  const usersLocation = useSelector((state) => state.ui.usersLocation);
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [value, setValue] = useState(null);
  const { typeOfItems } = useParams();
  const [allUserContacts, setAllUserContacts] = useState([]);
  const [allSubItems, setAllSubItems] = useState([]);
  const [autoCompleteId, setAutoCompleteId] = useState([]);
  const [allUserContactsNumber, setAllUserContactsNumber] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const pages = Array.from(Array(totalPages).keys());
  const [pageNumber, setPageNumber] = useState(0);
  const [currentIndexInTable, setCurrentIndexInTable] = useState(null);
  const [objWasDel, setObjWasDel] = useState('ff');
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [employeeLink, setEmployeeLink] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  // const [all_o_and_d_Locations, setAll_o_and_d_Locations] = useState(useSelector((state) => state.ui.userProfile.All_O_and_D_Locations));
  const [all_o_and_d_Locations, setAll_o_and_d_Locations] = useState([]);
  const [selectedOD, setSelectedOD] = useState([]);
  const [tagRouteOptions, setTagRouteOptions] = useState([]);
  const [tagRouteOptionsAdmin, setTagRouteOptionsAdmin] = useState([]);
  const [tagRouteOptionsShareable, setTagRouteOptionsShareable] = useState([]);
  const [selectedOptionsBySelectedCat, setSelectedOptionsBySelectedCat] = useState([]);
  const [allLocationTag, setAllLocationTag] = useState([]);
  const [nearStartLocation, setNearStartLocation] = useState([]);
  const [goLocations, setGoLocations] = useState([]);
  const [displayNone, setDisplayNone] = useState(true);


  // const [isHover, setIsHover] = useState(false);
  // const [chooseODBox, setChooseODBox] = useState(false);
  const [searchNow, setSearchNow] = useState(false);
  const [searchNearNow, setSearchNearNow] = useState(false);
  const [searchWithDate, setSearchWithDate] = useState(false);
  const [searchNear, setSearchNear] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [chooseODBox, setChooseODBox] = useState(false);
  const [isHoverTAG, setIsHoverTAG] = useState(false);
  const [chooseTAGBox, setChooseTAGBox] = useState(false);
  const [isHoverADD, setIsHoverADD] = useState(false);
  const [chooseADDBox, setChooseADDBox] = useState(false);
  const [chooseADDNewBox, setChooseADDNewBox] = useState(false);
  const [isHoverCLOSE, setIsHoverCLOSE] = useState(false);
  const [chooseITEMBox, setChooseITEMBox] = useState(false);
  const [isHoverITEM, setIsHoverITEM] = useState(false);
  const [nearLocations, setNearLocations] = useState([]);
  const [usersLngLat, setUsersLngLat] = useState({lat: null, lng: null});
  const [radius, setRadius] = useState(5);
  const [modelChooseTagRoute, setModelChooseTagRoute] = useState(false);
  const [modelSection, setModelSection] = useState('choose');
  const [currentCatRouteName, setCurrentCatRouteName] = useState(null);
  const [allCoupons, setAllCoupons] = useState([]);
  const [allCouponsNumber, setAllCouponsNumber] = useState();
  const [tableotalCouponsPages, setTotalCouponsPages] = useState();
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [searchContactName, setSearchContactName] = useState(false);
  const [autoCompleteC, setAutoCompleteC] = useState([]);
  const [typeRoute, setTypeRoute] = useState('admin_routes');
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [catOptions, setCatOptions] = useState([]);
  const [subCatOptions, setSubCatOptions] = useState([]);
  const [idsTagsOptions, setIdsTagsOptions] = useState([]);
  const [optionsAdminRoutes, setOptionsAdminRoutes] = useState({
    country: {show: true, id: '', name: ''},
    city: {show: false, id: '', name: ''},
    cat: {show: false, id: '', name: ''},
    sub_cat: {show: false, id: '', name: ''},
    tag: {show: false, id: '', name: ''},
  })

  useEffect(() => {
    let allcountryOptions = [];
    let allcityOptions = [];
    let allcatOptions = [];
    let allsubCatOptions = [];
    let allidsTagsOptions = [];
    userProfile.AllCountryCityCatSubCatIdsTags &&
    userProfile.AllCountryCityCatSubCatIdsTags.map(elem => {
      if(elem.type === "id_country"){
        allcountryOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "id_city"){
        allcityOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "id_cat"){
        allcatOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "id_sub_cat"){
        allsubCatOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "ids_tags"){
        allidsTagsOptions.push({key: elem.first_name, value: elem._id})
      }
    });
    setCountryOptions(
        [...allcountryOptions]
      )
    setCityOptions(
        [...allcityOptions]
      )
    setCatOptions(
      [...allcatOptions]
      )
    setSubCatOptions(
        [...allsubCatOptions]
      )
    setIdsTagsOptions(
        [...allidsTagsOptions]
      )
  },[userProfile])

  const setAutoCompleteCFromSearchContactHandler = (e) => {
    console.log("setAutoCompleteCFromSearchContactHandler_E!", e)
    // if(contact_O_r_D === 'C'){
      setAutoCompleteC([[0, e]])
      setSearchContactName(false)
    // }
  }

  const handleChangeOD = event => {
    console.log(event.target.value);
    setSelectedOD(event.target.value);
    setChooseODBox(false)
  };
  const selectPrevent = (e) => {
    e.preventDefault();
  }

  const buttonStyleWithHover = {
    fontSize: '34px', 
    padding: '1px',  
    margin: '2px 0px 0px',
    backgroundColor: isHover ? 'rgb(109 109 109)' : 'rgb(237 237 237)',
    color: isHover ? '#ffffff' : '#1976d2',
    borderRadius: '25px',
    cursor: 'pointer'
  }

  const buttonStyleWithHoverITEM = {
    fontSize: '34px', 
    padding: '1px',  
    margin: '2px 0px 0px',
    backgroundColor: isHoverITEM ? 'rgb(109 109 109)' : 'rgb(237 237 237)',
    color: isHoverITEM ? '#ffffff' : '#1976d2',
    borderRadius: '25px',
    cursor: 'pointer'
  }
  const buttonStyleWithHoverTAG = {
    fontSize: '34px', 
    padding: '1px',  
    margin: '2px 0px 0px',
    backgroundColor: isHoverTAG ? 'rgb(109 109 109)' : 'rgb(237 237 237)',
    color: isHoverTAG ? '#ffffff' : '#1976d2',
    borderRadius: '25px',
    cursor: 'pointer'
  }
  const buttonStyleWithHoverADD = {
    fontSize: '34px', 
    padding: '1px',  
    margin: '2px 0px 0px',
    backgroundColor: isHoverADD ? 'rgb(109 109 109)' : 'rgb(237 237 237)',
    color: isHoverADD ? '#ffffff' : '#1976d2',
    borderRadius: '25px',
    cursor: 'pointer'
  }
  const buttonStyleWithHoverCLOSE = {
    fontSize: '34px', 
    padding: '1px',  
    margin: '7px 16px 5px',
    backgroundColor: isHoverCLOSE ? 'rgb(109 109 109)' : 'rgb(237 237 237)',
    color: isHoverCLOSE ? '#ffffff' : 'rgb(81 81 81)',
    borderRadius: '25px',
    cursor: 'pointer'
  }

  
  const handleChange = evt => {
    setEmployeeLink(evt.target.value);
    console.log(value,evt);
  };
  const configSelect = {
    select: true,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange,
    label: 'בחר עובד לשיוך',
    margin: 'normal'
  };

  const ChangeCurrentIndexInTable = (index) => {
    if(currentIndexInTable === index){
      setCurrentIndexInTable(null)
    } else {
      setCurrentIndexInTable(index)
    }
  }


  useEffect(() => {
    let allTagRoute = [];
    let allTagRoute2 = [];
    let allTagRouteShareable = [];
    // userProfile.AllTagRoute && userProfile.AllTagRoute.length > 0 &&
    console.log("userProfile.AllTagRoute>", userProfile.AllTagRoute)
    userProfile.AllTagRoute && userProfile.AllTagRoute.length > 0 && userProfile.AllTagRoute.map(elem => {
      allTagRoute.push({key: elem.first_name, value: elem._id, shareable: elem.shareable})
    });
    userProfile.AllTagRouteShareable && userProfile.AllTagRouteShareable.length > 0 &&
    userProfile.AllTagRouteShareable.map(elem => {
      allTagRouteShareable.push({
        key: elem.first_name,
        value: elem._id,
        shareable: elem.shareable,
        ...(elem.shareable_users && elem.shareable_users.length > 0 &&  {shareable_users: [...elem.shareable_users]}),
        }
        )
    });
    userProfile.AllAdminTagRoute && userProfile.AllAdminTagRoute.length > 0 && userProfile.AllAdminTagRoute.map(elem => {
      allTagRoute2.push({key: elem.first_name, value: elem._id})
    });
    setTagRouteOptionsAdmin(
        [...allTagRoute2]
      )
    setTagRouteOptions(
        [...allTagRoute]
      )
    setTagRouteOptionsShareable(
        [...allTagRouteShareable]
      )
  }, [userProfile])


  useEffect(() => {
    setAll_o_and_d_Locations([...userProfile.All_O_and_D_Locations]);
  },[userProfile])

  useEffect(() => {
    console.log('allCoupons>', allCoupons)
  },[allCoupons])

  useEffect(() => {
    let allTheLocationTag = [];
    userProfile.AllLocationTag &&
    userProfile.AllLocationTag.map(elem => {
      allTheLocationTag.push(elem)
    });
    setAllLocationTag(
        [...allTheLocationTag]
      )
  },[userProfile])

  const selectedMapCatChanged = (tagId,tagName) => {
    // const currentCat = event.target.value;
    const currentCat = tagId;
    const currentCatName = tagName;
    setCurrentCatRouteName(tagName)
    console.log("currentCat",currentCat)
    // setSelectedOptionsBySelectedCat([]);
    // setAllUserContacts([]);
    // allLocationTag.map(place => {
    //   console.log("place", place)
    //   if(place.id_tag_route === currentCat){
    //     console.log("placeFOUND!", place)
    //     setSelectedOptionsBySelectedCat (oldArray => [
    //       ...oldArray,
    //       {...place}
    //     ])
    //     // setAllUserContacts (oldArray => [
    //     //   ...oldArray,
    //     //   {...place}
    //     // ])
    //   }
    // })
     //   setAllUserContacts([...selectedOptionsBySelectedCat])
  //   console.log("selectedOptionsBySelectedCat>>",selectedOptionsBySelectedCat)

    // console.log("selectedOptionsBySelectedCat", selectedOptionsBySelectedCat)

    setNearLocations([])
    setAllUserContacts([])  
        // }
        const fetchContactsData3 = async () => {
          // console.log("auth.token:", auth.token)
          const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
            let urlJobs
            let pageNumber
            let autoCompleteId
            
            let locationsByAdminTag = 'locationsByAdminTag'
          urlJobs = '/api/items/'+locationsByAdminTag+'/user/'+storedDataOfUser2.userId
          +'?startdate=all&page=0&autocompleteid=autoCompleteId&tagId='+currentCat
          +'&cityId='+optionsAdminRoutes.city.id
          +'&countryId='+optionsAdminRoutes.country.id
          +'&catId='+optionsAdminRoutes.cat.id
          +'&subCatId='+optionsAdminRoutes.sub_cat.id
          +'&tagsId='+optionsAdminRoutes.tag.id;
          const response = await fetch(
            urlJobs,
            {
              method: 'GET',
              body: null,
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
              },
            }
          );
          const dataContacts = await response.json();
          // console.log(dataContacts)
          if(dataContacts.contacts){
            if (dataContacts.contacts) {
              
              setNearLocations([...dataContacts.contacts])
              setAllUserContacts([...dataContacts.contacts, ...selectedOptionsBySelectedCat])

            }
          }
          return dataContacts;
        };
        try {
          fetchContactsData3();
        } catch (error) {
          console.log("error NEAR:", error)
        }
  }

  const selectedRadius = event => {
    const currentRadius = event.target.value;
    console.log("currentRadius",currentRadius)
    setRadius(currentRadius)
  }


  useEffect(() => {
    let allEmployees = [];
    userProfile.AllEmployee &&
    // console.log("userProfile.allEmployee:", userProfile.AllEmployee)
    userProfile.AllEmployee.map(elem => {
      // console.log("userProfile.allEmployee:elem", elem)
      allEmployees.push({key: elem.first_name, value: elem.first_name})
    });
    setEmployeesOptions(
        [...allEmployees]
      )
      // console.log("employeesOptions",employeesOptions)
  },[userProfile])


  useEffect(() => {
    console.log("format>> startDate):",format(startDate, 'MM/dd/yyyy'))
  },[startDate])

  // useEffect(() => {
  //   setAllUserContacts([...selectedOptionsBySelectedCat])
  //   console.log("selectedOptionsBySelectedCat>>",selectedOptionsBySelectedCat)
  // },[selectedOptionsBySelectedCat])




  useEffect(() => {
     console.log("allUserContacts>>", allUserContacts)
     setLoadingLocations(true)
     setTimeout(() => {
      setLoadingLocations(false)
     }, 3000);
  },[allUserContacts])


  useEffect(() => {
    
    if(typeOfItems === 'calculateroute'){
      setAllUserContacts ([...selectedOptionsBySelectedCat])
      }
              
    if(typeOfItems === 'scheduledtoday'){
      setAllUserContacts([])

    // }
    const fetchContactsData = async () => {
      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      let fromParam = typeOfItems;
      if(fromParam === 'scheduledtoday'){fromParam = 'scheduled'}
      console.log("startDate:",startDate[0],startDate[1],startDate[2])
      console.log("format> startDate):",format(startDate, 'MM/dd/yyyy'))
      console.log("format>-new Date(2014, 1, 11), 'MM/dd/yyyy'):",format(new Date(), 'MM/dd/yyyy'))
      const response = await fetch(
        `/api/items/${typeOfItems}/user/${storedDataOfUser.userId}?startdate=${format(startDate, 'MM/dd/yyyy')}&page=${pageNumber}&autocompleteid=${autoCompleteId}`,
        {
          method: 'GET',
          body: null,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          },
        }
      );
      const dataContacts = await response.json();
      // console.log(dataContacts)
      if(dataContacts.contacts || selectedOptionsBySelectedCat.length > 0){
        if (dataContacts.contacts) {
          
          setAllUserContacts([...dataContacts.contacts, ...selectedOptionsBySelectedCat])
        }
        if (!dataContacts.contacts) {
          setAllUserContacts([...selectedOptionsBySelectedCat])
          
        }
      }
      setAllSubItems(dataContacts.subItems)
      setAllUserContactsNumber(dataContacts.totalData)
      setTotalPages(dataContacts.totalPages)
      setCurrentIndexInTable(null)
      // console.log("AllUserContacts::",allUserContacts)
      // console.log("AllUserContactsNumber::",allUserContactsNumber)
      return dataContacts;
    };
    try {
      fetchContactsData();
    } catch (error) {
      console.log(error)
    }
  }
    if(typeOfItems === 'jobs'){
      setAllUserContacts([])

    // }
    const fetchContactsData = async () => {
      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      let fromParam = typeOfItems;
      // if(fromParam === 'scheduledtoday'){fromParam = 'scheduled'}
      console.log("startDate:",startDate[0],startDate[1],startDate[2])
      console.log("format> startDate):",format(startDate, 'MM/dd/yyyy'))
      console.log("format>-new Date(2014, 1, 11), 'MM/dd/yyyy'):", format(new Date(), 'MM/dd/yyyy'))
      let urlJobs;
      if (searchWithDate === true) {
        urlJobs = `/api/items/${typeOfItems}/user/${storedDataOfUser.userId}?startdate=${format(startDate, 'MM/dd/yyyy')}&page=${pageNumber}&autocompleteid=${autoCompleteId}`
      } else if (searchWithDate === false)
        urlJobs = `/api/items/${typeOfItems}/user/${storedDataOfUser.userId}?startdate=all&page=${pageNumber}&autocompleteid=${autoCompleteId}`
      const response = await fetch(
        urlJobs,
        {
          method: 'GET',
          body: null,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          },
        }
      );
      const dataContacts = await response.json();
      // console.log(dataContacts)
      if(dataContacts.contacts || selectedOptionsBySelectedCat.length > 0){
        if (dataContacts.contacts) {
          
          setAllUserContacts([...dataContacts.contacts, ...selectedOptionsBySelectedCat])
        }
        if (!dataContacts.contacts) {
          setAllUserContacts([...selectedOptionsBySelectedCat])
          
        }
      }
      setAllSubItems(dataContacts.subItems)
      setAllUserContactsNumber(dataContacts.totalData)
      setTotalPages(dataContacts.totalPages)
      setCurrentIndexInTable(null)
      // console.log("AllUserContacts::",allUserContacts)
      // console.log("AllUserContactsNumber::",allUserContactsNumber)
      return dataContacts;
    };
    try {
      fetchContactsData();
    } catch (error) {
      console.log(error)
    }
  }
    
  // },[pageNumber,autoCompleteId, typeOfItems, startDate,selectedOD,selectedOptionsBySelectedCat,searchNow])
  },[pageNumber,searchNow, typeOfItems])

  useEffect(() => {
    if(usersLocation.lng !== usersLngLat.lng){
    setUsersLngLat({lat: usersLocation.lat, lng: usersLocation.lng})
    console.log("setUsersLngLat CHANGED!")
    }
  }, [usersLocation]);

  const fatchNearCoupons = () => {
    // console.log("autoCompleteId:",autoCompleteId)
    const fetchCouponsData = async () => {
      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      // console.log("storedDataOfUser:",storedDataOfUser.userId)
      let typeOfCat = 'all'
      const response = await fetch(
        // `/api/contacts/${typeOfContacts}/user/${storedDataOfUser.userId}?page=${pageNumber}&autocompleteid=${autoCompleteId}`
        `/api/coupons/${typeOfCat}/user/${storedDataOfUser.userId}?page=${pageNumber}&autocompleteid=${autoCompleteId}`
      );
      // if (!response.ok) {
      //   throw new Error('Could not fetch contacts data of ' , storedDataOfUser.userId , '!');
      // }
      const dataContacts = await response.json();
      console.log(dataContacts)
      // setAllUserContacts(dataContacts.contacts)
      setAllCoupons(dataContacts.contacts)
      // setAllUserContactsNumber(dataContacts.totalData)
      setAllCouponsNumber(dataContacts.totalData)
      // setTotalPages(dataContacts.totalPages)
      setTotalCouponsPages(dataContacts.totalPages)
      // setCurrentIndexInTable(null)
      // console.log("AllUserContacts::",allUserContacts)
      // console.log("AllUserContactsNumber::",allUserContactsNumber)
      return dataContacts;
    };
    try {
      fetchCouponsData();
      // const contactsData = await fetchContactsData();
      // console.log('contactsData:', contactsData)
    } catch (error) {
      console.log(error)
    }
  
    
  }

  const handleNearStartLocation = () => {
     navigator.geolocation.getCurrentPosition(position => {
      console.log("position>",position)
      console.log("position.coords.latitude:", position.coords.latitude)
      console.log("position.coords.longitude:", position.coords.longitude)
      // dispatch(
      //   uiActions.setUsersLocation({lat: position.coords.latitude, lng: position.coords.longitude})
      // );
      dispatch(
        uiActions.setUsersLocation({lat: position.coords.latitude, lng: position.coords.longitude})
      );
      setNearStartLocation([parseFloat(position.coords.latitude), parseFloat(position.coords.longitude)])
      // urlJobs = `/api/items/${nearTypeLocationUrl}/user/${storedDataOfUser2.userId}?startdate=all&page=0&autocompleteid=autoCompleteId&ulng=${nearStartLocation[1]}&ulat=${nearStartLocation[0]}`
      // console.log("urlJobs", urlJobs)
    })
  }

  useEffect(() => {
    handleNearStartLocation()
  }, [searchNearNow]);


  useEffect(() => {
    
    console.log("usersLocation::>>", usersLocation)
    console.log("usersLngLat CHANGED", usersLngLat)
    console.log("isLatLngChanged CHANGED", isLatLngChanged)
        
    setNearLocations([])
    setAllUserContacts([])  
        // }
        const fetchContactsData2 = async () => {
          // console.log("auth.token:", auth.token)
          fatchNearCoupons(

          )
          const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
            let urlJobs
            let pageNumber
            let autoCompleteId
            
            let nearTypeLocationUrl = 'nearlocationsforuser'
          urlJobs = `/api/items/${nearTypeLocationUrl}/user/${storedDataOfUser2.userId}?startdate=all&page=0&autocompleteid=autoCompleteId&ulng=${nearStartLocation[1]}&ulat=${nearStartLocation[0]}&radius=${radius}`
          const response = await fetch(
            urlJobs,
            {
              method: 'GET',
              body: null,
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
              },
            }
          );
          const dataContacts = await response.json();
          // console.log(dataContacts)
          if(dataContacts.contacts){
            if (dataContacts.contacts) {
              
              setNearLocations([...dataContacts.contacts])
              setAllUserContacts([...dataContacts.contacts, ...selectedOptionsBySelectedCat])

            }
          }
          return dataContacts;
        };
        try {
          fetchContactsData2();
        } catch (error) {
          console.log("error NEAR:", error)
        }
      // }
      
    //   setUsersLngLat({lat: usersLocation.lat, lng: usersLocation.lng})
    // isLatLngChanged.lng = usersLocation.lng
    // isLatLngChanged.lat = usersLocation.lat
  }, [nearStartLocation])

  useEffect(() => {
    setNearLocations([])
    setAllUserContacts([])  

        if(goLocations.length > 0){

          try {
            // fetchContactsData2();
          setTimeout(() => {
            setAllUserContacts([...goLocations])
          }, 3000);
          
          } catch (error) {
            console.log("error NEAR:", error)
          }
        }
  }, [goLocations])

  const gotoPrevious = () => {
    setPageNumber(Math.max(0, pageNumber - 1));
  };

  const gotoNext = () => {
    setPageNumber(Math.min(totalPages - 1, pageNumber + 1));
  };

// const allDetailsCss = {display:'flex', justifyContent: 'space-between', maxWidth: '260px'};
  return (
<>
    <div style={{background: '#fff', padding: '0px', margin: '0px 10px 22px 10px', width: '100%', maxWidth: '500px'}} >
    {loadingLocations &&
      <div className="center loading_locations" style={{
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        background: '#ffffffd9',
        width: '100%',
        zIndex: '99999999',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <LoadingSpinner />
      </div>
    }
        {!typeOfItems === 'scheduledtoday' && !typeOfItems === 'jobs' &&
          <TextField {...configSelect}
            style={{maxWidth: '200px', marginTop: '0px', marginLeft: '10px'}}
          >
            {userProfile.AllEmployee.map((item, pos) => {
              console.log(item)
              return (
                <MenuItem key={item.first_name} value={item._id}>
                  {item.first_name}
                </MenuItem>
              )
            })}
          </TextField>
        }
        {/* </Grid> */}
        
        {/* <Grid item xs={6}> */}
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: '500px',
          flexDirection: 'row'
        }}>
          {/* <div style={{display: 'flex', background: '#1976d2', color: '#fff', alignItems: 'center', padding: '9px', justifyContent: 'center'}}>
              {typeOfItems === 'scheduledtoday' &&
              <div style={{ fontSize: '13px', width: '38px', textAlign: 'center', lineHeight: '20px' }}>
                {trnslt("My Route")}
              </div>
              }
          </div> */}
          <div style={{ display: 'flex', display: displayNone === true ? 'none' : 'flex' }}>
            <div style={{display: 'none'}}>
              <div style={{ margin: '0px 3px', border: '1px solid #e1e1e1', borderRadius: '5px', padding: '3px', paddingBottom: '7px'}}>
              <div style={{ margin: '7px 0px 4px 0px', textAlign: 'center', fontSize: '11px' }}>
                <div>{trnslt("choose")}</div>
                <div>{trnslt("date")}</div>
              </div>
              <LocalizationProvider 
              dateAdapter={AdapterDateFns}
              adapterLocale={hebrew}
              >
                  {/* <Tooltip title="Delete"> */}
                <div>
                  {/* <DatePicker */}
                  <DesktopDatePicker
                    // label="בחר תאריך"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                      setChooseTAGBox(false)
                      setChooseODBox(false)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              {/* </Tooltip> */}
                </LocalizationProvider>
                </div>
            </div>
            {/* {typeOfItems === 'jobs' && */}
              
            <div style={{ display: 'none', margin: '0px 3px', border: '1px solid #e1e1e1', borderRadius: '5px', padding: '3px'}}>
              <div style={{ margin: '7px 0px 4px 0px', textAlign: 'center', fontSize: '11px' }}>
                <div>{trnslt("near")}</div>
                <div>{trnslt("by")}</div>
              </div>
                { !searchNear && < RadioButtonCheckedIcon
                style={buttonStyleWithHoverITEM}
                onClick={() => {
                  setSearchNear(!searchNear)
                }}
                />}
                { searchNear && < RadioButtonUncheckedIcon
                style={buttonStyleWithHoverITEM}
                onClick={() => {
                  setSearchNear(!searchNear)
                }}
                />}
            </div>
            {/* } */}
            {typeOfItems === 'jobs' &&
              
            <div style={{ margin: '0px 3px', border: '1px solid #e1e1e1', borderRadius: '5px', padding: '3px'}}>
              <div style={{ margin: '7px 0px 4px 0px', textAlign: 'center', fontSize: '11px' }}>
                <div>{trnslt("all")}</div>
                <div>{trnslt("dates")}</div>
              </div>
                { !searchWithDate && < RadioButtonCheckedIcon
                style={buttonStyleWithHoverITEM}
                onClick={() => {
                  setSearchWithDate(!searchWithDate)
                }}
                />}
                { searchWithDate && < RadioButtonUncheckedIcon
                style={buttonStyleWithHoverITEM}
                onClick={() => {
                  setSearchWithDate(!searchWithDate)
                }}
                />}
            </div>
            }
            {typeOfItems === 'scheduledtoday' &&
            <div style={{ display: 'none',margin: '0px 3px', border: '1px solid #e1e1e1', borderRadius: '5px', padding: '3px'}}>
              <div style={{ margin: '7px 0px 4px 0px', textAlign: 'center', fontSize: '11px' }}>
                <div>{trnslt("add")}</div>
                <div>{trnslt("item")}</div>
              </div>
              < EmojiTransportationIcon
                style={buttonStyleWithHoverITEM}
                onClick={() => {
                  setChooseITEMBox(!chooseITEMBox)
                  setChooseODBox(false)
                  setChooseTAGBox(false)
                  setChooseADDBox(false)
                }}
                onMouseEnter={() => setIsHoverITEM(true)}
                onMouseLeave={() => setIsHoverITEM(false)}
              />
            </div>
              }
            <div style={{ margin: '0px 3px', border: '1px solid #e1e1e1', borderRadius: '5px', padding: '3px'}}>
              <div style={{ margin: '7px 0px 4px 0px', textAlign: 'center', fontSize: '11px' }}>
                <div>{trnslt("start")}</div>
                <div>{trnslt("& end")}</div>
              </div>
              < MultipleStopIcon 
                style={buttonStyleWithHover}
                onClick={() => {
                  setChooseODBox(!chooseODBox)
                  setChooseTAGBox(false)
                  setChooseADDBox(false)
                  setChooseITEMBox(false)
                }}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              />
            </div>

          

            {/* {typeOfItems === 'calculateroute' && */}
            {/* <Tooltip title='תוויות למסלולים' arrow placement="top"> */}
            <div style={{ margin: '0px 3px', border: '1px solid #e1e1e1', borderRadius: '5px', padding: '3px'}}>
              <div style={{ margin: '7px 0px 4px 0px', textAlign: 'center', fontSize: '11px' }}>
                <div>{trnslt("add")}</div>
                <div>{trnslt("route")}</div>
              </div>
                <RouteIcon
                  style={buttonStyleWithHoverTAG}
                  onClick={() => {
                      setChooseTAGBox(!chooseTAGBox)
                      setChooseODBox(false)
                      setChooseADDBox(false)
                      setChooseITEMBox(false)
                    }
                  }
                  onMouseEnter={() => setIsHoverTAG(true)}
                  onMouseLeave={() => setIsHoverTAG(false)}
              />
            </div>
            <div style={{ position: 'relative', margin: '0px 3px', border: '1px solid #e1e1e1', borderRadius: '5px', padding: '3px'}}>
              {tagRouteOptions.length < 1 && <div className="over" style={{width: '100%', position: 'absolute', height: '100%', background: '#c0c0c08a', top: '0', right: '0'}}></div>}
              <div style={{ margin: '7px 0px 4px 0px', textAlign: 'center', fontSize: '11px' }}>
                <div>{trnslt("add")}</div>
                <div>{trnslt("address")}</div>
              </div>
                < AddLocationIcon 
                  style={buttonStyleWithHoverADD}
                  onClick={() => {
                      setChooseADDBox(!chooseADDBox)
                      setChooseTAGBox(false)
                      setChooseODBox(false)
                      setChooseITEMBox(false)
                    }
                  }
                  onMouseEnter={() => setIsHoverADD(true)}
                  onMouseLeave={() => setIsHoverADD(false)}
              />
            </div>
              {/* </Tooltip> */}
          {/* }  */}

          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '6px'}}>
          {/* <div style={{ display: 'flex', justifyContent: 'flex-start' }}> */}
          <div style={{ display: 'none', justifyContent: 'flex-start' }}>
            {/* <div> */}
                {/* <select  onChange={handleChangeOD} style={{
                height: "29px", padding: "1px 1px", border: "0", borderRadius: "6px", marginTop: "4px", fontSize: '14px', background: '#959595', color: '#fff', width: '93px', marginLeft: '1px', marginRight: ltrOrRtl('1px', '1px')
              }} id="allod">
                  <option key={'999999458458'} value={574865695543}>
                    {trnslt("choose start & end")}
                  </option>
                {all_o_and_d_Locations.map(place => {
                  return (
                    <option key={place._id} value={place._id}>
                          {`${place.location_o[0]} - ${place.location_d[0]}`}
                        </option>
                      )
                    })}
                </select> */}
            {/* </div> */}
              <div style={{display: 'flex', background: '#0f3d6b', borderRadius: '5px'}}>
                <div 
                  style={{ display: 'flex' }}
                  onClick={(e)=> {
                  setModelChooseTagRoute(true)}}>
                <div style={{
                  padding: '9px 10px',
                  color: '#fff'
                }}>{currentCatRouteName === null ? 'בחר מסלול' : currentCatRouteName}
                </div>
               <ArrowDropDownIcon style={{color: '#fff', paddingTop: '8px'}} />
                </div>
                {/* <select
                onClick={(e)=> {
                  selectPrevent(e)
                  setModelChooseTagRoute(true)}}
                  onChange={(e) => selectedMapCatChanged(e)}
                  style={{
                  height: "29px",
                  padding: "1px 1px",
                  border: "0",
                  borderRadius: "6px",
                  marginTop: "0px",
                  fontSize: '14px',
                  background: 'rgb(15 61 107)', color: '#fff', width: '90px', marginLeft: '11px', marginRight: '1px'
                }}
                >
                  <option key={'0'} value={'0'}>
                          {trnslt("בחר מסלול")}
                        </option>
                {tagRouteOptionsAdmin.map(el => {
                    return (
                      <option key={el.value} value={el.value}>
                          {el.key}
                        </option>
                      )
                })}
                {tagRouteOptions.map(el => {
                    return (
                      <option key={el.value} value={el.value}>
                          {el.key}
                        </option>
                      )
                })}
                </select> */}
                
              {
              // <Button variant='contained' onClick={() => {
              //               setSearchNow(!searchNow)
              //               // props.setChooseTAGBox()
              //           }
              // }>חפש</Button>
              }
              <div>
              {modelChooseTagRoute &&
                <div className='FormikUiAddContactInMape' style={{ marginTop: '20px' }}>
                  <Modal
                    style_modal__content={{maxHeight: 'calc(100vh - 41px)', overflow: 'auto',
                    padding: '0px'}}
                    show={modelChooseTagRoute}
                    onCancel={() => {
                      // props.setChooseODBox()
                      setModelChooseTagRoute(false)
                    }}
                    header={'בחירת מסלול לטיול'}
                    closeButton={<Button variant='contained'
                      onClick={() => {
                        setModelChooseTagRoute(false)
                        // props.setChooseODBox()
                      }
                                        
                      }>סגור</Button>}
                  >
                    <div style={{display: 'flex', flexDirection: 'column',
                      height: 'calc(100vh - 60px)',
                      justifyContent: 'space-between'
                  }}>
                      <div style={{maxHeight: 'calc(100vh - 195px)'}}>
              {typeRoute !== 'admin_routes' &&

              
                    <SearchContactsRoute 
                      AutoCompleteCFromAddContactHandler={(e) => {
                        setAutoCompleteCFromSearchContactHandler(e)
                      }}
                      selectedMapCatChanged={selectedMapCatChanged}
                      setModelChooseTagRoute={setModelChooseTagRoute}
                      setModelSection={setModelSection}
                      modelSection={modelSection}
                      // onClick={() => setModelSection('choose')}
                    />
                  }
                      <div style={{display: 'flex'}}>
                          <div style={{
                              // maxHeight: '40vh',
                              height: 'calc(100vh - 189px)',
                              overflow: 'auto',
                              padding: '5px',
                              marginLeft: '5px',
                              width: '100%'
                            }}>

                            {modelSection === 'choose' && typeRoute !== 'admin_routes' &&
                              <div style={{
                              background: '#1976d2',
                              color: '#fff',
                              textAlign: 'center',
                              marginBottom: '8px',
                              padding: '10px'
                              }}>
                                {/* {typeRoute} */}
                                {/* {typeRoute === 'admin_routes' &&
                                  <>{'בחר מסלול'}</>
                                } */}
                                {typeRoute === 'my_routes' &&
                                  <>{'המסלולים שלי'}</>
                                }
                                {typeRoute === 'shareable_routes' &&
                                  <>{'טיולים משותפים'}</>
                                }
                                
                                {/* טיולים משותפים  */}
                              </div>
                            }

                            {modelSection === 'choose' &&
                              <div>
                              
                              {typeRoute === 'shareable_routes' && tagRouteOptionsShareable.map(el => {
                                  return (
                                    <div style={{display: 'flex', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '10px 4px'}}>
                                      <div style={{
                                        padding: '8px 7px 9px',
                                        fontSize: '15px',
                                        border: '1px solid rgb(25, 118, 210)',
                                        boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
                                        background: '#fff',
                                        color: '#000',
                                      }} key={el.value} value={el.value}
                                      onClick={()=> {
                                        setModelChooseTagRoute(false)
                                        selectedMapCatChanged(el.value,el.key)
                                      }
                                      }
                                      >
                                          {el.key}
                                      </div>
                                      {/* <div>ע"י: {}</div> */}
                                      {/* ===EDIT BUTTONS=== */}
                                      <EditRouteButtons obj={el} buttons={['connect_to_shareable_route']} />
                                      {/* ===END EDIT BUTTONS=== */}
                                    </div>
                                    )
                              })}
                              {/* {names.map((name) => (
                                <option key={name} value={name}>
                                  {name}
                                </option>
                              ))} */}
                            {typeRoute === 'admin_routes' && optionsAdminRoutes.country.name.length > 1 &&
                              <div 
                                onClick={()=> {
                                  setModelChooseTagRoute(false)
                                  selectedMapCatChanged(null,null,'searchAdminPlaces')
                                }}
                                style={{
                                  background: 'rgb(0 86 169)',
                                  color: 'rgb(255, 255, 255)',
                                  textAlign: 'center',
                                  padding: '13px',
                                  flexGrow: '1',
                                  margin: '11px 0px 17px 0px',
                                  borderRadius: '2px',
                                  boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
                                }}
                              >צא לטיול</div>
                            }
                              {typeRoute === 'admin_routes' &&  
                                <div>
                                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {optionsAdminRoutes.country.name.length > 1 &&
                                      <div
                                      style={optionsDiv}>
                                        {optionsAdminRoutes.country.name}
                                        <span style={optionsSpan}
                                         onClick={() => setOptionsAdminRoutes({
                                          ...optionsAdminRoutes,
                                          country: { ...optionsAdminRoutes.country, show: true },
                                          city: { ...optionsAdminRoutes.city, show: false },
                                          cat: { ...optionsAdminRoutes.cat, show: false },
                                          sub_cat: { ...optionsAdminRoutes.sub_cat, show: false },
                                          tag: { ...optionsAdminRoutes.tag, show: false },
                                        })}>שנה מדינה</span>
                                        <div style={optionsClose}
                                            onClick={() => setOptionsAdminRoutes({
                                              ...optionsAdminRoutes,
                                              country: { ...optionsAdminRoutes.country, show: true, id: '', name: '', },
                                              city: { ...optionsAdminRoutes.city, show: false, id: '', name: '', },
                                              cat: { ...optionsAdminRoutes.cat, show: false, id: '', name: '', },
                                              sub_cat: { ...optionsAdminRoutes.sub_cat, show: false, id: '', name: '', },
                                              tag: { ...optionsAdminRoutes.tag, show: false, id: '', name: '', },
                                            })}
                                          >
                                            <CloseIcon style={optionsCloseIcon} />
                                        </div>
                                      </div>
                                    }
                                    {optionsAdminRoutes.city.name.length > 1 &&
                                      <div
                                        style={optionsDiv}>
                                        {optionsAdminRoutes.city.name}
                                        <span  
                                          onClick={() => setOptionsAdminRoutes({
                                            ...optionsAdminRoutes,
                                            country: { ...optionsAdminRoutes.country, show: false },
                                            city: { ...optionsAdminRoutes.city, show: true },
                                            cat: { ...optionsAdminRoutes.cat, show: false },
                                            sub_cat: { ...optionsAdminRoutes.sub_cat, show: false },
                                            tag: { ...optionsAdminRoutes.tag, show: false },
                                          })}
                                        style={optionsSpan}>שנה עיר</span>
                                        <div style={optionsClose}
                                          onClick={() => setOptionsAdminRoutes({
                                            ...optionsAdminRoutes,
                                            country: { ...optionsAdminRoutes.country, show: false },
                                            city: { ...optionsAdminRoutes.city, show: true, id: '', name: '',  },
                                            cat: { ...optionsAdminRoutes.cat, show: false, id: '', name: '',  },
                                            sub_cat: { ...optionsAdminRoutes.sub_cat, show: false, id: '', name: '',  },
                                            tag: { ...optionsAdminRoutes.tag, show: false, id: '', name: '',  },
                                          })}
                                        >
                                          <CloseIcon style={optionsCloseIcon} />
                                        </div>
                                        </div >
                                    }
                                    {optionsAdminRoutes.cat.name.length > 1 &&
                                      <div 
                                        style={optionsDiv}>{optionsAdminRoutes.cat.name}
                                        <span
                                          onClick={() => setOptionsAdminRoutes({
                                            ...optionsAdminRoutes,
                                            country: { ...optionsAdminRoutes.country, show: false },
                                            city: { ...optionsAdminRoutes.city, show: false },
                                            cat: { ...optionsAdminRoutes.cat, show: true},
                                            sub_cat: { ...optionsAdminRoutes.sub_cat, show: false},
                                            tag: { ...optionsAdminRoutes.tag, show: false},
                                          })}
                                          style={optionsSpan}>שנה קטגוריה</span>
                                        <div style={optionsClose}
                                            onClick={() => setOptionsAdminRoutes({
                                              ...optionsAdminRoutes,
                                              country: { ...optionsAdminRoutes.country, show: false },
                                              city: { ...optionsAdminRoutes.city, show: false },
                                              cat: { ...optionsAdminRoutes.cat, show: true, id: '', name: '',    },
                                              sub_cat: { ...optionsAdminRoutes.sub_cat, show: false, id: '', name: '',    },
                                              tag: { ...optionsAdminRoutes.tag, show: false, id: '', name: '',    },
                                            })}
                                          >
                                            <CloseIcon style={optionsCloseIcon} />
                                        </div>
                                      </div>
                                    }
                                    {optionsAdminRoutes.sub_cat.name.length > 1 &&
                                      <div 
                                      style={optionsDiv}>{optionsAdminRoutes.sub_cat.name}
                                        <span 
                                          onClick={() => setOptionsAdminRoutes({
                                            ...optionsAdminRoutes,
                                            country: { ...optionsAdminRoutes.country, show: false },
                                            city: { ...optionsAdminRoutes.city, show: false },
                                            cat: { ...optionsAdminRoutes.cat, show: false },
                                            sub_cat: { ...optionsAdminRoutes.sub_cat, show: true },
                                            tag: { ...optionsAdminRoutes.tag, show: false },
                                          })}
                                          style={optionsSpan}>שנה תת קטגוריה</span>
                                        <div style={optionsClose}
                                            onClick={() => setOptionsAdminRoutes({
                                              ...optionsAdminRoutes,
                                              country: { ...optionsAdminRoutes.country, show: false },
                                              city: { ...optionsAdminRoutes.city, show: false },
                                              cat: { ...optionsAdminRoutes.cat, show: false },
                                              sub_cat: { ...optionsAdminRoutes.sub_cat, show: true, id: '', name: '',    },
                                              tag: { ...optionsAdminRoutes.tag, show: false, id: '', name: '',},
                                            })}
                                          >
                                            <CloseIcon style={optionsCloseIcon} />
                                        </div>
                                      </div>
                                    }

                                    {optionsAdminRoutes.tag.name.length > 1 &&
                                      <div 
                                        style={optionsDiv}>{optionsAdminRoutes.tag.name}
                                        <span
                                          onClick={() => setOptionsAdminRoutes({
                                            ...optionsAdminRoutes,
                                            country: { ...optionsAdminRoutes.country, show: false },
                                            city: { ...optionsAdminRoutes.city, show: false },
                                            cat: { ...optionsAdminRoutes.cat, show: false },
                                            sub_cat: { ...optionsAdminRoutes.sub_cat, show: false },
                                            tag: { ...optionsAdminRoutes.tag, show: true },
                                          })}
                                          style={optionsSpan}>שנה תגית</span>
                                        <div style={optionsClose}
                                            onClick={() => setOptionsAdminRoutes({
                                              ...optionsAdminRoutes,
                                              country: { ...optionsAdminRoutes.country, show: false },
                                              city: { ...optionsAdminRoutes.city, show: false },
                                              cat: { ...optionsAdminRoutes.cat, show: false },
                                              sub_cat: { ...optionsAdminRoutes.sub_cat, show: false },
                                              tag: { ...optionsAdminRoutes.tag, show: true, id: '', name: '', },
                                            })}
                                          >
                                            <CloseIcon style={optionsCloseIcon} />
                                        </div>
                                      </div>
                                    }
                                  </div>
                                  <div>
                {optionsAdminRoutes.country.show &&
                  <div style={{width: '100%', maxWidth: '600px', padding: '6px'}}>
                  <div style={optionsTitle}>בחר מדינה</div>
                  {countryOptions.map(el => {
                  return (
                    <div style={{display: 'flex', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '10px 4px'}}>
                      <div style={{
                        padding: '8px 7px 9px',
                        fontSize: '15px',
                        border: '1px solid rgb(25, 118, 210)',
                        boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
                        background: '#fff',
                        color: '#000',
                      }} key={el.value} value={el.value}
                      onClick={()=> {
                        setOptionsAdminRoutes({
                          ...optionsAdminRoutes,
                          country: {
                            ...optionsAdminRoutes.country,
                            id: el.value,
                            name: el.key,
                            show: false,
                          },
                          city: { ...optionsAdminRoutes.city, show: true, id: '', name: '', },
                          cat: { ...optionsAdminRoutes.cat, show: false, id: '', name: '',  },
                          sub_cat: { ...optionsAdminRoutes.sub_cat, show: false, id: '', name: '',  },
                          tag: { ...optionsAdminRoutes.tag, show: false, id: '', name: '',  },
                        });
                      }
                      }
                      >
                          {el.key}
                      </div>
                    </div>
                    )})
                  }
                  </div>
                }
                {optionsAdminRoutes.city.show &&
                  <div style={{width: '100%', maxWidth: '600px', padding: '6px'}}>
                    <div style={optionsTitle}>בחר עיר</div>
                                  {cityOptions.map(el => {
                                    return (
                                      <div style={{display: 'flex', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '10px 4px'}}>
                                        
                                        <div style={{
                                          padding: '8px 7px 9px',
                                          fontSize: '15px',
                                          border: '1px solid rgb(25, 118, 210)',
                                          boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
                                          background: '#fff',
                                          color: '#000',
                                        }} key={el.value} value={el.value}
                                        onClick={()=> {
                                          setOptionsAdminRoutes({
                                            ...optionsAdminRoutes,
                                            city: {
                                              ...optionsAdminRoutes.city,
                                              id: el.value,
                                              name: el.key,
                                              show: false
                                            },
                                            country: { ...optionsAdminRoutes.country, show: false },
                                            cat: { ...optionsAdminRoutes.cat, show: true, id: '', name: '',  },
                                            sub_cat: { ...optionsAdminRoutes.sub_cat, show: false, id: '', name: '',  },
                                            tag: { ...optionsAdminRoutes.tag, show: false, id: '', name: '',  },
                                          });
                                        }
                                        }
                                        >
                                            {el.key}
                                        </div>
                                      </div>
                                      )})
                                    }
                  </div>
                }
                {optionsAdminRoutes.cat.show &&
                  <div style={{width: '100%', maxWidth: '600px', padding: '6px'}}>
                    <div style={optionsTitle}>בחר קטגוריה</div>
                                  {catOptions.map(el => {
                                    return (
                                      <div style={{display: 'flex', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '10px 4px'}}>
                                        
                                        <div style={{
                                          padding: '8px 7px 9px',
                                          fontSize: '15px',
                                          border: '1px solid rgb(25, 118, 210)',
                                          boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
                                          background: '#fff',
                                          color: '#000',
                                        }} key={el.value} value={el.value}
                                        onClick={()=> {
                                          setOptionsAdminRoutes({
                                            ...optionsAdminRoutes,
                                            cat: {
                                              ...optionsAdminRoutes.cat,
                                              id: el.value,
                                              name: el.key,
                                              show: false
                                            },
                                            country: { ...optionsAdminRoutes.country, show: false },
                                            city: { ...optionsAdminRoutes.city, show: false },
                                            sub_cat: { ...optionsAdminRoutes.sub_cat, show: true, id: '', name: '',  },
                                            tag: { ...optionsAdminRoutes.tag, show: false, id: '', name: '',  },
                                          });
                                        }
                                        }
                                        >
                                            {el.key}
                                        </div>
                                      </div>
                                      )})
                                    }
                  </div>
                }
                {optionsAdminRoutes.sub_cat.show &&
                  <div style={{width: '100%', maxWidth: '600px', padding: '6px'}}>
                    <div style={optionsTitle}>בחר תת קטגוריה</div>
                                  {subCatOptions.map(el => {
                                    return (
                                      <div style={{display: 'flex', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '10px 4px'}}>
                                        
                                        <div style={{
                                          padding: '8px 7px 9px',
                                          fontSize: '15px',
                                          border: '1px solid rgb(25, 118, 210)',
                                          boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
                                          background: '#fff',
                                          color: '#000',
                                        }} key={el.value} value={el.value}
                                        onClick={()=> {
                                          setOptionsAdminRoutes({
                                            ...optionsAdminRoutes,
                                            sub_cat: {
                                              ...optionsAdminRoutes.sub_cat,
                                              id: el.value,
                                              name: el.key,
                                              show: false
                                            },
                                            country: { ...optionsAdminRoutes.country, show: false },
                                            city: { ...optionsAdminRoutes.city, show: false },
                                            cat: { ...optionsAdminRoutes.cat, show: false },
                                            tag: { ...optionsAdminRoutes.tag, show: true, id: '', name: '',  },
                                          });
                                        }
                                        }
                                        >
                                            {el.key}
                                        </div>
                                      </div>
                                      )})
                                    }
                  </div>
                }
                {optionsAdminRoutes.tag.show &&
                  <div style={{width: '100%', maxWidth: '600px', padding: '6px'}}>
                    <div style={optionsTitle}>בחר תגית סינון</div>
                                  {idsTagsOptions.map(el => {
                                    return (
                                      <div style={{display: 'flex', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '10px 4px'}}>
                                        
                                        <div style={{
                                          padding: '8px 7px 9px',
                                          fontSize: '15px',
                                          border: '1px solid rgb(25, 118, 210)',
                                          boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
                                          background: '#fff',
                                          color: '#000',
                                        }} key={el.value} value={el.value}
                                        onClick={()=> {
                                          setOptionsAdminRoutes({
                                            ...optionsAdminRoutes,
                                            tag: {
                                              ...optionsAdminRoutes.tag,
                                              id: el.value,
                                              name: el.key,
                                              show: false
                                            },
                                            country: { ...optionsAdminRoutes.country, show: false },
                                            city: { ...optionsAdminRoutes.city, show: false },
                                            cat: { ...optionsAdminRoutes.cat, show: false },
                                            sub_cat: { ...optionsAdminRoutes.sub_cat, show: false },
                                          });
                                        }
                                        }
                                        >
                                            {el.key}
                                        </div>
                                      </div>
                                      )})
                                    }
                  </div>
                }

                                  </div>
                                  {
                                    typeRoute !== 'admin_routes' && tagRouteOptionsAdmin.map(el => {
                                      return (
                                        <div style={{display: 'flex', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '4px'}}>
                                          <div style={{
                                            padding: '8px 7px 9px',
                                            fontSize: '15px',
                                            border: '1px solid rgb(25, 118, 210)',
                                            boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
                                            background: '#fff',
                                            color: '#000',
                                          }} key={el.value} value={el.value}
                                          onClick={()=> {
                                            setModelChooseTagRoute(false)
                                            selectedMapCatChanged(el.value,el.key)
                                          }}>
                                              {el.key}
                                          </div>
                                        </div>
                                        )
                                    })
                                  }
                                </div>
                              }

                              {typeRoute === 'my_routes' && tagRouteOptions.map(el => {
                                return (
                                  <div style={{display: 'flex', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '10px 4px'}}>
                                    <div style={{
                                      padding: '8px 7px 9px',
                                      fontSize: '15px',
                                      border: '1px solid rgb(25, 118, 210)',
                                      boxShadow: 'rgb(55, 55, 55) 1px 3px 5px',
                                      background: '#fff',
                                      color: '#000',
                                    }} key={el.value} value={el.value}
                                    onClick={()=> {
                                      setModelChooseTagRoute(false)
                                      selectedMapCatChanged(el.value,el.key)
                                    }
                                    }
                                    >
                                        {el.key}
                                    </div>
                                    {/* ===EDIT BUTTONS=== */}
                                    <EditRouteButtons obj={el} buttons={['allow_shareable', 'del']} />
                                    {/* ===END EDIT BUTTONS=== */}
                                  </div>
                                  )
                              })}

                            </div>
                          }
                          </div>
                      </div>
                      <div style={{display: 'flex', display: 'none'}}>
                        
                            <div style={{
                                maxHeight: '100vh',
                                overflow: 'auto',
                                padding: '5px',
                                marginLeft: '5px',
                                width: '49%'
                              }}>
                              <div style={{
                              background: '#1976d2',
                              color: '#fff',
                              textAlign: 'center',
                              marginBottom: '8px',
                              padding: '10px'
                              }}>
                                בחר מסלול
                              </div>
                              <div>
                                {typeRoute === 'admin_routes' &&  tagRouteOptionsAdmin.map(el => {
                                    return (
                                      <div style={{display: 'flex', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '4px'}}>
                                        <div style={{
                                          padding: '8px 6px 5px',
                                          fontSize: '15px',
                                        }} key={el.value} value={el.value}
                                        onClick={()=> {
                                          setModelChooseTagRoute(false)
                                          selectedMapCatChanged(el.value,el.key)
                                        }}>
                                            {el.key}
                                        </div>
                                        {/* <EditRouteButtons obj={el} /> */}
                                      </div>
                                      )
                                })}
                              </div>
                            </div>

                            <div style={{
                                maxHeight: '100vh',
                                overflow: 'auto',
                                padding: '5px',
                                marginLeft: '5px',
                                width: '49%'
                              }}>
                              <div style={{
                              background: '#1976d2',
                              color: '#fff',
                              textAlign: 'center',
                              marginBottom: '8px',
                              padding: '10px'
                              }}>
                                המסלולים שלי
                              </div>
                              <div>
                                {typeRoute === 'my_routes' && tagRouteOptions.map(el => {
                                    return (
                                      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', background: 'rgb(222 222 222)', color: '#fff', borderBottom: '1px solid rgb(135, 135, 135)', padding: '4px'}}>
                                        <div style={{
                                          padding: '8px 6px 5px',
                                          fontSize: '15px',
                                        }} key={el.value} value={el.value}
                                        onClick={()=> {
                                          setModelChooseTagRoute(false)
                                          selectedMapCatChanged(el.value,el.key)
                                        }
                                        }
                                        >
                                            {el.key}
                                        </div>
                                        {/* ===EDIT BUTTONS=== */}
                                        <EditRouteButtons obj={el} buttons={['allow_shareable', 'del']} />
                                        {/* ===END EDIT BUTTONS=== */}
                                      </div>
                                      )
                                })}
                                {/* {names.map((name) => (
                                  <option key={name} value={name}>
                                    {name}
                                  </option>
                                ))} */}
                              </div>
                            </div>

                      </div>
                      </div>
                      <div style={{display: 'flex', padding: '5px'}}>
                            <div 
                              onClick={() => {
                                setTypeRoute('admin_routes')
                                setModelSection('choose')
                              }}
                              style={{
                              background: typeRoute === 'admin_routes' ? '#002142' : '#1976d2',
                              color: '#fff',
                              textAlign: 'center',
                              // marginBottom: '8px',
                              padding: '13px',
                              flexGrow: '1',
                              margin: '2px',
                              border: '1px solid #1976d2',
                              borderRadius: '2px',
                              boxShadow: 'rgb(55, 55, 55) 1px 3px 5px'
                              }}>
                                בחר מסלול
                            </div>
                            <div 
                              onClick={() => {
                                setTypeRoute('my_routes')
                                setModelSection('choose')
                              }}
                              style={{
                              background: typeRoute === 'my_routes' ? '#002142' : '#1976d2',
                              color: '#fff',
                              textAlign: 'center',
                              // marginBottom: '8px',
                              padding: '13px',
                              flexGrow: '1',
                              margin: '2px',
                              border: '1px solid #1976d2',
                              borderRadius: '2px',
                              boxShadow: 'rgb(55, 55, 55) 1px 3px 5px'
                              }}>
                                המסלולים שלי
                            </div>
                            <div 
                              onClick={() => {
                                setTypeRoute('shareable_routes')
                                setModelSection('choose')
                              }}
                              style={{
                              background: typeRoute === 'shareable_routes' ? '#002142' : '#1976d2',
                              color: '#fff',
                              textAlign: 'center',
                              // marginBottom: '8px',
                              padding: '13px',
                              flexGrow: '1',
                              margin: '2px',
                              border: '1px solid #1976d2',
                              borderRadius: '2px',
                              boxShadow: 'rgb(55, 55, 55) 1px 3px 5px'
                            }}>
                              טיולים משותפים
                            </div>
                            {/* <div>
                      </div> */}
                          </div>
                    </div>
                    {/* { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
                inputes: ['location_d','id_tag_route'] , subject: 'places'}, */}
                    
                    {/* <Grid item xs={12}>
                        <FormikUiAddContact 
                        typeLocation='contact_o'
                        typeOfForm={['tag_route', 'שם מסלול']}
                        formPurpesTitle={'formPurpes'}
                        zIndex={'zIndex6'}
                        selectedAddressD={'selectedAddressD_RD'}
                        selectedAddressO={'selectedAddressO_RD'}
                        responseNewTagRoute={(e)=> responseNewTagRoute(e)}
                        />
                    </Grid> */}
                    
                    {/* {newTagRouteCreated !== false && */}
                      {/* // <Grid item xs={12}> */}
                        {/* // <div> */}
                          {/* { newTagRouteCreated } */}
                          {/* </div> */}
                        {/* // <AddTagAndLocationsByCopy */}
                          {/* // newTagRouteCreated={newTagRouteCreated} */}
                          {/* // allLocationsToCopy={props.allLocationsToCopy} */}
                          {/* // typeOfForm={['locations_tag_by_copy', 'מוצא ויעד', 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', ['location_o', 'location_d']]} */}
                          {/* // submitTextButton='צור העתק של כל הפריטים למסלול החדש' */}
                        {/* /> */}
                      {/* </Grid> */}
                    {/* } */}

                  </Modal>
                </div>
              }
              </div>
              </div>
              <div className="jjj" onClick={() => setDisplayNone(!displayNone)}
                style={{width: '32px'}}></div>
              {/* <select
                onChange={(e) => selectedMapCatChanged(e)}
              style={{
                height: "29px", padding: "1px 1px", border: "0", borderRadius: "6px", marginTop: "4px", fontSize: '14px', background: '#959595', color: '#fff', width: '65px', marginLeft: '1px', marginRight: '1px'
              }}
                // id="allod"
               >
                <option key={'0'} value={'0'}>
                        {trnslt("choose pro")}
              </option>

{userProfile.connected_movers && userProfile.connected_movers.map(el => {
                // if(place.form_purpes === 'd&o'){
                  return (
                    <option key={el._id} value={el._id}>
                        {el.business_name}
                      </option>
                    )
                // }
              })}
              </select> */}
              {/* <select
                onChange={(e) => selectedMapCatChanged(e)}
              style={{
                height: "29px", padding: "1px 1px", border: "0", borderRadius: "6px", marginTop: "4px", fontSize: '14px', background: '#959595', color: '#fff', width: '64px', marginLeft: '1px', marginRight: '1px'
              }}
                // id="allod"
               >
                <option key={'0'} value={'0'}>
                        {trnslt("choose client")}
                      </option>
              {tagRouteOptions.map(el => {
                // if(place.form_purpes === 'd&o'){
                  return (
                    <option key={el.value} value={el.value}>
                        {el.key}
                      </option>
                    )
                // }
              })}
              </select> */}
            </div>
            {/* =======SearchContacts=========== */}
            <div style={{
                  height: '37px',
                  maxWidth: '233px'
              }} 
              onClick={()=> {
                setModelChooseTagRoute(true) 
                setModelSection('choose')
              }}
            >
              <div className="searchInputs" style={{height: '37px', paddingLeft: '3px'}}>
                <input
                style={{
                  maxWidth: '110px',
                  height: '33px',
                  border: '0',
                  borderRadius: '12px',
                  paddingRight: '5px',
                }}
                  type="text"
                  placeholder={'חיפוש מסלול לטיול'}
                  // value={wordEntered}
                  // onChange={handleFilter}
                  // onChange={(e) => {onChangeTwo(e)
                  //   setWordEntered(e.target.value)
                  // }}
                />
                <div className="searchIcon">
                  {/* {optionsTwo.length === 0 ? ( */}
                    <SearchIcon style={{fontSize: '25px'}} />
                  {/* ) : ( */}
                    {/* <CancelOutlinedIcon id="clearBtn" onClick={clearInput} /> */}
                  {/* )} */}
                </div>
              </div>
              
            </div>
            {/* ======END SearchContacts============ */}
            <div style={{background: '#0f3d6b', borderRadius: '5px'}}>
              <select
                  onChange={(e) => selectedRadius(e)}
                  style={{
                    height: "29px",
                    padding: "1px 1px",
                    border: "0",
                    borderRadius: "6px",
                    marginTop: "0px",
                    fontSize: '14px',
                    background: 'rgb(15 61 107)', color: '#fff', width: '72px', marginLeft: '11px', marginRight: '1px'
                  }}
                  // id="allod"
                >
                
                  <option key={'0'} value={'0'}>
                          {/* {trnslt("radius")} */}
                          {/* {trnslt(kmOptions[0].key)} */}
                          {trnslt(radius+'km')}
                        </option>
                {kmOptions.map(el => {
                  // if(place.form_purpes === 'd&o'){
                    return (
                      <option key={el.value} value={el.value}>
                          {el.key}
                        </option>
                      )
                  // }
                })}
              </select>
              {
              <Button variant='contained' onClick={() => {
                            setSearchNearNow(!searchNearNow)
                            // props.setChooseTAGBox()
                        }
              }>בסביבתי</Button>
              }
            </div>
        </div>

        {chooseITEMBox &&
          <>
            {/* <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', alignItems: 'center'}}> */}
              {/* <div style={{fontSize: '20px', color: '#989898'}}>הוסף כתובת</div> */}
            <CreateTagRoute formPurpes='c_o_d' submitTextButton={trnslt("add") + trnslt("item")} setChooseITEMBox={()=> setChooseITEMBox(!chooseITEMBox)} />
          {/* </div> */}
          </>
          }
        {chooseADDBox &&
          <>
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', alignItems: 'center'}}>
              <div style={{fontSize: '20px', color: '#989898'}}>{trnslt("add") + trnslt("address")} </div>

            {/* <div>
              <select
                onChange={(e) => selectedMapCatChanged(e)}
                style={{height: "40px", padding: "8px", border: "0", borderBottom: "1px solid #bbb", margin: "8px 0px 25px 0px", fontSize: '17px'}}
                // id="allod"
               >
                <option key={'0'} value={'0'}>
                        {'בחר תווית'}
                      </option>
              {tagRouteOptions.map(el => {
                // if(place.form_purpes === 'd&o'){
                  return (
                    <option key={el.value} value={el.value}>
                        {el.key}
                      </option>
                    )
                // }
              })}
              </select>
            </div> */}

            <CreateTagRoute formPurpes='location_tag' submitTextButton={trnslt("add address to route")} setChooseADDBox={()=> setChooseADDBox(!chooseADDBox)} isTagarouteExist={tagRouteOptions.length > 0} />
          </div>
          </>
          }
        {/* {chooseTAGBox && */}
          {chooseTAGBox &&
          <>
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', alignItems: 'center'}}>
              <div style={{fontSize: '20px', color: '#989898'}}>{trnslt("add route name")}</div>

            {/* <div>
              <select
                onChange={(e) => selectedMapCatChanged(e)}
                style={{height: "40px", padding: "8px", border: "0", borderBottom: "1px solid #bbb", margin: "8px 0px 25px 0px", fontSize: '17px'}}
                // id="allod"
               >
                <option key={'0'} value={'0'}>
                        {'בחר תווית'}
                      </option>
              {tagRouteOptions.map(el => {
                // if(place.form_purpes === 'd&o'){
                  return (
                    <option key={el.value} value={el.value}>
                        {el.key}
                      </option>
                    )
                // }
              })}
              </select>
            </div> */}

            {/* <CreateTagRoute formPurpes='tag_route' submitTextButton='הוספת שם מסלול' setChooseADDBox={()=> setChooseADDBox(!chooseADDBox)} /> */}
            <CreateTagRoute formPurpes='tag_route' submitTextButton={trnslt("add route name")} setChooseTAGBox={()=> setChooseTAGBox(!chooseTAGBox)} />
          </div>
          </>
          }
          {/* } */}

        {chooseODBox &&
          <>
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', alignItems: 'center'}}>
              <div style={{fontSize: '20px', color: '#989898'}}>{trnslt("add start & end")}</div>
             {/* <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', alignItems: 'center', background: '#e0e0e0', borderRadius: '23px'}}> */}
            {/* <div style={{fontSize: '20px', color: '#989898'}}>בחירת מוצא ויעד</div> */}
              {/* <div style={{display: 'flex',width: '100%', justifyContent: 'flex-end'}}>
                <CancelIcon 

                    style={buttonStyleWithHoverCLOSE}
                    onClick={() => {
                        // setChooseADDBox(!chooseADDBox)
                        // setChooseTAGBox(false)
                        // setChooseODBox(false)
                      setChooseODBox(false)
                      }
                    }
                    onMouseEnter={() => setIsHoverCLOSE(true)}
                    onMouseLeave={() => setIsHoverCLOSE(false)}
                />
            </div> */}
            
              {/* <div>
                <select  onChange={handleChangeOD} style={{height: "40px", maxWidth: '300px', padding: "8px", border: "0", borderBottom: "1px solid #bbb", margin: "8px 0px 12px 0px", fontSize: '14px'}} id="allod">
                  <option key={'999999458458'} value={574865695543}>
                    {' בחר התחלה וסוף למסלול'}
                  </option>
                {all_o_and_d_Locations.map(place => {
                  return (
                    <option key={place._id} value={place._id}>
                          {`${place.location_o[0]} - ${place.location_d[0]}`}
                        </option>
                      )
                    })}
                </select>
            </div> */}

              {/* <div style={{marginBottom: "8px", marginBottom: '8px', marginLeft: '71px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}> */}
                {/* <CreateTagRoute formPurpes='o_and_d' submitTextButton='צור התחלה וסוף חדשים' /> */}
              {/* </div> */}
              <CreateTagRoute formPurpes='o_and_d' submitTextButton={trnslt("create new start & end")} setChooseODBox={()=> setChooseODBox(!chooseODBox)} />
            </div>
          </>
        }

      {typeOfItems === 'scheduledtoday' && !typeOfItems === 'jobs' && !typeOfItems === 'calculateroute' &&

        <div>
        <div style={{ marginTop: 50 }}>
        </div>
        <NavLink to="/items/all" exact>
        <button 
              style={{
              background: 'rgb(25 118 210)', 
              ...(typeOfItems === 'all' && { background: '#003264' }),
              borderRadius: '10px',
              fontSize: '15px',
              color: '#fff',
              marginLeft: '6px',
              width: '132px',
              margin: '30px 9px 18px 9px',
              height: '39px',
              border: '1px solid #dbdbdb',
              cursor: 'pointer',
            }}
              onClick={() => setAutoCompleteId('')} 
              >{trnslt("all items")}</button>
        </NavLink>
        <NavLink to="/items/pending" exact>
        <button 
              style={{
              background: 'rgb(25 118 210)', 
              ...(typeOfItems === 'pending' && { background: '#003264' }),
              borderRadius: '10px',
              fontSize: '15px',
              color: '#fff',
              marginLeft: '6px',
              width: '132px',
              margin: '30px 9px 18px 9px',
              height: '39px',
              border: '1px solid #dbdbdb',
              cursor: 'pointer',
            }}
              onClick={() => setAutoCompleteId('')} 
              >{trnslt("pending")}</button>
        </NavLink>
        <NavLink to="/items/waiting_for_execution" exact>
        <button 
              style={{
              background: 'rgb(25 118 210)', 
              ...(typeOfItems === 'waiting_for_execution' && { background: '#003264' }),
              borderRadius: '10px',
              fontSize: '15px',
              color: '#fff',
              marginLeft: '6px',
              width: '132px',
              margin: '30px 9px 18px 9px',
              height: '39px',
              border: '1px solid #dbdbdb',
              cursor: 'pointer',
            }}
              onClick={() => setAutoCompleteId('')} 
              >ממתין להעמסה</button>
        </NavLink>
        <NavLink to="/items/completed" exact>
        <button 
              style={{
              background: 'rgb(25 118 210)', 
              ...(typeOfItems === 'completed' && { background: '#003264' }),
              borderRadius: '10px',
              fontSize: '15px',
              color: '#fff',
              marginLeft: '6px',
              width: '132px',
              margin: '30px 9px 18px 9px',
              height: '39px',
              border: '1px solid #dbdbdb',
              cursor: 'pointer',
            }}
              onClick={() => setAutoCompleteId('')} 
              >בוצע</button>
        </NavLink>
        <NavLink to="/items/scheduled" exact>
        <button 
              style={{
              background: 'rgb(25 118 210)', 
              ...(typeOfItems === 'scheduled' && { background: '#003264' }),
              borderRadius: '10px',
              fontSize: '15px',
              color: '#fff',
              marginLeft: '6px',
              width: '132px',
              margin: '30px 9px 18px 9px',
              height: '39px',
              border: '1px solid #dbdbdb',
              cursor: 'pointer',
            }}
              onClick={() => setAutoCompleteId('')} 
              >כל הלוזים</button>
        </NavLink>
        <NavLink to="/items/scheduledtoday" exact>
        <button 
              style={{
              background: 'rgb(25 118 210)', 
              ...(typeOfItems === 'scheduledtoday' && { background: '#003264' }),
              borderRadius: '10px',
              fontSize: '15px',
              color: '#fff',
              marginLeft: '6px',
              width: '132px',
              margin: '30px 9px 18px 9px',
              height: '39px',
              border: '1px solid #dbdbdb',
              cursor: 'pointer',
            }}
              onClick={() => setAutoCompleteId('')} 
              >לוז להיום</button>
        </NavLink>

        </div>
      }

      { !typeOfItems === 'scheduledtoday' && !typeOfItems === 'jobs' && !typeOfItems === 'calculateroute' &&
        <div>

              <div>{allUserContactsNumber} {trnslt("items")}</div>
              <div>{trnslt("page")} {pageNumber+1} {trnslt("from")} {totalPages} {trnslt("pages")}</div>
        </div>
      }

      {allUserContacts && typeOfItems !== 'scheduled' && !typeOfItems === 'scheduledtoday' && !typeOfItems === 'jobs' && !typeOfItems === 'calculateroute' && (
        <table
          cellSpacing="0"
          style={{ borderCollapse: 'collapse', width: "100%", height: height, padding: "5px 10px", marginTop: '15px' }}
        >
          <thead style={{ backgroundColor: "black", color: "white" }}>
            <tr>
              {colNames.map((headerItem, index) => (
                <th key={index}>{headerItem.toUpperCase()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allUserContacts.map((obj, index) => (
                <>
                
                  <tr key={obj._id} style={{border: '1px solid #bbb'}}>
                    {/* {Object.values(obj).map((value, index2) => ( */}
                    <td key={index+'-'+index+1} onClick={() => {ChangeCurrentIndexInTable(index)}}> <AddCircleOutlineOutlined color="primary" /> {obj._id}</td>
                    <td key={index+'-'+index+2} >{obj.packs_pallet}</td>
                  {objWasDel === obj._id ? 
                    <td key={index+'-'+index+3} style={{color: 'red'}}>איש קשר זה נמחק לצמיתות!</td>
                  : 
                  <td key={index+'-'+index+4} >{obj.id_client && obj.id_client.first_name}</td>
                }
                <td key={index+'-'+index+5} >{
                  obj.from_id_warehouse ? obj.from_id_warehouse.first_name
                  : obj.from_id_clients_client.first_name
                }</td>
                <td key={index+'-'+index+6} >{
                  obj.to_id_warehouse ? obj.to_id_warehouse.first_name
                  : obj.to_id_clients_client.first_name
                }</td>
                <td key={index+'-'+index+7} >{obj.id_manager && obj.id_manager.name}</td>
                <td key={index+'-'+index+8} >{
                  obj.where_to === 'warehouse_to_clients_client' ? 'ממחסן ללקוח'
                  : obj.where_to === 'clients_client_to_warehouse' ? 'מלקוח למחסן' 
                  : obj.where_to === 'warehouse_to_warehouse' ? 'ממחסן למחסן'
                  : obj.where_to === 'clients_client_to_clients_client' ? 'מלקוח ללקוח' : null
                }</td>
                  </tr>


                  <tr key={index+'-dfh'} style={{border: '1px solid #bbb'}}>
                    <td key={index+'-'+index+10} colspan="7" style={{marginBottom: '20px'}}>
                      <ItemsEditButtons 
                        obj={obj} 
                        employeeLink={employeeLink}
                        AllEmployee={userProfile.AllEmployee}
                        startDate={startDate}
                      />
                    </td>
                  </tr>
              {currentIndexInTable === index &&
                <tr key={index}>
                    
                    <td key={index+'-'+index+1} colspan="7" style={{marginBottom: '20px'}}>        
                        <div style={{maxWidth: '370px', padding: '6px', background: '#f1f1f1', marginTop: '16px'}}>
                            <div style={{fontWeight: 'bold', marginBottom: '20px'}}>כל הפריטים</div>
                            {allSubItems.map((objSub, indexSub) => {
                              return objSub.uuidNumber ===  obj.uuidNumber ?
                              <div style={{marginBottom: '10px', borderBottom: '1px solid #bbb'}}>
                                <div style={{marginBottom: '10px'}}>
                                    <div style={{color: '#757575'}}>קוד פריט</div>
                                    <div>{objSub.uuidNumber}</div>
                                </div>
                                <div style={{marginBottom: '10px'}}>
                                    <div style={{color: '#757575'}}>מספר משטח</div>
                                    <div>{objSub.current_pallet}</div>
                                </div>
                              </div>
                              : null
                            })}
                        </div>

                        {/* <div style={allDetailsCss}>
                            <div>טלפון 2</div>
                            <div>{obj.phone2}</div>
                        </div>
                        <div style={allDetailsCss}>
                            <div>טלפון 3</div>
                            <div>{obj.phone3}</div>
                        </div>
                        <div style={allDetailsCss}>
                            <div>פקס</div>
                            <div>{obj.fax}</div>
                        </div>
                        <div style={allDetailsCss}>
                            <div>תיבת דואר</div>
                            <div>{obj.po_box}</div>
                        </div>
                        <div style={allDetailsCss}>
                            <div>כתובת</div>
                            <div>כניסה {obj.apartment} - קומה {obj.floor} - דירה {obj.entry}</div>
                        </div>
                        <div style={allDetailsCss}>
                            <div>תיאור</div>
                            <div>{obj.description}</div>
                        </div> */}
                    </td>
                    {/* ))} */}
                </tr>
              }
              </>
            ))} 
          </tbody>
          
        </table>
      )}
      {allUserContacts && typeOfItems === 'scheduled' && (
        <div>
          <ItemsSchedule
            allItems={allUserContacts}
            allSubItems={allSubItems}
          />
        </div>
      )}


      {/* {typeOfItems === 'calculateroute' && !showMapLeaflet && leafletMap.length < 1 && selectedOptionsBySelectedCat.length < 1 && ( */}
      {typeOfItems === 'calculateroute' && selectedOptionsBySelectedCat.length < 1 && (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <div style={{position: 'absolute', background: '#fff', padding: '10px', width: "100%", maxWidth: '500px'}} >
            <div style={{"display":"flex","justifyContent":"center","alignItems":"center","height":"247px","fontSize":"36px","padding":"93px","textAlign":"center","background":"#f2f2f2","color":"#9d9d9d"}
          }>נא בחרו מוצא ויעד וכן תווית מיקומים</div>
          </div>
        </div>
      )}
              
      {allUserContacts.length === 0 && typeOfItems === 'scheduledtoday' && (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          {chooseADDNewBox &&
      <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
        <Modal
        show={chooseADDNewBox}
            onCancel={() => {
                // props.setChooseADDBox()
                setChooseADDBox(false)
            }}
        header={'הוספת כתובת לטיול'}
            closeButton={<Button variant='contained' onClick={() => {
              setChooseADDNewBox(false)
            }
            }>סגור</Button>}
            >
        <Grid item xs={12}>
            <AddPackagesForTransportation 
              typeOfForm={['location_tag', 'מיקומים לתווית - לחישוב מסלול', 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי שם מסלול לצורך חישוב מסלול בלבד', ['location_d', 'choose_tag_then_location']]}
              submitTextButton='הוסף כתובת לטיול'
            />
        </Grid>
        </Modal>
      </div>
    }
          <div 
            style={{
            padding: '19px',
            width: '100%', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'}}>
              {/* <div style={{fontSize: '16px', 
              marginBottom: windowSize[0] > 950 ? '27px' : '12px', 
              marginTop: windowSize[0] > 950 ? '110px' : '30px',
              textAlign: 'center'}}>צור מסלול לטיול</div> */}
              <div style={{fontSize: '16px', marginTop: windowSize[0] > 950 ? '40px' : '30px', marginBottom: '27px',textAlign: 'center'}}>הוסף כתובת</div>
              <div onClick={() => {
                  setChooseADDNewBox(true)
                }}
              style={{cursor: 'pointer', margin: windowSize[0] > 950 ? '38px 0px 124px 0px' : '0px 0px 44px'}}>
                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="AddLocationIcon" 
                style={{
                  filter: 'drop-shadow(3px 5px 1px rgb(3 0 3 / 0.9))',
                  fontSize: '117px',
                  color: 'rgb(15, 61, 107)',
                  boxShadow: 'rgb(11 73 135) 1px 2px 0px 1px',
                  background: '#faebd700',
                  borderRadius: '127px',
                  padding: '15px',
                }}>
                  <path d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm4 8h-3v3h-2v-3H8V8h3V5h2v3h3v2z"
                  style={{boxShadow: 'rgb(11, 73, 135) 0px 2px 20px 12px, rgba(255, 255, 255, 0.53) 0px 4px 5px 0px, rgb(255, 255, 255) 0px 1px 10px 0px'}}></path>
                </svg>
              </div>
          </div>
              {/* <div style={{}}>
                <AddLocationIcon 
                  style={{
                    fontSize: '28px',
                    color: 'rgb(15, 61, 107)',
                  }}
                  onClick={() => {
                      setChooseADDNewBox(true)
                    }
                  }
              />
            </div> */}
          {/* <div style={{zIndex: zIndex, position: 'absolute', background: '#fff', padding: '10px', width: "100%", maxWidth: '500px'}} >
              <div className="instructions" style={instructions}>
                <div style={{ margin: '18px 5px', fontSize: '24px', color: '#454545' }}>{trnslt("Just a few clicks and you're on the track") }</div>
                <Instructions
                  titleNumber='1'
                  title={trnslt("create route name")}
                  icon={<RouteIcon style={buttonStyleWithHoverTAG} />}
                  textExpln_1={trnslt("press on this button")}
                  textExpln_2={trnslt("add")}
                  textExpln_3={trnslt("route")}
                  textExpln_4={trnslt("and add a name to your route you want to create")}
                />
                <Instructions
                  titleNumber='2'
                  title={trnslt("add locations to route")}
                  icon={<AddLocationIcon style={buttonStyleWithHoverTAG} />}
                  textExpln_1={trnslt("press on this button")}
                  textExpln_2={trnslt("add")}
                  textExpln_3={trnslt("address")}
                  textExpln_4={trnslt("choose the route name you just created")}
                  textExpln_5={trnslt("and add as many locations you want")}
                />
                <Instructions
                  titleNumber='3'
                  title={trnslt("add start & end points for your route")}
                  icon={<MultipleStopIcon style={buttonStyleWithHoverTAG} />}
                  textExpln_1={trnslt("press on this button")}
                  textExpln_2={trnslt("start")}
                  textExpln_3={trnslt("& end")}
                  textExpln_4={trnslt("add one location for the start of the route and a second location for the end of your route")}
                  textExpln_5={trnslt("Please make sure that the start and end addresses are not exactly the same.")}
                />
                <Instructions
                  titleNumber='4'
                  title={trnslt("watch your route")}
                  textExpln_1=''
                  textExpln_4={trnslt("After you have created a route and added addresses to it")}
                  textExpln_5={trnslt("Select the name of the route in the bar as well as a 'start and end' point for the route")}
                />
              </div>
          </div> */}
          </div>
          

      )}

      {allUserContacts && typeOfItems === 'scheduledtoday' && (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          
          <ItemsScheduleToday
            allItems={allUserContacts}
            allSubItems={allSubItems}
            startDate={startDate}
            selectedOD={selectedOD}
            operation={typeOfItems}
            allCoupons={allCoupons}
            setGoLocations={setGoLocations}
          />
        </div>
      )}
      {allUserContacts && typeOfItems === 'calculateroute' && (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          {allUserContacts.length}
          {allUserContacts.map((s, index) => (
          // <div>{s._id}</div>
          <>
          {index === 1 &&
            <ItemsScheduleToday
            allItems={allUserContacts}
            allSubItems={allSubItems}
            startDate={startDate}
            selectedOD={selectedOD}
            operation={typeOfItems}
            />
          }
            </>
          ))}
        </div>
      )}
      {allUserContacts.length === 0 && typeOfItems === 'jobs' && (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <div style={{zIndex: zIndex, position: 'absolute', background: '#fff', padding: '10px', width: "100%", maxWidth: '500px'}} >
            <div style={{"display":"flex","justifyContent":"center","alignItems":"center","height":"247px","fontSize":"36px","padding":"93px","textAlign":"center","background":"#f2f2f2","color":"#9d9d9d"}
          }>לא נמצאו דרושים לתאריך זה</div>
          </div>
        </div>
      )}
      {allUserContacts && typeOfItems === 'jobs' && (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <ItemsJobs
            allItems={allUserContacts}
            allSubItems={allSubItems}
            startDate={startDate}
            selectedOD={selectedOD}
          />
        </div>
      )}



    </div>
    {/* { !typeOfItems === 'scheduledtoday' && !typeOfItems === 'jobs' &&

      <div style={{margin: '22px 0px' }}>
        <button 
            style={{
              background: '#00376c', 
              color: '#fff',
              marginLeft: '6px',
              width: '60px',
              height: '39px',
              border: '1px solid #dbdbdb',
              cursor: 'pointer',
            }}
            onClick={gotoPrevious} 
            >הקודם</button>

              {pages.map((pageIndex) => (
                // background: #1975d2;
                // color: #fff;
                <button key={pageIndex} onClick={() => setPageNumber(pageIndex)} style={{
                  background: pageIndex === pageNumber ? '#1975d2' : '#fff',
                  color: pageIndex === pageNumber ? '#fff' : '#000',
                  marginLeft: '6px',
                  width: '52px',
                  height: '39px',
                  border: '1px solid #dbdbdb',
                  cursor: 'pointer',
                }}>
                  {pageIndex + 1}
                </button>
              ))}

          <button 
          style={{
            background: '#00376c', 
            color: '#fff',
            marginLeft: '6px',
            width: '60px',
            height: '39px',
            border: '1px solid #dbdbdb',
            cursor: 'pointer',
          }}
          onClick={gotoNext}>הבא</button>
        </div>
        } */}
        </>
  )
}

export default ItemsTableData