import React, { useEffect, useRef, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { Grid, TextField } from '@mui/material'

var autocomplete_contact;
var autocomplete_contact_p;
var autocomplete_contact_c;
var autocomplete_contact_o;
var autocomplete_contact_d;
var autocomplete_o;
var autocomplete_d;
const google = window.google;

function AddressInput (props) {
  const { label, name, ...rest } = props
  const [chosenLocation, setChosenLocation] = useState(null);
  const [searchString, setSearchString] = useState(null);
//   let textRef = useRef();

    // useEffect(() => {console.log("textRef:",textRef)},[textRef])
    useEffect(() => {
        console.log("FFF")
        if (props.typeLocation === 'p') {
            console.log("FFF_P")
        setTimeout(

                function initMap3() {
                    console.log("initMap2contact_p")
                        autocomplete_contact_p = new google.maps.places.Autocomplete((document.getElementById('search_location_w' + props.typeLocation + props.addToId)), {
                            types: ['geocode'],
                        });
        console.log("autocomplete_contact_p", autocomplete_contact_p)
                        google.maps.event.addListener(autocomplete_contact_p, 'place_changed', function () {
                            var near_place = autocomplete_contact_p.getPlace();
                            console.log("near_place: ", near_place)
                            console.log("near_place.geometry.location.lat: ", near_place.geometry.location.lat())
                            console.log("near_place.geometry.location.lng: ", near_place.geometry.location.lng())
                            let cityOnly = '';
                            near_place.address_components.forEach((comp) => {
                                console.log(comp)
                                if (comp.types[0] === "locality") {
                                    cityOnly = comp.long_name;
                                }
                            })
                            props.updateAddressesFields(props.addToId,[
                                near_place.formatted_address,
                                near_place.geometry.location.lat(),
                                near_place.geometry.location.lng(),
                                cityOnly
                            ], props.typeLocation)
                        });
                    }, 500);
            }
        if (props.typeLocation === 'contact_c') {
            console.log("FFF_contact_c")
        setTimeout(

                function initMap2() {
                    console.log("initMap2contact_c")
                        autocomplete_contact_c = new google.maps.places.Autocomplete((document.getElementById('search_location_w' + props.typeLocation + props.addToId)), {
                            types: ['geocode'],
                        });
        console.log("autocomplete_contact_c", autocomplete_contact_c)
                        google.maps.event.addListener(autocomplete_contact_c, 'place_changed', function () {
                            var near_place = autocomplete_contact_c.getPlace();
                            console.log("near_place: ", near_place)
                            console.log("near_place.geometry.location.lat: ", near_place.geometry.location.lat())
                            console.log("near_place.geometry.location.lng: ", near_place.geometry.location.lng())
                            let cityOnly = '';
                            near_place.address_components.forEach((comp) => {
                                console.log(comp)
                                if (comp.types[0] === "locality") {
                                    cityOnly = comp.long_name;
                                }
                            })
                            props.updateAddressesFields(props.addToId,[
                                near_place.formatted_address,
                                near_place.geometry.location.lat(),
                                near_place.geometry.location.lng(),
                                cityOnly
                            ], props.typeLocation)
                        });
                    }, 500);
            }
        if (props.typeLocation === 'contact_o') {
            console.log("FFF_contact_o")
        setTimeout(

                function initMap2() {
                    console.log("initMap2contact_o")
                        autocomplete_contact_o = new google.maps.places.Autocomplete((document.getElementById('search_location_w' + props.typeLocation + props.addToId)), {
                            types: ['geocode'],
                        });
        console.log("autocomplete_contact_o", autocomplete_contact_o)
                        google.maps.event.addListener(autocomplete_contact_o, 'place_changed', function () {
                            var near_place = autocomplete_contact_o.getPlace();
                            console.log("near_place: ", near_place)
                            console.log("near_place.geometry.location.lat: ", near_place.geometry.location.lat())
                            console.log("near_place.geometry.location.lng: ", near_place.geometry.location.lng())
                            let cityOnly = '';
                            near_place.address_components.forEach((comp) => {
                                console.log(comp)
                                if (comp.types[0] === "locality") {
                                    cityOnly = comp.long_name;
                                }
                            })
                            props.updateAddressesFields(props.addToId,[
                                near_place.formatted_address,
                                near_place.geometry.location.lat(),
                                near_place.geometry.location.lng(),
                                cityOnly
                            ], props.typeLocation)
                        });
                    }, 500);
            }
        if (props.typeLocation === 'contact_d') {
            console.log("FFF_contact_d")
        setTimeout(

                function initMap2() {
                    console.log("initMap2contact_d")
                        autocomplete_contact_d = new google.maps.places.Autocomplete((document.getElementById('search_location_w' + props.typeLocation + props.addToId)), {
                            types: ['geocode'],
                        });
        console.log("autocomplete_contact_d", autocomplete_contact_d)
                        google.maps.event.addListener(autocomplete_contact_d, 'place_changed', function () {
                            var near_place = autocomplete_contact_d.getPlace();
                            console.log("near_place: ", near_place)
                            console.log("near_place.geometry.location.lat: ", near_place.geometry.location.lat())
                            console.log("near_place.geometry.location.lng: ", near_place.geometry.location.lng())
                            let cityOnly = '';
                            near_place.address_components.forEach((comp) => {
                                console.log(comp)
                                if (comp.types[0] === "locality") {
                                    cityOnly = comp.long_name;
                                }
                            })
                            props.updateAddressesFields(props.addToId,[
                                near_place.formatted_address,
                                near_place.geometry.location.lat(),
                                near_place.geometry.location.lng(),
                                cityOnly
                            ], props.typeLocation)
                        });
                    }, 500);
            }
        if (props.typeLocation === 'o') {
            console.log("FFF_o")
        setTimeout(

                function initMap2() {
                    console.log("initMap2")
                        autocomplete_o = new google.maps.places.Autocomplete((document.getElementById('search_location_w' + props.typeLocation + props.addToId)), {
                            types: ['geocode'],
                        });
        console.log("autocomplete_o", autocomplete_o)
                        google.maps.event.addListener(autocomplete_o, 'place_changed', function () {
                            var near_place = autocomplete_o.getPlace();
                            console.log("near_place: ", near_place)
                            console.log("near_place.geometry.location.lat: ", near_place.geometry.location.lat())
                            console.log("near_place.geometry.location.lng: ", near_place.geometry.location.lng())
                            let cityOnly = '';
                            near_place.address_components.forEach((comp) => {
                                console.log(comp)
                                if (comp.types[0] === "locality") {
                                    cityOnly = comp.long_name;
                                }
                            })
                            props.updateAddressesFields(props.addToId,[
                                near_place.formatted_address,
                                near_place.geometry.location.lat(),
                                near_place.geometry.location.lng(),
                                cityOnly
                            ], props.typeLocation)
                        });
                    }, 500);
            }
        if (props.typeLocation === 'd') {
            console.log("FFF_D")
            console.log('GGG')
        setTimeout(

                function initMap3() {
                    console.log("initMap2d")
                        autocomplete_d = new google.maps.places.Autocomplete((document.getElementById('search_location_w' + props.typeLocation + props.addToId)), {
                            types: ['geocode'],
                        });
        console.log("autocomplete_d", autocomplete_d)
                        google.maps.event.addListener(autocomplete_d, 'place_changed', function () {
                            var near_place = autocomplete_d.getPlace();
                            console.log("near_place: ", near_place)
                            // setChosenLocation(near_place.formatted_address)
                            console.log("near_place.geometry.location.lat: ", near_place.geometry.location.lat())
                            console.log("near_place.geometry.location.lng: ", near_place.geometry.location.lng())
                            let cityOnly = '';
                            near_place.address_components.forEach((comp) => {
                                console.log(comp)
                                if (comp.types[0] === "locality") {
                                    cityOnly = comp.long_name;
                                }
                            })
                            props.updateAddressesFields(props.addToId,[
                                near_place.formatted_address,
                                near_place.geometry.location.lat(),
                                near_place.geometry.location.lng(),
                                cityOnly
                                // near_place.address_components
                            ], props.typeLocation)
                            // console.log("textRef", textRef)
                            // textRef.current.value = ''
                        });
                        // const inputDiv = document.getElementById('search_location_w' + props.addToId)
                        // inputDiv.value = ''
                        // textRef.current.value = ''
                        // if(props.changeFormikAddressValues) {
        
                        //     props.changeFormikAddressValues()
                        // }
                    }, 500);
            }
        }, []);

  return (

            <Grid item xs={12} md={12}>
                <TextField
                    id={'search_location_w' + props.typeLocation + props.addToId}
                    name={name}
                    label={props.specialLabal ? props.specialLabal : "כתובת איש קשר"}
                    margin='normal'
                    variant='outlined'
                    fullWidth={true}
                    // inputRef={textRef}
                    // onChange={(e) => {
                    //         props.changeFormikAddressValues()
                    //         // e.target.value = ''
                    //         }
                    // }
                    // type={props.type}
                    // {...configTextfield}
                />
            </Grid>

  )
}

export default AddressInput

