import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { ConstructionOutlined } from '@mui/icons-material';
function SwitchButton (props) {
  const handleSwitcher = (e) => {
    // console.log(e);
    props.handleSwitchTrueOrFalse(e);
  }
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  const { label, name, ...rest } = props
  return (
    <div className='form-control' style={{display: 'flex', alignItems: 'center'}} >
      <div htmlFor={name}>{label}</div>
      <Android12Switch defaultChecked 
      onChange={(event) => handleSwitcher(event.target.checked)}
      // onClick={props.handleSwitchTrueOrFalse}
      />
      {/* <Field id={name} name={name} {...rest} type={props.type} /> */}
      {/* <ErrorMessage component={TextError} name={name} /> */}
    </div>
  )
}

export default SwitchButton
