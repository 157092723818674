import { AddCircleOutlineOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from 'react-redux';

import { Grid, TextField, Typography, Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import React, { useContext, useEffect, useState } from "react"
import Autocomplete from "@mui/material/Autocomplete";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import axios from "axios";

import "./SearchBar.css";
import "./style.css";
import Modal from "../shared/components/UIElements/Modal";
import FormikUiAddContact from '../shared/components/FormikElements/FormikUiAddContact';
import { NavLink, useParams } from "react-router-dom";
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import { format } from "timeago.js"; 



const btnStyle = {
  backgroundColor: "black",
  color: "white",
  border: "none",
  padding: "5px 10px",
}


const buttonStyle = {
  background: 'rgb(25 118 210)', 
  borderRadius: '67px',
  padding: '0px 13px 0px 13px',
  fontSize: '15px',
  color: '#fff',
  marginLeft: '6px',
  minWidth: '61px',
  margin: '6px 2px 6px',
  height: '39px',
  border: '1px solid #dbdbdb',
  cursor: 'pointer'
}

function Table({
  list,
  zIndex,
  colNames = [
      'קוד לקוח',
      'שם',
      'אימייל',
      'טלפון',
    'כתובת',
    'קבוצה',
    'תגיות'
    ],
  pageNum = 0,
  pageSize = 10,
  width = "auto",
  height = "auto",
  totalList,
  // totalPages,
}) {

const auth = useContext(AuthContext);
const userProfile = useSelector((state) => state.ui.userProfile);
const getCitiesTwo = async (str) => {
  try {
    let searchableCity = str.replace(/,/g, "");
    let url = "/api/images/";
    const response = await fetch(url,
          {method: 'GET',
          body: null,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            },
          }
        );
        let dataContacts = await response.json();

    dataContacts = dataContacts.filter( (el) => el.user_id === userProfile._id)    
    return dataContacts;
  } catch (error) {
    console.error(error);
  }
};
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { typeOfCat } = useParams();
  const typeOfContacts = 'all';
  const [allUserContacts, setAllUserContacts] = useState([]);
  const [allMediaCats, setAllMediaCats] = useState([]);
  const [autoCompleteId, setAutoCompleteId] = useState([]);
  const [allUserContactsNumber, setAllUserContactsNumber] = useState([]);
  const [allUserContactsPageNumber, setAllUserContactsPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState([]);
  const pages = Array.from(Array(totalPages).keys());
  // const [page, setPage] = useState(pageNum)
  const [pageNumber, setPageNumber] = useState(0);
  const [currentIndexInTable, setCurrentIndexInTable] = useState(null);
  const [createContactName, setCreateContactName] = useState(false);
  const [createContactName2, setCreateContactName2] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [objWasDel, setObjWasDel] = useState('ff');

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  const ChangeCurrentIndexInTable = (index) => {
    if(currentIndexInTable === index){
      setCurrentIndexInTable(null)
    } else {
      setCurrentIndexInTable(index)
    }
  }

  const [optionsOne, setOptionsOne] = useState([]);
  const [optionsTwo, setOptionsTwo] = useState([]);
  const [value, setValue] = useState("");

  const onChangeTwo = async (e) => {
    console.log(e.target)
    if (e.target.value) {
      let data = await getCitiesTwo(e.target.value);
      setOptionsTwo(data);
    }
  };


useEffect(() => {
  console.log("AllMediaCats>", allMediaCats)
},[allMediaCats])





  useEffect(() => {
    const fetchUsersMediaCatsData = async () => {
      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      const response = await fetch(
        `/api/images/madia_cats/user/${storedDataOfUser.userId}`
      );
      const dataContacts = await response.json();
      console.log(dataContacts)
      setAllMediaCats(dataContacts.contacts[0].all_tags_routes)
      return dataContacts;
    };
    try {
      fetchUsersMediaCatsData();
    } catch (error) {
      console.log(error)
    }
  
    
  },[])




  useEffect(() => {
    console.log("autoCompleteId:",autoCompleteId)
    const fetchContactsData = async () => {
      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      // console.log("storedDataOfUser:",storedDataOfUser.userId)
      
      const response = await fetch(
        // `/api/contacts/${typeOfContacts}/user/${storedDataOfUser.userId}?page=${pageNumber}&autocompleteid=${autoCompleteId}`
        `/api/images/${typeOfCat}/user/${storedDataOfUser.userId}?page=${pageNumber}&autocompleteid=${autoCompleteId}`
      );
      // if (!response.ok) {
      //   throw new Error('Could not fetch contacts data of ' , storedDataOfUser.userId , '!');
      // }
      const dataContacts = await response.json();
      console.log(dataContacts)
      setAllUserContacts(dataContacts.contacts)
      setAllUserContactsNumber(dataContacts.totalData)
      setTotalPages(dataContacts.totalPages)
      setCurrentIndexInTable(null)
      console.log("AllUserContacts::",allUserContacts)
      console.log("AllUserContactsNumber::",allUserContactsNumber)
      return dataContacts;
    };
    try {
      fetchContactsData();
      // const contactsData = await fetchContactsData();
      // console.log('contactsData:', contactsData)
    } catch (error) {
      console.log(error)
    }
  
    
  },[pageNumber,autoCompleteId, typeOfCat])


  const gotoPrevious = () => {
    setPageNumber(Math.max(0, pageNumber - 1));
  };

  const gotoNext = () => {
    setPageNumber(Math.min(totalPages - 1, pageNumber + 1));
  };
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  // const handleFilter = (event) => {
  //   const searchWord = event.target.value;
  //   setWordEntered(searchWord);
  //   const newFilter = data.filter((value) => {
  //     return value.title.toLowerCase().includes(searchWord.toLowerCase());
  //   });

  //   if (searchWord === "") {
  //     setFilteredData([]);
  //   } else {
  //     setFilteredData(newFilter);
  //   }
  // };

  const clearInput = () => {
    console.log('ffff')
    setOptionsTwo([]);
    setWordEntered("");
  };


  const confirmDeleteHandler = async (objectId,path) => {
    setShowConfirmModal(false);

    const deleteContactById = async () => {
      try {
        await sendRequest(
          path,
          'DELETE',
          null,
          {
            Authorization: 'Bearer ' + auth.token
          }
          );
          // props.onDelete(props.id);
        } catch (err) {
          console.log('NOT DELETED!!!',err,objectId,path)
        }
    }
    await deleteContactById();
    // await console.log('DELETED!!!',objectId,path)
    // await alert('איש קשר זה נמחק!')
  };


const allDetailsCss = {display:'flex', justifyContent: 'right', maxWidth: '260px', margin: '10px'};
  return (

    // <div style={{zIndex: zIndex, position: 'absolute', background: '#fff', padding: '10px', maxWidth: '450px', width: "100%", maxWidth: '1000px', margin: '22px 10px'}} >
    <div>
      {/* <Typography style={{color: 'red'}}  variant="h2">{objWasDel}</Typography> */}
      <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
      {/* <div style={{ marginTop: 5, maxWidth: '500px', display: 'flex'}}>
        <div className="search" style={{width: '63%'}}>
          <div className="searchInputs">
            <input
              type="text"
              placeholder={'חיפוש איש קשר'}
              value={wordEntered}
              // onChange={handleFilter}
              onChange={(e) => {onChangeTwo(e)
                setWordEntered(e.target.value)
              }}
            />
            <div className="searchIcon">
              {optionsTwo.length === 0 ? (
                <SearchIcon />
              ) : (
                <CancelOutlinedIcon id="clearBtn" onClick={clearInput} />
              )}
            </div>
          </div>


          {optionsTwo.length != 0 && (
            <div className="dataResult">
              {optionsTwo.slice(0, 15).map((value, key) => {
                return (
                  // <a className="dataItem" href={value.link} target="_blank">
                  <div className="singleResult" onClick={(e, id) => {
                    setAutoCompleteId(value._id)
                    clearInput()
                  }
                  }>{value.first_name + ' - ' + value.last_name + ' - ' + value.phone1 + ' - ' + value.email} עעעע</div>
                  // </a>
                );
              })}
            </div>
          )}
          </div>
          
          <div className="create_contact" style={{width: '37%'}}>
            <div style={{ width: '100%', display: 'flex',  flexDirection: 'column', alignItems: 'center'}}>
              <Grid style={{margin: '0px 10px 0px 10px'}}>
                <Button 
                style={{width: '100%'}} 
                 onClick={() => {
                  setCreateContactName2(true)
                }}
                  variant='outlined'
                >צור איש קשר</Button>
              </Grid>
            </div>
      
            {createContactName2 &&
              <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createContactName2}
                  onCancel={() => setCreateContactName2(false)}
                  header={'צור לקוח'}
                  // contentClass="place-item__modal-content"
                  // footerClass="place-item__modal-actions"
                  // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                  closeButton={<Button variant='contained' onClick={() => setCreateContactName2(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    // AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_o'
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                </Modal>
              </div>
            }
        </div>
      </div> */}
        <NavLink to="/images/all" exact>
        <button 
              style={{
              ...buttonStyle,
              ...(typeOfCat === 'all' && { background: '#003264' }),
            }}
              onClick={() => setAutoCompleteId('')} 
              >כל התמונות</button>
        </NavLink>
      {allMediaCats && allMediaCats.map((cat) => (
        <NavLink to={`/images/${cat._id}`} exact>
        <button 
              style={{
              ...buttonStyle,
              ...(typeOfCat === cat._id && { background: '#003264' }),
            }}
              onClick={() => setAutoCompleteId('')} 
              >{cat.first_name}</button>
        </NavLink>
      ))}
      {/* <NavLink to="/contacts/person" exact>
      <button 
            style={{
            ...buttonStyle,
            ...(typeOfContacts === 'person' && { background: '#003264' }),
          }}
            onClick={() => setAutoCompleteId('')} 
            >אנשים</button>
      </NavLink>
      <NavLink to="/contacts/client" exact>
      <button 
            style={{
            ...buttonStyle,
            ...(typeOfContacts === 'client' && { background: '#003264' }),
          }}
            onClick={() => setAutoCompleteId('')} 
            >לקוחות</button>
      </NavLink>
      <NavLink to="/contacts/employee" exact>
      <button 
            style={{
              ...buttonStyle,
            ...(typeOfContacts === 'employee' && { background: '#003264' }),
          }}
            onClick={() => setAutoCompleteId('')} 
            >עובדים</button>
      </NavLink>
      <NavLink to="/contacts/clients_client" exact>
      <button 
            style={{
              ...buttonStyle, 
            ...(typeOfContacts === 'clients_client' && { background: '#003264' })
          }}
            onClick={() => setAutoCompleteId('')} 
            >לקוחות קצה</button>
      </NavLink>
      <NavLink to="/contacts/warehouse" exact>
      <button 
            style={{
            ...buttonStyle,
            ...(typeOfContacts === 'warehouse' && { background: '#003264' })
          }}
            onClick={() => setAutoCompleteId('')} 
            >מחסנים</button>
      </NavLink> */}
      </div>


      <div>{allUserContactsNumber} תמונות</div>
      <div>דף {pageNumber+1} מתוך {totalPages} דפים</div>
      {allUserContacts && (
        <div style={{padding: "5px 10px", marginTop: '15px' }}>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {allUserContacts.map((obj, index) => (
                <>
                
                {/* <div style={{
                  padding: '1px',
                  }}> */}
                  <div className="single_contact_container" key={index} style={{
                    backgroundImage: `url(${obj.image})`,
                    // height: '270px',
                    border: '3px solid #FFF',
                    position: 'relative',
                    backgroundPosition: 'center center',
                    height: windowSize[0] > 1200 ? '370px' : windowSize[0] > 600 ? '270px' : '270px', minWidth: '250px',
                    padding: '3px',
                    width: windowSize[0] > 800 ? '33.3%' : windowSize[0] > 600 ? '50%' : '100%', minWidth: '250px',
                    }}>
                    {/* <div key={index+'-'+index+1} onClick={() => {ChangeCurrentIndexInTable(index)}}> <AddCircleOutlineOutlined color="primary" /> {obj.sku_client}</div> */}
                  {objWasDel === obj._id ? 
                    <div key={index+'-'+index+2} style={{color: 'red'}}>איש קשר זה נמחק לצמיתות!</div>
                  :
                  <>
                    <div style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      padding: '3px',
                      color: 'rgb(255 255 255)',
                      background: 'rgb(25 118 210 / 85%)',
                      fontSize: '12px',
                      borderRadius: '11px'
                    }}>
                      {obj.id_tag_route && obj.id_tag_route.first_name && obj.id_tag_route.first_name}
                    </div>
                  <div style={{
                    position: 'absolute',
                    top: '39px',
                    right: '10px',
                    padding: '3px',
                    color: 'rgb(255 255 255)',
                    background: 'rgb(25 118 210 / 85%)',
                    fontSize: '12px',
                    borderRadius: '11px'
                  }}>
                    {obj.id_item && obj.id_item.title && obj.id_item.title}
                  </div>
                  <div style={{
                    position: 'absolute',
                    top: '69px',
                    right: '10px',
                    padding: '3px',
                    color: 'rgb(0 0 0)',
                    background: 'rgb(255 255 255 / 73%)',
                    borderRadius: '11px',
                    fontSize: '12px',
                  }}>
                    {format(obj.date)}
                  </div>
                  <div style={{
                    position: 'absolute',
                    bottom: '8px',
                    left: '10px',
                    padding: '7px',
                    color: '#fff',
                    background: '#1976d282',
                  }}>
                    <div key={obj.text} >{obj.text} {obj.last_name} - {obj.business_name} </div>
                  </div>
                  </> 
                  }
                      {/* <div key={index+'-'+index+3}>{obj.email}</div>
                      <div key={index+'-'+index+4}>{obj.phone1}</div>
                      <div key={index+'-'+index+5}>{obj.address}</div>
                      <div key={index+'-'+index+6}>{obj.cat}</div>
                      <div key={index+'-'+index+7}>{obj.tag}</div> */}
                  
              {currentIndexInTable === index &&
                <div key={index}>
                    
                    <div key={index+'-'+index+1}>
                        <div style={allDetailsCss}>
                        
                        <Button style={{marginLeft: '8px'}} onClick={() => {
                          setShowConfirmModal(true);
                        }} variant='outlined'>מחק</Button>
                        


                        {showConfirmModal &&
                        <div className='FormikUiAddContactInMape' style={{marginTop: '20px', marginLeft: '20px'}}>
                          <Modal
                            show={showConfirmModal}
                            onCancel={() => setShowConfirmModal(false)}
                            header="Are you sure?"
                            footerClass="place-item__modal-actions"
                          >
                            <p>
                              בטוחים שרוצים למחוק איש קשר זה?
                            </p>
                              <React.Fragment>
                                <Button variant='outlined' inverse onClick={() => setShowConfirmModal(false)}>
                                  בטל
                                </Button>
                                <Button variant='outlined' danger onClick={() => {
                                  confirmDeleteHandler(obj._id, `/api/contacts/${obj._id}`)
                                  // console.log("obj._Id",JSON.stringify(obj._id))
                                  setObjWasDel(obj._id)
                                  ChangeCurrentIndexInTable(index)
                                }
                                  }>
                                  מחק לצמיתות
                                </Button>
                              </React.Fragment>
                          </Modal>
                        </div>
                      }


                        <Button onClick={() => {
                          setCreateContactName(true)
                          // openMapHandler()
                        }} variant='outlined'>עדכן</Button>
                        {createContactName &&
                        <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                          <Modal
                            show={createContactName}
                            onCancel={() => setCreateContactName(false)}
                            header={'עדכון איש קשר'}
                            closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                          >
                            <Grid item xs={12}>
                              <FormikUiAddContact

                              formPurpesTitle='edit_contact'
                              edit_contact={obj}
                              zIndex={'zIndex6'}
                              // mapCatsDropDown={{}}
                              selectedAddressD={'selectedAddressD_RD'}
                              selectedAddressO={'selectedAddressO_RD'}
                              />
                            </Grid>
                          </Modal>
                        </div>
                      }
                        </div>
                        <div style={allDetailsCss}>
                            <div>טלפון 2</div>
                            <div>{obj.phone2}</div>
                        </div>
                        <div style={allDetailsCss}>
                            <div>טלפון 3</div>
                            <div>{obj.phone3}</div>
                        </div>
                        <div style={allDetailsCss}>
                            <div>פקס</div>
                            <div>{obj.fax}</div>
                        </div>
                        <div style={allDetailsCss}>
                            <div>תיבת דואר</div>
                            <div>{obj.po_box}</div>
                        </div>
                        <div style={allDetailsCss}>
                            <div>כתובת</div>
                            <div>כניסה {obj.apartment} - קומה {obj.floor} - דירה {obj.entry}</div>
                        </div>
                        <div style={allDetailsCss}>
                            <div>תיאור</div>
                            <div>{obj.description}</div>
                        </div>
                    </div>
                    {/* ))} */}
                </div>
                  }
                  </div>
                {/* </div> */}
              </>
            ))} 
          </div>
          
        </div>
      )}
      <div style={{margin: '22px 0px' }}>
        <button 
            style={{
            background: '#00376c', 
            color: '#fff',
            marginLeft: '6px',
            width: '60px',
            height: '39px',
            border: '1px solid #dbdbdb',
            cursor: 'pointer',
          }}
            onClick={gotoPrevious} 
            >הקודם</button>

              {pages.map((pageIndex) => (
                    // background: #1975d2;
                    // color: #fff;
                <button key={pageIndex} onClick={() => setPageNumber(pageIndex)} style={{
                  background: pageIndex === pageNumber ? '#1975d2' : '#fff',
                  color: pageIndex === pageNumber ? '#fff' : '#000',
                  marginLeft: '6px',
                  width: '52px',
                  height: '39px',
                  border: '1px solid #dbdbdb',
                  cursor: 'pointer',
                }}>
                  {pageIndex + 1}
                </button>
              ))}

          <button 
          style={{
            background: '#00376c', 
            color: '#fff',
            marginLeft: '6px',
            width: '60px',
            height: '39px',
            border: '1px solid #dbdbdb',
            cursor: 'pointer',
          }}
          onClick={gotoNext}>הבא</button>
        </div>
    </div>
  )
}

export default Table