import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { Grid, TextField } from '@mui/material'
import { useField } from 'formik';

function Input (props, ...otherProps) {
  const { label, name, ...rest } = props;
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined'
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }


  return (
<Grid item xs={12}>
      <TextField
        // id={name}
        // name={name}
        label={label}
        margin='normal'
        type={props.type}
        {...configTextfield}
      />
  {/* <Field {...configTextfield} >
      {({ field, form }) => (
        // <FormControl isInvalid={form.errors[name] && form.touched[name]}>
        //   <FormLabel htmlFor={name}>{label}</FormLabel>
        //   <Input id={name} {...rest} {...field} />
        //   <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        // </FormControl>



                              <TextField
                                id={name}
                                name={name}
                                label={label}
                                margin='normal'
                              />
                    // <div className='form-control'>
                    //   <label htmlFor={name}>{label}</label>
                    //   <Field id={name} name={name} {...rest} />
                    //   <ErrorMessage component={TextError} name={name} />
                    // </div>
                    )}
    



    </Field> */}
                    </Grid>
  )
}

export default Input
