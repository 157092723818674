import { AddCircleOutlineOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from "react"
// import { Link } from 'react-router-dom';

import "./SearchBar.css";
import waze from '../waze.png'
// import 'react-datepicker/dist/react-datepicker.css'
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { Link, NavLink, useParams } from "react-router-dom";
import { useHttpClient } from '../shared/hooks/http-hook';
import { AuthContext } from '../shared/context/auth-context';
import ItemSingleSchedule from "./ItemSingleSchedule";
import ItemsMaps from "./ItemsMaps";


function ItemsScheduleByEmployee({
  employee,
  allSubItems,
  allItems,
  scheduleDate,
  colNames = [
      'קוד פריט',
      'כמות',
      'לקוח',
      'העמסה',
      'פריקה',
      'חברת משלוחים',
      'סוג'
    ],
    height = "auto",
}) {

  
  
  
  const userProfile = useSelector((state) => state.ui.userProfile);
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [allUserContacts, setAllUserContacts] = useState([]);
  const [allLatLngInSchedule, setAllLatLngInSchedule] = useState([]);
  const [allNoDuplicates, setAllNoDuplicates] = useState([]);
  const [allNoDuplicatesWithOrder, setAllNoDuplicatesWithOrder] = useState([]);
  const [newRouteOrder, setNewRouteOrder] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const pages = Array.from(Array(totalPages).keys());


  useEffect(() => {
      // console.log(allItems)
      // console.log("scheduleDate[[]]:",scheduleDate)
      setAllUserContacts(allItems.filter(element => {
        return element.id_emploeey._id === employee._id && scheduleDate ===  element.start_date_for_emploeey.split(',')[0]
      }))
  },[allItems])


  let allLatLngInSchedule2 = [];
  let noDuplicates = [];
  useEffect(() => {
        // console.log("allUserContacts:():",allUserContacts)
        if(allUserContacts.length >= 0){

          allUserContacts.forEach((item,index) => {
            let toLat
            let toLng
            let toId
             if(item.to_id_warehouse){
              toId = item.to_id_warehouse._id
             } else if(item.to_id_clients_client) {
              if(item.to_id_clients_client.location) {
                toId = item.to_id_clients_client._id
              }
             }
             if(item.to_id_warehouse){
              toLat = item.to_id_warehouse.location.lat
             } else if(item.to_id_clients_client) {
              if(item.to_id_clients_client.location) {
              toLat = item.to_id_clients_client.location.lat
              }
             }
             if(item.to_id_warehouse){
              toLng = item.to_id_warehouse.location.lng
             } else if(item.to_id_clients_client) {
              if(item.to_id_clients_client.location) {
                toLng = item.to_id_clients_client.location.lng
              }
             }

             allLatLngInSchedule2.push({
               id: item._id,
               packsNum: item.packs_pallet,
               from: [
                 item.from_id_warehouse ? item.from_id_warehouse.location.lat
                 : item.from_id_clients_client.location.lat,
                 item.from_id_warehouse ? item.from_id_warehouse.location.lng
                 : item.from_id_clients_client.location.lng,
                 item.from_id_warehouse ? item.from_id_warehouse.address
                 : item.from_id_clients_client.address,
                 item.from_id_warehouse ? item.from_id_warehouse._id
                 : item.from_id_clients_client._id],
                 to: [ toLat, toLng,
                  item.to_id_warehouse ? item.to_id_warehouse.address
                  : item.to_id_clients_client.address, toId]
                })
              })

              let orderCount = 0;
              allLatLngInSchedule2.forEach((elem, index) => {
                const existMoreThenOne_from = allLatLngInSchedule2.filter(e => e.from[3] === elem.from[3])
                console.log("existMoreThenOne_from",existMoreThenOne_from)
                const existMoreThenOne_to = allLatLngInSchedule2.filter(e => e.to[3] === elem.to[3])
                let from_firstName;
                let to_firstName;
                const from_firstName_find = allItems.filter(e => e._id === elem.id)
                if(from_firstName_find[0].from_id_warehouse){
                  from_firstName = from_firstName_find[0].from_id_warehouse.first_name
                }
                if(from_firstName_find[0].from_id_clients_client){
                  from_firstName = from_firstName_find[0].from_id_clients_client.first_name
                }

                const to_firstName_find = allItems.filter(e => e._id === elem.id)
                if(to_firstName_find[0].to_id_warehouse){
                  to_firstName = to_firstName_find[0].to_id_warehouse.first_name
                }
                if(to_firstName_find[0].to_id_clients_client){
                  to_firstName = to_firstName_find[0].to_id_clients_client.first_name
                }


                if(existMoreThenOne_from.length > 1){
                  let idsAndPacks_from = []
                  let sum_from = 0
                  existMoreThenOne_from.forEach((single,ind) => {

                    const to_firstName_find = allItems.filter(e => e._id === single.id)
                    if(to_firstName_find[0].to_id_warehouse){
                      to_firstName = to_firstName_find[0].to_id_warehouse.first_name
                    }
                    if(to_firstName_find[0].to_id_clients_client){
                      to_firstName = to_firstName_find[0].to_id_clients_client.first_name
                    }

                    idsAndPacks_from.push([single.id, single.packsNum, to_firstName])
                    sum_from = sum_from + parseInt(single.packsNum);
                  })
                  const existInnoDuplicates = noDuplicates.filter(e => e.from[3] === elem.from[3])
                  if(!existInnoDuplicates.length){
                    noDuplicates.push({
                      location: [elem.from[0],elem.from[1],elem.from[3],from_firstName],
                      id: [...idsAndPacks_from],
                      packsNum: sum_from,
                      from: [...elem.from],
                      to: elem.to,
                      type: 'from',
                      multiple: true,
                      orderCount
                    })
                    orderCount++
                  }                 
                }
                if(existMoreThenOne_to.length > 1){
                  let idsAndPacks_to = []
                  let sum_to = 0
                  // let multiple = true
                  existMoreThenOne_to.forEach((single,ind) => {
                    idsAndPacks_to.push([single.id, single.packsNum])
                    sum_to = sum_to + parseInt(single.packsNum);
                  })
                  const existInnoDuplicates = noDuplicates.filter(e => e.to[3] === elem.to[3])
                  if(!existInnoDuplicates.length){
                    noDuplicates.push({
                      location: [elem.from[0],elem.from[1],elem.from[3]],
                      id: [...idsAndPacks_to],
                      packsNum: sum_to,
                      from: [...elem.from],
                      to: elem.to,
                      type: 'to',
                      multiple: true,
                      orderCount
                    })
                    orderCount++
                  }
                } 
                
                
                if(existMoreThenOne_from.length > 1 && existMoreThenOne_to.length === 1) {
                    let from_firstName;
                    let to_firstName;
                    const from_firstName_find = allItems.filter(e => e._id === elem.id)
                    if(from_firstName_find[0].from_id_warehouse){
                      from_firstName = from_firstName_find[0].from_id_warehouse.first_name
                    }
                    if(from_firstName_find[0].from_id_clients_client){
                      from_firstName = from_firstName_find[0].from_id_clients_client.first_name
                    }

                    const to_firstName_find = allItems.filter(e => e._id === elem.id)
                    if(to_firstName_find[0].to_id_warehouse){
                      to_firstName = to_firstName_find[0].to_id_warehouse.first_name
                    }
                    if(to_firstName_find[0].to_id_clients_client){
                      to_firstName = to_firstName_find[0].to_id_clients_client.first_name
                    }
                  noDuplicates.push({
                    location: [elem.to[0],elem.to[1],elem.to[3],to_firstName],
                    id: [elem.id, elem.packsNum],
                    packsNum: elem.packsNum,
                    from: [...elem.from,from_firstName],
                    to: [...elem.to],
                    type: 'to',
                    multiple: false,
                    orderCount
                  })
                  orderCount++
                }



                if(existMoreThenOne_from.length === 1 && existMoreThenOne_to.length === 1) {
                  let from_firstName;
                  let to_firstName;
                const from_firstName_find = allItems.filter(e => e._id === elem.id)
                if(from_firstName_find[0].from_id_warehouse){
                  from_firstName = from_firstName_find[0].from_id_warehouse.first_name
                }
                if(from_firstName_find[0].from_id_clients_client){
                  from_firstName = from_firstName_find[0].from_id_clients_client.first_name
                }

                const to_firstName_find = allItems.filter(e => e._id === elem.id)
                if(to_firstName_find[0].to_id_warehouse){
                  to_firstName = to_firstName_find[0].to_id_warehouse.first_name
                }
                if(to_firstName_find[0].to_id_clients_client){
                  to_firstName = to_firstName_find[0].to_id_clients_client.first_name
                }
                  noDuplicates.push({
                    location: [elem.from[0],elem.from[1],elem.from[3],from_firstName],
                    id: [elem.id, elem.packsNum],
                    packsNum: elem.packsNum,
                    from: [...elem.from],
                    to: [...elem.to,to_firstName],
                    type: 'from',
                    multiple: false,
                    orderCount
                  })
                  orderCount++
                  noDuplicates.push({
                    location: [elem.to[0],elem.to[1],elem.to[3],to_firstName],
                    id: [elem.id, elem.packsNum],
                    packsNum: elem.packsNum,
                    from: [...elem.from,from_firstName],
                    to: [...elem.to],
                    type: 'to',
                    multiple: false,
                    orderCount
                  })
                  orderCount++
                }
              })




              setAllLatLngInSchedule([...allLatLngInSchedule2])
              setAllNoDuplicates([...noDuplicates.sort((a,b) => a.orderCount - b.orderCount)])
              setAllNoDuplicatesWithOrder([...noDuplicates.sort((a,b) => a.orderCount - b.orderCount)])
              // console.log("allLatLngInSchedule2<>", allLatLngInSchedule2)
              // console.log("allNoDuplicates<>", noDuplicates)
        }
      },[allUserContacts])



      const storeRouteHandler = async (id_emploeey,start_date_for_emploeey,route) => {
          // var d = new Date();
          // d.toLocaleString()
          // AllEmployee && AllEmployee.forEach((item, pos) => {
          //   if(item._id === employeeLink){
          //     console.log("item-imployee:",item.first_name)
          //     setEmployeeName('')
          //   }
          // })
        const storeRoute = async () => {
    
          const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
          try {
            await sendRequest(
              `/api/items/store_route/user/${storedDataOfUser.userId}`,
              'POST',
              JSON.stringify({
                id_emploeey,
                start_date_for_emploeey,
                route,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
              }
              );
            } catch (err) {
              console.log('ROUTE NOT STORED!!!',err)
            }
        }
        await storeRoute();
      };


  useEffect(() => {
    if(userProfile.AllRoutes && allNoDuplicates){

      if(userProfile.AllRoutes.length > 0){
      console.log("userProfile.AllRoutes::",userProfile.AllRoutes, userProfile.AllRoutes.length)
      console.log("allNoDuplicates::",allNoDuplicates)
      let NEWtempallNoDuplicates = []
      let tempallNoDuplicates = allNoDuplicates.slice()
      let numm
      tempallNoDuplicates.forEach((elem,index) => {
        console.log("elem.orderCount" , elem.orderCount)
        const orderCount = userProfile.AllRoutes.forEach((e, idx) => {
          // element.id_emploeey._id === employee._id && scheduleDate ===  element.start_date_for_emploeey.split(',')[0]
          if (e.id_emploeey === employee._id && scheduleDate ===  e.start_date_for_emploeey.split(',')[0]){
            e.route.forEach((el, idxR) => {
              if (el === elem.orderCount){
                numm = idxR
              }
            })
            NEWtempallNoDuplicates.push({...elem, orderCount: numm})
            console.log("ROUTE MATCH userProfile.AllRoutes >>",scheduleDate, ' - ', e.start_date_for_emploeey, ' || ',e.id_emploeey, ' - ', employee._id, ' || ', e.route )
          }
        })
        // console.log("ROUTE MATCH userProfile.AllRoutes >>",e)

        let counhh = 0
      })
      setAllNoDuplicatesWithOrder(NEWtempallNoDuplicates)
      console.log("NEWtempallNoDuplicates<<|>>",NEWtempallNoDuplicates)
      
    }
  }
  },[userProfile,allNoDuplicates])

  
  useEffect(() => {
    if(newRouteOrder.length > 0){
      let NEWtempallNoDuplicates = []
      let tempallNoDuplicates = allNoDuplicates.slice()
      let numm
      tempallNoDuplicates.forEach((elem,index) => {
        console.log("elem.orderCount" , elem.orderCount)
        const orderCount = newRouteOrder.forEach((e, idx) => {
          if (e === elem.orderCount){
            numm = idx
          }
        })

          NEWtempallNoDuplicates.push({...elem, orderCount: numm})
        let counhh = 0
      })
      setAllNoDuplicatesWithOrder(NEWtempallNoDuplicates)
      console.log("NEWtempallNoDuplicates>>",NEWtempallNoDuplicates)
      console.log("tempallNoDuplicates>>",tempallNoDuplicates)
      console.log("newRouteOrder CHANGED!!!",newRouteOrder)
      console.log("allUserContacts!!!",allUserContacts)

      try {
        // employee._id && scheduleDate
        // storeRouteHandler(id_manager,id_emploeey,start_date_for_emploeey,route)
        storeRouteHandler(employee._id,scheduleDate,newRouteOrder)
      } catch (error) {
        console.log("error - storeRouteHandler:", error)
      }
      
    }
  },[newRouteOrder])

      const numbersStyle = {background: '#1976d2', color: '#fff', width: '114px', height: '25px', borderRadius: '30px',textAlign: 'center', paddingTop: '3px'}

  return (
    <div>
    {allUserContacts.length > 0 &&
      <div>
            <div style={{
              textAlign: 'center',
              width: '300px',
              background: '#002244',
              fontSize: '16px',
              padding: '8px',
              color: '#fff',
              margin: '20px 0px'}}>
                {employee.first_name+' - '+scheduleDate}
                </div>
            {/* {allLatLngInSchedule && 
              allLatLngInSchedule.map(single => (
                <>
                  <div>{single.id}</div>
                  <div>{single.from[0]} - {single.from[1]} - {single.from[2]} - {single.from[3]}</div>
                  <div>{single.to[0]} - {single.to[1]} - {single.to[2]} - {single.to[3]}</div>
                  <div>{single.packsNum}</div>
                </>
              ))
            } */}
            {allNoDuplicatesWithOrder && 
              allNoDuplicatesWithOrder.sort((a, b) => a.orderCount - b.orderCount).map((single, indx) => (
                <div>
                {single.type === 'from' && single.id.length > 1 && single.multiple === true &&
                <div>
                 
                  <div style={{display: "flex"}}>
                    <div style={numbersStyle}> {indx+1}. <span style={{fontWeight: 'bold'}}> העמסה + </span>
                    </div>
                    <div>
                      <a
                      href={`https://waze.com/ul?ll=${single.location[0]},${single.location[1]}&z=10`}
                      target="_blank" rel="noopener noreferrer"
                      ><img src={waze} style={{width: "21px", height: "21px", marginRight: "8px", marginTop: "2px"}} alt="waze" />
                      </a>
                    </div>
                  </div>
                  <div>העמסה מ {single.location[3]} | <span>ל {single.id.length} נקודות | </span><span>סה"כ {single.packsNum} משטחים</span></div>
                  <div>עבור:</div>
                  {single.id.map((ids,idx) => (
                    <div>
                      {ids.length > 2 &&
                      <>
                        <div>#{idx+1} {ids[2]} - {ids[1]} משטח</div>
                      </>
                      }
                    </div>
                  ))}
                </div>
                }
                {single.type === 'from' &&  single.multiple === false &&
                <div>
                  <div style={{display: "flex"}}>
                  <div style={numbersStyle}> {indx+1}. <span style={{fontWeight: 'bold'}}> העמסה </span>
                  </div>
                    <div>
                      <a
                      href={`https://waze.com/ul?ll=${single.location[0]},${single.location[1]}&z=10`}
                      target="_blank" rel="noopener noreferrer"
                      ><img src={waze} style={{width: "21px", height: "21px", marginRight: "8px", marginTop: "2px"}} alt="waze" />
                      </a>
                    </div>
                  </div>
                  <div>העמסה מ {single.location[3]} | <span>עבור {single.to[4]}</span> | <span>סה"כ {single.packsNum} משטחים</span></div>
                </div>
                }
                {single.type === 'to' &&  single.multiple === false &&
                <div>
                  
                  <div style={{display: "flex"}}>
                  <div style={numbersStyle}> {indx+1}. <span style={{fontWeight: 'bold'}}> פריקה </span>
                  </div>
                    <div>
                      <a
                      href={`https://waze.com/ul?ll=${single.location[0]},${single.location[1]}&z=10`}
                      target="_blank" rel="noopener noreferrer"
                      ><img src={waze} style={{width: "21px", height: "21px", marginRight: "8px", marginTop: "2px"}} alt="waze" />
                      </a>
                    </div>
                  </div>
                  <div>פריקה ל {single.location[3]} | <span>עבור {single.from[4]}</span> | <span>סה"כ {single.packsNum} משטחים</span></div>
                </div>
                }
                
                </div>
              ))
            }
            <div>
              <ItemsMaps 
                allNoDuplicates={allNoDuplicates}
                setNewRouteOrder={setNewRouteOrder}
                submitID={allNoDuplicates && allNoDuplicates[0]} />
            </div>
            <div>
                <table
                  cellSpacing="0"
                  style={{ borderCollapse: 'collapse', width: "100%", height: height, padding: "5px 10px", marginTop: '15px' }}
                >
                  <thead style={{ backgroundColor: "#343434", color: "white" }}>
                    <tr>
                      {colNames.map((headerItem, index) => (
                        <th key={index}>{headerItem.toUpperCase()}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                {allUserContacts.map((obj, index) => {
                  return employee ?
                  <>
                    <ItemSingleSchedule
                    firstItem={index}
                    obj={obj}
                    employeeTitleName={employee.key}
                    index={index}
                    colNames={colNames}
                    allSubItems={allSubItems}
                    />
                  </> : null
                })}
                </tbody>
              </table>
        </div>
    </div>
  }
    </div>
  )
}

export default ItemsScheduleByEmployee