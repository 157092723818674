import React, { useState, useCallback, useRef, Children, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';



import { cartActions } from '../store/places-slice';
import { uiActions } from '../store/ui-slice';
import FormikUiAddContact from '../shared/components/FormikElements/FormikUiAddContact';
import AddContactCat from '../shared/components/FormikElements/AddContactCat';
import AddContactTag from '../shared/components/FormikElements/AddContactTag';
import ItemsTableData from './ItemsTableData';
import AddPackagesForTransportation from '../shared/components/miu/AddSomething';

var map;

var mapCats;
const Items = (props) => {



  // const dispatch = useDispatch();
  // cartItems = useSelector((state) => state.cart.items);
  // mapCats = useSelector((state) => state.cart.placesCats);
  
  // const markers2 = useSelector((state) => state.cart.markers);
  // const selectedAddressD_RD = useSelector((state) => state.cart.selectedAddress_d);
  // const selectedAddressO_RD = useSelector((state) => state.cart.selectedAddress_o);
  // const selectedAddressRD = useSelector((state) => state.cart.selectedAddress);
  // const selectedLngRD = useSelector((state) => state.cart.selectedLng);
  // const selectedLatRD = useSelector((state) => state.cart.selectedLat);
  // const changedRD = useSelector((state) => state.cart.changed);
  // const showCart = useSelector((state) => state.ui.cartIsVisible);

  // const [response, setResponse] = useState(null);
  // const [show1, setShow1] = React.useState(0);
  // const [formPurpes, setFormPurpes] = React.useState('d');
  let zIndex0, zIndex1, zIndex2, zIndex3, zIndex4, zIndex5, zIndex6;
  // show1 === 0 ? zIndex0 = '99' : zIndex0 = '0';
  // show1 === 1 ? zIndex1 = '99' : zIndex1 = '0';
  // show1 === 2 ? zIndex2 = '99' : zIndex2 = '0';
  // show1 === 3 ? zIndex3 = '99' : zIndex3 = '0';
  // show1 === 4 ? zIndex4 = '99' : zIndex4 = '0';
  // show1 === 5 ? zIndex5 = '99' : zIndex5 = '0';
  // show1 === 6 ? zIndex6 = '99' : zIndex6 = '0';
  // const [value3, setValue3] = React.useState(0);

  // const handleChange3 = (event, newValue) => {
  //   setValue3(newValue);
  // };
  // const handleChange2 = (event, newValue) => {
  //   setValue2(newValue);
  // };


  return (
    <div>

      {/* <div style={{width: '100%'}}> */}
          {/* <div value={value3} onChange={handleChange3} aria-label="icon label tabs example"> */}
            {/* <div><AddLocationAltOutlinedIcon /></div>
            <div><AddLocationAltOutlinedIcon /></div> */}
            {/* <div icon={<AddLocationAltOutlinedIcon />} label="הוספת פריטים לשינוע " onClick={() => {setShow1(6); setFormPurpes('d');}} />
            <div icon={<ListIcon />} label="הוספת קטגוריה" onClick={() => {setShow1(3)}}/>
            <div icon={<AccountTreeIcon />} label="הוספת תגית" onClick={() => {setShow1(5)}}/> */}
          {/* </div> */}
      {/* </div> */}

      
    
      
      <div id="container"  style={{width: '100%', height: 'calc(100vh - 70px)', display: "flex", justifyContent: 'center'}}>
      {/* <div class="absulute_bg" style={{position: 'absolute', background: '#fff', width: '100%', height: '100vh', zIndex: '98'}}></div> */}
      {/* <div class="absulute_bg" ></div> */}
      <ItemsTableData zIndex={zIndex0} />

      {/* <AddPackagesForTransportation zIndex={zIndex6}
        mapCatsDropDown={mapCats}
        selectedAddress={selectedAddressRD} 
        selectedLat={selectedLatRD} 
        selectedLng={selectedLngRD}
      /> */}
      {/* <FormikUiAddContact
        formPurpesTitle={formPurpes}
        zIndex={zIndex6}
        mapCatsDropDown={mapCats}
        selectedAddressD={selectedAddressD_RD}
        selectedAddressO={selectedAddressO_RD}
      />  */}
      {/* <AddContactCat zIndex={zIndex3}
          selectedAddress={selectedAddressRD} 
          selectedLat={selectedLatRD} 
          selectedLng={selectedLngRD}
        />
      <AddContactTag zIndex={zIndex5}
          selectedAddress={selectedAddressRD} 
          selectedLat={selectedLatRD} 
          selectedLng={selectedLngRD}
        /> */}

      </div>
      </div>
  );
};
export default Items;