import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import StoreSlider from '../components/StoreSlider';
import AllProducts from '../pages/AllProducts';
import StoreCatList from '../components/StoreCatList';
const Store = () => {

  //let routes;

  const cat_id = useParams().cat_id;

  return (
      <Router>
        <StoreCatList />

        <Switch>
          <Route path="/store" exact>
            {/* <StoreSlider /> */}
            <AllProducts />
          </Route>
          <Route path="/store/cat/:cat_id" exact>
            <AllProducts/>
          </Route>
          <Route path="/store/sub/:sub_id" exact>
            <AllProducts/>
          </Route>
          <Route path="/store/sub_sub/:sub_sub_id" exact>
            <AllProducts/>
          </Route>
          <Redirect to="/store" />
        </Switch>

      </Router>
  );
};

export default Store;
