import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18next from "i18next";


export const useLang = () => {
  const { i18n, t } = useTranslation();

  const [currentLang, setCurrentLang] = useState();

  useEffect(() => {
    setCurrentLang(i18next.language) 
  }, [i18next.language]);
// 
function ltrOrRtl(ltr, rtl) {
  if (currentLang === 'he') {
    return rtl
  } else {
    return ltr
  }
}
function trnslt(string) {
    return t(string)
}

  return { currentLang, ltrOrRtl, trnslt };
};