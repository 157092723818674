import { AddCircleOutlineOutlined, SubjectOutlined } from '@mui/icons-material';
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, {useContext, useEffect} from 'react';
import { NavLink , useLocation} from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ChatIcon from '@mui/icons-material/Chat';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import LanguageIcon from '@mui/icons-material/Language';
// import { usePWAInstallPrompt } from '../../..';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AuthContext } from '../../context/auth-context';
import { uiActions } from '../../../store/ui-slice';
import { useSelector, useDispatch } from 'react-redux';
import InstallPWA from '../../../Pwa'
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useLang } from '../../../shared/hooks/lang-hook';


const drawerWidth = '100%';


const stylePasegNav = {
    containerButton: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: '6px 9px 6px 9px',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '6px 0px 6px 0px'
    },
    containerTopButton: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: '3px 9px',
        alignItems: 'center',
        // flexDirection: 'column',
        borderRadius: '6px 0px 6px 0px'
        
    },
    manuButtonStyle: {    
        border: '1px solid #ddd',
        margin: '6px',
        // background: '#cfcfcf',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px'
    }
}


const useStyles = makeStyles({
    drawer: {
        // background: '#f9f9f9',
        width: drawerWidth
    },
    drawerPaper: {
        width: drawerWidth,
        maxWidth: '248px',
        borderRadius: '7px',
        margin: '7px',
        left: 0,
        backgroundColor: '#e1e1e1'
    },
    active: { backgroundColor: '#e1e1e1' },
    listItemText: { fontSize: '12px' }//Insert your required size
 
})


const DrawerMui = (props) => {

    const { i18n, t } = useTranslation();
    const { currentLang, ltrOrRtl, trnslt } = useLang();

    useEffect(() => {
        if (localStorage.getItem("i18nextLng")){
            if (localStorage.getItem("i18nextLng").length > 2) { 
                i18next.changeLanguage("en");
            }
         }
	}, []);

	const handleLanguageChange = (e) => {
		i18n.changeLanguage(e.target.value);
	};

    
    // Download PWA
    // const [deferredPrompt, setPrompt] = usePWAInstallPrompt()
    // const installPWA = async () => {
    //     if (deferredPrompt) {
    //         deferredPrompt.prompt();
    //         const choiceResult = await deferredPrompt.userChoice;
    //         if (choiceResult.outcome) {
    //             console.log('user accepted the A2HD prompt')
    //         }
    //         else {
    //             console.log('User dimissed the A2HS prompt');
    //         }
    //     }
    // }

    const shareApp = () => {
        navigator.share({
            title: "Drive And Ship",
            text: "תכנון מסלולים וניהול עבודה למשלוחנים ומובילים",
            url: '/',
        })
            .then(() => {
            
            console.log("The share was successful");    
        }).catch(error => {
            console.log("THre was an error sharing the PWA", error);
        })
    }

    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.ui.userProfile);

    const auth = useContext(AuthContext);
    const locationPath = useLocation();

    const classes = useStyles();
    const menuItems = [
        {
            text: trnslt("My_Route"),
            icon: <EmojiTransportationIcon color={locationPath.pathname === '/items/scheduledtoday' ? 'fff' : "primary"} />,
            path: '/items/scheduledtoday'
        },
        
        {
            text: 'תמונות',
            icon: <PermMediaIcon color={locationPath.pathname === '/images/all' ? 'fff' : "primary"} />,
            path: '/images/all'
        },
        {
            text: 'לוח מודעות',
            icon: <ChatIcon color={locationPath.pathname === `/messenger` ? 'fff' : "primary"} />,
            path: `/messenger`
        },
        {
            text: 'קופונים',
            icon: <CardGiftcardIcon color={locationPath.pathname === '/coupons' ? 'fff' : "primary"} />,
            path: '/coupons/all'
        },
    ]

    return (
        <div>
            <div>
                <div style={{display: 'flex', maxWidth: '100%'}}>

                <div style={{display: 'flex', backgroundColor: '#f3f3f3', padding: '1px', width: '100%',
                flexDirection: props.place === 'menu' ? 'column' : props.place === 'top' && 'row'}}>



                        {/* <NavLink to={'/home'} style={{ textDecoration: 'none', backgroundColor: '#fff' }}>
                            <ListItem
                                onClick={props.closeOnClickNav}
                                button key={'home'}
                                className={classes.activee}
                                style={locationPath.pathname === '/home' ? { backgroundColor: '#d0d0d0', color: "#fff", textDecoration: 'none', fontSize: '12px' } : { fontSize: '12px' }}
                            >
                                <ListItemIcon>{<SubjectOutlined color="primary" />}</ListItemIcon>
                                <ListItemText primary={trnslt("Home")}
                                    classes={{ primary: classes.listItemText }}
                                    // classes={{paper: classes.drawerPaper}}
/>
                            </ListItem>
                        </NavLink> */}
                        {props.place === 'top' && locationPath.pathname !== '/home' && locationPath.pathname !== '/items/scheduledtoday' &&
                            <div style={{display: 'flex', alignItems: 'center', background: 'rgb(101 101 101)', width: '100%'}}>
                                <NavLink to='/items/scheduledtoday' style={{textDecoration: 'none'}}>
                                    <div style={{
                                        borderLeft: '1px solid #fff',
                                        padding: '0px 4px',
                                        marginLeft: '4px'
                                    }}>
                                        <ChevronRightIcon style={{color: "#fff"}} />
                                    </div>
                                </NavLink>
                                {props.place === 'top' && menuItems.map(item => (
                                    <> 
                                        {locationPath.pathname === item.path &&
                                                <NavLink to={item.path === 'close' ? '' : item.path} style={{textDecoration: 'none'}}>
                                                        <div 
                                                    onClick={props.closeOnClickNav}
                                                    button key={item.text}
                                                    className={classes.activee} 
                                                    style={
                                                        {
                                                        color: "#fff", textDecoration: 'none',
                                                        ...stylePasegNav.containerTopButton,
                                                        // backgroundColor: '#0f3d6b'
                                                        }
                                                        } 
                                                    >
                                                            <div style={{width: '33px'}}>
                                                                {item.icon}
                                                            </div>
                                                            <div style={{ width: '88px', textAlign: 'center', fontSize: '16px'}}
                                                            >{item.text}</div>
                                                    </div>
                                                </NavLink>
                                        }
                                    </>
                                ))}
                            </div>
                        }
                        {props.place === 'menu' && menuItems.map(item => (
                            <> 
                                {
                                <NavLink to={item.path === 'close' ? '' : item.path} style={{textDecoration: 'none',backgroundColor: '#fff', flexGrow: 1}}>
                                        <div 
                                    onClick={props.closeOnClickNav}
                                    button key={item.text}
                                    className={classes.activee} 
                                    style={
                                        locationPath.pathname === item.path ? {backgroundColor: '#0f3d6b', color: "#fff", textDecoration: 'none', ...stylePasegNav.containerButton, ...stylePasegNav.manuButtonStyle, backgroundColor: '#0f3d6b'} : {...stylePasegNav.containerButton, ...stylePasegNav.manuButtonStyle}
                                            // :  {...stylePasegNav.containerButton}
                                } 
                                    >
                                            <div style={{width: '33px'}}>
                                                {item.icon}
                                            </div>
                                            <div style={{ width: '63px', textAlign: 'center', fontSize: '12px'}}
                                            >{item.text}</div>
                                    </div>
                                </NavLink>
                                }
                            </>
                        ))}
                        {/* {menuItems.map(item => (
                        <NavLink to={item.path === 'close' ? '' : item.path} style={{textDecoration: 'none',backgroundColor: '#fff'}}>
                                <ListItem 
                            onClick={props.closeOnClickNav}
                            button key={item.text}
                            className={classes.activee} 
                            style={locationPath.pathname === item.path ? {backgroundColor: '#d0d0d0', color: "#fff", textDecoration: 'none'} : null} 
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText
                                        primary={item.text}
                                        classes={{ primary: classes.listItemText }}
                                    />
                            </ListItem>
                        </NavLink>
                        ))} */}
                        



                       
                </div>
                {/* <div>
                    <Typography variant="h5" >home page</Typography>
                </div> */}
            </div>
            </div>

        </div>
    )
};

export default DrawerMui;