import { AddCircleOutlineOutlined, SubjectOutlined } from '@mui/icons-material';
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState, useEffect } from 'react'
import { NavLink , useLocation} from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import LanguageIcon from '@mui/icons-material/Language';
// import { usePWAInstallPrompt } from '../../..';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AuthContext } from '../../context/auth-context';
import { uiActions } from '../../../store/ui-slice';
import { useSelector, useDispatch } from 'react-redux';
import InstallPWA from '../../../Pwa'
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useLang } from '../../../shared/hooks/lang-hook';
import Pages from './Pages';
import EditProfile from '../FormikElements/EditProfile';
import AddCoupon from '../../../shared/components/FormikElements/AddCoupon';

import CreateTagRoute from '../../../items/componnents/CreateTagRoute'
import Modal from '../UIElements/Modal';
import { Avatar, Button, Grid } from '@mui/material';


const drawerWidth = '100%';

const useStyles = makeStyles({
    drawer: {
        // background: '#f9f9f9',
        width: drawerWidth
    },
    drawerPaper: {
        width: drawerWidth,
        maxWidth: '248px',
        borderRadius: '7px',
        margin: '7px',
        left: 0,
        backgroundColor: '#e1e1e1'
    },
    active: {backgroundColor: '#e1e1e1'}
})


const DrawerMui = (props) => {

    const { i18n, t } = useTranslation();
    const { currentLang, ltrOrRtl, trnslt } = useLang();
    const [createContactName, setCreateContactName] = useState(false);
    const [chooseADDBox, setChooseADDBox] = useState(false);


    useEffect(() => {
        if (localStorage.getItem("i18nextLng")){
            if (localStorage.getItem("i18nextLng").length > 2) { 
                i18next.changeLanguage("en");
            }
         }
	}, []);

	const handleLanguageChange = (e) => {
		i18n.changeLanguage(e.target.value);
	};

    
    // Download PWA
    // const [deferredPrompt, setPrompt] = usePWAInstallPrompt()
    // const installPWA = async () => {
    //     if (deferredPrompt) {
    //         deferredPrompt.prompt();
    //         const choiceResult = await deferredPrompt.userChoice;
    //         if (choiceResult.outcome) {
    //             console.log('user accepted the A2HD prompt')
    //         }
    //         else {
    //             console.log('User dimissed the A2HS prompt');
    //         }
    //     }
    // }

    const shareApp = () => {
        navigator.share({
            title: "Drive And Ship",
            text: "תכנון מסלולים וניהול עבודה למשלוחנים ומובילים",
            url: '/',
        })
            .then(() => {
            
            console.log("The share was successful");    
        }).catch(error => {
            console.log("THre was an error sharing the PWA", error);
        })
    }

    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.ui.userProfile);

    const auth = useContext(AuthContext);
    const locationPath = useLocation();

    const classes = useStyles();
    const menuItems = [
        // {
        //     text: 'עמוד ראשי',
        //     icon: <SubjectOutlined color="primary" />,
        //     path: '/'
        // },
        // {
        //     text: trnslt("My_Route"),
        //     icon: <EmojiTransportationIcon color="primary" />,
        //     path: 'items/scheduledtoday'
        // },
        
        // {
        //     text: 'מדריך - קובץ גוגל דוק',
        //     icon: <AddCircleOutlineOutlined color="primary" />,
        //     path: 'https://docs.google.com/document/d/1BFvTMQ9r7D-3ioSjDATMYPuvrVEHwddlCabyz0QvBMc/edit?usp=sharing'
        // },
        // {
        //     text: 'פריטים לשינוע',
        //     icon: <AddCircleOutlineOutlined color="primary" />,
        //     path: `/items/all`
        // },
        // {
        //     text: 'מיקומים שלי',
        //     icon: <AddCircleOutlineOutlined color="primary" />,
        //     path: `/${auth.userId}/places`
        // },
        // {
        //     text: 'צאטים',
        //     icon: <AddCircleOutlineOutlined color="primary" />,
        //     path: `/messenger`
        // },
        {
            text: trnslt("My Contacts"),
            icon: <MenuBookOutlinedIcon color="primary" />,
            path: `/contacts/all`
        },
        // {
        //     text: 'חנות',
        //     icon: <AddCircleOutlineOutlined color="primary" />,
        //     path: `/store`
        // },
    ]

    return (
        <div>
            {createContactName &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                show={createContactName}
                onCancel={() => {
                    setCreateContactName(false) 
                    props.closeOnClickNav()
                    }
                }
                header={'פרטי העסק שלי'}
                closeButton={<Button 
                    variant='contained' 
                    onClick={() => {
                        setCreateContactName(false)
                        props.closeOnClickNav()
                    }}
                >סגור</Button>}
                >
                <Grid item xs={12}>
                    <EditProfile 
                        onCloseEditProfileWindow={
                            () => setCreateContactName(false)
                        }
                        whatToEdit='bizDetails'
                        // onCloseWindow={props.closeOnClickNav}
                        // headerTitle="פרטי העסק שלי"
                    />
                </Grid>
                </Modal>
                </div>
                }
            <Drawer 
                className={classes.drawer} 
                variant="permanent" 
                anchor="right"
                classes={{paper: classes.drawerPaper}}
            >
                <div style={{padding: '10px', maxWidth: '245px'}}>
                <div>
                    <IconButton onClick={props.closeOnClickNav}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List style={{backgroundColor: '#f3f3f3', padding: '10px'}}>
                        <li className='header_textXS' style={{marginBottom: '10px'}}>
                            { auth.isLoggedIn &&
                                <div style={{
                                    fontSize: '18px',
                                }} className='header_textXS' > {trnslt("welcome")} {userProfile.name}</div>
                            }
                        </li>
                        {/* {auth.isLoggedIn && ( */}
                            {/* <li>
                                <Avatar onClick={() => {
                                            setCreateContactName(true)
                                            // openMapHandler()
                                            }} style={{background: 'white', boxShadow: 'rgb(255 255 255 / 36%) 1px 2px 5px 1px'}} alt={userProfile.name} src={userProfile.imageOne} />
                                        
                            </li> */}
                            {/* ) */}
                        {/* // } */}
                        
                        
                        <Pages  
                            place='menu' 
                            closeOnClickNav={props.closeOnClickNav}>
                        </Pages>
                        {/* <NavLink to={'/home'} style={{ textDecoration: 'none', backgroundColor: '#fff' }}>
                            <ListItem
                                onClick={props.closeOnClickNav}
                                button key={'home'}
                                className={classes.activee}
                                style={locationPath.pathname === '/home' ? { backgroundColor: '#d0d0d0', color: "#fff", textDecoration: 'none' } : null}
                            >
                                <ListItemIcon>{<SubjectOutlined color="primary" />}</ListItemIcon>
                                <ListItemText primary={trnslt("Home")} />
                            </ListItem>
                        </NavLink> */}
                        {menuItems.map(item => (
                        <NavLink to={item.path === 'close' ? '' : item.path} style={{textDecoration: 'none',backgroundColor: '#fff'}}>
                            <ListItem 
                            onClick={props.closeOnClickNav}
                            button key={item.text}
                            className={classes.activee} 
                            style={locationPath.pathname === item.path ? {backgroundColor: '#d0d0d0', color: "#fff", textDecoration: 'none'} : null} 
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        </NavLink>
                        ))}
                        
                        {userProfile._id === '627538581659696d4c7ff55f' &&
                            <NavLink to={'/allusers'} style={{ textDecoration: 'none', backgroundColor: '#fff' }}>
                                <ListItem
                                    onClick={props.closeOnClickNav}
                                    button key={'users'}
                                    className={classes.activee}
                                    style={locationPath.pathname === '/allusers' ? { backgroundColor: '#d0d0d0', color: "#fff", textDecoration: 'none' } : null}
                                >
                                    <ListItemIcon>{<SubjectOutlined color="primary" />}</ListItemIcon>
                                    <ListItemText primary={trnslt("Professionals")} />
                                </ListItem>
                            </NavLink>
                        }
                        {userProfile._id ===  '649d43c5389ae51478ae42ae' || userProfile._id === '627538581659696d4c7ff55f' ?
                            <NavLink to={'/xlsx'} style={{ textDecoration: 'none', backgroundColor: '#fff' }}>
                                <ListItem
                                    onClick={props.closeOnClickNav}
                                    button key={'xlsx'}
                                    className={classes.activee}
                                    style={locationPath.pathname === '/xlsx' ? { backgroundColor: '#d0d0d0', color: "#fff", textDecoration: 'none' } : null}
                                >
                                    <ListItemIcon>{<SubjectOutlined color="primary" />}</ListItemIcon>
                                    <ListItemText primary={trnslt("Add Excel")} />
                                </ListItem>
                            </NavLink> : null
                       }
                        <li style={{ textDecoration: 'none', backgroundColor: '#fff' }}>
                            <ListItem
                                onClick={() => {
                                    // props.closeOnClickNav()
                                    setCreateContactName(true)
                                }}
                                button key={'home'}
                                className={classes.activee}
                                style={locationPath.pathname === '/ffffffffffff' ? { backgroundColor: '#d0d0d0', color: "#fff", textDecoration: 'none' } : null}
                            >
                                <ListItemIcon>{<SubjectOutlined color="primary" />}</ListItemIcon>
                                <ListItemText primary={trnslt("פרטי העסק שלי")} />
                            </ListItem>
                        </li>
                        {chooseADDBox &&
                            <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                            <Modal
                            show={chooseADDBox}
                            onCancel={() => {
                                setChooseADDBox(false) 
                                props.closeOnClickNav()
                                }
                            }
                            header={'הוספת קופון'}
                            closeButton={<Button 
                                variant='contained' 
                                onClick={() => {
                                    setChooseADDBox(false)
                                    props.closeOnClickNav()
                                }}
                            >סגור</Button>}
                            >
                                <Grid item xs={12}>
                                    <AddCoupon
                                                typeOfForm={['location_tag', 'מיקומים לתווית - לחישוב מסלול', 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי שם מסלול לצורך חישוב מסלול בלבד', ['location_d', 'id_tag_route']]}
                                                submitTextButton='הוסף קופון '
                                    />
                                </Grid>
                            </Modal>
                            </div>
                        }
                        {/* {chooseADDBox &&
                            <>
                                <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', alignItems: 'center'}}>
                                    <div style={{fontSize: '20px', color: '#989898'}}>{trnslt("add") + trnslt("address")} </div>
                                    <CreateTagRoute
                                        formPurpes='add_coupon'
                                        submitTextButton={trnslt("add address to route")}
                                        setChooseADDBox={()=> setChooseADDBox(!chooseADDBox)}
                                        isTagarouteExist={false}
                                    />
                                </div>
                            </>
                        } */}
                        <li style={{ textDecoration: 'none', backgroundColor: '#fff' }}>
                            <ListItem
                                onClick={() => {
                                    // props.closeOnClickNav()
                                    setChooseADDBox(true)
                                }}
                                button key={'home'}
                                className={classes.activee}
                                style={locationPath.pathname === '/ffffffffffff' ? { backgroundColor: '#d0d0d0', color: "#fff", textDecoration: 'none' } : null}
                            >
                                <ListItemIcon>{<SubjectOutlined color="primary" />}</ListItemIcon>
                                <ListItemText primary={trnslt("הוספת קופון")} />
                            </ListItem>
                        </li>


                           <button
                            onClick={shareApp}
                            style={{width: '100%', margin: '30px 0px 15px 0px', height: '45px', border: '1px solid #cdcdcd', background: '#1976d2', color: '#fff', fontSize: '16px'}}
                        >{trnslt("Share App")}</button>
                        

                        <li className="nav-item">
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                background: '#2f2f2f',
                                marginTop: '12px',
                                padding: '6px 0px 6px 0px',
                            }}
                                onClick={() => console.log(i18next.language)}
                            >
                                <div style={{color: '#fff', paddingTop: '2px'}}>
                                    <LanguageIcon sx={{fontSize: 26}} />
                                </div>
                            
                                <div>
                                    <select
                                        style={{
                                            width: '147px',
                                            height: '32px',
                                            background: '#2f2f2f',
                                            color: '#fff',
                                            border: '0px',
                                            fontSize: '16px',
                                            textAlign: 'center',
                                        }}
                                        className="nav-link bg-dark border-0 ml-1 mr-2"
                                        value={localStorage.getItem("i18nextLng")}
                                        onChange={handleLanguageChange}
                                    >
                                        <option value="en">English</option>
                                        {/* <option value="fr">Français</option> */}
                                        {/* <option value="es">Español</option> */}
                                        <option value="he">עברית</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                    {auth.isLoggedIn && (
                        
                        <li className='header_textXS'>
                        <button onClick={() => 
                            {
                            auth.logout()
                            localStorage.removeItem('userProfile')
                            dispatch(
                                uiActions.setUserProfile({name: trnslt("Hi There")})
                            )
                            props.closeOnClickNav()
                            }
                        }
                        style={{
                            width: '100%',
                            height: '31px',
                            fontSize: '18px',
                            color: '#1976d2',
                            border: '1px solid #e92929',
                            background: '#ffffff',
                            borderRadius: '30px',
                            marginTop: '30px'
                        }}
                        >{trnslt("Log Out")}</button>
                            </li>
                            
                        )}
                     
                        {/* <li
                            className='header_textXS' style={{ marginBottom: '10px' }}
                            
                        >
                        <button onClick={installPWA}>לחץ להורדת אפליקציה</button>    
                        </li> */}
                        {/* <li
                            style={{
                            width: '100%',
                            height: '31px',
                            fontSize: '18px',
                            color: '#1976d2',
                            border: '1px solid #e92929',
                            background: '#ffffff',
                            borderRadius: '30px',
                            marginTop: '30px'
                            }}
                        > */}
                            {/* <InstallPWA obj='התקן אפליקציה' /> */}
                        {/* </li> */}
                     
                </List>
                {/* <div>
                    <Typography variant="h5" >home page</Typography>
                </div> */}
            </div>
            </Drawer>

        </div>
    )
};

export default DrawerMui;