import React, { useState , useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
//import Axios from "axios";
// import $ from 'jquery';



const StoreCatList3 = (props) => {

    return (

          <React.Fragment>       
            {props.all_cats2.map(place3 => ( 
              place3.grandparent_cat_id ==  props.current_cat2 && 
              <li>
                <NavLink to={`/store/sub_sub/${place3.id}`} exact className="nav-link align-middle">{place3.name}</NavLink>
                {/* <a href="?sub_sub=1264">{place3.name}</a> */}
              </li>      
            ))}
         </React.Fragment> 


    )
    
};


export default StoreCatList3;
