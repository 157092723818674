import { uiActions } from './ui-slice';
import { cartActions } from './places-slice';


export const fetchCatsMapData = () => {

  return async (dispatch) => {

    // const fetchPlaces = async () => {
    //   try {
    //     const responseData = await sendRequest(
    //       `http://localhost:5000/api/places/user/${userId}`
    //     );
    //     setLoadedPlaces(responseData.places);
    //   } catch (err) {}
    // };
    // fetchPlaces();

    const fetchData = async () => {
      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      const response = await fetch(
        `/api/places/cat/user/${storedDataOfUser.userId}`
      );
      if (!response.ok) {
        throw new Error('Could not fetch cart data!');
      }
      const data = await response.json();
      return data;
    };

    try {
      dispatch(
        uiActions.showNotification({
          status: 'success',
          title: 'Success!',
          message: 'Sent cart data successfully!',
        })
      );
      const catsMapData = await fetchData();
      dispatch(
        cartActions.replacePlacesCats({
          placesCats: catsMapData.places || [],
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: 'error',
          title: 'Error!',
          message: 'Fetching cart data failed!',
        })
      );
    }
  };
};
export const fetchCatsMapData3 = () => {

  return async (dispatch) => {

    const fetchData = async () => {
      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      const response = await fetch(
        `/api/places/cat/user/${storedDataOfUser.userId}`
      );
      if (!response.ok) {
        throw new Error('Could not fetch cart data!');
      }
      const data = await response.json();
      return data;
    };

    try {
      dispatch(
        uiActions.showNotification({
          status: 'success',
          title: 'Success!',
          message: 'Sent cart data successfully!',
        })
      );
      const catsMapData = await fetchData();
      dispatch(
        cartActions.replacePlacesCats({
          placesCats: catsMapData.places || [],
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: 'error',
          title: 'Error!',
          message: 'Fetching cart data failed!',
        })
      );
    }
  };
};


export const fetchCartData = () => {

  return async (dispatch) => {

    // const fetchPlaces = async () => {
    //   try {
    //     const responseData = await sendRequest(
    //       `http://localhost:5000/api/places/user/${userId}`
    //     );
    //     setLoadedPlaces(responseData.places);
    //   } catch (err) {}
    // };
    // fetchPlaces();

    const fetchData = async () => {
      const storedDataOfUser = JSON.parse(localStorage.getItem('userData'));
      const response = await fetch(
        `/api/places/user/${storedDataOfUser.userId}`
      );
      if (!response.ok) {
        throw new Error('Could not fetch cart data!');
      }
      const data = await response.json();
      return data;
    };

    try {
      dispatch(
        uiActions.showNotification({
          status: 'success',
          title: 'Success!',
          message: 'Sent cart data successfully!',
        })
      );
      const cartData = await fetchData();
      console.log('cartData:', cartData)
      dispatch(
        cartActions.replaceCart({
          items: cartData.places || [],
          totalQuantity: cartData.places.length,
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: 'error',
          title: 'Error!',
          message: 'Fetching cart data failed!',
        })
      );
    }
  };
};

