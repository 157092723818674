import React, {useContext, useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from "axios";
import FormikControl from './FormikControl'
import { useHistory } from 'react-router'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import hebrew from 'date-fns/locale/he';
import { format } from 'date-fns'

import { uiActions } from '../../../store/ui-slice';
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';
import { Avatar, Button, Grid, IconButton, ListItem, ListItemAvatar, Tab, Tabs, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ListIcon from '@mui/icons-material/List';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import AddMedia from "../addMedia/AddMedia";


// import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
// import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import SearchIcon from '@mui/icons-material/Search';
import FormikUiAddContact from './FormikUiAddContact';
import SearchContacts from '../../../contacts/searchContacts';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../UIElements/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemText from '@mui/material/ListItemText';





// import './UiWithFormik.css';
import './forumStyle.css';
import { letterSpacing } from '@mui/system';
const hideShowButton ={
  background: '#e7e7e7',
  borderRadius: '21px',
  color: '#0f3d6b',
  padding: '3px 0px',
  width: '100px',
  textAlign: 'center',
  height: '24px',
  marginBottom: '5px',
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 74%), -1px -1px 3px 0 rgb(0 0 0 / 47%)',
  cursor: 'pointer',
}
const buttonStepForum_numbrer = {
    borderRadius: '30px',
    width: '30px',
    height: '30px',
    padding: '4px 10px 0px 0px',
    background: '#1976d2',
    color: '#fff',
    fontWeight: '700',
    margin: '0px 0px 8px 8px',
}
const buttonStepForum_text = { color: '#ooo', paddingTop: '4px' }


function AddPackagesForTransportation (props) {
  
const auth = useContext(AuthContext);
const userProfile = useSelector((state) => state.ui.userProfile);
const getCitiesTwo = async (str) => {
  try {
    let searchableCity = str.replace(/,/g, "");
    let url = "/api/contacts/autocomplete_two?city=" + searchableCity;
        const response = await fetch(url,
          {method: 'GET',
          body: null,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            },
          }
        );
        let dataContacts = await response.json();
        dataContacts = dataContacts.filter( (el) => el.user_id === userProfile._id)    
    return dataContacts;
  } catch (error) {
    console.error(error);
  }
};




  const usersClientsFromContacts = useSelector((state) => state.ui.userProfile.AllClients);
  
  const changeFormikAddressValues = (numInArray) => {
    if(formRef) {// formRef ? 
    formRef.current.values.location_o[numInArray] = 'sdgs sdgs k' 
    // : null 
    console.log("formRef.current.values::",formRef.current.values)
  }
    // console.log("formik.values:", formik.values)
  }


  const allFormInputName = ['id_manager','id_client','id_emploeey',
  'date_delivery','where_to','packs_pallet','packs_bags',
  'packs_cartons','packs_people','description',
  'location_o','location_d','contact_O_r_D',
  'payment','vehicle','physical_work','id_tag_route'];


  const chooseAddSubject = [
    ['מיקומים','places',<AddLocationAltOutlinedIcon sx={{ fontSize: 40 }} />],
    ['אינדקס','index',<MenuBookOutlinedIcon sx={{ fontSize: 40 }} />]
  ]
  
  const formTypeOptions = [
      { title: 'איש קשר', subTitle: 'הוספת איש קשר רגיל לכל מטרה', value: 'person', subject: 'index'},
      { title: 'עובד', subTitle: 'הוספת נהג/עובד לו תכולו לשייך משטחים/פריטים לשינוע', value: 'employee', subject: 'index'},
      { title: 'לקוח', subTitle: 'תוכלו לציין עבור איזה לקוח מבוצע עבודה וכן תוכלו לקבל עבודות מלקוחות אליכם', value: 'client', subject: 'index'},
      { title: 'לקוח קצה', subTitle: 'לכל לקוח תוכלו להוסיף תחתיו לקוח קצה. כך תוכלו לעבוד מול חברות המבקשים לשלוח פריטים ללקוחות קצה שלהם.', value: 'clients_client', subject: 'index'},
      { title: 'מחסן', subTitle: 'בכל פריט תוכלו להגדיר מאיזה מחסן יועמס/יופרק הפריט', value: 'warehouse', subject: 'index'},
      { title: 'תווית למסלול', subTitle: 'יש לכם מסלול עם כתובות? צרו תווית ואחר כך תוסיפו תחתיו כתובות ואחכ לקבל מסלול מחושב כולל נקודות במפה', value: 'tag_route', subject: 'index'},


    { title: 'פריטים לשינוע - לקוחות ומחסנים', subTitle: 'הוספת פריט לשינוע עם לקוחות ומחסנים שמורים  במערכת', value: 'c_to_w',
      inputes: ['id_manager','id_client','id_emploeey',
      'date_delivery','where_to','packs_pallet','packs_bags',
      'packs_cartons','packs_people','description'], subject: 'places' },
    { title: 'פריטים לשינוע - מאיש קשר לאיש קשר', subTitle: 'הוספת פריט מאיש קשר לאיש קשר', value: 'contact_to_contact',
    inputes: ['id_manager','id_client','id_emploeey',
    'date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D'] , subject: 'places'},
    { title: 'הוספת פריט', subTitle: 'הוספת פריט מאיש קשר לאיש קשר', value: 'c_o_d',
    inputes: ['date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D','payment','vehicle','physical_work','id_connected_mover','id_connected_businesses'] , subject: 'places'},
    { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
    inputes: ['location_d','id_tag_route'] , subject: 'places'},
    { title: 'מוצא ויעד', subTitle: 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', value: 'o_and_d',
    inputes: ['location_o','location_d'] , subject: 'places'},
    { title: 'דרושים', subTitle: 'הוספת פריט למחלקת הדרושים למציאת נהג לשינוע', value: 'jobs',
    inputes: ['date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
    'contact_O_r_D',
    'payment','vehicle','physical_work','id_connected_mover','id_connected_businesses'] , subject: 'places'},
    { title: 'קו קבוע לפי יום - עבור המטריקס', subTitle: 'הוסף מוצא ויעד קבוע באחד מימי השבוע, כך נוכל לשלוח לך עבודות על הדרך', value: 'location_by_day',
    inputes: ['date_delivery', 'location_o','location_d','vehicle'] , subject: 'places'},
    { title: 'הובלת דירה', subTitle: 'מילוי טופס להובלת דירה או משרד', value: 'home_office' ,
    inputes: ['id_manager','id_client','id_emploeey',
    'date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description','contact_O_r_D',
    'payment','vehicle','physical_work'], subject: 'places'}
  ];
  
  // const ClientsClientOptions = [
  //   { key: 'אושר עד חיפה', value: '666' },
  //   { key: 'אושר עד כנות', value: '777' },
  //   { key: 'יוחננוף טבריה', value: '888' },
  //   { key: 'מחסני השוק צפת', value: '999' },
  //   { key: 'יש השומר', value: '11222' }
  // ];

  const dispatch = useDispatch();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [morDetails0,setMorDetails0] = useState(true)
  const [morDetails1,setMorDetails1] = useState([true,'1'])
  const [morDetails2,setMorDetails2] = useState([true,'1'])
  const [morDetails3,setMorDetails3] = useState(false)
  const [morDetails4,setMorDetails4] = useState(false)
  const [morDetails5,setMorDetails5] = useState(false)
  const [morDetails6,setMorDetails6] = useState([true,'1'])
  const [morDetails7,setMorDetails7] = useState(false)
  const [morDetails8,setMorDetails8] = useState(false)
  const [displayJobForm,setDisplayJobForm] = useState(false)
  const [displayDandOForm,setDisplayDandOForm] = useState(false)
  const [valueDateMode, setValueDateMode] = React.useState(0);
  const [dateType, setDateType] = useState('date');
  const [switchMatrixx, setSwitchMatrixx] = useState('fgfg');
  const [optionsTwo, setOptionsTwo] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [autoCompleteC, setAutoCompleteC] = useState([]);
  const [autoCompleteO, setAutoCompleteO] = useState([]);
  const [locationO, setlocationO] = useState([]);
  const [locationD, setlocationD] = useState([]);
  const [autoCompleteD, setAutoCompleteD] = useState([]);
  const [contact_O_r_D, setContact_O_r_D] = useState('');
  const [typeOfForm, setTypeOfForm] = useState(props.typeOfForm);
  // const [typeOfForm, setTypeOfForm] = useState(['','','',[]]);
  const [showTypeOfFormOptions, setShowTypeOfFormOptions] = useState(true);
  const [showAddSubject, setShowAddSubject] = useState('places');
  //  contact_to_contact 
  // location_tag 
  const [createTagRoute, setCreateTagRoute] = useState(false);
  const [isTagRouteSelected, setIsTagRouteSelected] = useState(false);
  const [createContactName, setCreateContactName] = useState(false);
  const [searchContactName, setSearchContactName] = useState(false);
  const [optionsManagersIds, setOptionsManagersIds] = useState([]);
  const [optionsConnected_movers, setOptionsConnected_movers] = useState([]);
  const [optionsConnected_businesses, setOptionsConnected_businesses] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [pointNum, setPointNum] = useState([{num: 1}]);
  const [allUsersClientContacts, setAllUsersClientContacts] = useState([]);
  const [allEmployeeContacts, setAllEmployeeContacts] = useState([]);
  const [warehousesOptions, setWarehousesOptions] = useState([]);
  const [tagRouteOptions, setTagRouteOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [catOptions, setCatOptions] = useState([]);
  const [subCatOptions, setSubCatOptions] = useState([]);
  const [idsTagsOptions, setIdsTagsOptions] = useState([]);
  const [clientsClientOptions, setClientsClientOptions] = useState([]);
  const [sendedMessage, setSendedMessage] = useState('');
  const [changed, setChanged] = useState(false);
  const [addressesFieldsDetails, setAddressesFieldsDetails] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [cityO, setCityO] = useState('');
  const [cityD, setCityD] = useState('');
  const [cityLt, setCityLt] = useState('');
  const [newtripOrUpdate, setNewtripOrUpdate] = useState('new');
  const [currentCatRouteId, setCurrentCatRouteId] = useState('');
  const [addNewInContact, setAddNewInContact] = useState('');
  

  const handleSelectChange = (e) => {
    console.log("value Tag>", e)
      const currentCat = e;
      console.log("currentCat",currentCat)
      setIsTagRouteSelected(true)
      // setAllSubLocations([])  
          // }
          // const fetchContactsData3 = async () => {
          //   // console.log("auth.token:", auth.token)
          //   const storedDataOfUser2 = JSON.parse(localStorage.getItem('userData'));
          //     let urlJobs
          //     let pageNumber
          //     let autoCompleteId
              
          //     let locationsByAdminTag = 'locationsByAdminTag'
          //   urlJobs = `/api/items/${locationsByAdminTag}/user/${storedDataOfUser2.userId}?startdate=all&page=0&autocompleteid=autoCompleteId&tagId=${currentCat}`
          //   const response = await fetch(
          //     urlJobs,
          //     {
          //       method: 'GET',
          //       body: null,
          //       headers: {
          //         'Content-Type': 'application/json',
          //         Authorization: 'Bearer ' + auth.token
          //       },
          //     }
          //   );
          //   const dataContacts = await response.json();
          //   console.log(dataContacts)
  
          //   let allTagRouteOptions = []
            
            
          //   if(dataContacts.contacts){
          //     dataContacts.contacts.map(elem => {
          //       allTagRouteOptions.push({key: elem.title, value: elem._id})
          //     });
          //     setAllSubLocations(
          //         [...allTagRouteOptions]
          //       )
          //   }
          //   return dataContacts;
          // };
          // try {
          //   fetchContactsData3();
          // } catch (error) {
          //   console.log("error NEAR:", error)
          // }
  }

  const selectedMapCatChanged = (tagId,tagName) => {
    const currentCat = tagId;
    const currentCatName = tagName;
    setCurrentCatRouteId(tagId)
    console.log("currentCat",currentCat)
  }


  let formRef = useRef();
  useEffect(() => {
    // formRef.current.values.location_o[0] = 'sdgs sdgs k'
    // if(!showTypeOfFormOptions && showAddSubject === 'places'){
      console.log("formRef.current.values",formRef.current.values)
    // }
  },[formRef, showTypeOfFormOptions])

  useEffect(() => {
      console.log("autoCompleteCCCC",autoCompleteC)
  }, [autoCompleteC])

  useEffect(() => {
      console.log("autoCompleteD??",autoCompleteD)
  },[autoCompleteD])

  const setAutoCompleteCFromAddContactHandler = (e) => {
    console.log("setAutoCompleteCFromAddContactHandler_E!", e)
    if(contact_O_r_D === 'C'){
      setAutoCompleteC(  [[0, e.contact]])
    }
    if(contact_O_r_D === 'O'){
      setAutoCompleteO(  [[0, e.contact]])
    }
    if(contact_O_r_D === 'D'){
      setAutoCompleteD(  [[0, e.contact]])
    }
  }
  const setAutoCompleteCFromSearchContactHandler = (e) => {
    console.log("setAutoCompleteCFromSearchContactHandler_E!", e)
    if(contact_O_r_D === 'C'){
      setAutoCompleteC([[0, e]])
      setSearchContactName(false)
    }
    if(contact_O_r_D === 'O'){
      setAutoCompleteO([[0, e]])
      setSearchContactName(false)
    }
    if(contact_O_r_D === 'D'){
      setAutoCompleteD([[0, e]])
      setSearchContactName(false)
    }
  }

  const handleSwitchTrueOrFalse = (e) => {
    console.log('switch hes been clicked!');
    switcher = !switcher;
    console.log(switcher);
    // setSwitchMatrixx(switcher);
    // console.log(e)
  };

  const updateAddressesFields = (objNum, addessesFields,typeLocation) => {
    console.log("addessesFields", addessesFields)
    console.log("objNum", objNum)
    // console.log("locationO", locationO)
    // setAddressesFieldsDetails([...addessesFields])
    if(typeLocation === 'o'){
      const indexExistInArrayO = locationO.filter( (singleO) => {
        console.log("singleO[0] => ",singleO[0]) 
          if (singleO[0] === objNum){
            return singleO[0] === objNum
          }
        } 
      )

      console.log("indexExistInArray.length => ", indexExistInArrayO.length, indexExistInArrayO)
      if (indexExistInArrayO.length === 0){
        setlocationO(  [...locationO , [objNum, addessesFields]])
      } else if (indexExistInArrayO.length > 0) {
        const newlocationO = locationO.filter((singleO) => singleO[0] !== objNum);
        setlocationO(  [...newlocationO , [objNum, addessesFields]])
      }
    }
    if(typeLocation === 'd'){
      const indexExistInArrayD = locationD.filter( (singleD) => {
        console.log("singleD[0] => ",singleD[0]) 
          if (singleD[0] === objNum){
            return singleD[0] === objNum
          }
        } 
      )

      console.log("indexExistInArrayD.length => ", indexExistInArrayD.length, indexExistInArrayD)
      if (indexExistInArrayD.length === 0){
        setlocationD(  [...locationD , [objNum, addessesFields]])
      } else if (indexExistInArrayD.length > 0) {
        const newlocationD = locationD.filter((singleD) => singleD[0] !== objNum);
        setlocationD(  [...newlocationD , [objNum, addessesFields]])
      }
    }

  }


  let points = Array.from(Array(pointNum).keys());

  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);

  useEffect(() => {
    setOptionsManagersIds(
      [{key: userProfile.name, value: userProfile._id}]
    );
    userProfile.managers_ids &&
    userProfile.managers_ids.map(elem => {
      setOptionsManagersIds(
        [...optionsManagersIds, {key: elem.name, value: elem._id}]
        )
      });
  }, [userProfile])
  
  useEffect(() => {
    let TEMP_optionsConnected_movers = []
    userProfile.connected_movers &&
      
    userProfile.connected_movers.map(elem => {
      TEMP_optionsConnected_movers.push({key: elem.business_name, value: elem._id})
    });

    setOptionsConnected_movers(
      [...optionsConnected_movers, ...TEMP_optionsConnected_movers]
    )

    userProfile.connected_businesses &&
    userProfile.connected_businesses.map(elem => {
      setOptionsConnected_businesses(
        [...optionsConnected_businesses, {key: elem.name, value: elem._id}]
        )
    });
    
  },[userProfile])
    
  useEffect(() => {
    setAllUsersClientContacts(
      // [{key: userProfile.name, value: userProfile._id}]
      [{key: 'בחר לקוח', value: userProfile._id}]
    );
    let allContactsFromRedux = [];
    userProfile.AllClients &&
    userProfile.AllClients.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    userProfile.wathIsMyClientNumber &&
    userProfile.wathIsMyClientNumber.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    setAllUsersClientContacts(
        [{key: 'בחר לקוח', value: userProfile._id}, ...allContactsFromRedux]
      )
  },[userProfile])


  useEffect(() => {
    setAllEmployeeContacts(
      // [{key: userProfile.name, value: userProfile._id}]
      [{key: 'בחר נהג', value: ''}]
    );
    let allContactsFromRedux = [];
    userProfile.AllEmployee &&
    userProfile.AllEmployee.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    userProfile.wathIsMyClientNumber &&
    userProfile.wathIsMyClientNumber.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    setAllEmployeeContacts(
        [{key: 'בחר נהג', value: ''}, ...allContactsFromRedux]
      )
  },[userProfile])


  useEffect(() => {
    let allTagRoute = [];
    userProfile.AllTagRoute &&
    userProfile.AllTagRoute.map(elem => {
      allTagRoute.push({key: elem.first_name, value: elem._id})
    });
    setTagRouteOptions(
        [...allTagRoute]
      )
  },[userProfile])
// =========
  useEffect(() => {
    let allcountryOptions = [];
    let allcityOptions = [];
    let allcatOptions = [];
    let allsubCatOptions = [];
    let allidsTagsOptions = [];
    userProfile.AllCountryCityCatSubCatIdsTags &&
    userProfile.AllCountryCityCatSubCatIdsTags.map(elem => {
      if(elem.type === "id_country"){
        allcountryOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "id_city"){
        allcityOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "id_cat"){
        allcatOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "id_sub_cat"){
        allsubCatOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "ids_tags"){
        allidsTagsOptions.push({key: elem.first_name, value: elem._id})
      }
    });
    setCountryOptions(
        [...allcountryOptions]
      )
    setCityOptions(
        [...allcityOptions]
      )
    setCatOptions(
      [...allcatOptions]
      )
    setSubCatOptions(
        [...allsubCatOptions]
      )
    setIdsTagsOptions(
        [...allidsTagsOptions]
      )
  },[userProfile])
// ==========

  useEffect(() => {
    let allWarehouses = [];
    userProfile.warehouses &&
    userProfile.warehouses.map(elem => {
      allWarehouses.push({key: elem.first_name, value: elem._id})
    });
    setWarehousesOptions(
        [...allWarehouses]
      )
  },[userProfile])

  useEffect(() => {
    let allClientsClient = [];
    userProfile.AllClientsClient &&
    userProfile.AllClientsClient.map(elem => {
      allClientsClient.push({key: elem.first_name, value: elem._id})
    });
    userProfile.AllManagersClientsClient &&
    userProfile.AllManagersClientsClient.map(elem => {
      allClientsClient.push({key: elem.first_name, value: elem._id})
    });
    setClientsClientOptions(
        [...allClientsClient]
      )
      console.log("allClientsClient:", allClientsClient)
  },[userProfile])




  const onChangeTwo = async (e) => {
    console.log(e.target)
    if (e.target.value) {
      let data = await getCitiesTwo(e.target.value);
      setOptionsTwo(data);
    }
  };
  
  const clearInput = () => {
    console.log('ffff')
    setOptionsTwo([]);
    setWordEntered("");
  };


let switcher = true;
  // useEffect(() => {
  // if(props.formPurpesTitle === 'd'){
  //   setDisplayDandOForm(false);
  //   }
  // if(props.formPurpesTitle !== 'd'){
  //   setDisplayDandOForm(true);
  //   }
  // },[props.formPurpesTitle])

  // useEffect(() => {
  //   console.log('autoCompleteO => ',autoCompleteO)
  // },[autoCompleteO])

  // useEffect(() => {
  //   console.log('autoCompleteC => ',autoCompleteC)
  // },[autoCompleteC])

  // useEffect(() => {
  //   console.log('pointNum => ',pointNum)
  // },[pointNum])


  // const handleChange4 = (event, newValuee) => {
  //   setValueDateMode(newValuee);
  // };
  
  // const handleSwitchTrueOrFalse = (e) => {
  //   console.log('switch hes been clicked!');
  //   switcher = !switcher;
  //   console.log(switcher);
  //   // setSwitchMatrixx(switcher);
  //   // console.log(e)
  // };

  // const auth = useContext(AuthContext);
  let allMapCats = []

// props.mapCatsDropDown.forEach((single, idx, allArray)  => {
//   let objVal = single.title;
//   if(objVal){
//     const obj = {value: single.id, key: single.title};
//     allMapCats.push(obj);
//     // console.log(single)
//   }
// })

  const initialValues = {
    // id_country: { type: mongoose.Types.ObjectId, required: false, ref: 'Contact' },
    // id_city: { type: mongoose.Types.ObjectId, required: false, ref: 'Contact' },
    // id_cat: { type: mongoose.Types.ObjectId, required: false, ref: 'Contact' },
    // id_sub_cat: { type: mongoose.Types.ObjectId, required: false, ref: 'Contact' },
    // ids_tags: { type: mongoose.Types.ObjectId, required: false, ref: 'Contact' },
    // id_country id_city id_city id_cat id_sub_cat ids_tags
    id_country: '',
    id_city: '',
    id_cat: '',
    id_sub_cat: '',
    ids_tags: '',

    pointNum: '',
    typeOfForm: '',
    city_o: '',
    city_d: '',
    city_lt: '',
    title: '',
    video_link: '',
    web_link: '',
    id_manager: '',
    id_connected_mover: '',
    id_connected_businesses: '',
    where_to: ['warehouse_to_clients_client'],
    from_id_warehouse: [''],
    to_id_clients_client: [''],
    from_id_clients_client: [''],
    to_id_warehouse: [''],
    form_purpes: props.formPurpesTitle,
    description: [' '],
    location_o: [' '],
    // email: '',
    // bio: '',
    // ['id_manager','id_client','id_emploeey',
    // 'date_delivery','where_to','packs_pallet','packs_bags',
    // 'packs_cartons','packs_people','description',
    // 'location_o','location_d','contact_O_r_D',
    // 'payment','vehicle','physical_work'];



    cat: '',
    route_name: '',
    selectedAddressD: {
      address: 'props.selectedAddressD.address',
      lat: 'props.selectedAddressD.selectedLat',
      lng: 'props.selectedAddressD.selectedLng',
      entry: '',
      floor: '',
      apartment: '',
      description: ''
    },
    selectedAddressO: {
      address: 'props.selectedAddressO.address',
      lat: 'props.selectedAddressO.selectedLat',
      lng: 'props.selectedAddressO.selectedLng',
      entry: '',
      floor: '',
      apartment: '',
      description: ''
    },
    c_contact_id: autoCompleteC._id,
    o_contact_id: autoCompleteO._id,
    o_contact: {
      o_name: '',
      o_phone: '',
      o_address: ''
    },
    d_contact_id: autoCompleteD._id,
    d_contact: {
      d_name: '',
      d_phone: '',
      d_address: ''
    },
    // packs: {
      packs_bags: [''],
      packs_cartons: [''],
      packs_people: [''],
      packs_pallet: [''],
    // },
    // packs_pallet,
    vehicle: {
      type: '',
      ramp: '',
      forklift: '',
      lever: ''
    },
    physical_work: {
      d: '',
      o: '',
      desc: ''
    },
    payment: {
      amount: '',
      whopays: '',
      method: '',
    },
    modeOfdate: dateType,
    date_delivery: '',
    day: '',
    matrix: ''
      }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    // cat: Yup.string().required('בחר קטגוריה'),
    // title: Yup.string().required('הזן כותרת')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

  const history = useHistory();


  useEffect(() => {
    console.log("format>> startDate):",format(startDate, 'MM/dd/yyyy'))
  },[startDate])


  const onSubmit = async values => {
    console.log('Form data values:', values)

    
    // c_contact_id: autoCompleteC._id,contact_O_r_D
    values.typeOfForm = typeOfForm[0];
    
    // values.city_lt = cityLt
    
    if (typeOfForm[3].includes('contact_O_r_D')){
      console.log("locationCOD",autoCompleteC,autoCompleteO,autoCompleteD)
    if (values.typeOfForm !== 'jobs'){
      values.c_contact_id = autoCompleteC[0][1]._id;
    }
      values.city_o = autoCompleteO[0][1].city;
      values.city_d = autoCompleteD[0][1].city;
      
      values.o_contact_id = autoCompleteO[0][1]._id;
      values.d_contact_id = autoCompleteD[0][1]._id;
    }
    if (typeOfForm[3].includes('location_o')){
      values.location_o = locationO[0][1];
    }
    if (typeOfForm[3].includes('location_d')) {
      values.city_lt = locationD[0][1][3]
      values.location_d = locationD[0][1];
    }
    values.pointNum = pointNum;
    if (typeOfForm[3].includes('date_delivery')){
      values.startDate = format(startDate, 'MM/dd/yyyy');
    }
    // if(values.typeOfForm === 'c_o_d') {
    // let orderedFormValues = [];
    // values.pointNum.map(elem => {
    //   console.log(elem.num)
    //   values.packs_pallet.map((single, index) => {
    //     console.log("single", single, "index", index)
    //     if(index === elem.num-1){
    //       orderedFormValues.push(
    //           {
    //             packs_pallet: single,
    //             where_to: values.where_to[elem.num-1],
    //             // from_id_clients_client: values.from_id_clients_client[elem.num-1] ? values.from_id_clients_client[elem.num-1] : '',
    //             from_id_warehouse: values.from_id_warehouse[elem.num-1] ? values.from_id_warehouse[elem.num-1] : '',
    //             to_id_clients_client: values.to_id_clients_client[elem.num-1] ? values.to_id_clients_client[elem.num-1] : '',
    //             // to_id_warehouse: values.to_id_warehouse[elem.num-1] ? values.to_id_warehouse[elem.num-1] : '',
    //             description: values.description[elem.num-1] ? values.description[elem.num-1] : ''
    //           }
    //         )
    //       console.log("single:", single, "index:", index)
    //     }
    //   })
    //   // allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    // });
    // values.allItems = orderedFormValues;
    // console.log(orderedFormValues,values.pointNum)
    // }
    if(values.typeOfForm === 'c_to_w' || values.typeOfForm === 'contact_to_contact') {
    let orderedFormValues = [];
    values.pointNum.map(elem => {
      console.log(elem.num)
      values.packs_pallet.map((single, index) => {
        console.log("single", single, "index", index)
        if(index === elem.num-1){
          orderedFormValues.push(
              {
                packs_pallet: single,
                where_to: values.where_to[elem.num-1],
                from_id_clients_client: values.from_id_clients_client[elem.num-1] ? values.from_id_clients_client[elem.num-1] : '',
                from_id_warehouse: values.from_id_warehouse[elem.num-1] ? values.from_id_warehouse[elem.num-1] : '',
                to_id_clients_client: values.to_id_clients_client[elem.num-1] ? values.to_id_clients_client[elem.num-1] : '',
                to_id_warehouse: values.to_id_warehouse[elem.num-1] ? values.to_id_warehouse[elem.num-1] : '',
                description: values.description[elem.num-1] ? values.description[elem.num-1] : ''
              }
            )
          console.log("single:", single, "index:", index)
        }
      })
      // allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    values.allItems = orderedFormValues;
    console.log(orderedFormValues,values.pointNum)
    }
    console.log("startDate:",startDate[0],startDate[1],startDate[2])
    console.log("format> startDate):",format(startDate, 'MM/dd/yyyy'))
    console.log("format>-new Date(2014, 1, 11), 'MM/dd/yyyy'):",format(new Date(), 'MM/dd/yyyy'))

    console.log('Form data values222:', values)


        // ========STAGE 1 CREATE TAG ROUTE=========
    let responseDataContacts;
    try {
      if(newtripOrUpdate === 'new') {
        var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", 'Bearer ' + auth.token);
          // const responseData = await sendRequest('/api/contacts/add', 'POST', JSON.stringify(values), myHeaders);
          responseDataContacts = await sendRequest('/api/contacts/add', 'POST', JSON.stringify({
            first_name: values.route_name,
            type: "tag_route",
          }), myHeaders);
          console.log("responseDataNEW_TAG_CREATED<>", responseDataContacts)
          // if(props.AutoCompleteCFromAddContactHandler){
          //   props.AutoCompleteCFromAddContactHandler(responseData)
          // }
        setSendedMessage(`${'שם טיול'} חדש נוצר בהצלחה! ${responseDataContacts.contact._id}`)
        if(responseDataContacts.contact.type === 'tag_route') {
          dispatch(
            uiActions.addTagToTagRoute({
            _id: responseDataContacts.contact._id,
            user_id: responseDataContacts.contact.user_id,
            type: responseDataContacts.contact.type,
            first_name: responseDataContacts.contact.first_name
            })
          );
          // if (props.responseNewTagRoute) {
          //   props.responseNewTagRoute(responseDataContacts.contact)
          // }
        }
      }
    } catch (err) {console.log(err)}




    // ========STAGE 2=========

    
    let idTag;
    if (newtripOrUpdate === 'new') {
      // idTag = responseDataContacts.contact._id
      values.id_tag_route = responseDataContacts.contact._id
    }
    if (newtripOrUpdate === 'update') {
      // idTag = values.id_tag_route
      values.id_tag_route = values.id_tag_route
      // idTag = id_tag_route
    }
    try {
      setSendedMessage('מעבד בקשה...')
      console.log("pointNum=>",pointNum)
      var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + auth.token);
      const responseData = await sendRequest('/api/items/add', 'POST', JSON.stringify(values), myHeaders);
      setSendedMessage('הפריטים התווספו לשינוע בהצלחה!')
      console.log("responseData<>", responseData)
      // setSendedMessage(`${props.typeOfForm[1]} חדש נוצר בהצלחה!`)


      if (responseData.contact.form_purpes === 'location_tag') {
          console.log("dispatch addLocationToTagRoute")
          dispatch(
            uiActions.addLocationToTagRoute({
            location_d: responseData.contact.location_d,
            _id: responseData.contact._id,
            user_id: responseData.contact.user_id,
            form_purpes: responseData.contact.form_purpes,
            id_tag_route: responseData.contact.id_tag_route,
            title: responseData.contact.title,
            web_link: responseData.contact.web_link,
            video_link: responseData.contact.video_link,
            })
          );
        var oldItems = JSON.parse(localStorage.getItem('userProfile'));

        var newItem = {
          location_d: responseData.contact.location_d,
            _id: responseData.contact._id,
            user_id: responseData.contact.user_id,
            form_purpes: responseData.contact.form_purpes,
            id_tag_route: responseData.contact.id_tag_route,
            title: responseData.contact.title,
            web_link: responseData.contact.web_link,
            video_link: responseData.contact.video_link,
        };

        oldItems.AllLocationTag.push(newItem);
        localStorage.setItem(
          'userProfile',
          JSON.stringify(oldItems)
        );
        // formRef.current.values = ''
        // console.log("formRef.current.values", formRef)


      }

      if (responseData.contact.form_purpes === 'o_and_d') {
          console.log("dispatch add_O_and_D_Locations")
          dispatch(
            uiActions.addOAndDLocations({
              _id: responseData.contact._id,
              user_id: responseData.contact.user_id,
              form_purpes: responseData.contact.form_purpes,
              location_d: responseData.contact.location_d,
              location_o: responseData.contact.location_o,

            })
          );
        
        var oldItems = JSON.parse(localStorage.getItem('userProfile'));

        var newItem = {
          _id: responseData.contact._id,
          user_id: responseData.contact.user_id,
          form_purpes: responseData.contact.form_purpes,
          location_d: responseData.contact.location_d,
          location_o: responseData.contact.location_o,
        };

        oldItems.All_O_and_D_Locations.push(newItem);
        localStorage.setItem(
          'userProfile',
          JSON.stringify(oldItems)
        );
        }
      // history.push('/maps');

    } catch (err) {console.log(err)}

  }
  return (
    <>





    {/* {showAddSubject === 'places' && !showTypeOfFormOptions && */}
    
    <Formik 
    // enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {formik => {
        return (
          // style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px'}}
          <Form>
            {/* <FormikControl
              control='radio'
              label='צד המשלם'
              name='modeOfContact'
              options={RadioOptions}
            /> */}
















            {/* <Grid item xs={12}> */}
              {/* <Typography  variant="h4" > הוספת פריטים לשינוע */}
                {/* {props.formPurpesTitle === 'd' && 'הוספת נקודת יעד'}
                {props.formPurpesTitle === 'd&o' && 'הוספת מוצא ויעד'}
                {props.formPurpesTitle === 'job' && 'הוספת עבודה'} */}
              {/* </Typography> */}
            {/* </Grid> */}

            {/* <FormikControl
              control='select'
              // type='number'
              label='בחר סוג טופס'
              name='formType'
              options={formTypeOptions}
            /> */}

            {typeOfForm[3].includes('id_manager') &&

              <FormikControl
                control='select'
                // type='number'
                label='חברת משלוחים'
                name='id_manager'
                options={optionsManagersIds}
              />
            }
            {typeOfForm[3].includes('id_connected_mover') && optionsConnected_movers.length > 0 &&
            
            <FormikControl
            control='select'
            // type='number'
            label='משלוחנים מחוברים'
            name='id_connected_mover'
            options={optionsConnected_movers}
            />
            }

            {typeOfForm[3].includes('id_connected_businesses') && optionsConnected_businesses.length > 0 &&
              <FormikControl
                control='select'
                // type='number'
                label='מעבידים'
                name='id_connected_businesses'
                options={optionsConnected_businesses}
              />
            }

            {typeOfForm[3].includes('id_client') &&
              <FormikControl
                control='select'
                // type='number'
                label='שם לקוח'
                name='id_client'
                options={allUsersClientContacts}
              />
            }

            {typeOfForm[3].includes('id_emploeey') &&
              <FormikControl
                control='select'
                // type='number'
                label='עבור נהג'
                name='id_emploeey'
                options={allEmployeeContacts}
              />
            }

            {/* {typeOfForm[3].includes('choose_tag_then_location') && 
              <div>
                <Grid item xs={12}>
                    <FormikUiAddContact 
                    // AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_o'
                    typeOfForm={['tag_route', 'שם טיול']}
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
              </div>
            } */}


            {typeOfForm[3].includes('id_tag_route') &&
              <>
              <Grid style={{margin: '5px', flexDirection: 'row-reverse', display: 'flex'}}>
                <Button onClick={() => {
                  setCreateTagRoute(true)
                  // setAddTagRoute(true)
                  openMapHandler()
                  }} variant='outlined'>צור שם טיול חדש</Button>
              </Grid>
              {createTagRoute &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createTagRoute}
                  onCancel={() => setCreateTagRoute(false)}
                  header={'צור שם טיול'}
                  closeButton={<Button variant='contained' onClick={() => setCreateTagRoute(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    // AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_o'
                    typeOfForm={['tag_route', 'שם טיול']}
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                </Modal>
                </div>
                }
                <FormikControl
                  control='select'
                  // handleSelectChange={handleSelectChange}
                  // type='number'
                  label='בחר שם טיול'
                  name='id_tag_route'
                  options={tagRouteOptions}
                />
              </>
            }

            
            


            


          {typeOfForm[3].includes('date_delivery') &&
            <>
            <LocalizationProvider 
              dateAdapter={AdapterDateFns}
              adapterLocale={hebrew}
              >
              <DatePicker
                label="בחר תאריך"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
              {/* <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  // startIcon={<KeyboardArrowUpIcon/>}
                  endIcon={morDetails4 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  onClick={() => setMorDetails4(!morDetails4)}
                >
                  <Typography>עבור תאריך</Typography>
                </Button>
              </Grid> */}
              {/* {morDetails4 &&  */}
              {/* <div className='mor_details' id="mor_details2">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikControl
                      control='date'
                      type='date'
                      label=''
                      name='date_delivery'
                    />

                  </Grid>
                </Grid>
              </div> */}
              {/* } */}
            </>
          }

        {pointNum.map((obj, index) => (
          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {/* <div style={{"width":"35px","height":"35px","background":"#545454","display":"flex","alignItems":"center","justifyContent":"center","color":"#fff","fontSize":"18px","fontWeight":"600","borderRadius":"19px"}}>{index+1}</div> */}
              {/* <div style={{"width":"35px","height":"35px","background":"#545454","display":"flex","alignItems":"center","justifyContent":"center","color":"#fff","fontSize":"18px","fontWeight":"600","borderRadius":"19px"}}>{obj.num-1}</div> */}
              <div style={{display: 'flex', flexDirection: 'column', marginRight: '6px', marginLeft: '30px'}}>
                {/* <div>
                  <IconButton aria-label="fingerprint" color="primary"
                    disabled={index === 0 ?  true : false }
                  >
                    <ArrowDropUpIcon 
                     onClick={() => {
                      setChanged(!changed)
                        const lengthOfArr = pointNum.length
                        const objNum = obj.num-1
                        const pointNumArrSliceBeforeIndexMinusOne = pointNum.slice(0,index-1)
                        const pointNumArrSliceIndex = pointNum.slice(index,index+1)
                        const pointNumArrSliceOneBeforeIndex = pointNum.slice(index-1,index)
                        const pointNumArrSliceAllAfterOneAfterIndex = pointNum.slice(index+1)
                        // console.log("pointNumArrSliceBeforeIndex ",index ,pointNumArrSliceBeforeIndex)
                        // console.log("pointNumArrSliceIndex ",pointNumArrSliceIndex)
                        // console.log("pointNumArrSliceOneAfterIndex ",pointNumArrSliceOneAfterIndex)
                        // console.log("pointNumArrSliceAllAfterOneAfterIndex ",pointNumArrSliceAllAfterOneAfterIndex)
                        let newArrPointNum = [...pointNumArrSliceBeforeIndexMinusOne,...pointNumArrSliceIndex,...pointNumArrSliceOneBeforeIndex,...pointNumArrSliceAllAfterOneAfterIndex]
                        console.log([...pointNumArrSliceBeforeIndexMinusOne,...pointNumArrSliceIndex,...pointNumArrSliceOneBeforeIndex,...pointNumArrSliceAllAfterOneAfterIndex])
                        // console.log("pointNumArrSliceAfter ",pointNum.slice(index))
                        // let newArrPointNum = [...pointNumArrSlice, {num: pointNum.length+1}, ...pointNum.slice(index+1)]
                        // console.log("newArrPointNum: ", newArrPointNum)
                        setPointNum([...newArrPointNum])
                        }
                      }
                    />
                  </IconButton>
                </div> */}
                {/* <div>
                  <IconButton aria-label="fingerprint" color="primary" 
                  disabled={pointNum.length-1 === index ?  true : false }
                  >

                  <ArrowDropDownIcon 
                      onClick={() => {
                        setChanged(!changed)
                        const lengthOfArr = pointNum.length
                        // console.log("lengthOfArr",index,lengthOfArr)
                        const objNum = obj.num-1
                        const pointNumArrSliceBeforeIndex = pointNum.slice(0,index)
                        const pointNumArrSliceIndex = pointNum.slice(index,index+1)
                        const pointNumArrSliceOneAfterIndex = pointNum.slice(index+1,index+2)
                        const pointNumArrSliceAllAfterOneAfterIndex = pointNum.slice(index+2)
                        console.log("pointNumArrSliceBeforeIndex ",index ,pointNumArrSliceBeforeIndex)
                        console.log("pointNumArrSliceIndex ",pointNumArrSliceIndex)
                        console.log("pointNumArrSliceOneAfterIndex ",pointNumArrSliceOneAfterIndex)
                        console.log("pointNumArrSliceAllAfterOneAfterIndex ",pointNumArrSliceAllAfterOneAfterIndex)
                        let newArrPointNum = [...pointNumArrSliceBeforeIndex,...pointNumArrSliceOneAfterIndex,...pointNumArrSliceIndex,...pointNumArrSliceAllAfterOneAfterIndex]
                        console.log([...pointNumArrSliceBeforeIndex,...pointNumArrSliceOneAfterIndex,...pointNumArrSliceIndex,...pointNumArrSliceAllAfterOneAfterIndex])
                        // console.log("pointNumArrSliceAfter ",pointNum.slice(index))
                        // let newArrPointNum = [...pointNumArrSlice, {num: pointNum.length+1}, ...pointNum.slice(index+1)]
                        // console.log("newArrPointNum: ", newArrPointNum)
                        setPointNum([...newArrPointNum])
                        }
                      }
                    />
                  </IconButton>
                </div> */}
              </div>
              {/* <Box sx={{ '& > :not(style)': { m: 1 } }}> */}
                {/* <Fab color="primary" aria-label="add"> */}
                  {/* <AddIcon 
                  onClick={() => {
                    const lengthOfArr = pointNum.length
                    const objNum = obj.num-1
                    console.log("objNum ",objNum)
                    console.log("index ",index)
                    console.log("lengthOfArr ",lengthOfArr)
                    console.log("pointNum ",pointNum)
                    let pointNumArrSlice = pointNum.slice(0,index+1)
                    console.log("pointNumArrSlice ",pointNumArrSlice)
                    console.log("pointNumArrSliceAfter ",pointNum.slice(index))
                    // pointNum
                    const maxNumOfPointNum = Math.max.apply(Math,pointNum.map(function(o){return o.num;}))
                    // alert('Max y = ' + res);
                    let newArrPointNum = [...pointNumArrSlice, {num: maxNumOfPointNum+1}, ...pointNum.slice(index+1)]
                    console.log("newArrPointNum: ", newArrPointNum)
                    setPointNum([...newArrPointNum])
                    }
                  }
                  /> */}
                {/* </Fab> */}
                {/* <Fab color="secondary" aria-label="edit">
                  <EditIcon />
                </Fab> */}
                {/* <Fab aria-label="delete"
                disabled={pointNum.length <= 1 ?  true : false }
                >
                  <DeleteForeverIcon 
                   onClick={() => {
                      const pointNumArrSliceBeforeIndex = pointNum.slice(0,index)
                      const pointNumArrSliceAllAfterIndex = pointNum.slice(index+1)
                      let newArrPointNum = [...pointNumArrSliceBeforeIndex,...pointNumArrSliceAllAfterIndex]
                      setPointNum([...newArrPointNum])
                      }
                    }
                  />
                </Fab> */}
              {/* </Box> */}
            </div>
            <div>
          {/* <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={12}> */}
            <div>
            {locationO.map((single) => (
              single[0] === obj.num-1
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>מ:</div>
                      <div style={{color: '#000'}}>{single[1][0]}</div>
                    </div>
                )
                : null
              ))}
            {/* {locationD.map((single) => (
              single[0] === obj.num-1
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>ל:</div>
                      <div style={{color: '#000'}}>{single[1][0]}</div>
                    </div>
                )
                : null
              ))} */}
            {/* {autoCompleteC.map((single) => (
              single[0] === obj.num-1
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>עבור:</div>
                      <div style={{color: '#000'}}>{single[1].first_name}</div>
                    </div>
                )
                : null
              ))} */}
            {autoCompleteO.map((single) => (
              single[0] === obj.num-1
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>ל:</div>
                      <div style={{color: '#000'}}>{single[1].first_name}</div>
                    </div>
                )
                : null
              ))}
            {autoCompleteD.map((single) => (
              single[0] === obj.num-1 && !typeOfForm[3].includes('choose_tag_then_location')
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>תכולה:</div>
                      <div style={{color: '#000'}}>{single[1].first_name}</div>
                    </div>
                )
                : null
              ))}
            </div>
            <div>
              {formik.values.packs_pallet[obj.num-1] && 
              <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>משטחים:</div>
                <div style={{color: '#000'}}>{formik.values.packs_pallet[obj.num-1]}</div>
              </div>
              }
              {formik.values.where_to[obj.num-1] && typeOfForm[3].includes('where_to') &&
              <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                <div style={{color: '#000', fontWeight: 'bold'}}>{
                formik.values.where_to[obj.num-1] === 'warehouse_to_clients_client' ? 'ממחסן - ללקוח קצה' : 
                formik.values.where_to[obj.num-1] === 'clients_client_to_warehouse' ? 'מלקוח קצה - למחסן' :
                formik.values.where_to[obj.num-1] === 'warehouse_to_warehouse' ? 'ממחסן - למחסן' :
                formik.values.where_to[obj.num-1] === 'clients_client_to_clients_client' && 'מלקוח קצה - ללקוח קצה'}</div>
              </div>
              }
            </div>
            {/* </Grid>
          </Grid> */}
          </div>
            {typeOfForm[3].includes('where_to') &&
            <>
            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12}>
                <FormikControl
                  control='radio'
                  style={{flexDirection: 'column-reverse', alignItems: 'center'}}
                  objectNum={obj.num-1}
                  label=''
                  name={`where_to[${obj.num-1}]`}
                  options={[
                    { key: 'ממחסן - ללקוח קצה', value: 'warehouse_to_clients_client' },
                    { key: 'מלקוח קצה - למחסן', value: 'clients_client_to_warehouse' },
                    { key: 'ממחסן - למחסן', value: 'warehouse_to_warehouse' },
                    { key: 'מלקוח קצה - ללקוח קצה', value: 'clients_client_to_clients_client' },
                  ]}
                />
              </Grid>
            </Grid>

            {formik.values.where_to[obj.num-1] === 'warehouse_to_clients_client' ? 
              
              <div>
                <WarehousesAndClientsClientField  objNum={obj.num-1} labal='מחסן' name={`from_id_warehouse[${obj.num-1}]`} options={warehousesOptions} />
                <WarehousesAndClientsClientField  objNum={obj.num-1} labal='לקוח קצה' name={`to_id_clients_client[${obj.num-1}]`} options={clientsClientOptions} />
              </div>

              :  formik.values.where_to[obj.num-1] === 'clients_client_to_warehouse' ? 

              <div>
                <WarehousesAndClientsClientField  objNum={obj.num-1} labal='לקוח קצה' name={`from_id_clients_client[${obj.num-1}]`} options={clientsClientOptions} />
                <WarehousesAndClientsClientField objNum={obj.num-1} labal='מחסן' name={`to_id_warehouse[${obj.num-1}]`} options={warehousesOptions} />
              </div>

            : formik.values.where_to[obj.num-1] === 'warehouse_to_warehouse' ? 

              <div>
                <WarehousesAndClientsClientField objNum={obj.num-1} labal='מחסן' name={`from_id_warehouse[${obj.num-1}]`} options={warehousesOptions} />
                <WarehousesAndClientsClientField objNum={obj.num-1} labal='מחסן' name={`to_id_warehouse[${obj.num-1}]`} options={warehousesOptions} />
              </div>

              : formik.values.where_to[obj.num-1] === 'clients_client_to_clients_client' && 

              <div>
              <WarehousesAndClientsClientField objNum={obj.num-1} labal='לקוח קצה' name={`from_id_clients_client[${obj.num-1}]`} options={clientsClientOptions} />
              <WarehousesAndClientsClientField objNum={obj.num-1} labal='לקוח קצה' name={`to_id_clients_client[${obj.num-1}]`} options={clientsClientOptions} />
              </div>
            }
            </>
          }
            <div className='mor_details' id="mor_details2">
              <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                  <AddMedia
                    // objectId={obj._id}
                    // pushCommentToCommentsItem={pushCommentToCommentsItem}
                  />
                </Grid> */}
              {typeOfForm[3].includes('packs_pallet') &&
                <Grid item xs={3}>
                  <FormikControl
                    control='input'
                    type='number'
                    label='משטחים'
                    name={`packs_pallet[${obj.num-1}]`}
                  />
                </Grid>
              }
              {typeOfForm[3].includes('packs_bags') &&
                <Grid item xs={3}>
                  <FormikControl
                    control='input'
                    type='number'
                    label='שקיות'
                    name={`packs_bags[${obj.num-1}]`}
                    />
                </Grid>
              }
              {typeOfForm[3].includes('packs_cartons') &&
                <Grid item xs={3}>
                  <FormikControl
                    control='input'
                    type='number'
                    label='קרטונים'
                    name={`packs_cartons[${obj.num-1}]`}
                  />
                </Grid>
              }
              {typeOfForm[3].includes('packs_people') &&
                <Grid item xs={3}>
                  <FormikControl
                    control='input'
                    type='number'
                    label='אנשים'
                    name={`packs_people[${obj.num-1}]`}
                  />
                </Grid>
              }
              </Grid>

              {typeOfForm[3].includes('description') &&
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='textarea'
                  label='הערות'
                  name={`description[${obj.num-1}]`}
                  value=' '
                />
              </Grid>
              }
            </div>
            
            {/* <Grid item xs={12}>
              <Typography>{userProfile.location && 'כתובת נוכחית: '+userProfile.address}</Typography>
            </Grid> */}
            {/* <Button variant='contained' type='button' onClick={() => setAddLocationO(true)}>
              הוסף כתובת מוצא
            </Button> */}
            {/* {addLocationO &&  */}


          {typeOfForm[3].includes('location_o') &&
            <Grid item xs={12}>
            {/* {locationO.map((singleO) => (
              singleO[0] === obj.num-1
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>מ:</div>
                      <div style={{color: '#000'}}>{singleO[1][0]}</div>
                    </div>
                )
                : null
              ))} */}
              
                <FormikControl
                  control='addressInput'
                  addToId={obj.num-1}
                  type='string'
                  label='כתובת אוטומטית'
                  name={`location_o[${obj.num-1}]`}
                  typeLocation='o'
                  specialLabal="כתובת מוצא"
                  // changed={changed}
                  // changeFormikAddressValues={() => {
                  //   changeFormikAddressValues(obj.num-1)
                  //   // updateAddressesFields = (obj.num-1, addessesFields)
                  //   }
                  // }
                  // AddressFromRedux={userProfile.address}
                  updateAddressesFields={updateAddressesFields}
                />
            </Grid>
          }
            {/* // } */}
      {/* } */}

      {typeOfForm[3].includes('choose_tag_then_location') &&
        <>
          <div>
            <div style={{
                // background: newtripOrUpdate === 'new' ? 'rgb(221 221 221)' : '#fff',
                  padding: '10px',
                  margin: '10px',
              }}>
                {typeOfForm[3].includes('id_tag_route') || typeOfForm[3].includes('choose_tag_then_location') &&
              <>
              <Grid style={{margin: '6px 0px 18px 0px', flexDirection: 'row-reverse', display: 'flex', justifyContent: 'center'}}>
                <Button onClick={() => {
                  setCreateTagRoute(true)
                  // setAddTagRoute(true)
                  openMapHandler()
                  }} variant='outlined'>צור שם טיול חדש</Button>
              </Grid>
              {createTagRoute &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createTagRoute}
                  onCancel={() => setCreateTagRoute(false)}
                  // header={'מה שם הטיול החדש?'}
                  header={''}
                  closeButton={<Button variant='contained' onClick={() => setCreateTagRoute(false)}>חזור</Button>}
                >
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    // AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_o'
                    typeOfForm={['tag_route', 'שם טיול']}
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                </Modal>
                </div>
                }
                {/* <FormikControl
                  control='select'
                  // type='number'
                  label='בחר שם טיול'
                  name='id_tag_route'
                  options={tagRouteOptions}
                /> */}
              </>
            }
              {/* <div 
                onClick={() => {
                  setNewtripOrUpdate('new')
                }} >
                <FormikControl
                  control='input'
                  type='string'
                  label='מה שם הטיול?'
                  name='route_name'
                />
              </div> */}
              <div style={{
                  textAlign: 'center',
                  background: 'rgb(227 227 227)',
                  color: 'rgb(107 107 107)',
                  fontSize: '18px',
                  padding: '7px',
                  margin: '6px 0px',
                }}>או</div>
                <div 
                  onClick={() => {
                    setNewtripOrUpdate('update')
                  }}
                  >
                    <div style={{
                      // background: 'rgb(222 222 222)',
                      // border: isTagRouteSelected ? '0px' : '3px solid red',
                      borderRadius: '4px',
                      padding: '4px',
                      }}>
                        {!isTagRouteSelected &&
                          <div style={{color: 'red'}}>*חובה</div>
                        }
                      <FormikControl
                        control='select'
                        handleSelectChange={handleSelectChange}
                        label='הוסף לטיול קיים'
                        name='id_tag_route'
                        options={tagRouteOptions}
                      />
                    </div>
                </div>
            </div>
          </div>
        </>  
      }

{isTagRouteSelected &&
<>
      {typeOfForm[3].includes('location_d') &&
            <Grid item xs={12}>
            {/* {locationD.map((singleD) => (
              singleD[0] === obj.num-1
                ? (
                  <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                  <div style={{color: '#727272', marginLeft: '10px'}}>ל:</div>
                  <div style={{color: '#000'}}>{singleD[1][0]}</div>
                  </div>
                  )
                  : null
                ))} */}
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {!typeOfForm[3].includes('choose_tag_then_location') &&
                <div 
                  onClick={() => {
                    setNewtripOrUpdate('new')
                  }} >
                  <FormikControl
                    control='input'
                    type='string'
                    label='מה שם הטיול?'
                    name='route_name'
                  />
                </div>
                }
                {userProfile._id === '627538581659696d4c7ff55f'   || userProfile._id ===  '649d43c5389ae51478ae42ae' ?
                  <>
                  <div style={{width: '50%', padding: '6px'}}>
                    <FormikControl
                      control='select'
                      label='מדינה'
                      name='id_country'
                      options={countryOptions}
                    />
                  

                    <div onClick={() => {
                      addNewInContact !== 'id_country' ? setAddNewInContact('id_country') : setAddNewInContact('') 
                      }}
                      style={hideShowButton}
                      >
                        {addNewInContact !== 'id_country' ? 'הוסף מדינה' : addNewInContact === 'id_country' && 'הסתר'}
                    </div>
                    {addNewInContact === 'id_country' && 
                    <AddToContactComponnent
                      mustParent={[false]}
                      name_field='id_country'
                      nameAddButton='הוסף מדינה'
                      nameLabalField='שם מדינה'
                    />
                    }
                  </div>

                  <div style={{width: '50%', padding: '6px'}}>
                    <FormikControl
                      control='select'
                      label='עיר'
                      name='id_city'
                      options={cityOptions}
                    />

                    <div onClick={() => {
                      addNewInContact !== 'id_city' ? setAddNewInContact('id_city') : setAddNewInContact('') 
                      }}
                      style={hideShowButton}
                      >
                        {addNewInContact !== 'id_city' ? 'הוסף עיר' : addNewInContact === 'id_city' && 'הסתר'}
                    </div>
                    {addNewInContact === 'id_city' && 
                    <>
                      {/* {formik.values.id_tag_route} */}
                      <AddToContactComponnent
                        mustParent={[true, 'נא לבחור מדינה', formik.values.id_country, 'id_country']}
                        name_field='id_city'
                        nameAddButton='הוסף עיר'
                        nameLabalField='שם עיר'
                      />
                    </>
                    }
                  </div>

                  <div style={{width: '50%', padding: '6px'}}>
                    <FormikControl control='select' label='קטגוריה' name='id_cat' options={catOptions}
                    />
                    <div onClick={() => {
                      addNewInContact !== 'id_cat' ? setAddNewInContact('id_cat') : setAddNewInContact('') 
                      }}
                      style={hideShowButton}>{addNewInContact !== 'id_cat' ? 'הוסף קטגוריה' : addNewInContact === 'id_cat' && 'הסתר'}
                    </div>
                    {addNewInContact === 'id_cat' && 
                      <AddToContactComponnent
                        mustParent={[false]}
                        name_field='id_cat'
                        nameAddButton='הוסף קטגוריה'
                        nameLabalField='שם קטגוריה'
                      />
                    }
                  </div>

                  <div style={{width: '50%', padding: '6px'}}>
                    <FormikControl control='select' label='תת קטגוריה' name='id_sub_cat' options={subCatOptions}
                    />
                    <div onClick={() => {
                      addNewInContact !== 'id_sub_cat' ? setAddNewInContact('id_sub_cat') : setAddNewInContact('') 
                      }}
                      style={{...hideShowButton, width: '140px'}}>{addNewInContact !== 'id_sub_cat' ? 'הוסף תת קטגוריה' : addNewInContact === 'id_sub_cat' && 'הסתר'}
                    </div>
                    {addNewInContact === 'id_sub_cat' && 
                      <AddToContactComponnent
                        mustParent={[true, 'נא לבחור קטגוריה', formik.values.id_cat, 'id_cat']}
                        name_field='id_sub_cat'
                        nameAddButton='הוסף תת קטגוריה'
                        nameLabalField='שם תת קטגוריה'
                      />
                    }
                  </div>

                  <div style={{width: '50%', padding: '6px'}}>
                    <FormikControl control='select' label='תגיות' name='ids_tags' options={idsTagsOptions}
                    />
                    <div onClick={() => {
                      addNewInContact !== 'ids_tags' ? setAddNewInContact('ids_tags') : setAddNewInContact('') 
                      }}
                      style={hideShowButton}>{addNewInContact !== 'ids_tags' ? 'הוסף תגיות' : addNewInContact === 'ids_tags' && 'הסתר'}
                    </div>
                    {addNewInContact === 'ids_tags' && 
                      <AddToContactComponnent
                        mustParent={[true, 'נא לבחור קטגוריה', formik.values.id_cat, 'id_cat']}
                        name_field='ids_tags'
                        nameAddButton='הוסף תגיות'
                        nameLabalField='שם תגיות'
                      />
                    }
                  </div>
                  </>
                : null }
                  {/* <div style={{width: '50%', padding: '6px'}}>
                    <FormikControl
                      control='select'
                      label='תגיות'
                      name='ids_tags'
                      options={idsTagsOptions}
                    />
                  </div> */}
                </div>

                <FormikControl
                  control='addressInput'
                  addToId={obj.num-1}
                  type='string'
                  label='כתובת אוטומטית'
                  name={`location_d[${obj.num-1}]`}
                  typeLocation='d'
                  specialLabal="כתובת יעד"
                  changed={changed}
                  // changeFormikAddressValues={() => {
                  //   changeFormikAddressValues(obj.num-1)
                  //   // updateAddressesFields = (obj.num-1, addessesFields)
                  //   }
                  // }
                  // AddressFromRedux={userProfile.address}
                  updateAddressesFields={updateAddressesFields}
                />
                
                <FormikControl
                  control='input'
                  type='string'
                  label='כותרת'
                  name='title'
                />
                {userProfile._id === '627538581659696d4c7ff55f' &&
                  <FormikControl
                  control='input'
                  type='string'
                  label='בחר סרטון'
                  name='video_link'
                  />
                }
                {userProfile._id === '627538581659696d4c7ff55f' &&
                  <FormikControl
                    control='input'
                    type='string'
                    label='לינק לויקיפדיה'
                    name='web_link'
                  />
                }
            </Grid>
          }


          {typeOfForm[3].includes('contact_O_r_D')  &&
          // {1+1 === 2 &&
          <>

          {typeOfForm[0] !== 'jobs' && !typeOfForm[3].includes('choose_tag_then_location') &&
          <>
            <div style={{ display: 'flex', marginTop: '15px'}}>
              <div style={buttonStepForum_numbrer} >1</div>
              <div style={buttonStepForum_text}>לקוח - כתובת ואיש קשר</div>
            </div>
                
                <div className='mor_details' id="mor_details1">

      {autoCompleteC.map((single) => (
        single[0] === obj.num-1
          ? (
            <div style={{display: 'flex', flexDirection: 'column', padding: '10px', background: '#262626', borderRadius: '10px', marginBottom: '13px'}}>
              <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>שם:</div>
                <div style={{color: '#fff'}}>{single[1].first_name + ' - ' + single[1].last_name}</div>
              </div>
              <div style={{display: 'flex', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>טלפון:</div>
                <div style={{color: '#fff'}}>{single[1].phone1}</div>
              </div>
              <div style={{display: 'flex', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>אימייל:</div>
                <div style={{color: '#fff'}}>{single[1].email}</div>
              </div>
              <div style={{display: 'flex', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>כתובת:</div>
                <div style={{color: '#fff'}}>{single[1].address}</div>
              </div>
            </div>
          )
          : null
      ))}
    
    
              
    
    
                  <Grid container columnSpacing={1} rowSpacing={1}>
                    <Grid item xs={8}>
                      {/* <div> */}
                        <div className="search">
                            <div className="searchInputs">
                              <input
                                type="text"
                                placeholder={'חיפוש לקוח'}
                                value={contact_O_r_D === 'C' ? wordEntered : ''}
                                // onChange={handleFilter}
                                onChange={(e) => {
                                  setContact_O_r_D('C')
                                  onChangeTwo(e)
                                  setWordEntered(e.target.value)
                                }}
                              />
                              <div className="searchIcon">
                                {optionsTwo.length === 0 && 
                                  <SearchIcon />
                                }
                                {optionsTwo.length !== 0 && contact_O_r_D === 'C' && 
                                <CancelOutlinedIcon id="clearBtn" onClick={clearInput} />
                                }
                              </div>
                          </div>
    
    
                          {optionsTwo.length != 0 && contact_O_r_D === 'C' && (
                            <div className="dataResult">
                              {optionsTwo.slice(0, 15).map((value, key) => {
                                return (
                                  // <a className="dataItem" href={value.link} target="_blank">
                                    <div className="singleResult"
                                      onClick={ ()=> {
                                        console.log(value)
                                        const indexExistInArray = autoCompleteC.filter( (single) => {
                                          // console.log("single[0] => ",single[0]) 
                                            // if (single[0] === index){
                                              return single[0] === obj.num-1
                                            // }
                                          } 
                                        )
                                        console.log("indexExistInArray.length => ", indexExistInArray.length, indexExistInArray)
                                        if (indexExistInArray.length === 0){
                                          setAutoCompleteC(  [...autoCompleteC , [obj.num-1, value]])
                                        } else if (indexExistInArray.length > 0) {
                                          const newAutoCompleteC = autoCompleteC.filter((single) => single[0] !== obj.num-1);
                                          console.log("newAutoCompleteC:", newAutoCompleteC)
                                          setAutoCompleteC(  [...newAutoCompleteC , [obj.num-1, value]])
                                        }
                                        clearInput()
                                      }
                                        // (e) => setAutoCompleteId(e)
                                      }>
                                      {value.first_name + ' - ' + value.last_name + ' - ' + value.phone1 + ' - ' + value.email + ' - ' + value.address} </div>
                                  // </a>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      {/* </div> */}
                    </Grid>
                    <Grid container>
                      <Grid style={{margin: '5px'}}>
                        <Button onClick={() => {
                          setCreateContactName(true)
                          setContact_O_r_D('C')
                          openMapHandler()
                          }} variant='outlined'>צור לקוח </Button>
                      </Grid>
                      <Grid style={{margin: '5px'}}>
                        <Button onClick={() => {
                          setSearchContactName(true)
                          setContact_O_r_D('C')
                          // openMapHandler()
                          }} variant='outlined'>חיפוש באינדקס</Button>
                      </Grid>
                    </Grid>
                    {searchContactName && contact_O_r_D === 'C' &&
                      <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                        <Modal
                          show={searchContactName}
                          onCancel={() => setSearchContactName(false)}
                          header={'חיפוש באינדקס'}
                          closeButton={<Button variant='contained' onClick={() => setSearchContactName(false)}>סגור</Button>}
                        >
                          <Grid item xs={12}>
                            <SearchContacts 
                              AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromSearchContactHandler(e)}
                            />
                          </Grid>
                        </Modal>
                      </div>
                    }
                    {createContactName && contact_O_r_D === 'C' &&
                    <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                    <Modal
                      show={createContactName}
                      onCancel={() => setCreateContactName(false)}
                      header={'צור לקוח'}
                      // contentClass="place-item__modal-content"
                      // footerClass="place-item__modal-actions"
                      // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                      closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                    >
                      <Grid item xs={12}>
                        <FormikUiAddContact 
                        AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                        typeOfForm={['client', 'לקוח', 'תוכלו לציין עבור איזה לקוח מבוצע עבודה וכן תוכלו לקבל עבודות מלקוחות אליכם', 'type_inputes']}
                        typeLocation='contact_o'
                        formPurpesTitle={'formPurpes'}
                        zIndex={'zIndex6'}
                        // mapCatsDropDown={{}}
                        selectedAddressD={'selectedAddressD_RD'}
                        selectedAddressO={'selectedAddressO_RD'}
                    //     typeLocation='contact_o'
                    // formPurpesTitle={'formPurpes'}
                    // zIndex={'zIndex6'}
                    // // mapCatsDropDown={{}}
                    // selectedAddressD={'selectedAddressD_RD'}
                    // selectedAddressO={'selectedAddressO_RD'}
                        />
                      </Grid>
                    </Modal>
                    </div>
                    }
                  </Grid>
    
                </div>
                </>
                }










        {!typeOfForm[3].includes('choose_tag_then_location') &&
          <>
            <div style={{ display: 'flex', marginTop: '15px'}}>
              <div style={buttonStepForum_numbrer} >2</div>
              <div style={buttonStepForum_text}>מוצא - כתובת ואיש קשר</div>
            </div>

            {/* {morDetails1[0] && morDetails1[1] === index && */}
            
            <div className='mor_details' id="mor_details1">
{/* {data.map((record) => (
    record.list.length > 0
      ? (<YourRenderComponent record={record} key={record.id} />)
      : null
  ))} */}
  {autoCompleteO.map((single) => (
    single[0] === obj.num-1
      ? (
        <div style={{display: 'flex', flexDirection: 'column', padding: '10px', background: '#262626', borderRadius: '10px', marginBottom: '13px'}}>
          <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>שם:</div>
            <div style={{color: '#fff'}}>{single[1].first_name + ' - ' + single[1].last_name}</div>
          </div>
          <div style={{display: 'flex', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>טלפון:</div>
            <div style={{color: '#fff'}}>{single[1].phone1}</div>
          </div>
          <div style={{display: 'flex', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>אימייל:</div>
            <div style={{color: '#fff'}}>{single[1].email}</div>
          </div>
          <div style={{display: 'flex', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>כתובת:</div>
            <div style={{color: '#fff'}}>{single[1].address}</div>
          </div>
        </div>
      )
      : null
  ))}


          


              <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={8}>
                  {/* <div> */}
                    <div className="search">
                        <div className="searchInputs">
                          <input
                            type="text"
                            placeholder={'חיפוש איש קשר'}
                            value={contact_O_r_D === 'O' ? wordEntered : ''}
                            // onChange={handleFilter}
                            onChange={(e) => {
                              setContact_O_r_D('O')
                              onChangeTwo(e)
                              setWordEntered(e.target.value)
                            }}
                          />
                          <div className="searchIcon">
                            {optionsTwo.length === 0 && 
                              <SearchIcon />
                            }
                            {optionsTwo.length !== 0 && contact_O_r_D === 'O' && 
                            <CancelOutlinedIcon id="clearBtn" onClick={clearInput} />
                            }
                          </div>
                      </div>


                      {optionsTwo.length != 0 && contact_O_r_D === 'O' && (
                        <div className="dataResult">
                          {optionsTwo.slice(0, 15).map((value, key) => {
                            return (
                                <div className="singleResult"
                                  onClick={ ()=> {
                                    console.log(value)
                                    const indexExistInArray = autoCompleteO.filter( (single) => {
                                          return single[0] === obj.num-1
                                      } 
                                    )
                                    console.log("indexExistInArray.length => ", indexExistInArray.length, indexExistInArray)
                                    if (indexExistInArray.length === 0){
                                      setAutoCompleteO(  [...autoCompleteO , [obj.num-1, value]])
                                    } else if (indexExistInArray.length > 0) {
                                      const newAutoCompleteO = autoCompleteO.filter((single) => single[0] !== obj.num-1);
                                      console.log("newAutoCompleteO:", newAutoCompleteO)
                                      setAutoCompleteO(  [...newAutoCompleteO , [obj.num-1, value]])
                                    }
                                    clearInput()
                                  }
                                  }>
                                  {value.first_name + ' - ' + value.last_name + ' - ' + value.phone1 + ' - ' + value.email + ' - ' + value.address} </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  {/* </div> */}
                  </Grid>
                  
                  <Grid container>
                    <Grid style={{margin: '5px'}}>
                      <Button onClick={() => {
                        setCreateContactName(true)
                        setContact_O_r_D('O')
                        openMapHandler()
                        }} variant='outlined'>צור חדש</Button>
                    </Grid>
                    <Grid style={{margin: '5px'}}>
                      <Button onClick={() => {
                        setSearchContactName(true)
                        setContact_O_r_D('O')
                        // openMapHandler()
                        }} variant='outlined'>חיפוש באינדקס</Button>
                    </Grid>
                  </Grid>
                  {searchContactName && contact_O_r_D === 'O' &&
                      <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                        <Modal
                          show={searchContactName}
                          onCancel={() => setSearchContactName(false)}
                          header={'חיפוש באינדקס'}
                          closeButton={<Button variant='contained' onClick={() => setSearchContactName(false)}>סגור</Button>}
                        >
                          <Grid item xs={12}>
                            <SearchContacts 
                              AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromSearchContactHandler(e)}
                            />
                          </Grid>
                        </Modal>
                      </div>
                    }
                {createContactName && contact_O_r_D === 'O' &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createContactName}
                  onCancel={() => setCreateContactName(false)}
                  header={'צור איש קשר'}
                  closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_o'
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                </Modal>
                </div>
                }
              </Grid>

            </div>
            {/* // } */}
          </>
        }
         
            <div style={{ display: 'flex', marginTop: '15px'}}>
            { typeOfForm[3].includes('choose_tag_then_location') ? 
              <>
              {/* <div style={buttonStepForum_numbrer} >3</div> */}
              <div style={{...buttonStepForum_text,marginBottom: '4px'}}
              >פרטי איש קשר</div>
              </>  : 
              <>
              <div style={buttonStepForum_numbrer} >3</div>
              <div style={buttonStepForum_text}>יעד - כתובת ואיש קשר</div>
              </>
            }
            </div>
            {/* }  */}
            {/* {morDetails6[0] && morDetails6[1] === index && */}
            <div className='mor_details' id="mor_details1">
              
              {autoCompleteD.map((single) => (
                single[0] === obj.num-1
                  ? (
                  <div style={{display: 'flex', flexDirection: 'column', padding: '10px', background: '#262626', borderRadius: '10px', marginBottom: '13px'}}>
                          <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>שם:</div>
                            <div style={{color: '#fff'}}>{single[1].first_name + ' - ' + single[1].last_name}</div>
                          </div>
                          <div style={{display: 'flex', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>טלפון:</div>
                            <div style={{color: '#fff'}}>{single[1].phone1}</div>
                          </div>
                          <div style={{display: 'flex', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>אימייל:</div>
                            <div style={{color: '#fff'}}>{single[1].email}</div>
                          </div>
                          <div style={{display: 'flex', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>כתובת:</div>
                            <div style={{color: '#fff'}}>{single[1].address}</div>
                          </div>
                        </div>
                  )
                  : null
              ))}


          <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={8}>
                  <div className="search">
                      <div className="searchInputs">
                        <input
                          type="text"
                          placeholder={'חיפוש איש קשר'}
                          value={contact_O_r_D === 'D' ? wordEntered : ''}
                          // onChange={handleFilter}
                          onChange={(e) => {
                            setContact_O_r_D('D')
                            onChangeTwo(e)
                            setWordEntered(e.target.value)
                          }}
                        />
                        <div className="searchIcon">
                          {optionsTwo.length === 0 &&
                            <SearchIcon />
                          }
                          {optionsTwo.length !== 0 && contact_O_r_D === 'D' && 
                          <CancelOutlinedIcon id="clearBtn" onClick={clearInput} />
                          }
                        </div>
                    </div>


                    {optionsTwo.length != 0 &&  contact_O_r_D === 'D' &&  (
                      <div className="dataResult">
                          {optionsTwo.slice(0, 15).map((value, key) => {
                          return (
                              <div className="singleResult"
                                onClick={ ()=> {
                                  console.log(value)
                                  const indexExistInArray = autoCompleteD.filter( (single) => {
                                    console.log("single[0] => ",single[0]) 
                                      if (single[0] === obj.num-1){
                                        return single[0] === obj.num-1
                                      }
                                    } 
                                  )
                                console.log("indexExistInArray.length => ", indexExistInArray.length, indexExistInArray)
                                if (indexExistInArray.length === 0){
                                  setAutoCompleteD(  [...autoCompleteD , [obj.num-1, value]])
                                } else if (indexExistInArray.length > 0) {
                                  const newAutoCompleteD = autoCompleteD.filter((single) => single[0] !== obj.num-1);
                                  setAutoCompleteD(  [...newAutoCompleteD , [obj.num-1, value]])
                                }
                                  clearInput()
                                }
                                }>
                                {value.first_name + ' - ' + value.last_name + ' - ' + value.phone1 + ' - ' + value.email + ' - ' + value.address} </div>
                            // </a>
                          );
                        })}
                      </div>
                    )}
                    
                  </div>
                </Grid>

                  <Grid container>
                    <Grid style={{margin: '5px'}}>
                      <Button onClick={() => {
                        setCreateContactName(true)
                        setContact_O_r_D('D')
                        openMapHandler()
                        }} variant='outlined'>צור חדש</Button>
                    </Grid>
                    <Grid style={{margin: '5px'}}>
                      <Button onClick={() => {
                        setSearchContactName(true)
                        setContact_O_r_D('D')
                        // openMapHandler()
                        }} variant='outlined'>חיפוש באינדקס</Button>
                    </Grid>
                  </Grid>
                  {searchContactName && contact_O_r_D === 'D' &&
                    <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                      <Modal
                        show={searchContactName}
                        onCancel={() => setSearchContactName(false)}
                        header={'חיפוש באינדקס'}
                        closeButton={<Button variant='contained' onClick={() => setSearchContactName(false)}>סגור</Button>}
                      >
                        <Grid item xs={12}>
                          <SearchContacts 
                            AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromSearchContactHandler(e)}
                          />
                        </Grid>
                      </Modal>
                    </div>
                  }
                {createContactName && contact_O_r_D === 'D' &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createContactName}
                  onCancel={() => setCreateContactName(false)}
                  header={'צור איש קשר חדש'}
                  // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                  closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_d'
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                </Modal>
                </div>
                }
              </Grid>
            </div>
            {/* } */}
            
        </>
        }
        {typeOfForm[3].includes('choose_tag_then_location') &&
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='textarea'
                  label='הערות'
                  name={`description[${obj.num-1}]`}
                  value=' '
                />
              </Grid>
              }
              {typeOfForm[3].includes('choose_tag_then_location') &&
                <Grid item xs={6}>
                  <FormikControl
                    control='input'
                    type='number'
                    label='מספר קרטונים'
                    name={`packs_cartons[${obj.num-1}]`}
                  />
                </Grid>
              }
            {/* <Grid item xs={12}>
              <Typography>מספר חבילות</Typography>
            </Grid> */}
            {/* <Grid item xs={12}>
              
              <Button
                variant="text"
                // color="primary"
                style={{color: '#fff'}}
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails2 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => {
                  morDetails2[1] === index ? setMorDetails2([!morDetails2[0],index])
                    :  morDetails2[0] === false ?  setMorDetails2([!morDetails2[0],index])
                     : morDetails2[0] === true &&  setMorDetails2([true,index])                  
                  }
                }
                
            ><Typography style={{color: '#fff'}}>מספר חבילות</Typography></Button>
            </Grid> */}


            {/* {morDetails2[0] && morDetails2[1] === index &&

            <div className='mor_details' id="mor_details2">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='משטחים'
                  name={`packs[${obj.num-1}].pack_pallet`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='שקיות'
                  name={`packs[${obj.num-1}].pack_bags`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קרטונים'
                  name={`packs[${obj.num-1}].pack_cartons`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='אנשים'
                  name={`packs[${obj.num-1}].pack_people`}
                />
              </Grid>
            </Grid>
            </div>
            } */}
  </>}
            </div>
            ))}
   {/* {displayDandOForm && */}
              {/* <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  // startIcon={<KeyboardArrowUpIcon/>}
                  endIcon={morDetails5 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  onClick={() => setMorDetails5(!morDetails5)}
                >
                  <Typography>כתובת מוצא</Typography>
                </Button>
              </Grid> */}
            {/* } */}
 {/* <Grid item xs={12}>
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails0 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails0(!morDetails0)}
                
              >
                <Typography>כתובת יעד</Typography>
              </Button>
            </Grid>
            {morDetails0 && 
            // <Grid item xs={12}>
            //   <Typography>כתובת יעד</Typography>
            // </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
            <FormikControl
              control='input'
              type='string'
              label='כותרת מיקום'
              name='title'
            />
            </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  label='קטגוריה'
                  name='cat'
                  options={allMapCats}
                />
              </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='כתובת יעד'
              name='selectedAddressD.address'
            />
            </Grid>
            <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='כניסה'
                  name='selectedAddressD.entry'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קומה'
                  name='selectedAddressD.floor'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='דירה'
                  name='selectedAddressD.apartment'
                />
              </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו אורך lat'
              name='selectedAddressD.lat'
            />
            </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו רוחב lng'
              name='selectedAddressD.lng'
            />
            </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='textarea'
                  label='הערות יעד'
                  name='selectedAddressD.description'
                />
              </Grid>
            </Grid>
          } */}

 {/* {morDetails5 && 

            <Grid container spacing={1}>
            <Grid item xs={12}>
            <FormikControl
              control='input'
              type='string'
              label='כתובת מלאה'
              name='selectedAddressO.address'
            />
            </Grid>
            <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='כניסה'
                  name='selectedAddressO.entry'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קומה'
                  name='selectedAddressO.floor'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='דירה'
                  name='selectedAddressO.apartment'
                />
              </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו אורך lat'
              name='selectedAddressO.lat'
            />
            </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו רוחב lng'
              name='selectedAddressO.lng'
            />
            </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='textarea'
                  label='הערות מוצא'
                  name='selectedAddressO.description'
                />
              </Grid>
            </Grid>
          } */}
            {/* <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם'
                  name='d_contact.d_name'
                />
              </Grid>
              <Grid item xs={6}>
              <FormikControl
                control='input'
                type='string'
                label='טלפון'
                name='d_contact.d_phone'
              />
              </Grid>
              <Grid item xs={6}>
              <FormikControl
                control='input'
                type='string'
                label='כתובת'
                name='d_contact.d_address'
              />
              </Grid>
            </Grid> */}
            {typeOfForm[3].includes('payment') &&
            <>
            <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails3 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails3(!morDetails3)}
                
            ><Typography>פרטי תשלום</Typography></Button>
            </Grid>
            {morDetails3 && 

            <div className='mor_details' id="mor_details2">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='סכום'
                  name='payment.amount'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='צד המשלם'
                  name='payment.whopays'
                  options={[
                    { key: 'מי משלם', value: '' },
                    { key: 'לקוח', value: 'c' },
                    { key: 'מוצא', value: 'o' },
                    { key: 'יעד', value: 'd' },
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='צורת תשלום'
                  name='payment.method'
                  options={[
                    { key: 'צורת תשלום', value: '' },
                    { key: 'מערכת dlance', value: 'dlance' },
                    { key: 'אשראי', value: 'card' },
                    { key: 'העברה בנקאית', value: 'bank' },
                    { key: 'ציק', value: 'check' },
                  ]}
                />
              </Grid>
            </Grid>
            </div>
            }
          </>
          }

          {typeOfForm[3].includes('vehicle') &&
          <>
            {/* {displayDandOForm && */}
              <Grid item xs={12}>
                
                <Button
                  variant="text"
                  color="primary"
                  // startIcon={<KeyboardArrowUpIcon/>}
                  endIcon={morDetails7 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  onClick={() => setMorDetails7(!morDetails7)}
                  
              ><Typography>סוג רכב</Typography></Button>
              </Grid>
            {/* } */}
            {morDetails7 && 

            <div className='mor_details' id="mor_details2">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='סוג רכב'
                  name='vehicle.type'
                  options={[
                    { key: 'רכב', value: 'car' },
                    { key: 'אופנוע', value: 'motorcycle' },
                    { key: 'אופניים', value: 'bicycle' },
                    { key: 'משאית 5 טון', value: 'truck5' },
                    { key: 'משאית 7 טון', value: 'truck7' },
                    { key: 'משאית 10 טון', value: 'truck10' },
                    { key: 'משאית 12 טון', value: 'truck12' },
                    { key: 'משאית 15 טון', value: 'truck15' },
                    { key: 'משאית 18 טון', value: 'truck18' },
                    { key: 'משאית מעל 18 טון', value: 'truck18plus' },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='רמפה'
                  name='vehicle.ramp'
                  options={[
                    { key: 'לא חייב', value: 'no' },
                    { key: 'חייב רמפה', value: 'yes' },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='מלגזה'
                  name='vehicle.forklift'
                  options={[
                    { key: 'לא חייב', value: 'no' },
                    { key: 'חייב מלגזה', value: 'yes' },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='מנוף'
                  name='vehicle.lever'
                  options={[
                    { key: 'לא חייב', value: 'no' },
                    { key: 'חייב מנוף', value: 'yes' },
                  ]}
                />
              </Grid>
            </Grid>
            </div>
            }
          </>
          }

          {typeOfForm[3].includes('physical_work') &&
          <>
            <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails8 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails8(!morDetails8)}
                
            ><Typography>עבודה פיזית</Typography></Button>
            </Grid>
            {morDetails8 && 

            <div className='mor_details' id="mor_details2">
             <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <FormikControl
                  control='select'
                  // type='number'
                  label='בהלוך'
                  name='physical_work.o'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                /> */}
                <FormikControl
                  control='radio'
                  // type='radio'
                  label='בהלוך'
                  name='physical_work.o'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                  handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                />
              </Grid>
              <Grid item xs={6}>
                {/* <FormikControl
                  control='select'
                  // type='number'
                  label='בחזור'
                  name='physical_work.d'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                /> */}
                <FormikControl
                  control='radio'
                  // type='radio'
                  label='בחזור'
                  name='physical_work.d'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                  handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                />
              </Grid>

              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='textarea'
                  label='הערות סבלות'
                  name='physical_work.desc'
                />
              </Grid>
            </Grid>
            </div>
            }
          </>
          }
            {/* <Android12Switch/> */}
            {isTagRouteSelected &&
            <Button variant='contained' type='submit' disabled={!formik.isValid || !isTagRouteSelected}>
              {props.submitTextButton ? props.submitTextButton : 'צור חדש'}
            </Button>
            }
            <Grid item xs={6}>
                <Typography style={{color: 'green'}}>{sendedMessage}</Typography>
            </Grid>
          </Form>
        )
      }}
    </Formik>
    {/* } */}
    </>
  )
}


  // const AddToContactComponnent = ({name_field,nameAddButton, nameLabalField}) => (
  //   <div>
  //     <input 
  //       // value={fieldValueee}   
  //       id={name_field}
  //       type="text"
  //       name={name_field}
  //       onChange={e => {
  //         setFieldValueee(e.target.value)
  //         // setTypeAdd('id_country')
  //         console.log("autoCompleteCCCC")
  //       }} 
  //       />
  //     <div onClick={() => addNewInContactHandler('id_country','הוסף מדינה','שם מדינה')}>הוסף</div>
  //   </div>
  // );

function AddToContactComponnent({name_field,nameAddButton, nameLabalField, mustParent}) {
  const [fieldValueee, setFieldValueee] = useState('');  
  const [addAsFinishedMessage, setAddAsFinishedMessage] = useState('');  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);

  const addNewInContactHandler = async () => {
    // addNewInContactHandler('id_country','הוסף מדינה','שם מדינה')
    console.log("setFieldValue: ",fieldValueee)
    let responseDataContacts;
    try {
      if(fieldValueee.length > 0) {
        var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", 'Bearer ' + auth.token);
          // const responseData = await sendRequest('/api/contacts/add', 'POST', JSON.stringify(values), myHeaders);
          responseDataContacts = await sendRequest('/api/contacts/add', 'POST', JSON.stringify({
            first_name: fieldValueee,
            type: name_field,
            ...(mustParent[0] === true && mustParent[2].length > 1 &&
              {id_parent: mustParent[2]}
              ),
          }), myHeaders);
          console.log("responseDataNEW_TAG_CREATED<>", responseDataContacts)
          setFieldValueee('')
          setAddAsFinishedMessage(`${nameLabalField} חדש נוסף בהצלחה!`)
          // if(props.AutoCompleteCFromAddContactHandler){
          //   props.AutoCompleteCFromAddContactHandler(responseData)
          // }
        // setSendedMessage(`${'שם טיול'} חדש נוצר בהצלחה! ${responseDataContacts.contact._id}`)
        if(responseDataContacts.contact) {
          if(mustParent[0] !== true){
            dispatch(
              uiActions.addAllCountryCityCatSubCatIdsTags({
              _id: responseDataContacts.contact._id,
              user_id: responseDataContacts.contact.user_id,
              type: responseDataContacts.contact.type,
              first_name: responseDataContacts.contact.first_name
              })
            );
          }
          if(mustParent[0] === true){
            dispatch(
              uiActions.addAllCountryCityCatSubCatIdsTags({
              _id: responseDataContacts.contact._id,
              user_id: responseDataContacts.contact.user_id,
              type: responseDataContacts.contact.type,
              first_name: responseDataContacts.contact.first_name,
              parent_id: {
                _id: mustParent[2],
                // user_id: "627538581659696d4c7ff55f",
                type: mustParent[3],
                // first_name: "ישראל",
              }
              })
            );
          }
          // if (props.responseNewTagRoute) {
          //   props.responseNewTagRoute(responseDataContacts.contact)
          // }
        }
      }
    } catch (err) {console.log(err)}
  }

  return (
    <div>
      {mustParent[0] === false || mustParent[0] === true && mustParent[2].length > 1 ?
      <>
        <input 
          value={fieldValueee}   
          id={name_field}
          type="text"
          name={name_field}
          onChange={e => {
            setFieldValueee(e.target.value)
            // setTypeAdd('id_country')
            // console.log("autoCompleteCCCC")
          }} 
          style={{
            height: '43px',
            fontSize: '20px',
            width: '100%',
            border: '1px solid #00214257',
            padding: '7px',
            borderRadius: '4px',
          }}
        />
      
      <div
      // onClick={() => addNewInContactHandler('id_country','הוסף מדינה','שם מדינה')}
        onClick={() => addNewInContactHandler()}
        style={{
          background: '#0f3d6b',
          borderRadius: '21px',
          color: '#fff',
          padding: '3px 0px',
          width: '100px',
          textAlign: 'center',
          height: '24px',
          marginTop: '5px',
        }}
        >הוסף</div>
        {addAsFinishedMessage.length > 0 &&
          <div>{`${addAsFinishedMessage}!`}</div>
        }
      </> :
      <div>{mustParent[1]}</div>
      }
    </div>
  ) 
}

function WarehousesAndClientsClientField(props) {
  return (
          <FormikControl
            control='select'
            // type='number'
            label={props.labal}
            name={props.name}
            options={props.options}
          />
  );
}


export default AddPackagesForTransportation
