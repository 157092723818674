import React, {useContext, useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from "axios";
import FormikControl from './FormikControl'
import { useHistory } from 'react-router'
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';
import { Button, Grid, IconButton, Tab, Tabs, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import SearchIcon from '@mui/icons-material/Search';
import FormikUiAddContact from './FormikUiAddContact';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../UIElements/Modal';



import './UiWithFormik.css';

const getCitiesTwo = async (str) => {
  try {
    let searchableCity = str.replace(/,/g, "");
    let url = "/api/contacts/autocomplete_two?city=" + searchableCity;

    let { data } = await axios.get(url);
    return data;
  } catch (error) {
    console.error(error);
  }
};

function AddEmployeeSchedule (props) {
  const dispatch = useDispatch();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [morDetails0,setMorDetails0] = useState(true)
  const [morDetails1,setMorDetails1] = useState([false,'99'])
  const [morDetails2,setMorDetails2] = useState([false,'99'])
  const [morDetails3,setMorDetails3] = useState(false)
  const [morDetails4,setMorDetails4] = useState(false)
  const [morDetails5,setMorDetails5] = useState(false)
  const [morDetails6,setMorDetails6] = useState([false,'99'])
  const [morDetails7,setMorDetails7] = useState(false)
  const [morDetails8,setMorDetails8] = useState(false)
  const [displayJobForm,setDisplayJobForm] = useState(false)
  const [displayDandOForm,setDisplayDandOForm] = useState(false)
  const [valueDateMode, setValueDateMode] = React.useState(0);
  const [dateType, setDateType] = useState('date');
  const [switchMatrixx, setSwitchMatrixx] = useState('fgfg');
  const [optionsTwo, setOptionsTwo] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [autoCompleteO, setAutoCompleteO] = useState([]);
  // const [autoCompleteO, setAutoCompleteO] = useState({_id: ''});
  const [autoCompleteD, setAutoCompleteD] = useState([]);
  const [contact_O_r_D, setContact_O_r_D] = useState('');
  const [createContactName, setCreateContactName] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [pointNum, setPointNum] = useState([{num: 1}]);
  let points = Array.from(Array(pointNum).keys());

  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);




  const onChangeTwo = async (e) => {
    console.log(e.target)
    if (e.target.value) {
      let data = await getCitiesTwo(e.target.value);
      setOptionsTwo(data);
    }
  };
  
  const clearInput = () => {
    console.log('ffff')
    setOptionsTwo([]);
    setWordEntered("");
  };


let switcher = true;
  useEffect(() => {
  if(props.formPurpesTitle === 'd'){
    setDisplayDandOForm(false);
    }
  if(props.formPurpesTitle !== 'd'){
    setDisplayDandOForm(true);
    }
  },[props.formPurpesTitle])

  useEffect(() => {
    console.log('autoCompleteO => ',autoCompleteO)
  },[autoCompleteO])

  useEffect(() => {
    console.log('pointNum => ',pointNum)
  },[pointNum])


  const handleChange4 = (event, newValuee) => {
    setValueDateMode(newValuee);
  };
  
  const handleSwitchTrueOrFalse = (e) => {
    console.log('switch hes been clicked!');
    switcher = !switcher;
    console.log(switcher);
    // setSwitchMatrixx(switcher);
    // console.log(e)
  };

  const auth = useContext(AuthContext);
  let allMapCats = []

props.mapCatsDropDown.forEach((single, idx, allArray)  => {
  let objVal = single.title;
  if(objVal){
    const obj = {value: single.id, key: single.title};
    allMapCats.push(obj);
    // console.log(single)
  }
})

  const initialValues = {
    title: 'props.selectedAddressD.address',
    unloading_or_loading: '',
    form_purpes: props.formPurpesTitle,
    description: '',
    // email: '',
    // bio: '',
    cat: '',
    selectedAddressD: {
      address: 'props.selectedAddressD.address',
      lat: 'props.selectedAddressD.selectedLat',
      lng: 'props.selectedAddressD.selectedLng',
      entry: '',
      floor: '',
      apartment: '',
      description: ''
    },
    selectedAddressO: {
      address: 'props.selectedAddressO.address',
      lat: 'props.selectedAddressO.selectedLat',
      lng: 'props.selectedAddressO.selectedLng',
      entry: '',
      floor: '',
      apartment: '',
      description: ''
    },
    o_contact_id: autoCompleteO._id,
    o_contact: {
      o_name: '',
      o_phone: '',
      o_address: ''
    },
    d_contact_id: autoCompleteD._id,
    d_contact: {
      d_name: '',
      d_phone: '',
      d_address: ''
    },
    packs: {
      pack_bags: '',
      pack_cartons: '',
      pack_people: '',
      pack_pallet: ''
    },
    vehicle: {
      type: '',
      ramp: '',
      forklift: '',
      lever: ''
    },
    physical_work: {
      d: '',
      o: '',
      desc: ''
    },
    payment: {
      amount: '',
      whopays: '',
      method: '',
    },
    modeOfdate: dateType,
    date_delivery: '',
    day: '',
    matrix: ''
      }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    // cat: Yup.string().required('בחר קטגוריה'),
    // title: Yup.string().required('הזן כותרת')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

  const history = useHistory();

  const onSubmit = async values => {
    console.log('Form data values:', values)
    try {

      var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + auth.token);
      await sendRequest('/api/places/inmap', 'POST', JSON.stringify(values), myHeaders);
      history.push('/maps');
    } catch (err) {console.log(err)}

  }

  return (
    <Formik 
    // enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          // style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px'}}
          <Form style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px', maxWidth: '450px'}}>
            {/* <FormikControl
              control='radio'
              label='צד המשלם'
              name='modeOfContact'
              options={RadioOptions}
            /> */}



            <Grid item xs={12}>
              <Typography  variant="h4" > הוספת  לוז
                {/* {props.formPurpesTitle === 'd' && 'הוספת נקודת יעד'}
                {props.formPurpesTitle === 'd&o' && 'הוספת מוצא ויעד'}
                {props.formPurpesTitle === 'job' && 'הוספת עבודה'} */}
              </Typography>
            </Grid>


            



            <Grid item xs={12}>
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails4 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails4(!morDetails4)}
              >
              <Typography>תאריך הלוז</Typography></Button>
            </Grid>
            {morDetails4 && 
            <div className='mor_details' id="mor_details2">
              <div style={{width: '100%'}}>
                <div value={valueDateMode} className='tabs_date'>
                  {/* <div  label="לפי תאריך" onClick={() => setTypeOfdate('date')}>לפי תאריך</div>
                  <div  label="לפי יום בשבוע" onClick={() => setTypeOfdate('day')}>לפי יום בשבוע</div>
                  <div  label="בקרוב" onClick={() => setTypeOfdate('soon')}>בקרוב</div> */}
                  <div  className='tabs_date_item' style={{ background: dateType === 'date' ? '#002346' : '#525252'}} onClick={() => setDateType('date')}>לפי תאריך</div>
                  <div  className='tabs_date_item' style={{ background: dateType === 'day' ? '#002346' : '#525252'}} onClick={() => setDateType('day')}>לפי יום בשבוע</div>
                  <div  className='tabs_date_item' style={{ background: dateType === 'soon' ? '#002346' : '#525252'}} onClick={() => setDateType('soon')}>בקרוב</div>
                  {/* <div>{rrre}</div> */}
                  {/* <Tab icon={} label="כל המיקומים שלי" onClick={() => {}}/> */}
                </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {dateType === 'date' &&
                <FormikControl
                  control='input'
                  type='date'
                  label=''
                  name='date_delivery'
                />
                }
                {dateType === 'day' &&
                <FormikControl
                  control='select'
                  // type='number'
                  label='בחר יום בשבוע'
                  name='day'
                  options={[
                    { key: 'בחר יום בשבוע', value: '' },
                    { key: 'ראשון', value: '1' },
                    { key: 'שני', value: '2' },
                    { key: 'שלישי', value: '3' },
                    { key: 'רביעי', value: '4' },
                    { key: 'חמישי', value: '5' },
                    { key: 'שישי', value: '6' },
                    { key: 'שבת', value: '7' },
                  ]}
                />
                }
                {dateType === 'soon' &&
                <div className='soonWord'>בקרוב</div>
                }
              </Grid>
            </Grid>
            </div>
            }


        {pointNum.map((obj, index) => (
          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{"width":"35px","height":"35px","background":"#545454","display":"flex","alignItems":"center","justifyContent":"center","color":"#fff","fontSize":"18px","fontWeight":"600","borderRadius":"19px"}}>{index+1}</div>
              {/* <div style={{"width":"35px","height":"35px","background":"#545454","display":"flex","alignItems":"center","justifyContent":"center","color":"#fff","fontSize":"18px","fontWeight":"600","borderRadius":"19px"}}>{obj.num}</div> */}
              <div style={{display: 'flex', flexDirection: 'column', marginRight: '6px', marginLeft: '30px'}}>
                <div>
                  <IconButton aria-label="fingerprint" color="primary"
                    disabled={index === 0 ?  true : false }
                  >
                    <ArrowDropUpIcon 
                     onClick={() => {
                        const lengthOfArr = pointNum.length
                        const objNum = obj.num
                        const pointNumArrSliceBeforeIndexMinusOne = pointNum.slice(0,index-1)
                        const pointNumArrSliceIndex = pointNum.slice(index,index+1)
                        const pointNumArrSliceOneBeforeIndex = pointNum.slice(index-1,index)
                        const pointNumArrSliceAllAfterOneAfterIndex = pointNum.slice(index+1)
                        // console.log("pointNumArrSliceBeforeIndex ",index ,pointNumArrSliceBeforeIndex)
                        // console.log("pointNumArrSliceIndex ",pointNumArrSliceIndex)
                        // console.log("pointNumArrSliceOneAfterIndex ",pointNumArrSliceOneAfterIndex)
                        // console.log("pointNumArrSliceAllAfterOneAfterIndex ",pointNumArrSliceAllAfterOneAfterIndex)
                        let newArrPointNum = [...pointNumArrSliceBeforeIndexMinusOne,...pointNumArrSliceIndex,...pointNumArrSliceOneBeforeIndex,...pointNumArrSliceAllAfterOneAfterIndex]
                        console.log([...pointNumArrSliceBeforeIndexMinusOne,...pointNumArrSliceIndex,...pointNumArrSliceOneBeforeIndex,...pointNumArrSliceAllAfterOneAfterIndex])
                        // console.log("pointNumArrSliceAfter ",pointNum.slice(index))
                        // let newArrPointNum = [...pointNumArrSlice, {num: pointNum.length+1}, ...pointNum.slice(index+1)]
                        // console.log("newArrPointNum: ", newArrPointNum)
                        setPointNum([...newArrPointNum])
                        }
                      }
                    />
                  </IconButton>
                </div>
                <div>
                  <IconButton aria-label="fingerprint" color="primary" 
                  disabled={pointNum.length-1 === index ?  true : false }
                  >

                  <ArrowDropDownIcon 
                      onClick={() => {
                        const lengthOfArr = pointNum.length
                        // console.log("lengthOfArr",index,lengthOfArr)
                        const objNum = obj.num
                        const pointNumArrSliceBeforeIndex = pointNum.slice(0,index)
                        const pointNumArrSliceIndex = pointNum.slice(index,index+1)
                        const pointNumArrSliceOneAfterIndex = pointNum.slice(index+1,index+2)
                        const pointNumArrSliceAllAfterOneAfterIndex = pointNum.slice(index+2)
                        console.log("pointNumArrSliceBeforeIndex ",index ,pointNumArrSliceBeforeIndex)
                        console.log("pointNumArrSliceIndex ",pointNumArrSliceIndex)
                        console.log("pointNumArrSliceOneAfterIndex ",pointNumArrSliceOneAfterIndex)
                        console.log("pointNumArrSliceAllAfterOneAfterIndex ",pointNumArrSliceAllAfterOneAfterIndex)
                        let newArrPointNum = [...pointNumArrSliceBeforeIndex,...pointNumArrSliceOneAfterIndex,...pointNumArrSliceIndex,...pointNumArrSliceAllAfterOneAfterIndex]
                        console.log([...pointNumArrSliceBeforeIndex,...pointNumArrSliceOneAfterIndex,...pointNumArrSliceIndex,...pointNumArrSliceAllAfterOneAfterIndex])
                        // console.log("pointNumArrSliceAfter ",pointNum.slice(index))
                        // let newArrPointNum = [...pointNumArrSlice, {num: pointNum.length+1}, ...pointNum.slice(index+1)]
                        // console.log("newArrPointNum: ", newArrPointNum)
                        setPointNum([...newArrPointNum])
                        }
                      }
                    />
                  </IconButton>
                </div>
              </div>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Fab color="primary" aria-label="add">
                  <AddIcon 
                  onClick={() => {
                    const lengthOfArr = pointNum.length
                    const objNum = obj.num
                    console.log("objNum ",objNum)
                    console.log("index ",index)
                    console.log("lengthOfArr ",lengthOfArr)
                    console.log("pointNum ",pointNum)
                    let pointNumArrSlice = pointNum.slice(0,index+1)
                    console.log("pointNumArrSlice ",pointNumArrSlice)
                    console.log("pointNumArrSliceAfter ",pointNum.slice(index))
                    // pointNum
                    const maxNumOfPointNum = Math.max.apply(Math,pointNum.map(function(o){return o.num;}))
                    // alert('Max y = ' + res);
                    let newArrPointNum = [...pointNumArrSlice, {num: maxNumOfPointNum+1}, ...pointNum.slice(index+1)]
                    console.log("newArrPointNum: ", newArrPointNum)
                    setPointNum([...newArrPointNum])
                    }
                  }
                  />
                </Fab>
                {/* <Fab color="secondary" aria-label="edit">
                  <EditIcon />
                </Fab> */}
                <Fab aria-label="delete"
                disabled={pointNum.length <= 1 ?  true : false }
                >
                  <DeleteForeverIcon 
                   onClick={() => {
                      const pointNumArrSliceBeforeIndex = pointNum.slice(0,index)
                      const pointNumArrSliceAllAfterIndex = pointNum.slice(index+1)
                      let newArrPointNum = [...pointNumArrSliceBeforeIndex,...pointNumArrSliceAllAfterIndex]
                      setPointNum([...newArrPointNum])
                      }
                    }
                  />
                </Fab>
              </Box>
            </div>
            <div>
            {autoCompleteO.map((single) => (
              single[0] === obj.num
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>ל:</div>
                      <div style={{color: '#000'}}>{single[1].first_name}</div>
                    </div>
                )
                : null
              ))}
            {autoCompleteD.map((single) => (
              single[0] === obj.num
                ? (
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                      <div style={{color: '#727272', marginLeft: '10px'}}>תכולה:</div>
                      <div style={{color: '#000'}}>{single[1].first_name}</div>
                    </div>
                )
                : null
              ))}
            </div>
            <div>
              {formik.values.packs[obj.num] && 
              <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                <div style={{color: '#727272', marginLeft: '10px'}}>משטחים:</div>
                <div style={{color: '#000'}}>{formik.values.packs[obj.num].pack_pallet}</div>
              </div>
              }
              {formik.values.unloading_or_loading[obj.num] && 
              <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                <div style={{color: '#000', fontWeight: 'bold'}}>{formik.values.unloading_or_loading[obj.num] === 'unloading' ? 'פריקה' : formik.values.unloading_or_loading[obj.num] === 'loading' && 'העמסה'}</div>
              </div>
              }
            </div>
            
            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12}>
                <FormikControl
                  control='radio'
                  // type='radio'
                  label=''
                  name={`unloading_or_loading[${obj.num}]`}
                  options={[
                    { key: 'פריקה', value: 'unloading' },
                    { key: 'העמסה', value: 'loading' },
                  ]}
                  // handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                />
              </Grid>
            </Grid>



            <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails1 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => {
                  morDetails1[1] === index ? setMorDetails1([!morDetails1[0],index])
                    :  morDetails1[0] === false ?  setMorDetails1([!morDetails1[0],index])
                     : morDetails1[0] === true &&  setMorDetails1([true,index])                  
                  }
                }
                
            ><Typography>נקודת יעד</Typography></Button>
            </Grid>

            {morDetails1[0] && morDetails1[1] === index &&
            
            <div className='mor_details' id="mor_details1">
{/* {data.map((record) => (
    record.list.length > 0
      ? (<YourRenderComponent record={record} key={record.id} />)
      : null
  ))} */}
  {/* {autoCompleteO.map((single) => (
    single[0] === obj.num
      ? (
        <div style={{display: 'flex', flexDirection: 'column', padding: '10px', background: '#262626', borderRadius: '10px'}}>
          <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>שם:</div>
            <div style={{color: '#fff'}}>{single[1].first_name + ' - ' + single[1].last_name}</div>
          </div>
          <div style={{display: 'flex', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>טלפון:</div>
            <div style={{color: '#fff'}}>{single[1].phone1}</div>
          </div>
          <div style={{display: 'flex', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>אימייל:</div>
            <div style={{color: '#fff'}}>{single[1].email}</div>
          </div>
          <div style={{display: 'flex', marginBottom: '5px'}}>
            <div style={{color: '#727272', marginLeft: '10px'}}>כתובת:</div>
            <div style={{color: '#fff'}}>{single[1].address}</div>
          </div>
        </div>
      )
      : null
  ))} */}


          


              <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={8}>
                  {/* <div> */}
                    <div className="search">
                        <div className="searchInputs">
                          <input
                            type="text"
                            placeholder={'חיפוש איש קשר'}
                            value={contact_O_r_D === 'O' ? wordEntered : ''}
                            // onChange={handleFilter}
                            onChange={(e) => {
                              setContact_O_r_D('O')
                              onChangeTwo(e)
                              setWordEntered(e.target.value)
                            }}
                          />
                          <div className="searchIcon">
                            {optionsTwo.length === 0 && 
                              <SearchIcon />
                            }
                            {optionsTwo.length !== 0 && contact_O_r_D === 'O' && 
                            <CancelOutlinedIcon id="clearBtn" onClick={clearInput} />
                            }
                          </div>
                      </div>


                      {optionsTwo.length != 0 && contact_O_r_D === 'O' && (
                        <div className="dataResult">
                          {optionsTwo.slice(0, 15).map((value, key) => {
                            return (
                              // <a className="dataItem" href={value.link} target="_blank">
                                <div className="singleResult"
                                  onClick={ ()=> {
                                    console.log(value)
                                    const indexExistInArray = autoCompleteO.filter( (single) => {
                                      // console.log("single[0] => ",single[0]) 
                                        // if (single[0] === index){
                                          return single[0] === obj.num
                                        // }
                                      } 
                                    )
                                    console.log("indexExistInArray.length => ", indexExistInArray.length, indexExistInArray)
                                    if (indexExistInArray.length === 0){
                                      setAutoCompleteO(  [...autoCompleteO , [obj.num, value]])
                                    } else if (indexExistInArray.length > 0) {
                                      const newAutoCompleteO = autoCompleteO.filter((single) => single[0] !== obj.num);
                                      console.log("newAutoCompleteO:", newAutoCompleteO)
                                      setAutoCompleteO(  [...newAutoCompleteO , [obj.num, value]])
                                    }
                                    clearInput()
                                  }
                                    // (e) => setAutoCompleteId(e)
                                  }>
                                  {value.first_name + ' - ' + value.last_name + ' - ' + value.phone1 + ' - ' + value.email + ' - ' + value.address} </div>
                              // </a>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  {/* </div> */}
                </Grid>
                <Grid item xs={4}>
                  <Button onClick={() => {
                    setCreateContactName(true)
                    openMapHandler()
                    }} variant='outlined'>צור איש קשר</Button>
                </Grid>
                {createContactName &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createContactName}
                  onCancel={() => setCreateContactName(false)}
                  header={'צור איש קשר'}
                  // contentClass="place-item__modal-content"
                  // footerClass="place-item__modal-actions"
                  // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                  closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                </Modal>
                </div>
                }
              </Grid>

            </div>
            }
         

            {displayDandOForm &&
              <Grid item xs={12}>
                
                <Button
                  variant="text"
                  color="primary"
                  // startIcon={<KeyboardArrowUpIcon/>}
                  endIcon={morDetails6 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  onClick={() => {
                    morDetails6[1] === index ? setMorDetails6([!morDetails6[0],index])
                      :  morDetails6[0] === false ?  setMorDetails6([!morDetails6[0],index])
                       : morDetails6[0] === true &&  setMorDetails6([true,index])                  
                    }
                  }
                  
              >
                <Typography>שם תכולה</Typography>
                </Button>
              </Grid>
            } 
            {morDetails6[0] && morDetails6[1] === index &&
            <div className='mor_details' id="mor_details1">
              
              {/* {autoCompleteD.map((single) => (
                single[0] === obj.num
                  ? (
                  <div style={{display: 'flex', flexDirection: 'column', padding: '10px', background: '#262626', borderRadius: '10px'}}>
                          <div style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>שם:</div>
                            <div style={{color: '#fff'}}>{single[1].first_name + ' - ' + single[1].last_name}</div>
                          </div>
                          <div style={{display: 'flex', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>טלפון:</div>
                            <div style={{color: '#fff'}}>{single[1].phone1}</div>
                          </div>
                          <div style={{display: 'flex', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>אימייל:</div>
                            <div style={{color: '#fff'}}>{single[1].email}</div>
                          </div>
                          <div style={{display: 'flex', marginBottom: '5px'}}>
                            <div style={{color: '#727272', marginLeft: '10px'}}>כתובת:</div>
                            <div style={{color: '#fff'}}>{single[1].address}</div>
                          </div>
                        </div>
                  )
                  : null
              ))} */}


          <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={8}>
                  <div className="search">
                      <div className="searchInputs">
                        <input
                          type="text"
                          placeholder={'חיפוש איש קשר'}
                          value={contact_O_r_D === 'D' ? wordEntered : ''}
                          // onChange={handleFilter}
                          onChange={(e) => {
                            setContact_O_r_D('D')
                            onChangeTwo(e)
                            setWordEntered(e.target.value)
                          }}
                        />
                        <div className="searchIcon">
                          {optionsTwo.length === 0 &&
                            <SearchIcon />
                          }
                          {optionsTwo.length !== 0 && contact_O_r_D === 'D' && 
                          <CancelOutlinedIcon id="clearBtn" onClick={clearInput} />
                          }
                        </div>
                    </div>


                    {optionsTwo.length != 0 &&  contact_O_r_D === 'D' &&  (
                      <div className="dataResult">
                        {optionsTwo.slice(0, 15).map((value, key) => {
                          return (
                            // <a className="dataItem" href={value.link} target="_blank">
                              <div className="singleResult"
                                onClick={ ()=> {

                                  console.log(value)
                                  const indexExistInArray = autoCompleteD.filter( (single) => {
                                    console.log("single[0] => ",single[0]) 
                                      if (single[0] === obj.num){
                                        return single[0] === obj.num
                                      }
                                    } 
                                  )
                                console.log("indexExistInArray.length => ", indexExistInArray.length, indexExistInArray)
                                if (indexExistInArray.length === 0){
                                  setAutoCompleteD(  [...autoCompleteD , [obj.num, value]])
                                } else if (indexExistInArray.length > 0) {
                                  const newAutoCompleteD = autoCompleteD.filter((single) => single[0] !== obj.num);
                                  setAutoCompleteD(  [...newAutoCompleteD , [obj.num, value]])
                                }

                                }
                                  // setAutoCompleteD(value)
                                  // (e) => setAutoCompleteId(e)
                                }>
                                {value.first_name + ' - ' + value.last_name + ' - ' + value.phone1 + ' - ' + value.email + ' - ' + value.address} </div>
                            // </a>
                          );
                        })}
                      </div>
                    )}
                    
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Button onClick={() => {
                    setCreateContactName(true)
                    openMapHandler()
                    }} variant='outlined'>צור איש קשר</Button>
                </Grid>
                {createContactName &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createContactName}
                  onCancel={() => setCreateContactName(false)}
                  header={'צור איש קשר חדש'}
                  // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                  closeButton={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                </Modal>
                </div>
                }
              </Grid>
            </div>
            }

            {/* <Grid item xs={12}>
              <Typography>מספר חבילות</Typography>
            </Grid> */}
            <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails2 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => {
                  morDetails2[1] === index ? setMorDetails2([!morDetails2[0],index])
                    :  morDetails2[0] === false ?  setMorDetails2([!morDetails2[0],index])
                     : morDetails2[0] === true &&  setMorDetails2([true,index])                  
                  }
                }
                
            ><Typography>מספר חבילות</Typography></Button>
            </Grid>


            {morDetails2[0] && morDetails2[1] === index &&

            <div className='mor_details' id="mor_details2">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='משטחים'
                  name={`packs[${obj.num}].pack_pallet`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='שקיות'
                  name={`packs[${obj.num}].pack_bags`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קרטונים'
                  name={`packs[${obj.num}].pack_cartons`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikControl
                  control='input'
                  type='number'
                  label='אנשים'
                  name={`packs[${obj.num}].pack_people`}
                />
              </Grid>
            </Grid>
            </div>
            }
            </div>
            ))}
   {/* {displayDandOForm &&
              <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  // startIcon={<KeyboardArrowUpIcon/>}
                  endIcon={morDetails5 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  onClick={() => setMorDetails5(!morDetails5)}
                >
                  <Typography>כתובת מוצא</Typography>
                </Button>
              </Grid>
            } */}
 {/* <Grid item xs={12}>
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails0 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails0(!morDetails0)}
                
              >
                <Typography>כתובת יעד</Typography>
              </Button>
            </Grid>
            {morDetails0 && 
            // <Grid item xs={12}>
            //   <Typography>כתובת יעד</Typography>
            // </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
            <FormikControl
              control='input'
              type='string'
              label='כותרת מיקום'
              name='title'
            />
            </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  label='קטגוריה'
                  name='cat'
                  options={allMapCats}
                />
              </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='כתובת יעד'
              name='selectedAddressD.address'
            />
            </Grid>
            <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='כניסה'
                  name='selectedAddressD.entry'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קומה'
                  name='selectedAddressD.floor'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='דירה'
                  name='selectedAddressD.apartment'
                />
              </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו אורך lat'
              name='selectedAddressD.lat'
            />
            </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו רוחב lng'
              name='selectedAddressD.lng'
            />
            </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='textarea'
                  label='הערות יעד'
                  name='selectedAddressD.description'
                />
              </Grid>
            </Grid>
          } */}

 {/* {morDetails5 && 

            <Grid container spacing={1}>
            <Grid item xs={12}>
            <FormikControl
              control='input'
              type='string'
              label='כתובת מלאה'
              name='selectedAddressO.address'
            />
            </Grid>
            <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='כניסה'
                  name='selectedAddressO.entry'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קומה'
                  name='selectedAddressO.floor'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='דירה'
                  name='selectedAddressO.apartment'
                />
              </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו אורך lat'
              name='selectedAddressO.lat'
            />
            </Grid>
            <Grid item xs={6}>
            <FormikControl
              control='input'
              type='string'
              label='קו רוחב lng'
              name='selectedAddressO.lng'
            />
            </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='textarea'
                  label='הערות מוצא'
                  name='selectedAddressO.description'
                />
              </Grid>
            </Grid>
          } */}
            {/* <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם'
                  name='d_contact.d_name'
                />
              </Grid>
              <Grid item xs={6}>
              <FormikControl
                control='input'
                type='string'
                label='טלפון'
                name='d_contact.d_phone'
              />
              </Grid>
              <Grid item xs={6}>
              <FormikControl
                control='input'
                type='string'
                label='כתובת'
                name='d_contact.d_address'
              />
              </Grid>
            </Grid> */}
            {/* <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails3 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails3(!morDetails3)}
                
            ><Typography>פרטי תשלום</Typography></Button>
            </Grid> */}
            {/* {morDetails3 && 

            <div className='mor_details' id="mor_details2">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='סכום'
                  name='payment.amount'
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='צד המשלם'
                  name='payment.whopays'
                  options={[
                    { key: 'מי משלם', value: '' },
                    { key: 'לקוח', value: 'customer' },
                    { key: 'מוצא', value: 'o' },
                    { key: 'יעד', value: 'd' },
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='צורת תשלום'
                  name='payment.method'
                  options={[
                    { key: 'צורת תשלום', value: '' },
                    { key: 'מערכת dlance', value: 'dlance' },
                    { key: 'אשראי', value: 'card' },
                    { key: 'העברה בנקאית', value: 'bank' },
                    { key: 'ציק', value: 'check' },
                  ]}
                />
              </Grid>
            </Grid>
            </div>
            } */}
            {/* {displayDandOForm &&
              <Grid item xs={12}>
                
                <Button
                  variant="text"
                  color="primary"
                  // startIcon={<KeyboardArrowUpIcon/>}
                  endIcon={morDetails7 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  onClick={() => setMorDetails7(!morDetails7)}
                  
              ><Typography>סוג רכב</Typography></Button>
              </Grid>
            } */}
            {/* {morDetails7 && 

            <div className='mor_details' id="mor_details2">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='סוג רכב'
                  name='vehicle.type'
                  options={[
                    { key: 'רכב', value: 'car' },
                    { key: 'אופנוע', value: 'mc' },
                    { key: 'משאית', value: 'tr' },
                    { key: 'אופניים', value: 'b' },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='רמפה'
                  name='vehicle.ramp'
                  options={[
                    { key: 'לא חייב', value: 'no' },
                    { key: 'חייב רמפה', value: 'yes' },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='מלגזה'
                  name='vehicle.forklift'
                  options={[
                    { key: 'לא חייב', value: 'no' },
                    { key: 'חייב מלגזה', value: 'yes' },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='מנוף'
                  name='vehicle.lever'
                  options={[
                    { key: 'לא חייב', value: 'no' },
                    { key: 'חייב מנוף', value: 'yes' },
                  ]}
                />
              </Grid>
            </Grid>
            </div>
            } */}
            {/* <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails8 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails8(!morDetails8)}
                
            ><Typography>עבודה פיזית</Typography></Button>
            </Grid> */}
            {/* {morDetails8 &&  */}

            {/* <div className='mor_details' id="mor_details2"> */}
            {/* <Grid container spacing={2}> */}
              {/* <Grid item xs={6}>
                <FormikControl
                  control='select'
                  // type='number'
                  label='בהלוך'
                  name='physical_work.o'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                />
                <FormikControl
                  control='radio'
                  // type='radio'
                  label='בהלוך'
                  name='physical_work.o'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                  handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                {/* <FormikControl
                  control='select'
                  // type='number'
                  label='בחזור'
                  name='physical_work.d'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                /> */}
                {/* <FormikControl
                  control='radio'
                  // type='radio'
                  label='בחזור'
                  name='physical_work.d'
                  options={[
                    { key: 'לא', value: 'no' },
                    { key: 'כן', value: 'yes' },
                  ]}
                  handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                /> */}
              {/* </Grid> */}

              {/* <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='textarea'
                  label='הערות סבלות'
                  name='physical_work.desc'
                />
              </Grid> */}
            {/* </Grid> */}
            {/* </div> */}
            {/* } */}
            
            {/* <Android12Switch/> */}
            
            <Button variant='outlined' type='submit' disabled={!formik.isValid}>
              צור לוז
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddEmployeeSchedule
