import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import "./conversation.css";

export default function Conversation(props, { conversation, currentUser }) {
  const [user, setUser] = useState(null);
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
{/* <ul className="users-list">
      {props.items.map(user => (
        <UserItem
          key={user.id}
          id={user.id}
          image={user.image}
          name={user.name}
          placeCount={user.places.length}
        />
      ))}
    </ul> */}


  // useEffect(() => {
  //   const friendId = conversation.members.find((m) => m !== currentUser._id);

  //   const getUser = async () => {
  //     try {
  //       const res = await axios("/users?userId=" + friendId);
  //       setUser(res.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getUser();
  // }, [currentUser, conversation]);

  return (
    <div className="conversation">
      <div>
        <img
          className="conversationImg"
          src={
            props.image
              ? props.image 
              : PF + "person/noAvatar.png"
          }
          alt=""
          />
      </div>
      <div className="conversationLeft">
        <div>
          <span className="conversationName">{props.name}</span>
          {/* <span className="conversationName">{props.id}</span> */}
        </div>
        <div>
          <span style={{fontSize: '10px'}}>{props.text}</span>  
        </div>
      </div>
      
    </div>
  );
}
