import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UsersList from '../components/UsersList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';

const Users = () => {

  const userProfile = useSelector((state) => state.ui.userProfile);
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedUsers, setLoadedUsers] = useState();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          '/api/users'
        );
        console.log("responseDatausers", responseData)
        
        // userProfile.connected_movers &&
        // userProfile.connected_movers.map(elem => {
        //   setOptionsConnected_movers(
        //     [...optionsConnected_movers, {key: elem.business_name, value: elem._id}]
        //     )
        // }

        // TO DO
        try {
          
          if (responseData.users && userProfile.connected_movers) {
            userProfile.connected_movers.forEach((mover, index) => {
              responseData.users.forEach((user, indx) => {
                if (user._id === mover._id) {
                  responseData.users[indx].mover_connected_to_user = true
                }
              })
              // const isMatchToConnectedMover = responseData.users.filter((e) => {
              //   // console.log("element, e:", element, e)
              //   return e._id === element._id
              // })
              // console.log("isMatchToConnectedMover:", isMatchToConnectedMover)
            });
          }
        } catch (error) {
          console.log("element, e error:", error)
        }
      
        setLoadedUsers(responseData.users);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center" style={{marginTop: '30px'}}>
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedUsers && <UsersList items={loadedUsers} />}
    </React.Fragment>
  );
};

export default Users;
