import React from 'react'
import Input from './InputUiFormik'
import AddressInput from './AddressInput'
import Textarea from './Textarea'
import Select from './SelectUi'
import RadioButtons from './RadioButtons'
import CheckboxGroup from './CheckboxGroup'
import DatePicker from './DatePicker'
import ChakraInput from './ChakraInput'
import SwitchButton from './SwitchButton'

function FormikControl (props) {
  const { control, ...rest } = props
  switch (control) {
    case 'input':
      return <Input {...rest} />
    case 'addressInput':
      return <AddressInput {...rest} />
    case 'textarea':
      return <Textarea {...rest} />
    case 'select':
      return <Select {...rest} />
    case 'radio':
      return <RadioButtons {...rest} />
    case 'checkbox':
      return <CheckboxGroup {...rest} />
    case 'date':
      return <DatePicker {...rest} />
    case 'chakraInput':
      return <ChakraInput {...rest} />
    case 'switch':
      return <SwitchButton {...rest} />
    default:
      return null
  }
}

export default FormikControl
