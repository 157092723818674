import React from 'react';

export const AllCarTypes = [
    { key: "משלוחים - רכב פרטי", value: 'privet_car' },
    { key: "משלוחים - רכב מסחרי", value: "commercial_car" },
    { key: "משלוחים - אופניים", value: "bicycle" },
    { key: "משלוחים - אופנוע", value: "motorcycle" },
    { key: "הובלות -  רכב ונגררת", value: "vehicle_with_trailer" },
    { key: "הובלות -  משאית עד 10 טון", value: "truck_up_to_10_tons" },
    { key: "הובלות -  משאית מעל 10 טון", value: "truck_over_to_10_tons" },
    { key: "הובלות -  משאית קירור", value: "refrigerated_truck" },
    { key: "הובלות -  מנוף הרמה", value: "lifting_cranes" },
    { key: "גררי רכב", value: "tow_truck" },
];
export const AllBizTypes = [
    { key: "מסעדות", value:  "Restaurants" },
    { key: "קינוח", value: "Dessert" },
    { key: "ביס מהיר", value: "Quick Bites" },
    { key: "מאפיות", value: "Bakeries" },
    { key: "קפה תה", value: "Coffee & Tea" },
    { key: "ברים ופאבים", value: "Bars & Pubs" },
    { key: "שוק אוכל מיוחד", value: "Specialty Food Market" },
    { key: "משלוח בלבד", value: "Delivery Only" },
    { key: "הובלות -  מנוף הרמה", value: "lifting_cranes" },
    { key: "סעדו עם שף מקומי", value: "Dine With a Local Chef" },
];

export const AllCarTypesHE = (props) => {
    return (
        <>
            {AllCarTypes.map((single) => (
                <>
                    {single.value === props.carType &&
                        <div style={{fontSize: '12px', paddingTop: '4px', color: '#767676',  fontWeight: '500'}}>{single.key}</div>
                    }
                </>
            ))
            }
        </>
    );
    
}
    // { key: "משלוחים - רכב פרטי", value: 'privet_car' },
    // { key: "משלוחים - רכב מסחרי", value: "commercial_car" },
    // { key: "משלוחים - אופניים", value: "bicycle" },
    // { key: "משלוחים - אופנוע", value: "motorcycle" },
    // { key: "הובלות -  רכב ונגררת", value: "vehicle_with_trailer" },
    // { key: "הובלות -  משאית עד 10 טון", value: "truck_up_to_10_tons" },
    // { key: "הובלות -  משאית מעל 10 טון", value: "truck_over_to_10_tons" },
    // { key: "הובלות -  משאית קירור", value: "refrigerated_truck" },
    // { key: "הובלות -  מנוף הרמה", value: "lifting_cranes" },
    // { key: "גררי רכב", value: "tow_truck" },


// export default AllCarTypes;