import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux';

import { uiActions } from '../../store/ui-slice';
import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ImageUpload from '../../shared/components/FormElements/ImageUpload';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE
} from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './Auth.css';
// import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
            
const Auth = (props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.ui.userProfile);
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [isForgetMode, setIsForgetMode] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState(false);
  const [verifyForgetStatus, setVerifyForgetStatus] = useState('try');
  const [chackEmailForNewPass, setChackEmailForNewPass] = useState('try');
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  let { useridacc, verifyacc, forgetacc } = useParams();
  

  // useEffect(() => {
  //   // console.log("params:", params)
  //   if(useridacc && forgetacc) {
  //   const verifyForgetHandler = async () => {
  //     try {
  //       const responseData = await sendRequest(
  //         '/api/users/verifyforget',
  //         'POST',
  //         JSON.stringify({
  //           useridacc,
  //           forgetacc
  //         }),
  //         {
  //           'Content-Type': 'application/json'
  //         }
  //       );
  //       console.log("responseData:Login: ", responseData)
  //       if (responseData.verification) {
  //         setVerifyForgetStatus(true)
  //         setTimeout(() => {
  //           console.log('setTimeout')
  //           window.location.replace('../../../auth');
  //         }, 4000);
  //       } else {
  //         setVerifyForgetStatus(false)
  //         // window.location.replace(host + '/fff');
  //       }
        
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   }
  //     verifyForgetHandler()
  //   }
  // }, [verifyacc])



  useEffect(() => {
    // console.log("params:", params)
    if(useridacc && verifyacc) {
    const verifyHandler = async () => {
      try {
        const responseData = await sendRequest(
          '/api/users/verify',
          'POST',
          JSON.stringify({
            useridacc,
            verifyacc
          }),
          {
            'Content-Type': 'application/json'
          }
        );
        console.log("responseData:Login: ", responseData)
        if (responseData.verification) {
          setVerifyStatus(true)
          setTimeout(() => {
            console.log('setTimeout')
            window.location.replace('../../../auth');
          }, 4000);
        } else {
          setVerifyStatus(false)
          // window.location.replace(host + '/fff');
        }
        
      } catch (err) {
        console.log(err)
      }
    }
      verifyHandler()
    }

  }, [verifyacc])

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      }
    },
    false
  );

  const switchModeHandler = () => {
    setIsForgetMode(false)
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
          // image: undefined
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: '',
            isValid: false
          },
          // image: {
          //   value: null,
          //   isValid: false
          // }
        },
        false
      );
    }
    setIsLoginMode(prevMode => !prevMode);
  };

  const forgetHandler = async () => {
    console.log("fff")
      try {
        const responseData = await sendRequest(
          '/api/users/forget',
          'POST',
          JSON.stringify({
            email: formState.inputs.email.value,
          }),
          {'Content-Type': 'application/json'}
        );
        console.log("responseData:Login: ", responseData)
        setChackEmailForNewPass(true)
      } catch (err) {
        setChackEmailForNewPass(false)
        console.log("err FORGET", err)
      } 
  };

  const newPassHandler = async () => {
    console.log("newPassHandler - pass:", formState.inputs.password.value)
    
      try {
        const responseData = await sendRequest(
          '/api/users/newpass',
          'POST',
          JSON.stringify({
            password: formState.inputs.password.value,
            useridacc,
            forgetacc
          }),
          {'Content-Type': 'application/json'}
        );
        console.log("responseData:Forget: ", responseData)
        if (responseData.verification === true) {
          setVerifyForgetStatus(true)
          setTimeout(() => {
            console.log('setTimeout')
            window.location.replace('../../../auth');
          }, 4000);
        }
        if (responseData.verification === false) {
          setVerifyForgetStatus(false)
        }
      } catch (err) {
        console.log("newPassHandlerERROR:", err)
      } 
  };


  const authSubmitHandler = async event => {
    event.preventDefault();

    if (isLoginMode) {
      try {
        const responseData = await sendRequest(
          '/api/users/login',
          'POST',
          JSON.stringify({
            email: formState.inputs.email.value,
            password: formState.inputs.password.value
          }),
          {
            'Content-Type': 'application/json'
          }
        );
        console.log("responseData:Login: ", responseData)
        auth.login(responseData.userId, responseData.token);
        // dispatch(
        //   uiActions.setUserProfile({
        //     name: responseData.userProfile.name,
        //     id: responseData.userProfile.id,
        //     image: responseData.userProfile.image,
        //     email: responseData.userProfile.email,
        //   })
        // );
        dispatch(
          uiActions.setUserProfile(responseData.userProfile)
        );
        localStorage.setItem(
          'userProfile',
          JSON.stringify(responseData.userProfile)
        );
        // window.location = "./maps"
        // window.location.reload('./maps');
        
        // history.go('/Maps');
      } catch (err) {}
    } else {
      try {
        const formData = new FormData();
        formData.append('email', formState.inputs.email.value);
        formData.append('name', formState.inputs.name.value);
        formData.append('password', formState.inputs.password.value);
        // formData.append('image', formState.inputs.image.value);
        const responseData = await sendRequest(
          '/api/users/signup',
          'POST',
          formData
        );
          console.log("responseData:signUP: ", responseData)
          // dispatch(
          //   cartActions.replaceselectedLngLat_d({
          //     selectedAddress: near_place.formatted_address,
          //     selectedLat: near_place.geometry.location.lat(),
          //     selectedLng: near_place.geometry.location.lng(),
          //   })
          // );
        // auth.login(responseData.userId, responseData.token);
        setConfirmEmail(true)
      } catch (err) {}
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {!confirmEmail && !props.verifyAccount &&
        <Card className="authentication">
          {isLoading && <LoadingSpinner asOverlay />}
          <form onSubmit={authSubmitHandler}>
            {!isLoginMode && (
              <Input
                element="input"
                id="name"
                type="text"
                label={t("Full Name")}
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("Please enter your full name")}
                onInput={inputHandler}
              />
            )}
            {/* {!isLoginMode && (
              <ImageUpload
                center
                id="image"
                onInput={inputHandler}
                errorText="הכנס תמונה."
              />
            )} */}
            {!props.forgetAccount &&
            <Input
              element="input"
              id="email"
              type="email"
              label={t("Email")}
              validators={[VALIDATOR_EMAIL()]}
              errorText={t("Please enter your email address")}
              onInput={inputHandler}
            />
            }
            {!isForgetMode &&
              <Input
                element="input"
                id="password"
                type="password"
                label={t("Password")}
                validators={[VALIDATOR_MINLENGTH(6)]}
              errorText={t("Please enter a strong password, at least 6 characters.")}
              onInput={inputHandler}
            />
            }
            {isForgetMode && chackEmailForNewPass === 'try' &&
              <Button onClick={forgetHandler} type="button" disabled={!formState.inputs.email.isValid}>{t("Send password to email")}</Button>
            }
            {isForgetMode && chackEmailForNewPass === true &&
              <>
              <div>{t("At this moment an email was sent to you with instructions to reset your password") }</div>                
              </>
            }

            {isForgetMode && chackEmailForNewPass === false &&
              <>
                <div>{t("Oops! Something's wrong.")}</div>
              <div style={{ marginBottom: '20px' }}>{t("Try again.")}</div>
            
                <NavLink exact to={'../../../auth'} style={{ textDecoration: 'none', backgroundColor: '#fff' }}>
                  <div>{ t("connect to existing account") }</div>
                </NavLink>
              </>
            }

            {props.forgetAccount && verifyForgetStatus === 'try' &&
              <Button
                onClick={newPassHandler} type="button"
                // disabled={!formState.isValid}
              >{t("Create a new password")}</Button>
            }
            {props.forgetAccount && verifyForgetStatus === true &&
                <>
                  <div>{t("Your account has been successfully verified!")}</div>
              <div style={{ marginBottom: '20px' }}>{t("You are transferred to the login page,")}</div>
              <LoadingSpinner />
                
                </>
              }
            {props.forgetAccount && verifyForgetStatus === false &&
              <>
                <div>{t("Oops! Something's wrong.")}</div>
              <div style={{ marginBottom: '20px' }}>{t("Try again.")}</div>
            
                <NavLink exact to={'../../../auth'} style={{ textDecoration: 'none', backgroundColor: '#fff' }}>
                  <div>{ t("connect to existing account") }</div>
                </NavLink>
              </>
            }
            {!isForgetMode && !props.forgetAccount &&
              <Button type="submit" disabled={!formState.isValid}>
                {isLoginMode ? t("connect__NL") : t("create new account")}
              </Button>
            }
          </form>
          {!props.forgetAccount &&
            <Button inverse onClick={switchModeHandler}>
            {isLoginMode ? t("Switch to create new account") : t("Switch to connect to an existing account")}
            </Button>
          }

          {isLoginMode && !props.forgetAccount &&
            <div onClick={() => setIsForgetMode(true)} style={{ marginTop: '25px', fontSize: '11px', textDecoration: 'underline' }}>{t("I forgot my password. Can I create a new one?")}</div>
          }
        </Card>
      } 
      {confirmEmail &&
        <Card className="authentication">
          <div>{t("Congratulations for opening a new account on the website!")}</div>
          <div>{t("Please log in to your email and confirm the account.")}</div>
          <div>{t("After verifying your account, you will be able to log in to this website.")}</div>
        </Card>
      }
      {props.verifyAccount &&    
        <Card className="authentication">
          <div>
              {/* {verifyacc} -- {useridacc} */}
              {verifyStatus === true &&
                <>
              <div>{t("Your account has been successfully verified!")}</div>
              <div style={{ marginBottom: '20px' }}>{t("You are transferred to the login page,")}</div>
              <LoadingSpinner />
                
                </>
              }
              {verifyStatus === false &&
                <>
              <div>{t("Oops! Something's wrong.")}</div>
              <div style={{ marginBottom: '20px' }}>{t("Try again.")}</div>
              
                  <NavLink exact to={'../../../auth'} style={{ textDecoration: 'none', backgroundColor: '#fff' }}>
                <div>{ t("connect to existing account") }</div>
                  </NavLink>
                </>
              }
            
            </div>
        </Card>
      }
    </React.Fragment>
  );
};

export default Auth;
