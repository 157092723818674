import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useParams
} from 'react-router-dom';
import { Fragment, useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { usePWAInstallPrompt } from '..'
import ReactGA from 'react-ga';

import { uiActions } from './store/ui-slice';
import Layout from './shared/components/miu/Layout';
import Pages from './shared/components/miu/Pages';
import AddXlsx from './shared/components/FormikElements/AddXlsx';
// import Socket from './socket/Socket.js';
// import Mui from './shared/components/miu/Mui';
import Users from './user/pages/Users';
// import Maps from './maps/Maps';
import Contacts from './contacts/Contacts';
import Items from './items/Items';
import Store from './shopping/pages/Store';
// import Mapss from './maps/Mapss';
import NewPlace from './places/pages/NewPlace';
import UserPlaces from './places/pages/UserPlaces';
import UpdatePlace from './places/pages/UpdatePlace';
// import CalculateRoute from './items/CalculateRoute';
// import Messenger from "./pages/messenger/Messenger";
import Messenger from "./messenger/Messenger";
import Home from "./home/Home";
// import Leaflet from "./leaflet/Leaflet";
// import MapLeaflet from "./mapLeaflet/MapLeaflet";
import Auth from './user/pages/Auth';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import Cart from './shared/components/Cart/Cart';
// import Notification from './shared/components/UIElements/Notification';
import { sendCartData, fetchCatsMapData ,fetchCartData } from './store/places-actions';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Axios from 'axios';
import {usePWAInstallPrompt} from './index'
import { useTranslation } from 'react-i18next';
// import UserImages from './userImages/UserImages';
// import Userimages from './userImages/Userimages';
import Images from './images/Images';
import Coupons from './coupons/Coupons';

// import Aibuilder from './aibuilder/Aibuilder'
// import "./styles/bootstrap-custom.scss"

const TRACKING_ID = "UA-46291917-2";
ReactGA.initialize(TRACKING_ID);

const theme = createTheme(
  {
    palette: {
      primery: {
        main: '#fefefe'
      }
    }
  }
)
// const useStyles = makeStyles((theme) => ({
//   palette: {
//     primery: {
//       main: '#fefefe'
//     }
//   }
// }));

let isInitial = true;
let isLatLngChanged = {lng: 0, lat: 0}

const App = () => {
  for (const element of document.querySelectorAll(".css-1sucic7")) {
    element.style.zIndex = 0
  }
  const { t, i18n } = useTranslation();
  
  const lng = navigator.language;
  
  const { token, login, logout, userId } = useAuth();
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.ui.notification);
  const showCart = useSelector((state) => state.ui.cartIsVisible);
  const userProfile = useSelector((state) => state.ui.userProfile);
  const auth = useContext(AuthContext);

  


  const [deferredPrompt, handlePrompt] = usePWAInstallPrompt(); // PWA DOWNLOAD
  const [refreshPWA, setRefreshPWA] = useState(true); // PWA DOWNLOAD
  const [pwaHide, setPwaHide] = useState(true); // PWA DOWNLOAD
  const [usersLngLat, setUsersLngLat] = useState({lat: null, lng: null});
  const [getAgainUsersLocation, setGetAgainUsersLocation] = useState(false);
  const [nearLocations, setNearLocations] = useState([]);
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [window.location.pathname])


  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()])


  
  const Pwa = async () => {
    if (deferredPrompt && refreshPWA) {
      deferredPrompt.prompt();
      try {
        const choiceResult = await deferredPrompt.userChoice;
        if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
            setPwaHide(false);
        } else {
          setRefreshPWA(false)   
        }
      } catch(err) {
        console.error(err)
      }
    }
else {
alert("problem");
}
}



      // Download PWA
    // const [deferredPrompt, setPrompt] = usePWAInstallPrompt()
    // const installPWA = async () => {
    //     if (deferredPrompt) {
    //         deferredPrompt.prompt();
    //         const choiceResult = await deferredPrompt.userChoice;
    //         if (choiceResult.outcome) {
    //             console.log('user accepted the A2HD prompt')
    //         }
    //         else {
    //             console.log('User dimissed the A2HS prompt');
    //         }
    //     }
    // }
  
  // const [optionsManagersIds, setOptionsManagersIds] = useState([{key: userProfile.name, value: userProfile._id}]);


  useEffect(() => {
    const storedUserProfile = JSON.parse(localStorage.getItem('userProfile'));
    if(storedUserProfile){
      dispatch(
        uiActions.setUserProfile(storedUserProfile)
      );
    }
  }, []);

  
  // useEffect(() => {
  //   if(isLatLngChanged.lng !== usersLngLat.lng){
  //     console.log("usersLngLat CHANGED", usersLngLat)
  //     console.log("isLatLngChanged CHANGED", isLatLngChanged)
  //   }
  //   isLatLngChanged.lng = usersLngLat.lng
  //   isLatLngChanged.lat = usersLngLat.lat
  // }, [usersLngLat]);


  useEffect(() => {
    const myTimer = () => {
      navigator.geolocation.getCurrentPosition(position => {
        console.log(position)
        console.log("position.coords.latitude:", position.coords.latitude)
        console.log("position.coords.longitude:", position.coords.longitude)
        dispatch(
          uiActions.setUsersLocation({lat: position.coords.latitude, lng: position.coords.longitude})
        );
        setUsersLngLat({lat: position.coords.latitude, lng: position.coords.longitude})
      })
    }
    const myInterval = setInterval(myTimer, 50000000000000000);

    // ===GET NEAR LOCATIONS===

    // ===END GET NEAR LOCATIONS===
 
  // }, [getAgainUsersLocation]);
  }, []);
 

  let routes;
  
  useEffect(() => {
    dispatch(fetchCartData());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchCatsMapData());
  }, [dispatch]);
  // useEffect( () => {
  //   // $json = file_get_contents('https://api.konimbo.co.il/v1/items/?token=b38320501085fbd13056e9f473a71eadeae087d38140c387f4ca047a413339da');
  //   // Axios.get("https://api.konimbo.co.il/v1/items/?token=b38320501085fbd13056e9f473a71eadeae087d38140c387f4ca047a413339da").then((response_bb) => {
  //   Axios.get("https://haredimnet.co.il/").then((response_bb) => {
  //     console.log("response_bb", response_bb)
  //     // setAdsList(response.data);
  //   });  
  //   // console.log("AdsList:", AdsList);
  // },[])
  
  if (token && localStorage.getItem('userData')) {
    routes = (
      <Switch>
        {/* <Route path="/" exact> */}
        {/* <div> - - - </div> */}
          {/* <Users /> */}
        {/* </Route> */}

        {/* {userProfile._id === '627538581659696d4c7ff55f' ? */}
        
        <Route path="/xlsx" exact>
          {/* <Auth /> */}
          <AddXlsx
            typeOfForm={[
              'c_o_d','הוספת פריט','הוספת פריט הכוללת לקוח, מוצא ויעד', 
              ['date_delivery',
                'contact_O_r_D', 'id_connected_mover', 'id_connected_businesses'],
            ]}
      />
          {/* <div>fff</div> */}
        </Route>
        <Route path="/allusers" exact>
          {/* <Auth /> */}
          <Users />
        </Route>
        {/* : */}
        {/* <Route path="/allusers" exact> */}
          {/* <Auth /> */}
          {/* <Users /> */}
        {/* </Route> */}
        {/* } */}
        
        {/* <Route path="/mapleaflet" exact>
          <MapLeaflet />
        </Route> */}
        {/* <Route path="/leaflet" exact>
          <Leaflet />
        </Route> */}
        {/* <Route path="/aibuilder" exact>
          <Aibuilder />
        </Route> */}
        {/* <Route path="/home" exact>
          <Home />
        </Route> */}
        <Route path="/images/:typeOfCat" exact>
          {/* <UserImages /> */}
          <Images />
          {/* <Home /> */}
        </Route>
        <Route path="/coupons/:typeOfCat" exact>
          <Coupons />
        </Route>
        {/* <Route path="/calculateroute" exact>
          <CalculateRoute />operation={'calculateroute'}
        </Route> */}
        <Route path="/store">
            <Store />
        </Route>
        {/* <Route path="/maps" exact> */}
          {/* <Maps /> */}
          {/* <Mapss /> */}
        {/* </Route> */}
        <Route path="/contacts/:typeOfContacts" exact>
          <Contacts />
          {/* <Mapss /> */}
        </Route>
        <Route path="/items/:typeOfItems" exact>
          <Items />
          {/* <Mapss /> */}
        </Route>
        {/* <Route path="/chat" exact>
          <Socket />
        </Route> */}
        <Route path="/messenger">
          {/* {!user ? <Redirect to="/" /> : <Messenger />} */}
          {<Messenger />}
        </Route>
        <Route path="/:userId/places" exact>
          <UserPlaces />
        </Route>
        <Route path="/places/new" exact>
          <NewPlace />
        </Route>
        <Route path="/places/:placeId">
          <UpdatePlace />
        </Route>
        <Route path="/auth/verify/:verifyacc/:useridacc">
          <Auth verifyAccount />
        </Route>
        <Redirect to="/items/scheduledtoday" />
        {/* <Redirect to="/aibuilder" /> */}
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        {/* <Route path="/" exact> */}
          {/* <Auth /> */}
          {/* <Users /> */}
        {/* </Route> */}
        {/* <Route path="/mapleaflet" exact>
          <MapLeaflet />
        </Route> */}
        {/* <Route path="/leaflet" exact>
          <Leaflet />
        </Route> */}
        {/* <Route path="/calculateroute" exact>
          <CalculateRoute />
        </Route> */}
        {/* <Route path="/chat" exact>
          <Socket />
        </Route> */}
        <Route path="/messenger">
          {/* {!user ? <Redirect to="/" /> : <Messenger />} */}
          {<Messenger />}
        </Route>
        <Route path="/store">
            <Store />
        </Route>
        <Route path="/:userId/places" exact>
          <UserPlaces />
        </Route>
        <Route path="/home" exact>
          <Home />
        </Route>
        {/* <Route path="/aibuilder" exact>
          <Aibuilder />
        </Route> */}
        <Route path="/auth" exact>
          <Auth />
        </Route>
        <Route path="/auth/verify/:verifyacc/:useridacc">
          <Auth verifyAccount />
        </Route>
        <Route path="/auth/forget/:forgetacc/:useridacc">
          <Auth forgetAccount />
        </Route>
        <Redirect to="/home" />
        {/* <Redirect to="/aibuilder" /> */}
      </Switch>
    );
  }


  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout
      }}
    >
      {/* <div>{userId}</div> */}
      {/* {notification && (
        <Notification
          status={notification.status}
          title={notification.title}
          message={notification.message}
        />
      )} */}
        <Router>
      <ThemeProvider theme={theme} >
          <Layout>
            <Pages place='top'></Pages>
            {/* <UserImages /> */}
            {/* {deferredPrompt && pwaHide &&
              <li
                className='header_textXS' style={{ marginBottom: '10px' }}
                
              >
                <button onClick={Pwa}>לחץ להורדת אפליקציה</button>
              </li>
            } */}
            {/* <div style={{color: '#ededed'}}>{lng}</div> */}
            {/* <div>{t("greeting") }</div> */}
        
          {/* <Mui /> */}
          {/* <MainNavigation /> */}
          {/* <Layout> */}
            {showCart && <Cart />}
            
            {/* <Products /> */}
          {/* </Layout> */}
          <main>{routes}</main>
          </Layout>
      </ThemeProvider>
        </Router>
    </AuthContext.Provider>
  );
};

export default App;
