import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from "react"
// import { Link } from 'react-router-dom';

import { format } from "date-fns";
import { AddCircleOutlineOutlined } from "@mui/icons-material"
import PaidIcon from '@mui/icons-material/Paid';
// import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';



const divInTd = {maxWidth: '370px', padding: '6px', background: '#f1f1f1', minWidth: '310px', margin: '4px', marginTop: '16px'}

const allComments = [0,1,2,3]

function ItemsComments(props) {

  
      const containerStyle = {borderRight: '5px solid rgb(25, 118, 210)', background: '#fff', borderBottom: '4px solid #d8d8d8', minHeight: '100px'}
      const numbersStyle = {margin: '0px 2px', background: '#1976d2', color: '#fff', width: '114px', height: '25px',textAlign: 'center', paddingTop: '3px'}
      const toStyle = {padding: '6px 5px 4px 4px'}
      const DoneStyle = {textAlign: 'center', paddingTop: '2px', margin: '0px 6px 0px 0px', background: 'rgb(0 55 109)', color: 'rgb(255, 255, 255)', width: '50px', height: '25px', textalign: 'center', paddingtop: '3px'}

      const divInTd = {padding: '6px'}

  return (
    <div>
      <div>


          <div>
            <>
            { props.obj.comments &&
              props.obj.comments.map((single, indx) => (
              <div style={{display: 'flex', padding: '8px', flexDirection: 'column', maxWidth: '376px', background: '#404040', color: '#fff', borderRadius: '10px', marginBottom: '11px', paddingTop: '2px' }}>
                  <div style={{margin: '10px 0px 7px 0px', display: 'flex'}}>
                      <div style={{color: 'rgb(117, 117, 117)', marginLeft: '9px'}}>על ידי: </div>
                      {/* <div>{single.sender}</div> */}
                      {single.senderFull &&
                          <div>{single.senderFull[1]}</div>
                      }
                  </div><div style={{display: 'flex', flexDirection: 'column'}}>
                      {single.image.split(".").pop() === "mp4" ?
                          <div>
                            <video width="100%" height="300" controls >
                                <source src={single.image} type="video/mp4" />
                          </video>
                        </div>
                      : 
                      <div>
                            <img 
                            src={single.image}
                            />
                        </div>
                      }
                      
                  <div>{single.text}</div>
                    <div  
                    // onClick={() => {
                    //   // console.log("single.date",single.date)
                    //   // console.log(format(single.date, 'MM/dd/yyyy'))
                    //   // console.log(format(new Date(), 'MM/dd/yyyy'))
                    //   console.log(format(new Date(single.date.toString()), 'MM/dd/yyyy HH:MM:ss'))
                    //   }}
                      style={{marginRight: '10px', fontSize: '12px', paddingTop: '4px', textAlign: 'left'}}>
                      {format(new Date(single.date.toString()), 'MM/dd/yyyy HH:MM:ss')}
                      {/* {single.date} */}
                    </div>
                    {single.senderFull &&
                    <div  
                      style={{marginRight: '10px', fontSize: '12px', paddingTop: '4px', textAlign: 'left'}}>
                      {`${single.senderFull[2][0]} - ${single.senderFull[2][1]}`}
                    </div>
                    }
                  </div>
                  
              </div>
              ))
              }
            </>
          </div>


     
    </div>
    </div>
  )
}

export default ItemsComments