import React, {useContext, useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from "axios";
import FormikControl from './FormikControl'
import { useHistory } from 'react-router'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import hebrew from 'date-fns/locale/he';
import { format } from 'date-fns'

import { uiActions } from '../../../store/ui-slice';
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';
import { Avatar, Button, Grid, IconButton, ListItem, ListItemAvatar, Tab, Tabs, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ListIcon from '@mui/icons-material/List';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';


// import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
// import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import SearchIcon from '@mui/icons-material/Search';
import FormikUiAddContact from './FormikUiAddContact';
import SearchContacts from '../../../contacts/searchContacts';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../UIElements/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemText from '@mui/material/ListItemText';
import MaterialTable from 'material-table'
import XLSX from 'xlsx'
import LeafletXlsx from "../../../leaflet/LeafletXlsx";
import TableXlsx from './TableXlsx';



// import './UiWithFormik.css';
import './forumStyle.css';
import { letterSpacing } from '@mui/system';
import { CommentsDisabledOutlined, ContactSupportOutlined } from '@mui/icons-material';
const EXTENSIONS = ['xlsx', 'xls', 'csv']
const buttonStepForum_numbrer = {
    borderRadius: '30px',
    width: '30px',
    height: '30px',
    padding: '4px 10px 0px 0px',
    background: '#1976d2',
    color: '#fff',
    fontWeight: '700',
    margin: '0px 0px 8px 8px',
}
const buttonStepForum_text = { color: '#ooo', paddingTop: '4px' }


function AddXlsx (props) {

  useEffect(() => {
    // const  getAddressFromOsm  = async ()  => {

    //   let url = 'https://nominatim.openstreetmap.org/search.php?q=%D7%A8%D7%91%D7%99+%D7%A2%D7%A7%D7%99%D7%91%D7%90+10+%D7%91%D7%A0%D7%99+%D7%91%D7%A8%D7%A7&format=jsonv2&accept-language=%3Cbrowser%20language%20string%3E&addressdetails=[0]'
    //   // let url = "/api/contacts/autocomplete_two?city=" + searchableCity;
    //   const responseAddress = await fetch(url,
    //     // {method: 'GET',
    //     // body: null,
    //     //   headers: {
    //       //     'Content-Type': 'application/json',
    //       //     Authorization: 'Bearer ' + auth.token
    //       //   },
    //       // }
    //       );
    //   let dataResponseAddress = await responseAddress.json();
    //   console.log("responseAddress", responseAddress)
    //   console.log("dataResponseAddress", dataResponseAddress)
    // }
    // getAddressFromOsm();
          
}, [])

const [colDefs, setColDefs] = useState()
const [headersTable, setHeadersTable] = useState([])
const [data, setData] = useState()
const [newData, setNewData] = useState()
const [endedFullAddress, setEndedFullAddress] = useState(false)
const [indexNumEditedInXlsx, setIndexNumEditedInXlsx] = useState(0)
const [addressesFulfilled, setAddressesFulfilled] = useState(false)
const [numRowsInExsel, setNumRowsInExsel] = useState(0)





const auth = useContext(AuthContext);
const userProfile = useSelector((state) => state.ui.userProfile);
const getCitiesTwo = async (str) => {
  try {
    let searchableCity = str.replace(/,/g, "");
    let url = "/api/contacts/autocomplete_two?city=" + searchableCity;
        const response = await fetch(url,
          {method: 'GET',
          body: null,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            },
          }
        );
        let dataContacts = await response.json();
        dataContacts = dataContacts.filter( (el) => el.user_id === userProfile._id)    
    return dataContacts;
  } catch (error) {
    console.error(error);
  }
};


  const dispatch = useDispatch();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [contact_O_r_D, setContact_O_r_D] = useState('');
  const [typeOfForm, setTypeOfForm] = useState(props.typeOfForm);
  const [dateType, setDateType] = useState('date');
  const [optionsTwo, setOptionsTwo] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [autoCompleteC, setAutoCompleteC] = useState([]);
  const [autoCompleteO, setAutoCompleteO] = useState([]);
  const [locationO, setlocationO] = useState([]);
  const [locationD, setlocationD] = useState([]);
  const [autoCompleteD, setAutoCompleteD] = useState([]);
  const [optionsManagersIds, setOptionsManagersIds] = useState([]);
  const [optionsConnected_movers, setOptionsConnected_movers] = useState([]);
  const [optionsConnected_businesses, setOptionsConnected_businesses] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [pointNum, setPointNum] = useState([{num: 1}]);
  const [allUsersClientContacts, setAllUsersClientContacts] = useState([]);
  const [allEmployeeContacts, setAllEmployeeContacts] = useState([]);
  const [warehousesOptions, setWarehousesOptions] = useState([]);
  const [tagRouteOptions, setTagRouteOptions] = useState([]);
  const [clientsClientOptions, setClientsClientOptions] = useState([]);
  const [sendedMessage, setSendedMessage] = useState('');
  const [changed, setChanged] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [catOptions, setCatOptions] = useState([]);
  const [subCatOptions, setSubCatOptions] = useState([]);
  const [idsTagsOptions, setIdsTagsOptions] = useState([]);

  useEffect(() => {
    let allcountryOptions = [];
    let allcityOptions = [];
    let allcatOptions = [];
    let allsubCatOptions = [];
    let allidsTagsOptions = [];
    userProfile.AllCountryCityCatSubCatIdsTags &&
    userProfile.AllCountryCityCatSubCatIdsTags.map(elem => {
      if(elem.type === "id_country"){
        allcountryOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "id_city"){
        allcityOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "id_cat"){
        allcatOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "id_sub_cat"){
        allsubCatOptions.push({key: elem.first_name, value: elem._id})
      }
      if(elem.type === "ids_tags"){
        allidsTagsOptions.push({key: elem.first_name, value: elem._id})
      }
    });
    setCountryOptions(
        [...allcountryOptions]
      )
    setCityOptions(
        [...allcityOptions]
      )
    setCatOptions(
      [...allcatOptions]
      )
    setSubCatOptions(
        [...allsubCatOptions]
      )
    setIdsTagsOptions(
        [...allidsTagsOptions]
      )
  },[userProfile])
  
  
  useEffect(() => {
    console.log("NewData:",newData)
  }, [newData])
  useEffect(() => {
    console.log("indexNumEditedInXlsx:>",indexNumEditedInXlsx)
  }, [indexNumEditedInXlsx])
  
  
  
  const changeNewDataAddress = (indexInArray, objectName, newAddress) => {
    console.log("changeNewDataAddress:", indexInArray, objectName, newAddress[0])
    let TEMP_newData = newData
    TEMP_newData[indexInArray].כתובת = newAddress[0]
    TEMP_newData[indexInArray].fullAddress = newAddress[0]
    TEMP_newData[indexInArray].fullAddress_array = newAddress
    TEMP_newData[indexInArray].עיר = newAddress[3]
    console.log("TEMP_newData:", TEMP_newData)
    setNewData(TEMP_newData)
    setTimeout(() => {
        console.log("TEMP_newData setTimeout:",newData)
      }, 3000);
  
  }
  
  
    useEffect(() => {
      console.log("indexNumEitedInXlsx:", indexNumEditedInXlsx)
    }, [indexNumEditedInXlsx])
  
  
  //after excel is load looping thru all to update address and mor:
  useEffect(() => {
    console.log(data)
    if(userProfile._id === '627538581659696d4c7ff55f' || userProfile._id ===  '649d43c5389ae51478ae42ae') {
    if (data) {
      let TempData = data
      const startGetAddressesFromOsm = async () => {
        setEndedFullAddress(false)
        await TempData.forEach((elem, index) => {
          // if (index < 5) {
          const arrTableObj = [
            {columnName: 'מדינה', objName: 'countryIdFromDb', typeOptions: countryOptions},
            {columnName: 'עיר', objName: 'cityIdFromDb', typeOptions: cityOptions},
            {columnName: 'קטגוריה', objName: 'catIdFromDb', typeOptions: catOptions},
            {columnName: 'תת_קטגוריה', objName: 'subCatIdFromDb', typeOptions: subCatOptions},
            {columnName: 'תגית', objName: 'TagsIdFromDb', typeOptions: idsTagsOptions},
          ]
          // let countryInTable = elem.מדינה
          // let countryInTable = elem.arrOptaionsFromTable[0].tableColumnName
          arrTableObj.forEach(obj => {
            let objName = obj.objName
            let columnName = obj.columnName
            let typeOptions = obj.typeOptions
  
            TempData[index][objName] = '...'
            typeOptions.forEach((el) => {
                // console.log("el>?>", el, 'el.key === countryInTable', el.key, countryInTable)
                // if (el.key === countryInTable) {
                if (el.key === elem[columnName]) {
                  TempData[index][objName] = el.value
                }
            })
          })
          
          // console.log("countryInTable>?>", countryInTable)
          // console.log("countryOptions>?>", countryOptions)
          // console.log("TempData[index].countryIdFromDb>?>", TempData[index].countryIdFromDb)


            let fullAddress = `${elem.כתובת}, ${elem.עיר}, ${elem.מדינה}`
            console.log("fullAddress:", fullAddress)
            const CONTAINER_getAddressesFromOsm = async () => {
              const getAddressesFromOsm = async () => {
                TempData[index].fullAddress = '...'
      
                // let urlToOsm = `https://nominatim.openstreetmap.org/search.php?q=${fullAddress}&format=jsonv2&accept-language=%3Cbrowser%20language%20string%3E&addressdetails=[0]`
                try {
                  let urlToOsm = `https://maps.googleapis.com/maps/api/geocode/json?address=${fullAddress}&key=AIzaSyDa4lERSk0Z7clGGuBujvzgtgZdQJvRqd0`
                  const res = await fetch(urlToOsm);
                  // const res = '';
                  let dataRes = await res.json();
                  console.log("dataResponseAddress", dataRes, fullAddress, index)
                  TempData[index].fullAddress = dataRes.results[0].formatted_address
                  
  
  
                  let cityOnly = '';
                  dataRes.results[0].address_components.forEach((comp) => {
                      // console.log(comp)
                      if (comp.types[0] === "locality") {
                          cityOnly = comp.long_name;
                      }
                  })
  
                  // console.log("fullAddress_array:", [dataRes.results[0].formatted_address,
                  // dataRes.results[0].geometry.location.lat,
                  // dataRes.results[0].geometry.location.lng,
                  // cityOnly])
                  
                  TempData[index].fullAddress_array = [dataRes.results[0].formatted_address,
                  dataRes.results[0].geometry.location.lat,
                  dataRes.results[0].geometry.location.lng,
                  cityOnly]
                  console.log("fullAddress_array:", TempData[index].fullAddress_array)
                  setIndexNumEditedInXlsx(index)
                  
                  
                  
                  
                  
                  TempData[index].pleaseCheck = 'good'
                  let splitFirst = TempData[index].כתובת.split(' ')[0];
                  if (!dataRes.results[0].formatted_address.includes(splitFirst)) {
                    TempData[index].pleaseCheck = 'pleaseCheck'
                  }
                    // row['fullAddress'].includes(row['כתובת'].split(' ')[0])
                } catch (error) {
                  setIndexNumEditedInXlsx(index)
                  console.log("error:", error)
                  TempData[index].error = 'error'
                }
              } // end const chacking address by api
  
              
              try {
                const excecuteOsm = await getAddressesFromOsm();
                console.log("getAddressesFromOsm() AS FINISHED!", excecuteOsm)
                setNewData(TempData) // setting the new address and mor - for this index in loop
              } catch (error) {
                console.log("getAddressesFromOsm() AS ERROR!", error)
                
              }
            } // and const in loop contains const chackes address by api
  
            CONTAINER_getAddressesFromOsm() // executes the chack address - in loop
          // }
          console.log("index:",typeof index, index, "TempData.length:",typeof TempData.length, TempData.length, index+1 === TempData.length)
          if (index+1 === TempData.length) {
            setEndedFullAddress(true)
          }
        }) // end loop
      } // end const contains loop
      startGetAddressesFromOsm() // executes main const contains loop to chack address
  
    }
    }
  
  }, [data])
    
  
    const getExention = (file) => {
      const parts = file.name.split('.')
      const extension = parts[parts.length - 1]
      return EXTENSIONS.includes(extension) // return boolean
    }
  
    const convertToJson = (headers, data) => {
      const rows = []
      data.forEach(row => {
        let rowData = {}
        row.forEach((element, index) => {
          rowData[headers[index]] = element
        })
        rows.push(rowData)
  
      });
      return rows
    }
  
    const importExcel = (e) => {
      const file = e.target.files[0]
  
      const reader = new FileReader()
      reader.onload = (event) => {
        //parse data
  
        const bstr = event.target.result
        const workBook = XLSX.read(bstr, { type: "binary" })
  
        //get first sheet
        const workSheetName = workBook.SheetNames[0]
        const workSheet = workBook.Sheets[workSheetName]
        //convert to array
        const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
        console.log("fileData::", fileData)
        setNumRowsInExsel(fileData.length)
        const headers = fileData[0]
        console.log("headers:>", headers)
        setHeadersTable(headers)
        const heads = headers.map(head => ({ title: head, field: head }))
        setColDefs(heads)
  
        //removing header
        fileData.splice(0, 1)
  
        if (fileData.length < 199) {
          setData(convertToJson(headers, fileData))
        }
        
      }
  
      if (file) {
        if (getExention(file)) {
          reader.readAsBinaryString(file)
        }
        else {
          alert("Invalid file input, Select Excel, CSV file")
        }
      } else {
        setData([])
        setColDefs([])
      }
    }
    
    const getHeadings = () => {
      if (newData) {
        console.log("getHeadings - newData", newData)
            return Object.keys(newData[0]);
      }
    }
    
  

  let formRef = useRef();
  // useEffect(() => {
  //   // formRef.current.values.location_o[0] = 'sdgs sdgs k'
  //   if(!showTypeOfFormOptions && showAddSubject === 'places'){
  //     console.log("formRef.current.values",formRef.current.values)
  //   }
  // },[formRef, showTypeOfFormOptions])

  // useEffect(() => {
  //     console.log("autoCompleteCCCC",autoCompleteC)
  // }, [autoCompleteC])
  
  // useEffect(() => {
  //     console.log("autoCompleteD??",autoCompleteD)
  // },[autoCompleteD])

  const setAutoCompleteCFromAddContactHandler = (e) => {
    console.log("setAutoCompleteCFromAddContactHandler_E!", e)
    if(contact_O_r_D === 'C'){
      setAutoCompleteC(  [[0, e.contact]])
    }
    if(contact_O_r_D === 'O'){
      setAutoCompleteO(  [[0, e.contact]])
    }
    if(contact_O_r_D === 'D'){
      setAutoCompleteD(  [[0, e.contact]])
    }
  }



  const updateAddressesFields = (objNum, addessesFields,typeLocation) => {
    console.log("addessesFields", addessesFields)
    console.log("objNum", objNum)
    // console.log("locationO", locationO)
    // setAddressesFieldsDetails([...addessesFields])
    if(typeLocation === 'o'){
      const indexExistInArrayO = locationO.filter( (singleO) => {
        console.log("singleO[0] => ",singleO[0]) 
          if (singleO[0] === objNum){
            return singleO[0] === objNum
          }
        } 
      )

      console.log("indexExistInArray.length => ", indexExistInArrayO.length, indexExistInArrayO)
      if (indexExistInArrayO.length === 0){
        setlocationO(  [...locationO , [objNum, addessesFields]])
      } else if (indexExistInArrayO.length > 0) {
        const newlocationO = locationO.filter((singleO) => singleO[0] !== objNum);
        setlocationO(  [...newlocationO , [objNum, addessesFields]])
      }
    }
    if(typeLocation === 'd'){
      const indexExistInArrayD = locationD.filter( (singleD) => {
        console.log("singleD[0] => ",singleD[0]) 
          if (singleD[0] === objNum){
            return singleD[0] === objNum
          }
        } 
      )

      console.log("indexExistInArrayD.length => ", indexExistInArrayD.length, indexExistInArrayD)
      if (indexExistInArrayD.length === 0){
        setlocationD(  [...locationD , [objNum, addessesFields]])
      } else if (indexExistInArrayD.length > 0) {
        const newlocationD = locationD.filter((singleD) => singleD[0] !== objNum);
        setlocationD(  [...newlocationD , [objNum, addessesFields]])
      }
    }

  }


  let points = Array.from(Array(pointNum).keys());

  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);

  useEffect(() => {
    setOptionsManagersIds(
      [{key: userProfile.name, value: userProfile._id}]
    );
    userProfile.managers_ids &&
    userProfile.managers_ids.map(elem => {
      setOptionsManagersIds(
        [...optionsManagersIds, {key: elem.name, value: elem._id}]
        )
      });
  }, [userProfile])
  
  useEffect(() => {
    let TEMP_optionsConnected_movers = []
    userProfile.connected_movers &&
      
    userProfile.connected_movers.map(elem => {
      TEMP_optionsConnected_movers.push({key: elem.business_name, value: elem._id})
    });

    setOptionsConnected_movers(
      [...optionsConnected_movers, ...TEMP_optionsConnected_movers]
    )

    userProfile.connected_businesses &&
    userProfile.connected_businesses.map(elem => {
      setOptionsConnected_businesses(
        [...optionsConnected_businesses, {key: elem.name, value: elem._id}]
        )
    });
    
  },[userProfile])
    
  useEffect(() => {
    setAllUsersClientContacts(
      // [{key: userProfile.name, value: userProfile._id}]
      [{key: 'בחר לקוח', value: userProfile._id}]
    );
    let allContactsFromRedux = [];
    userProfile.AllClients &&
    userProfile.AllClients.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    userProfile.wathIsMyClientNumber &&
    userProfile.wathIsMyClientNumber.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    setAllUsersClientContacts(
        [{key: 'בחר לקוח', value: userProfile._id}, ...allContactsFromRedux]
      )
  },[userProfile])


  useEffect(() => {
    setAllEmployeeContacts(
      // [{key: userProfile.name, value: userProfile._id}]
      [{key: 'בחר נהג', value: ''}]
    );
    let allContactsFromRedux = [];
    userProfile.AllEmployee &&
    userProfile.AllEmployee.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    userProfile.wathIsMyClientNumber &&
    userProfile.wathIsMyClientNumber.map(elem => {
      allContactsFromRedux.push({key: elem.first_name, value: elem._id})
    });
    setAllEmployeeContacts(
        [{key: 'בחר נהג', value: ''}, ...allContactsFromRedux]
      )
  },[userProfile])


  useEffect(() => {
    let allTagRoute = [];
    userProfile.AllTagRoute &&
    userProfile.AllTagRoute.map(elem => {
      allTagRoute.push({key: elem.first_name, value: elem._id})
    });
    setTagRouteOptions(
        [...allTagRoute]
      )
  },[userProfile])

  useEffect(() => {
    let allWarehouses = [];
    userProfile.warehouses &&
    userProfile.warehouses.map(elem => {
      allWarehouses.push({key: elem.first_name, value: elem._id})
    });
    setWarehousesOptions(
        [...allWarehouses]
      )
  },[userProfile])

  useEffect(() => {
    let allClientsClient = [];
    userProfile.AllClientsClient &&
    userProfile.AllClientsClient.map(elem => {
      allClientsClient.push({key: elem.first_name, value: elem._id})
    });
    userProfile.AllManagersClientsClient &&
    userProfile.AllManagersClientsClient.map(elem => {
      allClientsClient.push({key: elem.first_name, value: elem._id})
    });
    setClientsClientOptions(
        [...allClientsClient]
      )
      console.log("allClientsClient:", allClientsClient)
  },[userProfile])




  const onChangeTwo = async (e) => {
    console.log(e.target)
    if (e.target.value) {
      let data = await getCitiesTwo(e.target.value);
      setOptionsTwo(data);
    }
  };
  
  const clearInput = () => {
    console.log('ffff')
    setOptionsTwo([]);
    setWordEntered("");
  };


let switcher = true;

  let allMapCats = []

  const initialValues = {
    pointNum: '',
    typeOfForm: '',
    city_o: '',
    city_d: '',
    city_lt: '',
    title: 'props.selectedAddressD.address',
    id_manager: '',
    id_connected_mover: '',
    id_connected_businesses: '',
    where_to: ['warehouse_to_clients_client'],
    from_id_warehouse: [''],
    to_id_clients_client: [''],
    from_id_clients_client: [''],
    to_id_warehouse: [''],
    form_purpes: props.formPurpesTitle,
    // description: [' '],
    location_o: [' '],
    // email: '',
    // bio: '',
    // ['id_manager','id_client','id_emploeey',
    // 'date_delivery','where_to','packs_pallet','packs_bags',
    // 'packs_cartons','packs_people','description',
    // 'location_o','location_d','contact_O_r_D',
    // 'payment','vehicle','physical_work'];



    cat: '',
    selectedAddressD: {
      address: 'props.selectedAddressD.address',
      lat: 'props.selectedAddressD.selectedLat',
      lng: 'props.selectedAddressD.selectedLng',
      entry: '',
      floor: '',
      apartment: '',
      description: ''
    },
    selectedAddressO: {
      address: 'props.selectedAddressO.address',
      lat: 'props.selectedAddressO.selectedLat',
      lng: 'props.selectedAddressO.selectedLng',
      entry: '',
      floor: '',
      apartment: '',
      description: ''
    },
    c_contact_id: autoCompleteC._id,
    o_contact_id: autoCompleteO._id,
    o_contact: {
      o_name: '',
      o_phone: '',
      o_address: ''
    },
    d_contact_id: autoCompleteD._id,
    d_contact: {
      d_name: '',
      d_phone: '',
      d_address: ''
    },
    // packs: {
      packs_bags: [''],
      packs_cartons: [''],
      packs_people: [''],
      packs_pallet: [''],
    // },
    // packs_pallet,
    vehicle: {
      type: '',
      ramp: '',
      forklift: '',
      lever: ''
    },
    physical_work: {
      d: '',
      o: '',
      desc: ''
    },
    payment: {
      amount: '',
      whopays: '',
      method: '',
    },
    modeOfdate: dateType,
    date_delivery: '',
    day: '',
    matrix: ''
      }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    // cat: Yup.string().required('בחר קטגוריה'),
    // title: Yup.string().required('הזן כותרת')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

  const history = useHistory();


  useEffect(() => {
    console.log("format>> startDate):",format(startDate, 'MM/dd/yyyy'))
  },[startDate])


  const onSubmit = async values => {
    console.log('Form data values:', values)

    
    // c_contact_id: autoCompleteC._id,contact_O_r_D
    values.typeOfForm = typeOfForm[0];
    
    // values.city_lt = cityLt
    
    if (typeOfForm[3].includes('contact_O_r_D')){
      console.log("locationCOD",autoCompleteC,autoCompleteO,autoCompleteD)
    // if (values.typeOfForm !== 'jobs'){
    //   values.c_contact_id = autoCompleteC[0][1]._id;
    // }
      // values.city_o = autoCompleteO[0][1].city;
      
      // values.o_contact_id = autoCompleteO[0][1]._id;
    }

    values.pointNum = pointNum;
    if (typeOfForm[3].includes('date_delivery')){
      values.startDate = format(startDate, 'MM/dd/yyyy');
    }

    console.log('Form values222:', values)
    console.log('XLSX newData 222:', newData)

    let beforeSubmit_newData = newData;
    try {
      beforeSubmit_newData.forEach((elem, index) => {
        elem.city_o = values.city_o
        elem.c_contact_id = values.c_contact_id
        elem.o_contact_id = values.o_contact_id
        elem.startDate = values.startDate
        elem.typeOfForm = 'c_o_d_xlsx'
        elem.id_connected_mover = values.id_connected_mover
        elem.form_purpes = 'c_o_d_xlsx'
        elem.destination_firsname = elem.שם
        elem.destination_lastname = elem.משפחה
        elem.destination_phone1 = elem.נייד
        elem.destination_phone2 = elem.נייד2
        elem.destination_apartment = elem.דירה
        elem.price = elem.מחיר
        elem.apartment = elem.דירה
        elem.sku = elem.מקט
        // elem.description = elem.הערות
        elem.quantity = elem.כמות ? elem.כמות : 1
        elem.id_country = elem.מדינה
        elem.id_city = elem.עיר
        elem.id_cat = elem.קטגוריה
        elem.id_sub_cat = elem.תת_קטגוריה
        elem.ids_tags = elem.כמות
        elem.ids_tags = elem.אתר
      })
    } catch (error) {
      console.log("error:",error)
    }
    // beforeSubmit_newData[indexInArray].כתובת = newAddress[0]
    // beforeSubmit_newData[indexInArray].fullAddress = newAddress[0]
    // beforeSubmit_newData[indexInArray].עיר = newAddress[3]
    // console.log("TEMP_newData:", TEMP_newData)
    // setNewData(TEMP_newData)
    // setTimeout(() => {
    //    console.log("TEMP_newData setTimeout:",newData)
    //   }, 3000);

  // }
    
    try {
      beforeSubmit_newData.forEach((el, ind) => {
        if (ind < 100) {
          console.log("ind:", ind)
        const sendSingleXlsx = async () => {
          setSendedMessage('מעבד בקשה מספר' + '-' + ind)
            console.log("pointNum=>",pointNum)
            var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", 'Bearer ' + auth.token);
            
            // ============================
            const responseData = await sendRequest('/api/items/add', 'POST', JSON.stringify({
              typeOfForm: 'c_o_d_xlsx_2',
              allXlsxItems: [beforeSubmit_newData[ind]],
            }), myHeaders);
            setSendedMessage('הפריטים התווספו לשינוע בהצלחה!'  + '-' + ind)
            console.log("responseData<>", responseData)
        }
          try {
            sendSingleXlsx()
            
          } catch (error) {
            console.log("error sendSingleXlsx:", error)
          }
        }
        
      })
      
      // ============================
      // setSendedMessage(`${props.typeOfForm[1]} חדש נוצר בהצלחה!`)


      // if (responseData.contact.form_purpes === 'location_tag') {
      //     console.log("dispatch addLocationToTagRoute")
      //     dispatch(
      //       uiActions.addLocationToTagRoute({
      //         location_d: responseData.contact.location_d,
      //       _id: responseData.contact._id,
      //       user_id: responseData.contact.user_id,
      //       form_purpes: responseData.contact.form_purpes,
      //       id_tag_route: responseData.contact.id_tag_route
      //       })
      //     );
      //   var oldItems = JSON.parse(localStorage.getItem('userProfile'));

      //   var newItem = {
      //     location_d: responseData.contact.location_d,
      //       _id: responseData.contact._id,
      //       user_id: responseData.contact.user_id,
      //       form_purpes: responseData.contact.form_purpes,
      //       id_tag_route: responseData.contact.id_tag_route
      //   };

      //   oldItems.AllLocationTag.push(newItem);
      //   localStorage.setItem(
      //     'userProfile',
      //     JSON.stringify(oldItems)
      //   );
      //   // formRef.current.values = ''
      //   // console.log("formRef.current.values", formRef)


      // }

      // if (responseData.contact.form_purpes === 'o_and_d') {
      //     console.log("dispatch add_O_and_D_Locations")
      //     dispatch(
      //       uiActions.addOAndDLocations({
      //         _id: responseData.contact._id,
      //         user_id: responseData.contact.user_id,
      //         form_purpes: responseData.contact.form_purpes,
      //         location_d: responseData.contact.location_d,
      //         location_o: responseData.contact.location_o,

      //       })
      //     );
        
      //   var oldItems = JSON.parse(localStorage.getItem('userProfile'));

      //   var newItem = {
      //     _id: responseData.contact._id,
      //     user_id: responseData.contact.user_id,
      //     form_purpes: responseData.contact.form_purpes,
      //     location_d: responseData.contact.location_d,
      //     location_o: responseData.contact.location_o,
      //   };

      //   oldItems.All_O_and_D_Locations.push(newItem);
      //   localStorage.setItem(
      //     'userProfile',
      //     JSON.stringify(oldItems)
      //   );
      //   }
      // history.push('/maps');

    } catch (err) {console.log("err:",err)}

  }
  return (
    <>
      <div style={{margin: '0px 5px'}}>
        <input type="file" onChange={importExcel} />
        <div style={{color: '#efefef', marginBottom: '10px'}}>indexNumEditedInXlsx = {indexNumEditedInXlsx}</div>
        <div>מספר שורות באקסל: { numRowsInExsel}</div>
        {numRowsInExsel > 199 &&
          <div style={{color: 'red'}}>מקסימום שורות באקסל המותר הינו 200!</div>
        }


        <div style={{color: '#efefef', marginBottom: '10px'}} className="container">
          {newData &&
    //         <TableXlsx changeNewDataAddress={changeNewDataAddress} theadData={getHeadings()} tbodyData={newData}/>
            <TableXlsx changeNewDataAddress={changeNewDataAddress} theadData={headersTable} tbodyData={newData}/>
          }
        </div>


      </div>

    
    <Formik 
    // enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {formik => {
        return (
          // style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px'}}
          <Form style={{padding: '10px'}}>

            {typeOfForm[3].includes('id_connected_mover_999999') && optionsConnected_movers.length > 0 &&
            
            <FormikControl
            control='select'
            // type='number'
            label='משלוחנים מחוברים'
            name='id_connected_mover'
            options={optionsConnected_movers}
            />
            }

          {typeOfForm[3].includes('date_delivery_99999999') &&
              <>
              <div style={{margin: '10px 0px'}}>
            <LocalizationProvider 
              dateAdapter={AdapterDateFns}
              adapterLocale={hebrew}
              >
              <DatePicker
                label="בחר תאריך"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
                </LocalizationProvider>
              </div>
              {/* <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  // startIcon={<KeyboardArrowUpIcon/>}
                  endIcon={morDetails4 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  onClick={() => setMorDetails4(!morDetails4)}
                >
                  <Typography>עבור תאריך</Typography>
                </Button>
              </Grid> */}
              {/* {morDetails4 &&  */}
              {/* <div className='mor_details' id="mor_details2">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikControl
                      control='date'
                      type='date'
                      label=''
                      name='date_delivery'
                    />

                  </Grid>
                </Grid>
              </div> */}
              {/* } */}
            </>
          }
            {/* <Android12Switch/> */}
            
            <Button variant='outlined' type='submit'
            //  disabled={!formik.isValid || userProfile._id !== '627538581659696d4c7ff55f'  || userProfile._id !==  '649d43c5389ae51478ae42ae'}>
             disabled={!formik.isValid || userProfile._id !==  '649d43c5389ae51478ae42ae'}>
              {props.submitTextButton ? props.submitTextButton : 'צור חדש'}
            </Button>
            <Grid item xs={6}>
                <Typography style={{color: 'green'}}>{sendedMessage}</Typography>
            </Grid>
          </Form>
        )
      }}
    </Formik>
    {/* } */}
    </>
  )
}

export default AddXlsx
