import React, { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { uiActions } from '../../../store/ui-slice';
import FormikControl from './FormikControl'
import { useHistory } from 'react-router'
import {useHttpClient} from '../../hooks/http-hook'
import { AuthContext } from '../../context/auth-context';
import { sendCartData, fetchCartData } from '../../../store/places-actions';
import { Avatar, Badge, Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FolderIcon from '@mui/icons-material/Folder';
import { AllCarTypes, AllBizTypes } from '../../moduls/allCarTypes'; 


import ImageUpload from '../FormElements/ImageUpload';
import AddMediaToS3 from './AddMediaToS3';
import './UiWithFormik.css';
import Modal from '../UIElements/Modal';
import { styled } from '@mui/material/styles';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function EditProfile(props) {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.ui.userProfile);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [morDetails0, setMorDetails0] = useState(true)
  const [morDetails1, setMorDetails1] = useState(false)
  const [morDetails2, setMorDetails2] = useState(true)
  const [morDetails3, setMorDetails3] = useState(false)
  const [morDetails4, setMorDetails4] = useState(false)
  const [morDetails5, setMorDetails5] = useState(false)
  const [morDetails6, setMorDetails6] = useState(false)
  const [morDetails7, setMorDetails7] = useState(false)
  const [morDetails8, setMorDetails8] = useState(false)
  const [displayJobForm, setDisplayJobForm] = useState(false)
  const [displayDandOForm, setDisplayDandOForm] = useState(false)
  const [valueDateMode, setValueDateMode] = React.useState(0);
  const [dateType, setDateType] = useState('date');
  const [switchMatrixx, setSwitchMatrixx] = useState('fgfg');
  const [sendedMessage, setSendedMessage] = useState('');
  const [addressesFieldsDetails, setAddressesFieldsDetails] = useState('');
  const [usersProfile, setUsersProfile] = useState('');
  const [oneImage, setOneImage] = useState(null);
  const [openImageUploadModel, setOpenImageUploadModel] = useState(false);
  const [isBiz, setIsBiz] = useState('');
  
  //   const handleTypeOfdate = (e) => {console.log('date')
  //   setRrre('dfh')
  //   console.log(e.target.value)
  // };

  const [loadedUsers, setLoadedUsers] = useState();
  const auth = useContext(AuthContext);

  const updateAddressesFields = (adtessesFields, address, typeLocation) => {
    console.log("adtessesFields", adtessesFields)
    console.log("address", address)
    console.log("typeLocation", typeLocation)
    setAddressesFieldsDetails([...address])
  }


  const handleChange4 = (event, newValuee) => {
    setValueDateMode(newValuee);
  };
  
  // const handleSwitchTrueOrFalse = (e) => {
  //   console.log('switch hes been clicked!');
  //   switcher = !switcher;
  //   console.log(switcher);
  //   // setSwitchMatrixx(switcher);
  //   // console.log(e)
  // };
  // const handleSwitchTrueOrFalse = (e) => {
  //   console.log('switch hes been clicked!');
  //   setSwitchMatrixx(e);
  //   // console.log(e)
  // };
  // const [typeOfdate,setTypeOfdate] = useState('soon')
  // const AllCarTypes = require('./data/jobs.js');  
  // let allCarTypes = [
  // { key:"משלוחים - רכב פרטי", value: 'privet_car' },
  // { key:"משלוחים - רכב מסחרי", value: "commercial_car" },
  // { key:"משלוחים - אופניים", value: "bicycle" },
  // { key: "משלוחים - אופנוע", value: "motorcycle" },
  // { key: "הובלות -  רכב ונגררת", value: "vehicle_with_trailer" },
  // { key: "הובלות -  משאית עד 10 טון", value: "truck_up_to_10_tons" },
  // { key: "הובלות -  משאית מעל 10 טון", value: "truck_over_to_10_tons" },
  // { key: "הובלות -  משאית קירור", value: "refrigerated_truck" },
  // { key: "הובלות -  מנוף הרמה", value: "lifting_cranes" },
  // { key: "גררי רכב", value: "tow_truck" },
  // ]
  // let approve_as_professional = [
  // { key:"בחר אופציה", value: 'choose' },
  // { key:"אני מאשר", value: 'yes' },
  // { key:"אני לא מאשר", value: "no" },
  // ]

//   props.mapCatsDropDown && props.mapCatsDropDown.forEach((single, idx, allArray)  => {
//   let objVal = single.title;
//   if(objVal){
//     const obj = {value: single.id, key: single.title};
//     allMapCats.push(obj);
//     // console.log(single)
//   }
// })

  const initialValues = {
    name: userProfile.name,
    name_biz: userProfile.name_biz && userProfile.name_biz,
    type: userProfile.type,
    business_name: userProfile.business_name,
    approve_as_professional: userProfile.approve_as_professional && userProfile.approve_as_professional,
    car_type: userProfile.car_type && userProfile.car_type,
    biz_type: userProfile.biz_type && userProfile.biz_type,

    // address_auto: '',
    // address: '',
    address: userProfile.address,
    address_biz: userProfile.address_biz && userProfile.address_biz,
    location: {
      lat: userProfile.location && userProfile.location.lat,
      lng: userProfile.location && userProfile.location.lng
    },
    loc: {
      coordinates: [userProfile.loc && userProfile.loc.coordinates[0],userProfile.loc && userProfile.loc.coordinates[1]],
    },
    entry: userProfile.entry,
    entry_biz: userProfile.entry_biz,
    floor: userProfile.floor,
    floor_biz: userProfile.floor_biz,
    apartment: userProfile.apartment,
    apartment_biz: userProfile.apartment_biz,

    email_biz: userProfile.email_biz,
    email: userProfile.email,
    phone1_biz: userProfile.phone1_biz,
    phone1: userProfile.phone1,
    phone2_biz: userProfile.phone2_biz,
    phone2: userProfile.phone2,
    phone3_biz: userProfile.phone3_biz,
    phone3: userProfile.phone3,
    fax_biz: userProfile.fax_biz,
    fax: userProfile.fax,
    po_box_biz: userProfile.po_box_biz,
    po_box: userProfile.po_box,

    cat: userProfile.cat,
    tag: userProfile.tag,

    sku_client: userProfile.sku_client,
    description: userProfile.description,
    description_biz: userProfile.description_biz,
    imageOne: userProfile.imageOne && userProfile.imageOne,
    imageOne_biz: userProfile.imageOne_biz && userProfile.imageOne_biz,
  }

  const validationSchema = Yup.object({
    // email: Yup.string()
    //   .email('Invalid email format')
    //   .required('Required'),
    // bio: Yup.string().required('Required'),
    // cat: Yup.string().required('בחר קטגוריה'),
    // first_name: Yup.string().required('הזן שם')
    // courseDate: Yup.date()
    //   .required('Required')
    //   .nullable()
  })

  const history = useHistory();

  const inputImageHandler = (pickedFile) => {
    console.log("inputHandler:pickedFile => ",pickedFile)
    setOneImage(pickedFile)
  };

  useEffect(() => {
    console.log('oneImage=>' , oneImage)
  },[oneImage])

  useEffect(() => {
    if(props.whatToEdit === 'bizDetails'){
      setIsBiz('biz')
    }
  },[])

  const onSubmit = async values => {
    console.log('Form data values:', values)
    console.log('STATE addressesFieldsDetails:', addressesFieldsDetails)
    // values.address = addressesFieldsDetails[0]
    // values.city = addressesFieldsDetails[3]
    // values.location.lat = addressesFieldsDetails[1]
    // values.location.lng = addressesFieldsDetails[2]
    if(isBiz === 'biz'){
      values.address_biz = addressesFieldsDetails[0]
      values.city_biz = addressesFieldsDetails[3]
      values.loc.coordinates = [addressesFieldsDetails[2],addressesFieldsDetails[1]]
    }
    if(isBiz !== 'biz'){
      // values.address_biz = userProfile.address_biz
      // values.city_biz = userProfile.city_biz
      // values.loc.coordinates = [addressesFieldsDetails[2],addressesFieldsDetails[1]]
      // values.loc.lng_biz = addressesFieldsDetails[2]

      values.address = addressesFieldsDetails[0]
      values.city = addressesFieldsDetails[3]
      values.location.lat = addressesFieldsDetails[1]
      values.location.lng = addressesFieldsDetails[2]
    }
    if(isBiz === 'biz'){
      values.imageOne_biz = oneImage ? oneImage : userProfile.imageOne_biz
    }
    if(isBiz !== 'biz'){
      values.imageOne = oneImage ? oneImage : userProfile.imageOne
    }
    
    values.isBiz = isBiz
  
    
// get secure url from our server
  // try {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", 'Bearer ' + auth.token);
  //   const { url } = await sendRequest(`/api/s3/s3Url`, 'POST', JSON.stringify({fileName: oneImage.name, userFolderId, userFolderId, subFolder}) , myHeaders);
  //   console.log("url from s3 => ",url)


  //   await fetch(url, {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "multipart/form-data"
  //     },
  //     body: oneImage
  //   })
  //   const imageUrl = url.split('?')[0]
  //     console.log(imageUrl)
  //     values.imageOne = imageUrl;
  // } catch (err) {console.log(err)}




    try {
      var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + auth.token);
      const response = await sendRequest(`/api/users/edit/${userProfile._id}`, 'POST', JSON.stringify(values), myHeaders);
      setSendedMessage('איש קשר עודכן בהצלחה!')
      dispatch(
          uiActions.updateUserDetailsAfterEditingProfile({
            // name: responseData.userProfile.name,
            // id: responseData.userProfile.id,
            // image: responseData.userProfile.image,
            // email: responseData.userProfile.email,

          name: values.name,
          phone1: values.phone1,
          address: addressesFieldsDetails[0],
          imageOne: oneImage ? oneImage : userProfile.imageOne,
          location: {
              "lat": addressesFieldsDetails[1],
              "lng": addressesFieldsDetails[2]
          }
          })
      );
      if (response) { props.onCloseEditProfileWindow() }
      auth.logout()
      localStorage.removeItem('userProfile')
      dispatch(
          uiActions.setUserProfile({name: "שלום אורח"})
      )
      // dispatch(fetchCartData());
      // history.push('/maps');
    } catch (err) {console.log(err)}

  }

  return (
    <Formik 
    // enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          // style={{zIndex: props.zIndex, position: 'absolute', background: '#fff', padding: '10px'}}
          <Form style={{zIndex: props.zIndex, background: '#fff', padding: '10px', maxWidth: '450px'}}>
            {/* <FormikControl
              control='radio'
              label='צד המשלם'
              name='modeOfContact'
              options={RadioOptions}
            /> */}
            {/* <Grid item xs={12}>
              <Typography  variant="h4" >הוספת שם</Typography>
            </Grid> */}
            {isBiz}
            <Grid item xs={12}>
              <Typography style={{fontSize: '12px', marginBottom: '21px', color: '#bbb'}}  variant="h5" >מספר מזהה: {userProfile._id}</Typography>
              {/* {"_id":"61444bc85d4a7e5054ec7c3e","email":"yehudaadd@gmail.com","image":"uploads\\images\\b8df1ce0-178d-11ec-989c-a535fb70cda5.jpeg","name":"yehuda tzin"} */}
            </Grid>

            {/* <Avatar onClick={() => {
                    setOpenImageUploadModel(true)
                    // openMapHandler()
                    }} style={{background: 'white'}} alt={userProfile.image} src={userProfile.image} /> */}
            {/* {props.whatToEdit && props.whatToEdit === 'bizDetails' &&
            <div>fff</div>
          } */}
          {/* {props.whatToEdit && props.whatToEdit === 'profileDetails' && */}
          {props.whatToEdit &&
          <>
            <Grid item xs={12} style={{marginBottom: '50px'}}>
              <Badge 
                onClick={() => setOpenImageUploadModel(true)}
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <SmallAvatar sx={{width: '70px', height: '70px'}}><FolderIcon sx={{width: '50px', height: '50px'}} /></SmallAvatar>
                }
                sx={{width: '144px', height: '179px'}}
              >
                <Avatar sx={{width: '200px', height: '200px', border: '1px solid #c9c9c9'}} alt="Travis Howard" src={isBiz === '' ? userProfile.imageOne : isBiz === 'biz' && userProfile.imageOne_biz} />
                
              </Badge>
            </Grid>





            {openImageUploadModel &&
                <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={openImageUploadModel}
                  onCancel={() => setOpenImageUploadModel(false)}
                  header={'העלאת תמונת פרופיל'}
                  style={{position: 'fixed', top: '81px', background: '#2e2e2e'}}
                  // contentClass="place-item__modal-content"
                  // footerClass="place-item__modal-actions"
                  // footer={<Button variant='contained' onClick={() => setCreateContactName(false)}>סגור</Button>}
                  closeButton={<Button variant='contained' onClick={() => setOpenImageUploadModel(false)}>סגור</Button>}
                >
                  <Grid item xs={12}>
                    {/* <ImageUpload  center id="image" onInput={inputHandler}
                      errorText="הכנס תמונה."
                      /> */}
                      <AddMediaToS3  center id="image" 
                        onInput={inputImageHandler}
                        errorText="הכנס תמונה."
                        userFolderId= {userProfile._id}
                        subFolder= 'profile'
                      />

                  </Grid>
                </Modal>
                </div>
                }
                {oneImage && 
                  <div>
                    <div>התמונה החדשה תהיה:</div>
                    {/* <div>{oneImage}</div> */}
                    <Avatar sx={{width: '200px', height: '200px'}} alt="Travis Howard" src={oneImage} />
                  </div>

                }

            {/* <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12}>
                <FormikControl
                  control='radio'
                  // type='radio'
                  label='סוג איש קשר:'
                  name={`type`}
                  options={[
                    { key: 'איש קשר', value: 'person', checked: true },
                    { key: 'עובד', value: 'employee' },
                    { key: 'לקוח', value: 'client' },
                    { key: 'לקוח של לקוח', value: 'clients_client' },
                  ]}
                  // handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                />
              </Grid>
            </Grid> */}

            {morDetails0 && 
            // <Grid item xs={12}>
            //   <Typography>כתובת יעד</Typography>
            // </Grid>
            <Grid container spacing={1}>
              {isBiz === 'biz' &&
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם העסק'
                  name={`name_biz`}
                />
              </Grid>
              }
              {isBiz !== 'biz' &&
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם מלא'
                  name={`name${isBiz}`}
                />
              </Grid>
              }
              {/* <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='שם משפחה'
                  name='last_name'
                />
              </Grid> */}
              
              {isBiz === 'biz' &&
              <>
                <Grid item xs={12}>
                <Typography>{userProfile.location && userProfile.address_biz && 'כתובת העסק: '+userProfile.address_biz}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormikControl
                        control='addressInput'
                        type='string'
                        label='כתובת אוטומטית'
                        name='address_auto'
                        specialLabal="כתובת"
                        addToId='profile'
                        typeLocation='p'
                      
                      // AddressFromRedux={userProfile.address}
                      updateAddressesFields={updateAddressesFields}
                    />
                </Grid>
              </>
              }  
              {isBiz !== 'biz' &&
              <>
                <Grid item xs={12}>
                <Typography>{userProfile.location && 'כתובת נוכחית: '+userProfile.address}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormikControl
                        control='addressInput'
                        type='string'
                        label='כתובת אוטומטית'
                        name='address_auto'
                        specialLabal="כתובת"
                        addToId='profile'
                        typeLocation='p'
                      
                      // AddressFromRedux={userProfile.address}
                      updateAddressesFields={updateAddressesFields}
                    />
                </Grid>
              </>
              }  


            {/* <Grid item xs={8}>
            <FormikControl
              control='input'
              type='string'
              label='כתובת'
              name='address'
            />
            </Grid> */}
            {isBiz === 'biz' &&
            <>
            <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='כניסה'
                  name='entry_biz'
                />
            </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קומה'
                  name='floor_biz'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='number'
                  label='דירה'
                  name='apartment_biz'
                />
              </Grid>
              </>
              }
            {isBiz !== 'biz' &&
            <>
            <Grid item xs={4}>
                <FormikControl
                  control='input'
                  type='number'
                  label='כניסה'
                  name='entry'
                />
            </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='number'
                  label='קומה'
                  name='floor'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='number'
                  label='דירה'
                  name='apartment'
                />
              </Grid>
              </>
              }
            </Grid>
          }
          <Grid item xs={12}>
              
              <Button
                variant="text"
                color="primary"
                // startIcon={<KeyboardArrowUpIcon/>}
                endIcon={morDetails2 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                onClick={() => setMorDetails2(!morDetails2)}
                
            >
              <Typography>אימייל וטלפונים</Typography>
            </Button>
            </Grid>
            {morDetails2 && 
            <div className='mor_details' id="mor_details2">
               {isBiz === 'biz' &&
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='email'
                  label='אימייל'
                  name='email_biz'
                />
              </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='string'
                  label='טלפון 1'
                  name='phone1_biz'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='טלפון 2'
                  name='phone2_biz'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='טלפון 3'
                  name='phone3_biz'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='פקס'
                  name='fax_biz'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='תיבת דואר'
                  name='po_box_biz'
                />
              </Grid>
              
              
            </Grid>
              }
               {isBiz !== 'biz' &&
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='email'
                  label='אימייל'
                  name='email'
                />
              </Grid>
              <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='string'
                  label='טלפון 1'
                  name='phone1'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='טלפון 2'
                  name='phone2'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='טלפון 3'
                  name='phone3'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='פקס'
                  name='fax'
                />
              </Grid>
              <Grid item xs={6}>
                <FormikControl
                  control='input'
                  type='string'
                  label='תיבת דואר'
                  name='po_box'
                />
              </Grid>
              
              
            </Grid>
              }
            </div>
            }

            {isBiz !== 'biz' &&
            <Grid item xs={12}>
                <FormikControl
                  control='input'
                  type='string'
                  label='קוד לקוח'
                  name='sku_client'
                />
              </Grid>
            }
            {isBiz === 'biz' &&
                <Grid item xs={12}>
                    <FormikControl
                      control='textarea'
                      label='תיאור לעסק'
                      name='description_biz'
                      />
                </Grid> 
      }          
                <Grid item xs={12}>
                  
                  {/* <Button
                    variant="text"
                    color="primary"
                    // startIcon={<KeyboardArrowUpIcon/>}
                    endIcon={morDetails1 ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    onClick={() => setMorDetails1(!morDetails1)}
                    
                >
                  <Typography>פרטי פרופיל משלוחן</Typography></Button> */}
                </Grid>
                {/* {morDetails1 &&  */}
                <div className='mor_details' id="mor_details1">
                {isBiz === 'biz' &&
                <Grid container columnSpacing={1} rowSpacing={1}>
                  {/* <Grid item xs={12}>
                    <FormikControl
                      control='input'
                      type='string'
                      label='שם עסק'
                      name='business_name'
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormikControl
                      control='select'
                      label='בחר קטגוריה לעסק'
                      name='biz_type'
                      options={AllBizTypes}
                    />
                  </Grid>

                  <FormikControl
                    control='radio'
                    // type='radio'
                    label='נא אשר כי הנך קראת את תקנון העסק וכי קראת הכל והינך מאשר את כל הכתוב בה'
                    name='approve_as_professional'
                    options={[
                      { key: 'מאשר', value: 'yes' },
                      { key: 'לא מאשר', value: 'no' },
                    ]}
                    // handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                  />
                </Grid>
                }
                {isBiz !== 'biz' &&
                <Grid container columnSpacing={1} rowSpacing={1}>
                  <Grid item xs={12}>
                    <FormikControl
                      control='input'
                      type='string'
                      label='שם עסק'
                      name='business_name'
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormikControl
                      control='select'
                      label='בחר סוג שירות/רכב'
                      name='car_type'
                      options={AllCarTypes}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormikControl
                      control='select'
                      label='בחר סוג שירות/רכב'
                      name='car_type'
                      options={AllCarTypes}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormikControl
                      control='select'
                      label='נא אשר הצטרפותך לרשימת בעלי המקצוע שלנו'
                      name='approve_as_professional'
                      options={approve_as_professional}
                    />
                  </Grid> */}

                  <FormikControl
                    control='radio'
                    // type='radio'
                    label='נא אשר הצטרפותך לרשימת בעלי המקצוע שלנו'
                    name='approve_as_professional'
                    options={[
                      { key: 'מאשר', value: 'yes' },
                      { key: 'לא מאשר', value: 'no' },
                    ]}
                    // handleSwitchTrueOrFalse={handleSwitchTrueOrFalse}
                  />
                </Grid>
                }
                </div>
                {/* }  */}
            <Grid item xs={6}>
              <Button variant='outlined' type='submit' disabled={!formik.isValid}>
                עדכן פרופיל
              </Button>
            </Grid>           
            <Grid item xs={6}>
                <Typography style={{color: 'green'}}>{sendedMessage}</Typography>
            </Grid>
            </>   
              }        
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditProfile
