import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import classes from './CartItem.module.css';
import { cartActions } from '../../../store/places-slice';

const CartItem = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(props.place)
  },[])
  // const { title, quantity, total, price, id } = props.item;

  // const removeItemHandler = () => {
  //   dispatch(cartActions.removeItemFromCart(id));
  // };
  const handleSelectedLetLng = async (lat,lng) => {
    // console.log("handleSelectedLetLng:", lat,lng)
     dispatch(
      cartActions.replaceselectedLngLat({
        selectedLat: lat,
        selectedLng: lng,
      })
    );
    // await dispatch(
    //   cartActions.replacePenTo({
    //     panToLng: lng,
    //     panToLat: lat,
    //   })
    // );

    // console.log(lat,lng)
  };

  // const addItemHandler = () => {
  //   dispatch(
  //     cartActions.addItemToCart({
  //       id,
  //       title,
  //       price,
  //     })
  //   );
  // };

  return (
    <li className={classes.item}>
      { props.place.place.title !== props.place.place.selectedAddressD.address &&
      <header>
          <h3 style={{fontSize: 13}}>{props.place.place.title} </h3>
      </header>
      }
      {props.place.place.form_purpes === 'd' &&
      <>
        <header>
          <h3 style={{fontSize: 13}}>{props.place.place.selectedAddressD.address} </h3>
        </header>
        <div className={classes.details}>
          <div className={classes.actions}>
            <button className="seeOnMap" data-locationlat={props.place.place.selectedAddressD.location.lat} data-locationlng={props.place.place.selectedAddressD.location.lng} onClick={() => handleSelectedLetLng(props.place.place.selectedAddressD.location.lat, props.place.place.selectedAddressD.location.lng)}>ראה במפה</button>
          </div>
        </div>
      </>
      }
      {props.place.place.form_purpes === 'd&o' &&
      <>
        <header style={{flexDirection: 'column'}}>
            <div style={{fontSize: 12}}>יעד: </div>
            <h3 style={{fontSize: 13}}>{props.place.place.selectedAddressD.address} </h3>
        </header>
        <div className={classes.details}>
          <div className={classes.actions}>
            <button className="seeOnMap" data-locationlat={props.place.place.selectedAddressD.location.lat} data-locationlng={props.place.place.selectedAddressD.location.lng} onClick={() => handleSelectedLetLng(props.place.place.selectedAddressD.location.lat, props.place.place.selectedAddressD.location.lng)}>ראה יעד במפה</button>
          </div>
        </div>

        <header style={{flexDirection: 'column'}}>
            <div style={{fontSize: 12}}>מוצא: </div>
            <h3 style={{fontSize: 13}}>{props.place.place.selectedAddressO.address} </h3>
        </header>
        <div className={classes.details}>
          <div className={classes.actions}>
            <button className="seeOnMap" data-locationlat={props.place.place.selectedAddressO.location.lat} data-locationlng={props.place.place.selectedAddressO.location.lng} onClick={() => handleSelectedLetLng(props.place.place.selectedAddressO.location.lat, props.place.place.selectedAddressO.location.lng)}>ראה מוצא במפה</button>
          </div>
        </div>
      </>
      }
    </li>
  );
};

export default CartItem;