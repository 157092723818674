import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HTTPApi from "i18next-http-backend";
import App from "./App";

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HTTPApi)
    .init({
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        react: { 
        useSuspense: false //   <---- this will do the magic
        }
    })

export default i18next

// on index.js just import this file: import './i18n'
// on every file thar useu i18n we import:
// import { useTranslation } from 'react-i18next';
// and const:
// const {t} = useTranslation()
// and use it in jsx by:
// { t("Email") }
// or:
// {t("Oops! Something's wrong.")}
// all translation files are located in:
// public\locales\en\translation.json