import React, { useState } from 'react';
import { AppBar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexbox } from '@mui/system';

import AppBarMui from './AppBarMui';
import DrawerMui from './DrawerMui';

import AddSomething from './AddSomething';
import ViewSomething from './ViewSomething';


// const useStyles = makeStyles((theme) => {
    
//     return {
//         page: {
//             background: '#f9f9f9',
//             width: '100%',
//             marginTop: '20px'
//         },
//         root: {
//             display: 'flex'
//         },
//         toolbarr: theme.mixins.toolbar
//     }
// })


const Layout = (props) => {
    const [isAppBarMuiOpen, setIsAppBarMuiOpen] = useState(false);
    const [isAddSomethingOpen, setIsAddSomethingOpen] = useState(false);
    const [isViewSomethingOpen, setIsViewSomethingOpen] = useState(false);
    // const classes = useStyles();
    // const drawerWidth = isAppBarMuiOpen === false ? 0 : 240;
    const drawerWidth = isAppBarMuiOpen === false ? 0 : 0;
    const handleOnCLickHamburger = () => {
        setIsAppBarMuiOpen(!isAppBarMuiOpen)
    }
    const handleOnCLickAddSomething = () => {
        setIsAddSomethingOpen(!isAddSomethingOpen)
        setIsViewSomethingOpen(false)
    }
    const handleOnCLickViewSomething = () => {
        setIsViewSomethingOpen(!isViewSomethingOpen)
        setIsAddSomethingOpen(false)
    }
    const handleOnCLickCloseAddAndView = () => {
        setIsViewSomethingOpen(false)
        setIsAddSomethingOpen(false)
    }


    return (
        <div>
            <div>
                <AppBarMui drawerWidth={drawerWidth}
                handleOnCLickHamburger={handleOnCLickHamburger}
                handleOnCLickAddSomething={handleOnCLickAddSomething}
                handleOnCLickViewSomething={handleOnCLickViewSomething}
                />
            </div>
            {isAppBarMuiOpen && <div style={{
                width: '100%',
                height: '1000px',
                background: '#e9e9e9',
                zIndex: '99999',
                position: 'sticky'}}>
                <DrawerMui drawerWidth={drawerWidth} closeOnClickNav={handleOnCLickHamburger} />
                </div> }
            <div style={{marginTop: '66px', display: 'flex', flexDirection: 'column'}}>
            {isAddSomethingOpen && <AddSomething zIndex={'zIndex5'}
                mapCatsDropDown={'mapCats'}
                selectedAddress={'selectedAddressRD'} 
                selectedLat={'selectedLatRD'} 
                selectedLng={'selectedLngRD'}
                handleOnCLickAddSomething={handleOnCLickAddSomething}
                />
            }
            {isViewSomethingOpen && <ViewSomething zIndex={'zIndex5'}
                mapCatsDropDown={'mapCats'}
                selectedAddress={'selectedAddressRD'} 
                selectedLat={'selectedLatRD'} 
                selectedLng={'selectedLngRD'}
                handleOnCLickCloseAddAndView={handleOnCLickCloseAddAndView}
                />
            }
                {props.children}
            </div>
        </div>
    )
};

export default Layout;