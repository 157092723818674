import React, { useContext, useEffect, useState } from "react" 
import { Button, Grid } from '@mui/material';

import Modal from '../../shared/components/UIElements/Modal';
import FormikUiAddContact from '../../shared/components/FormikElements/FormikUiAddContact';
import AddPackagesForTransportation from '../../shared/components/FormikElements/AddPackagesForTransportation';
import FormikControl from '../../shared/components/FormikElements/FormikControl'

function CreateTagRoute(props) {
    const [showMap, setShowMap] = useState(false);
    const [createTagRoute, setCreateTagRoute] = useState(false);

    useEffect(() => {
    if (props.formPurpes === 'location_tag') {
        setCreateTagRoute(true)
    }
    if (props.formPurpes === 'tag_route') {
        setCreateTagRoute(true)
    }
    if (props.formPurpes === 'o_and_d') {
        setCreateTagRoute(true)
    }
    if (props.formPurpes === 'c_o_d') {
        setCreateTagRoute(true)
    }

    }, [])
    

    const openMapHandler = () => setShowMap(true);
    return (
        <>
        {props.formPurpes === 'tag_route' &&
            <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                <Modal
                  show={createTagRoute}
                  onCancel={() => setCreateTagRoute(false)}
                  header={'צור שם מסלול'}
                        closeButton={<Button variant='contained' onClick={() => {
                            setCreateTagRoute(false)
                            props.setChooseTAGBox()
                        }
                        }>סגור</Button>}
                >
                  <Grid item xs={12}>
                    <FormikUiAddContact 
                    // AutoCompleteCFromAddContactHandler={(e) => setAutoCompleteCFromAddContactHandler(e)}
                    typeLocation='contact_o'
                    typeOfForm={['tag_route', 'שם מסלול']}
                    formPurpesTitle={'formPurpes'}
                    zIndex={'zIndex6'}
                    // mapCatsDropDown={{}}
                    selectedAddressD={'selectedAddressD_RD'}
                    selectedAddressO={'selectedAddressO_RD'}
                    />
                  </Grid>
                </Modal>
            </div>
        }
        {props.formPurpes === 'location_tag' &&
            <>
                    <Grid style={{margin: '5px'}}>
                        <Button onClick={() => {
                        setCreateTagRoute(true)
                        // setAddTagRoute(true)
                        openMapHandler()
                        }} variant='outlined'>הוסף כתובת למסלול</Button>
                </Grid>
                
                    {createTagRoute &&
                        <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                        <Modal
                        show={createTagRoute}
                            onCancel={() => {
                                props.setChooseADDBox()
                                setCreateTagRoute(false)
                            }}
                        header={'הוספת כתובת למסלול'}
                            closeButton={<Button variant='contained' onClick={() => {
                                setCreateTagRoute(false)
                                props.setChooseADDBox()
                            }
                            }>סגור</Button>}
                            >
                                {/* { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
            inputes: ['location_d','id_tag_route'] , subject: 'places'}, */}
                            {/* {props.isTagarouteExist === false &&
                                <div>
                                    {'כדי להוסיף כתובת, יש לחבר אותו לאחד משמות המסלולים שיצרת'}
                                    {'בבקשה צור לפחות שם מסלול אחד'}
                                </div>
                            } */}
                        <Grid item xs={12}>
                            <AddPackagesForTransportation 
                                        typeOfForm={['location_tag', 'מיקומים לתווית - לחישוב מסלול', 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי שם מסלול לצורך חישוב מסלול בלבד', ['location_d', 'id_tag_route']]}
                                        submitTextButton='הוסף כתובת למסלול'
                            />
                        </Grid>
                        </Modal>
                        </div>
                    }

            </>
        }
        {props.formPurpes === 'add_coupon' &&
            <>
                    <Grid style={{margin: '5px'}}>
                        <Button onClick={() => {
                        setCreateTagRoute(true)
                        // setAddTagRoute(true)
                        openMapHandler()
                        }} variant='outlined'>הוספת קופון</Button>
                </Grid>
                
                    {createTagRoute &&
                        <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                        <Modal
                        show={createTagRoute}
                            onCancel={() => {
                                props.setChooseADDBox()
                                setCreateTagRoute(false)
                            }}
                        header={'הוספת קופון לעסק שלי'}
                            closeButton={<Button variant='contained' onClick={() => {
                                setCreateTagRoute(false)
                                props.setChooseADDBox()
                            }
                            }>סגור</Button>}
                            >
                                {/* { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
            inputes: ['location_d','id_tag_route'] , subject: 'places'}, */}
                            {/* {props.isTagarouteExist === false &&
                                <div>
                                    {'כדי להוסיף כתובת, יש לחבר אותו לאחד משמות המסלולים שיצרת'}
                                    {'בבקשה צור לפחות שם מסלול אחד'}
                                </div>
                            } */}
                        <Grid item xs={12}>
                            <AddPackagesForTransportation 
                                        typeOfForm={['location_tag', 'מיקומים לתווית - לחישוב מסלול', 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי שם מסלול לצורך חישוב מסלול בלבד', ['location_d', 'id_tag_route']]}
                                        submitTextButton='הוסף קופון '
                            />
                        </Grid>
                        </Modal>
                        </div>
                    }

            </>
        }
                
            {props.formPurpes === 'o_and_d' &&
                <>
                    <Grid style={{margin: '5px'}}>
                        <Button onClick={() => {
                        setCreateTagRoute(true)
                        props.setChooseODBox()
                        // setAddTagRoute(true)
                        openMapHandler()
                        }} variant='outlined'>הוסף מוצא ויעד</Button>
                    </Grid>
                    {createTagRoute &&
                        <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                        <Modal
                        show={createTagRoute}
                         onCancel={() => {
                             props.setChooseODBox()
                             setCreateTagRoute(false)
                            }}
                            header={'הוספת התחלה וסוף למסלול'}
                            closeButton={<Button variant='contained'
                            onClick={() => {
                                setCreateTagRoute(false)
                                props.setChooseODBox()
                                }
                                    
                                }>סגור</Button>}
                            >
                                {/* { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
            inputes: ['location_d','id_tag_route'] , subject: 'places'}, */}
                        <Grid item xs={12}>
                            <AddPackagesForTransportation 
                                        typeOfForm={['o_and_d', 'מוצא ויעד', 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', ['location_o','location_d']]}
                                        submitTextButton='הוסף התחלה וסוף'
                            />
                        </Grid>
                        </Modal>
                        </div>
                    }

                    </>
                //              { title: 'מוצא ויעד', subTitle: 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', value: 'o_and_d',
                // inputes: ['location_o','location_d'] , subject: 'places'},
            }

            {props.formPurpes === 'c_o_d' &&
                <>
                    <Grid style={{margin: '5px'}}>
                        <Button onClick={() => {
                        setCreateTagRoute(true)
                        props.setChooseITEMBox()
                        // setAddTagRoute(true)
                        openMapHandler()
                        }} variant='outlined'>הוספת פריט</Button>
                    </Grid>
                    {createTagRoute &&
                        <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                        <Modal
                            show={createTagRoute}
                            onCancel={() => {
                             props.setChooseITEMBox()
                             setCreateTagRoute(false)
                            }}
                            header={'הוספת פריט הכולל פרטי לקוח, פרטי איסוף ופרטי הורדה'}
                            closeButton={<Button variant='contained'
                            onClick={() => {
                                setCreateTagRoute(false)
                                props.setChooseITEMBox()
                                }
                                    
                                }>סגור</Button>}
                            >
                                {/* { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
            inputes: ['location_d','id_tag_route'] , subject: 'places'}, */}
                        <Grid item xs={12}>
                            <AddPackagesForTransportation 
                                    typeOfForm={['c_o_d', 'הוספת פריט', 'הוספת פריט הכוללת לקוח, מוצא ויעד', ['date_delivery','packs_pallet','packs_bags',
    'packs_cartons','packs_people','description',
        'contact_O_r_D', 'payment', 'vehicle', 'physical_work','id_connected_mover','id_connected_businesses']]}
                                    // { show: true, title: 'הוספת פריט', subTitle: 'הוספת פריט הכוללת לקוח, מוצא ויעד', value: 'c_o_d',
                                    // inputes: ['date_delivery','packs_pallet','packs_bags',
                                    // 'packs_cartons','packs_people','description',
                                    //     'contact_O_r_D', 'payment', 'vehicle', 'physical_work','id_connected_mover','id_connected_businesses'], subject: 'places'
                                    //   }
                                        submitTextButton='הוסף התחלה וסוף'
                            />
                        </Grid>
                        </Modal>
                        </div>
                    }

                    </>
                //              { title: 'מוצא ויעד', subTitle: 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', value: 'o_and_d',
                // inputes: ['location_o','location_d'] , subject: 'places'},
            }


            {props.formPurpes === 'xlsx_address' &&
                <>
                    <Grid style={{margin: '2px'}}>
                        <Button style={{fontSize: '10px', padding: '0px'}} onClick={() => {
                        setCreateTagRoute(true)
                        // setAddTagRoute(true)
                        openMapHandler()
                        }} variant='contained' fontSize='10'>שינוי כתובת</Button>
                    </Grid>
                    {createTagRoute &&
                        <div className='FormikUiAddContactInMape' style={{marginTop: '20px'}}>
                        <Modal
                        show={createTagRoute}
                        onCancel={() => setCreateTagRoute(false)}
                        header={'שינוי כתובת'}
                        closeButton={<Button variant='contained' onClick={() => setCreateTagRoute(false)}>סגור</Button>}
                            >
                                {/* { title: 'מיקומים לתווית - לחישוב מסלול', subTitle: 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', value: 'location_tag',
            inputes: ['location_d','id_tag_route'] , subject: 'places'}, */}
                            <Grid item xs={12}>
                                <div>{`שינוי כתובת עבור: "${props.prevAddress}"`}</div>
                                <FormikControl
                  control='addressInput'
                  addToId={'569'}
                  type='string'
                  label='כתובת אוטומטית'
                  name={`location_d['6845']`}
                  typeLocation='d'
                  specialLabal="כתובת יעד"
                  changed={'changed'}
                  // changeFormikAddressValues={() => {
                  //   changeFormikAddressValues(obj.num-1)
                  //   // updateAddressesFields = (obj.num-1, addessesFields)
                  //   }
                  // }
                  // AddressFromRedux={userProfile.address}
                  updateAddressesFields={props.updateAddressesFields}
                />
                            {/* <AddPackagesForTransportation 
                                        typeOfForm={['location_tag', 'מיקומים לתווית - לחישוב מסלול', 'הוספת מיקום בודד לקבוצת מיקומים מאוגדים על ידי תווית לצורך חישוב מסלול בלבד', ['location_d', 'id_tag_route']]}
                                        submitTextButton='שלח עדכון כתובת'
                            /> */}
                        </Grid>
                        </Modal>
                        </div>
                    }

                    </>
                //              { title: 'מוצא ויעד', subTitle: 'הוספת מוצא ויעד לצורך שימוש בחישוב מסלול', value: 'o_and_d',
                // inputes: ['location_o','location_d'] , subject: 'places'},
            }
        </>
    )
}

export default CreateTagRoute