import React, { useState, useCallback, useRef, Children, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, Typography, Button } from '@mui/material'

import TableData from './CouponsTableData';
var cartItems;
var mapCats;

const Coupons = (props) => {

  cartItems = useSelector((state) => state.cart.items);
  mapCats = useSelector((state) => state.cart.placesCats);
  const [show1, setShow1] = React.useState(0);
  let zIndex0, zIndex1, zIndex2, zIndex3, zIndex4, zIndex5, zIndex6;
  show1 === 0 ? zIndex0 = '99' : zIndex0 = '0';

  return (
      <div>

        {/* <div style={{ width: '100%', display: 'flex',  flexDirection: 'column', alignItems: 'center', marginTop: '15px'}}>
          <Typography variant="h4">קופונים</Typography>
        </div> */}
        
        <div>
          <TableData/>
        </div>
      </div>
  );
};
export default Coupons;