import React, { useState , useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Axios from "axios";
// import $ from 'jquery';
import StoreCatList2 from './StoreCatList2';
import './StoreCatList.css';
import reactDom from 'react-dom';


const StoreCatList = () => {

  const [storeCatList, setStoreCatList] = useState([]);
  useEffect( () => {
    Axios.get("http://localhost:5001/api/store/193/all_cats").then((response) => {
      setStoreCatList(response.data);
    });  
    console.log(storeCatList);
  },[])
  // const [VideoList, setVideoList] = useState([]);
  // useEffect( () => {
  //   Axios.get("http://localhost:5001/api/homepage/video").then((response) => {
  //     setVideoList(response.data);
  //   });  
  //   console.log(VideoList);
  // },[])
  

  // const userId = useParams().userId;
  // const loadedProduct = prodList.filter(place => place.id > 0);
  // $(document).ready(function() {
  //   "use strict";
  //   $('.menu > ul > li:has( > ul)').addClass('menu-dropdown-icon');
  //   $('.menu > ul > li > ul:not(:has(ul))').addClass('normal-sub');
  //   $(".menu > ul").before("<a href=\"#\" class=\"menu-mobile\">&nbsp;</a>");
  //   $(".menu > ul > li").hover(function(e) {
  //     if ($(window).width() > 943) {
  //       $(this).children("ul").stop(true, false).fadeToggle(150);
  //       e.preventDefault();
  //     }
  //   });
  //   $(".menu > ul > li").click(function() {
  //     if ($(window).width() <= 943) {
  //       $(this).children("ul").fadeToggle(150);
  //     }
  //   });
  //   $(".menu-mobile").click(function(e) {
  //     $(".menu > ul").toggleClass('show-on-mobile');
  //     e.preventDefault();
  //   });
  // });
  // $(window).resize(function() {
  //   $(".menu > ul > li").children("ul").hide();
  //   $(".menu > ul").removeClass('show-on-mobile');
  // });
  console.log(storeCatList)
  const cats1 = storeCatList.filter(place => place.parent_id_cat < 1);
  console.log("cats1"+cats1)
 const  storeCatList2 = storeCatList;
    return (

      <div class="menu-container">
          <div class="menu">                            
              <a href="#" class="menu-mobile">&nbsp;</a>
              <ul class="clearfix">
                  <li>
                    <NavLink to="/store" exact className="nav-link align-middle">
                      {/* <a href="udel_store.php"> */}
                          <div class="str_cat_menu_icon">
                              <img src="https://haredimnet.co.il/_images_admin/icons/store_icons/folder.svg" />
                          </div>
                          <div class="str_cat_menu_title">כל הקטגוריות</div>
                      {/* </a> */}
                    </NavLink>
                  </li>
                  {cats1.map((place1,index) => (
                  <li class="menu-dropdown-icon">
                      <NavLink to={`/store/cat/${place1.id}`} exact className="nav-link align-middle">
                      {/* <a href={`?cat=${place1.id}`} class=""> */}
                          <div class="str_cat_menu_icon">
                              <img src={`https://haredimnet.co.il/_images_admin/icons/store_icons/${place1.file_name}`} />
                          </div>
                          <div class="str_cat_menu_title">{place1.name}</div>
                      {/* </a> */}
                      </NavLink>
                      <ul>
                      <StoreCatList2 all_cats={storeCatList} current_cat={place1.id}  />
                      </ul>
                  </li>
                  ))}
                  
                  
                  
              </ul>
          </div>
      </div>



    )
    
};


export default StoreCatList;
