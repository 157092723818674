import React, { useEffect, useState } from "react"

import RouteIcon from '@mui/icons-material/Route';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { useLang } from '../shared/hooks/lang-hook';


import HomeBoxItem from "./HomeBoxItem"
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";

// const buttonStepForum_numbrer = {
//   borderRadius: '30px',
//   width: '30px',
//   height: '30px',
//   padding: '4px 2px 0px 0px',
//   background: '#1976d2',
//   color: '#fff',
//   fontWeight: '700',
//   margin: '4px 0px 5px 8px',
// }

const instructions = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    background: 'rgb(242, 242, 242)',
    color: 'rgb(157, 157, 157)',
    flexDirection: 'column'
}
const instruction = {
    display: 'flex',
    marginTop: '15px',
    flexDirection: 'column',
    // alignItems: 'center',
    background: '#fff',
    padding: '58px',
    marginBottom: '11px',
    width: '374px'
}
const buttonStyleWithHoverTAG = {
    fontSize: '34px', 
    padding: '1px',  
    margin: '2px 0px 0px',
    backgroundColor: 'rgb(237 237 237)',
    color: '#1976d2',
    borderRadius: '25px',
    cursor: 'pointer'
}
const buttonStepForum_text = { fontSize: '18px' }

function Home(props) {    
  const { currentLang, ltrOrRtl, trnslt } = useLang();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

    return (
      <>
          <div style={{background: '#1976d2', color: '#fff',
          padding: windowSize[0] > 950 ? '59px 0px 50px 0px' : '29px',
          width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{fontSize: '24px', marginBottom: '0px'}}>לטייל בעולם נהיה קל</div>
            <div style={{display: "flex", flexDirection: 'column', justifyContent: "center", fontSize: "12px", padding: "13px 13px", textAlign: "center", color: '#000', background: '#fff',
    margin: '19px 0px', borderRadius: '5px', boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.2)'}
              }>
              <div style={{ margin: '12px', fontSize: '16px' }}>{trnslt("פתח חשבון חינם והתחל לטייל בעולם")}</div>
                <div style={{ margin: '0' }}>
                    <NavLink exact to={'/auth'} style={{ textDecoration: 'none'}} width='250px'>
                            <Button variant='contained' style={{ width: ltrOrRtl("243px", "250px"), background: '#00376c' }} נ> {trnslt("create account / connect")} </Button>
                    </NavLink>
                </div>
            </div>
          </div>
          <div style={{
            padding: '19px',
            width: '100%', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'}}>
              {/* <div style={{fontSize: '16px', 
              marginBottom: windowSize[0] > 950 ? '27px' : '12px', 
              marginTop: windowSize[0] > 950 ? '110px' : '30px',
              textAlign: 'center'}}>צור מסלול לטיול</div> */}
              <div style={{fontSize: '16px', marginTop: windowSize[0] > 950 ? '40px' : '30px', marginBottom: '27px',textAlign: 'center'}}>הוסף כתובת</div>
              <div style={{margin: windowSize[0] > 950 ? '38px 0px 124px 0px' : '0px 0px 44px'}}>
                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="AddLocationIcon" 
                style={{
                  filter: 'drop-shadow(3px 5px 1px rgb(3 0 3 / 0.9))',
                  fontSize: '117px',
                  color: 'rgb(15, 61, 107)',
                  boxShadow: 'rgb(11 73 135) 1px 2px 0px 1px',
                  background: '#faebd700',
                  borderRadius: '127px',
                  padding: '15px',
                }}>
                  <path d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm4 8h-3v3h-2v-3H8V8h3V5h2v3h3v2z"
                  style={{boxShadow: 'rgb(11, 73, 135) 0px 2px 20px 12px, rgba(255, 255, 255, 0.53) 0px 4px 5px 0px, rgb(255, 255, 255) 0px 1px 10px 0px'}}></path>
              </svg>
            </div>
          </div>
          <div style={{background: '#1976d2', color: '#fff', padding: '19px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{fontSize: '16px', marginBottom: '27px', textAlign: 'center'}}>קבל מסלולים לפי קירבה ולפי עיר או צור מסלולים בעצמך</div>
              <img 
                  style={{    
                    maxWidth: '294px',
                    maxHeight: '100%',
                    marginBottom: '30px',
                    boxShadow: '0px 2px 20px 12px rgb(11 73 135), 0px 4px 5px 0px rgb(255 255 255 / 53%), 0px 1px 10px 0px rgb(255 255 255)',
                    borderRadius: '4px'
                  }}
                      src={process.env.PUBLIC_URL + "/routes_section.jpeg"}
                      alt="drive abd ship"
              />
          </div>
          <div style={{background: 'rgb(0 52 103)', color: '#fff', padding: '19px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{fontSize: '24px', marginBottom: '15px'}}>וגם הטבות על הדרך</div>
            <div style={{fontSize: '16px', marginBottom: '27px', textAlign: 'center'}}>מערכת הקופונים שלנו לא ישאירו אותך בלי הטבות במחירים אטרקטיביים בכל מסלול שתסע</div>
              <img 
                  style={{    
                    maxWidth: '294px',
                    maxHeight: '100%',
                    marginBottom: '30px',
                    boxShadow: '0px 2px 20px 12px rgb(11 73 135), 0px 4px 5px 0px rgb(255 255 255 / 53%), 0px 1px 10px 0px rgb(255 255 255)',
                    borderRadius: '4px'
                  }}
                      src={process.env.PUBLIC_URL + "/coupons_section.jpeg"}
                      alt="drive abd ship"
              />
          </div>
          <div style={{background: 'rgb(0 17 35)', color: '#fff', padding: '19px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{fontSize: '24px', marginBottom: '15px'}}>מערכת תמונות לפי מיקומים ומסלולים</div>
            <div style={{fontSize: '16px', marginBottom: '27px', textAlign: 'center'}}>אחרי שמסיימים טיול ולאחר 10 שנים תדע תמיד איפה ומה צילמת. הכל מקוטלג לפי מיקומים ומסלולים בהם היית</div>
              <img 
                  style={{    
                    maxWidth: '294px',
                    maxHeight: '100%',
                    marginBottom: '30px',
                    boxShadow: '0px 2px 20px 12px rgb(11 73 135), 0px 4px 5px 0px rgb(255 255 255 / 53%), 0px 1px 10px 0px rgb(255 255 255)',
                    borderRadius: '4px'
                  }}
                      src={process.env.PUBLIC_URL + "/images_section.jpeg"}
                      alt="drive abd ship"
              />
          </div>

        </>
        )
}

export default Home