import React from "react" 
import { useLang } from '../../shared/hooks/lang-hook';


const buttonStyleWithHoverTAG = {
    fontSize: '34px', 
    padding: '1px',  
    margin: '2px 0px 0px',
    backgroundColor: 'rgb(237 237 237)',
    color: '#1976d2',
    borderRadius: '25px',
    cursor: 'pointer'
}
  
const buttonStepForum_numbrer = {
  borderRadius: '30px',
  width: '30px',
  height: '30px',
  padding: '4px 2px 0px 0px',
  background: '#1976d2',
  color: '#fff',
  fontWeight: '700',
  margin: '4px 0px 5px 8px',
}
const instruction = {
    display: 'flex',
    marginTop: '15px',
    flexDirection: 'column',
    // alignItems: 'center',
    background: '#fff',
    padding: '58px',
    marginBottom: '11px',
    width: '374px',
    borderLeft: '4px solid #1976d28f',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
}
const buttonStepForum_text = { fontSize: '18px' }


function Instructions(props) {
const { currentLang, ltrOrRtl, trnslt } = useLang();

    
{/* <Instructions
    titleNumber='2'
    title='הוספת כתובות למסלול'
    icon={<AddLocationIcon style={buttonStyleWithHoverTAG} />}
    textExpln_1='לחצו על כפתור זה'
    textExpln_2='הוסף'
    textExpln_3='כתובת'
    textExpln_4='בחרו את שם המסלול שיצרתם זה עתה'
    textExpln_5='והוסיפו עשרות או מאות כתובות'
/> */}
    

    return (
            <div className="instruction" style={instruction}>
                <div style={{display: 'flex', marginBottom: '19px'}}>
                <div><div style={buttonStepForum_numbrer} >{props.titleNumber}</div></div>
                <div style={{ fontSize: '23px', padding: '3px', color: '#474747' }}>{props.title}</div>
                </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flexStart', textAlign: `${ltrOrRtl('left', 'right')}` }}>
                {props.icon &&
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <div style={buttonStepForum_text}>{props.textExpln_1}</div>
                        <div style={{ margin: '0px 10px', border: '1px solid #e1e1e1', borderRadius: '5px', padding: '3px' }}>
                            <div style={{ margin: '7px 0px 4px 0px', textAlign: 'center', fontSize: '11px' }}>
                                <div>{props.textExpln_2}</div>
                                <div>{props.textExpln_3}</div>
                            </div>
                            
                            {props.icon}
                        </div>
                    </div>
                }
                
                    {props.textExpln_4 &&
                        <div style={buttonStepForum_text}>{props.textExpln_4}</div>
                    }
                    {props.textExpln_5 &&
                        <div style={buttonStepForum_text}>{props.textExpln_5}</div>
                    }
                </div>
            </div>
    )
}

export default Instructions